
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { BaseDesc, ListData } from '../models/base';
import { ListItem } from '../models/list-item';
import { SecurityControl, AccessLevel } from '../models/security-control';
import { Util } from '../utils/utils.module';
import { SchemaDef } from './schema.service';
import { LookupService } from '../services/lookup.service';
import { LocalizeService } from '../services/localize.service';
import { DataService } from '../services/data.service';

@Injectable()
export class ListService {
  private desc: BaseDesc;
  private data: ListData = {set:{},list:[]};
  public start = 0;
  public max: number;
  public descending: string = null;
  public ascending: string = null;
  public filter: string = null;
  public query: string = null;

  constructor(private lookupService: LookupService, private localizer: LocalizeService, private dataService: DataService) {
    this.max = Util.RestAPI.getDefualtMaxItems();
  }

  private checkSortParams(): void {
    if (this.desc && ((this.desc.id.length===0 && this.desc.type==='searches') || (this.desc.type==='fileplans'))) {
      const sortColumnsForSearches: string[] = ['DESCRIPTION', 'SYSTEM_ID', 'DOCNAME', 'LAST_EDIT_DATE'];
      if (sortColumnsForSearches.indexOf(this.ascending) < 0) {
        this.ascending = null;
      }
      if (sortColumnsForSearches.indexOf(this.descending) < 0) {
        this.descending = null;
      }
    }
  }

  private setDefaultSecurity(): void {
    const author: string = this.desc['AUTHOR_ID'];
    const user: string = Util.RestAPI.getUserID();
    const list = this.addAuthorTypistAsTrustee(author, user);
    if (!!list) {
      this.data.list = list;
      this.data.set = { max: 25, ascending: 'USER_ID', filter: {}, start: 0, descending: null, total: list.length };
    }
  }

  private setProfileDefaultTrustees(): Promise<any> {
    return Util.RestAPI.getProfileDefaultTrustees(this.desc['formName'], this.desc['appId'], this.desc).then((defaultTrustees: any[]) => {
      if (defaultTrustees && this.data && this.data.list) {
        for (const trustee of defaultTrustees) {
          const trusteeID = trustee['USER_ID'];
          if (!!trusteeID && (this.data.list.filter(d => d['USER_ID'] === trusteeID).length) === 0) {
            this.data.list.push(trustee);
            this.data.set.total += 1;
          }
        }
      }
      return this.data;
    });
  }

  public getListData(desc: BaseDesc, params: string, forceReload: boolean=false, ascending?: string, descending?: string, start: number=0, max?: number, postData?: any, initialList?: any): Promise<ListData> {
    if (!forceReload && (this.data.list.length && this.desc===desc && this.ascending===ascending && this.descending===descending && this.start===start && (!max || (this.max===max && this.data.list.length>=(this.max-this.start))))) {
      return Promise.resolve(this.data);
    }
    if (max) {
      this.max = max;
    }
    this.start = start;
    this.desc = desc;
    this.ascending = ascending;
    this.descending = descending;
    this.data = {set:{},list:[]};
    this.checkSortParams();
    if (!desc || (!desc.type && params!=='security') || desc.id===undefined || (!Util.RestAPI.isLoggedIn() && (!Util.isSharedDownloads(this.desc) || !Util.isSharedImports(this.desc)))) {
      return Promise.resolve(this.data);
    }
    if ((desc['edx_selected_security_choice']==='1' || desc.id==='0') && params==='security') {
      if (initialList) {
        // Restore previously populated trustee list for a new document
        this.data.list = initialList;
        this.data.set.total = this.data.list.length;
        return Promise.resolve(this.data);
      } else if (desc.id==='0') {
        if (desc.type === 'mass_profile') {
          desc.type = '';
          this.data.list = [];
          this.data.set.total = 0;
          return Promise.resolve(this.data);
        } else {
          this.setDefaultSecurity();
          // set profile default trustees(if any) when creating a new document.
          return Promise.resolve(this.setProfileDefaultTrustees());
        }
      }
    }
    if (Util.isSharedDownloads(this.desc) || Util.isSharedImports(this.desc)) {
      return Util.RestAPI.getDownloadsList(Util.isSharedImports(this.desc)).then(list => {
        this.data.list = list;
        this.data.set.total = this.data.list.length;
        this.data.set.rights = 95;
        return this.data;
      });
    }
    let filter = this.dataService.getFilters(this.desc);
    if (!!filter && (Util.RestAPI.canHaveFilters(this.desc)) && Object.keys(filter).length > 0) {
      filter = Object.keys(filter).map(key => key + '=' + filter[key]).join(' and ');
    } else {
      filter = null;
    }
    this.filter = this.filter ? this.filter : filter;
    if (this.desc.type === 'folders' && this.desc.id === 'recentedits') {
      const configRedStr: string = Util.RestAPI.getPreference('$edx_configred');
      if (!!configRedStr) {
        const kItemTypeMap = {documents:'D', folders:'F', emails:'E', paper:'M', fileplans:'P'};
        const configRed = JSON.parse(configRedStr);
        const keys = Object.keys(configRed);
        let redFilters = 'ITEM_TYPE=';
        let sep = '';
        for (const key of keys) {
          if (!!configRed[key]) {
            redFilters += sep + kItemTypeMap[key];
            sep = ',';
          }
        }
        if (!!this.filter) {
          this.filter += ' and ' + redFilters;
        } else {
          this.filter = redFilters;
        }
      }
    }
    let queryargs: string = 'start='+this.start+'&max='+this.max + (this.ascending?('&ascending='+this.ascending):'') + (this.descending?('&descending='+this.descending):'') + (this.filter?('&filter='+this.filter):'');
    if (postData && postData.$edx_extra_query) {
      queryargs += postData.$edx_extra_query;
      delete postData.$edx_extra_query;
    }
    if (this.query) {
      queryargs += this.query;
      this.query = null;
    }
    this.filter = null;
    //Check for post or get request
    const promiseRequest: Observable<ListData> = postData ? Util.RestAPI.post(desc, postData, params, queryargs) : Util.RestAPI.get(desc,params,queryargs);
    return promiseRequest.toPromise().then((listData: ListData) => {
      if (!!listData) {
        this.data = listData;
        if (params === 'security') {
          if (this.data.set.security[':SEC_REASON_LINK ']) {
            this.desc['SECURITY'] = this.data.set.security[':SEC_REASON_LINK '];
          }
          if (this.data.list.length===0) {
            this.setDefaultSecurity();
          } else {
            this.data.set.total = this.data.list.length;
          }
        } else if (params === 'references') {
          this.data.list = this.data.list.filter((item, index, self) => self.findIndex(objSelf => objSelf.DOCNUM === item.DOCNUM && objSelf.lib === item.lib) === index);
          this.data.list.splice(0, 0, {id:'',type:'libraries',lib:this.desc.lib,APP_ID:'',DOCNAME:this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.LIBRARY')});
          this.data.set.total = this.data.list.length;
        } else if (params === 'versions' || params === 'attachments') {
          this.data.list.every(item => item['id'] = item['DOCNUMBER']);
        }
      } else {
        this.data.set = {};
        this.data.list = [];
      }
      return this.data;
    }, err => {
      this.data.set = {};
      this.data.list = [];
      return this.data;
    });
  }

  public getFacets(desc: BaseDesc, postData: any, queryargs: string): Observable<any> {
    const params = 'facets';
    if (!desc || !desc.type || desc.id===undefined) {
      return observableOf(null);
    }
    // Check for post or get request
    const promiseRequest: Observable<any> = postData ? Util.RestAPI.post(desc, postData, params, queryargs) : Util.RestAPI.get(desc, params, queryargs);
    return promiseRequest;
  }

  public clearListData(): void {
    this.data.set = {};
    this.data.list = [];
    this.start = 0;
  }

  public addAuthorTypistAsTrustee(author: string, typist: string): any[] {
    const list: any[] = [{ flag: 2, USER_ID: typist, rights: AccessLevel.ACCESS_LEVEL_CREATOR }];
    if ((author && author.length) && (author.toUpperCase() !== typist.toUpperCase())) {
      list.push({ flag: 2, USER_ID: author, rights: AccessLevel.ACCESS_LEVEL_CREATOR });
    }
    return list;
  }

  public openItem(listItem: ListItem): void {
    let docName: string;
    if (listItem.type === 'activities' && !isNaN(parseInt(listItem['ACTIVITY_TYPE']))) {
      docName = this.localizer.getTranslation('HISTORY_ACTIONS.' + parseInt(listItem['ACTIVITY_TYPE']));
    } else {
      docName = listItem.DOCNAME;
    }
    if (listItem.type==='urls') {
      let address: string = listItem['URL_ADDRESS'];
      if (address && address.length) {
        if (address.indexOf(':')===-1) {
          if (address.indexOf(' ')===-1) {
            address = 'http://' + address;
          } else {
            address = 'search:' + address;
          }
        }
        Util.Help.openURL(address, docName);
      }
    } else if (listItem.lib && Util.isExternalLib(listItem.lib) && !!(listItem as any).url) {
      Util.Help.openURL((listItem as any).url, docName);
    } else {
      let param: string;
      let pathName: string;
      let queries: string = 'name='+Util.RestAPI.encodeChildRouteName(docName);
      if (listItem.imgPath) {
        queries += '&imgPath='+Util.RestAPI.encodeChildRouteName(listItem.imgPath);
      }
      if (Util.isContainer(listItem.type)) {
        if (listItem.type === 'searches' && listItem['NODE_TYPE'] === '%DV_SEARCH') {
          const searchId: string = listItem.id;
          if (searchId.indexOf(';')!==-1) {
            listItem.id = searchId.replace(';',',');
          }
        }
        param = null;
        pathName = listItem.type;
        queries += '&max='+this.max;
        Util.RestAPI.setCurDesc(listItem);
      } else {
        param = 'profile';
        pathName = 'profile';
      }
      const url = Util.RestAPI.makeChildRouteURL('home', 'tcc_outlet', pathName, listItem, param, queries);
      Util.RestAPI.navToURL(url);
    }
  }

  public openMetadata(listItems: ListItem[], set: any, securityControl: SecurityControl, selectedItem?: ListItem, tab?: string, queryArgs?: string): void {
    const listItem: ListItem = selectedItem? selectedItem : listItems[0];
    const name: string = Util.RestAPI.encodeChildRouteName(this.localizer.getTranslation('FOLDER_ACTIONS.PROFILE'));
    let query: string = 'name='+name + (!!tab ? '&tab='+tab : '') + (!!queryArgs ? queryArgs : '');
    const childRoute = /*Util.Device.isTabletLook() ? 'splitview' : */'metadata';

    if (listItem.imgPath) {
      query += '&imgPath='+Util.RestAPI.encodeChildRouteName(listItem.imgPath);
    }
    if (!!securityControl && securityControl.access !== AccessLevel.ACCESS_LEVEL_FULL) {
      query += '&rights=' + securityControl.access;
    }
    if (!!selectedItem) {
      query += '&id=' + selectedItem.id + '&type=' + selectedItem.type + '&lib=' + selectedItem.lib  + '&appid=' + encodeURIComponent(selectedItem.APP_ID)  + '&docname=' + encodeURIComponent(selectedItem.DOCNAME);
    }
    Util.RestAPI.setCurDesc(this.desc);
    Util.RestAPI.setCurList(listItems, set);
    Util.RestAPI.setCurItem(selectedItem);
    const url = Util.RestAPI.makeChildRouteURL('home', 'tcc_outlet', childRoute, listItem, 'profile', query);
    Util.RestAPI.navToURL(url);
  }

  public getLookupList(id: string, lib: string, profile: string, schemaDef: SchemaDef, key: string, filter?: string): Promise<any> {
    return this.lookupService.getList(id, lib, profile, schemaDef, key, filter).then(data => {
      this.data = data;
      return this.data;
    });
  }
}
