<div class="window-modal-preferences window-modal" [ngClass]="{web:ui==0, desktop:ui==1, mobile:ui!=0&&ui!=1, phone:ui==2||ui==4, oai:isOfficeAddin}">
  <edx-window-modal-header *ngIf="ui!==2 && ui!==4" [title]="getName()" [desc]="desc" [rightBtn]="showSave?saveStr:showReset?resetStr:null" [showCancel]="showSave" (rightHdrBtnClick)="rightHdrBtnClick()" (cancelHdrBtnClick)="cancelHdrBtnClick()"></edx-window-modal-header>
  <ng-template [ngIf]="!showSpinner">
    <div class="tabcontainer">
      <edx-tab-selector #tabSelector [receiver]="this" [tabdefs]="tabList" [disable]="showSave"></edx-tab-selector>
    </div>
    <div [ngSwitch]="tabId" class="contentpanel">
      <div *ngSwitchCase="'general'" class="formcontainer">
        <edx-form-wrapper #formWrapper [kind]="'__local_preferences_general'" [desc]="desc" [formDialog]="this" (okEnabled)="okEnabled($event)"></edx-form-wrapper>
      </div>
      <div *ngSwitchCase="'libraries'">
        <div class="loginlibchooser">
          <label for="loginchoosersel">{{forLoginLibStr}}</label>
          <edx-select [id]="'loginchoosersel'" name="loginchoosersel" [items]="loginLibSelItems" [value]="primaryLib" (change)="logInlibChanged($event)"></edx-select>
          <div class="note">{{resetNote}}</div>
        </div>
        <div class="liblistcontainer">
          <edx-list-table *ngIf="ui<2" [schemaId]="'LIBRARY_MATRIX'" [parent]="this" [desc]="desc" [hasFootprint]="true"></edx-list-table>
          <edx-list-mobile *ngIf="ui>=2" [schemaId]="'LIBRARY_MATRIX'" [parent]="this" [desc]="desc" [hasFootprint]="true" [schemaLayout]="true"></edx-list-mobile>
        </div>
      </div>
      <div *ngSwitchCase="'tiles'" class="formcontainer">
        <div class="resetwrapper">
          <label for="reset_tiles">{{forPrimaryStr}}</label>
        </div>
        <edx-form-wrapper #formWrapper [kind]="'__local_preferences_tiles'" [desc]="desc" [formDialog]="this" (okEnabled)="okEnabled($event)"></edx-form-wrapper>
      </div>
      <div *ngSwitchCase="'profile_defaults'" class="listcontainer">
        <ng-template [ngIf]="ui===2 || ui===4">
          <div class="selwrapper">
            <edx-select [items]="libSelItems" [value]="!!desc?desc.lib:''" (change)="libChanged($event)"></edx-select>
            <edx-select [items]="appIDSelItems" [value]="curAppID" (change)="appIDChanged($event)"></edx-select>
          </div>
          <div class="listwrapper">
            <edx-list-mobile [schemaId]="'APP_ID_FORMS'" [parent]="this" [desc]="desc" [schemaLayout]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this"></edx-list-mobile>
          </div>
        </ng-template>
        <ng-template [ngIf]="ui!==2 && ui!==4">
          <div class="left">
            <div class="selwrapper">
              <div class="sellabel">{{forLibStr}}</div>
              <edx-select [items]="libSelItems" [value]="!!desc?desc.lib:''" (change)="libChanged($event)"></edx-select>
            </div>
            <edx-filelist-sidebar [receiver]="this" [list]="appIDItems" [selectIndex]="curAppIDIndex" [desc]="desc" [disabled]="disabledNav"></edx-filelist-sidebar>
          </div>
          <div class="right">
            <ng-template [ngIf]="ui<2">
              <edx-list-table [schemaId]="'APP_ID_FORMS'" [parent]="this" [desc]="desc" [inlineActionMenuId]="6" [inlineActionTarget]="this"></edx-list-table>
            </ng-template>
            <ng-template [ngIf]="ui>=2">
              <edx-list-mobile [schemaId]="'APP_ID_FORMS'" [parent]="this" [desc]="desc" [schemaLayout]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this"></edx-list-mobile>
              <edx-action-bar *ngIf="cbChecked" [menuID]="6" [target]="this" id="edx_list_action_menu"></edx-action-bar>
              </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
  <edx-spinner *ngIf="showSpinner"></edx-spinner>
</div>
