/**
 * Created by kevin on 2016-09-30.
 *
 * Menu item model; not a server object, but can be created from JSON
 *
 */
import { TabItem } from './tab-item';

export interface MenuItemDesc {
  separator?: boolean;        // item is a separator
  iconic?: boolean;           // item is icon-only
  icon?: string;              // icon image filename
  submenu?: number;           // menuId of submenu (if present, item is a submenu)
  name?: string;              // raw string value or localization key
  nameargs?: string[];        // array of default args to pass to the localizer for the name
  checked?: boolean;          // check state; no checkmark if not present
  enabled?: boolean;          // menu item enable state
  cmd?: string;               // command identifier
  right?: boolean;            // float right in actionbar
  button?: boolean;           // item is a button
  heading?: boolean;          // item is a heading, must be first
  segments?: TabItem[];       // item is an iconic segment control
}

export class MenuItem {
  separator: boolean;
  iconic: boolean;
  icon: string;
  submenu: number;
  name: string;
  nameargs: string[];
  checkable: boolean;
  checked: boolean;
  enabled: boolean;
  cmd: string;
  right: boolean;
  button: boolean;
  heading: boolean;
  segments: TabItem[];

  // construct from JSON or copy
  constructor(desc?: MenuItemDesc, srcItem?: MenuItem) {
    if (srcItem) {
      this.separator = srcItem.separator;
      this.iconic = srcItem.iconic;
      this.icon = srcItem.icon;
      this.submenu = srcItem.submenu;
      this.name = srcItem.name;
      this.nameargs = srcItem.nameargs;
      this.checked = srcItem.checked;
      this.checkable = srcItem.checkable;
      this.enabled = srcItem.enabled;
      this.cmd = srcItem.cmd;
      this.right = srcItem.right;
      this.button = srcItem.button;
      this.heading = srcItem.heading;
      this.segments = srcItem.segments;
    } else {
      if (!desc) {
        desc = {};
      }
      this.separator = desc.separator || false;
      this.iconic = desc.iconic || false;
      this.icon = desc.icon || '';
      this.submenu = desc.submenu || 0;
      this.name = desc.name || '';
      this.nameargs = desc.nameargs || null;
      this.checked = desc.checked || false;
      this.checkable = (typeof desc.checked === 'undefined') ? false : true;
      this.enabled = (typeof desc.enabled === 'undefined') ? true : desc.enabled;
      this.cmd = desc.cmd || '';
      this.right = desc.right || false;
      this.button = desc.button || false;
      this.heading = desc.heading || false;
      this.segments = desc.segments || null;
    }
  }
}
