/**
 * Created by kevin on 2016-12-12.
 *
 * The contents of the hero tile
 */

import { Component, Input } from '@angular/core';
import { LocalizeService } from '../services/localize.service';
import { Util, UserInterface } from '../utils/utils.module';
import { ListItem } from '../models/list-item';
import { ListData } from '../models/base';
import { ListService } from '../services/list.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'edx-hero-tile',
  styleUrls: ['hero-tile-component.scss'],
  template: `
  <div class="main">
    <edx-refresher *ngIf="ui==3 || ui==5" id="edx-hero-refresh" (refresh)="refresh()" [name]="reloadStr"></edx-refresher>
    <div class="header welcome">{{welcomeHeader}}</div>
    <div class="header user-name" title="{{userName}}">{{userName}}</div>
    <div>
      <div class="user-info">
        <div><label>{{userIDGuide}}</label><span>{{userID}}</span></div>
        <div><label>{{userLibraryGuide}}</label><span>{{userLibrary}}</span></div>
        <div><label>{{userGroupGuide}}</label><span>{{userGroup}}</span></div>
      </div>
      <div class="recent-item">
        <div *ngIf="recentButton" class="heading">{{recentButton.header}}</div>
        <div *ngIf="recentButton" class="iconicbutton" (click)="doButton(recentButton.cmd, recentButton.desc)">
          <img src="{{recentButton.icon}}" alt="{{iconAltTextFromDesc(recentButton.desc)}}">
          <img *ngIf="displayMiniStatus(this.recentButton.desc)" class="overlaystatusicon" src="{{formatStatusIcon(this.recentButton.desc)}}" alt="{{formatStatusText(this.recentButton.desc)}}">
          <span>{{recentButton.label}}</span>
        </div>
      </div>
      <div>
        <div *ngIf="checkedOutButton" class="section checked-out">
          <div class="heading">{{checkedOutButton.header}}</div>
          <div class="iconicbutton" (click)="doButton(checkedOutButton.cmd)">
            <img src="{{checkedOutButton.icon}}" alt="{{this.localizer.getTranslation('DOC_STATUS.CHECKEDOUT')}}"><span>{{checkedOutButton.label}}</span>
          </div>
        </div>
        <div *ngIf="uploadButton" class="section upload" [ngClass]="{dragover:showDragOver}" (click)="doButton(uploadButton.cmd)">
          <div class="heading">{{uploadButton.header}}</div>
          <div class="upload-icon"></div>
        </div>
      </div>
    </div>
  </div>
  `
})
export class HeroTileComponent {
  private app: AppComponent;
  public ui: UserInterface = Util.Device.ui;
  public reloadStr: string;
  public welcomeHeader: string;
  public userName: string;
  public userID: string;
  public userLibrary: string;
  public userGroup: string;
  public userIDGuide: string;
  public userLibraryGuide: string;
  public userGroupGuide: string;
  public recentButton: HeroButton;
  public checkedOutButton: HeroButton;
  public uploadButton: HeroButton;
  @Input() showDragOver = false;

  constructor(protected listService: ListService, private localizer: LocalizeService) {
    this.app = Util.RestAPI.getAppComponent();
    this.userName = Util.RestAPI.getUserFullName();
    this.userID = Util.RestAPI.getUserID();
    this.userLibrary = Util.RestAPI.getPrimaryLibrary();
    this.userGroup = !!Util.RestAPI.getLoginReply()['GROUP'] ? Util.RestAPI.getLoginReply()['GROUP'] : Util.RestAPI.getLoginReply()['GROUPS'] && Util.RestAPI.getLoginReply()['GROUPS'].length ? Util.RestAPI.getLoginReply()['GROUPS'][0]['GROUP_ID'] : '';
    this.welcomeHeader = this.localizer.getTranslation('HERO.WELCOME');
    this.userIDGuide = this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.USERID');
    this.userLibraryGuide = this.localizer.getTranslation('NAVBAR.PRIMARY');
    this.userGroupGuide = this.localizer.getTranslation('NAVBAR.PRIMARY_GROUP');
    this.reloadStr = this.localizer.getTranslation('HERO.RELOAD_TILES');
    this.reloadList();
    if (Util.RestAPI.canUserCreateDocument()) {
      this.uploadButton = {
        label: '',
        cmd: 'upload',
        header: this.localizer.getTranslation('HERO.SECTIONS.UPLOAD_FILES')
      };
    }
  }

  public reloadList(): void {
    //fetch the last edited DOCUMENT only
    Util.RestAPI.get('folders', 'recentedits', 'max=1&descending=%RECENTACTIVITYDATE&filter=ITEM_TYPE=D and STORAGE=A,T and STATUS=0%7C3').subscribe((listData: ListData) => {
      const desc: ListItem = listData.list && listData.list.length ? listData.list[0] : null;
      if (!!desc) {
        this.recentButton = {
          label: desc.DOCNAME,
          icon: Util.Transforms.iconUrlFromDesc(desc),
          cmd: 'lastopened',
          value: +desc.id,
          desc,
          header: this.localizer.getTranslation('HERO.SECTIONS.RECENT')
        };
      }
    }, err => { });
    Util.RestAPI.get('folders', 'checkedout', 'start=0&max=0&descending=LAST_EDIT_DATE').subscribe((listData: ListData) => {
      this.checkedOutButton = {
        label: listData.set.total > 0 ? this.localizer.getTranslation('HERO.SECTIONS.ITEMS_COUNT', [listData.set.total]) : this.localizer.getTranslation('HERO.SECTIONS.NO_ITEMS'),
        icon: 'assets/images/title_checked_out36.svg',
        cmd: 'checkedout',
        value: listData.set.total,
        header: this.localizer.getTranslation('HERO.SECTIONS.CHECKED_OUT')
      };
    }, err => { });
  }

  private refresh(): void {
    Util.RestAPI.reloadTiles();
  }

  private setupButtons(buttons: HeroButton[]) {
    for (const button of buttons) {
      if (button.icon) {
          button.icon = 'assets/images/' + button.icon;
        }
      if (button.label) {
        button.label = this.localizer.getTranslation(button.label);
      }
    }
  }

  private doButton(cmd: string, desc?: ListItem): void {
    switch (cmd) {
      case 'lastopened':
        this.openLastEditedItem(desc);
        break;
      case 'checkedout':
        this.app.doCommand('checkedout');
        break;
      case 'upload':
        this.app.doCommand('newdocument');
        break;
    }
  }

  private openLastEditedItem(listItem: ListItem): void {
    if (listItem) {
      if (Util.isContainer(listItem.type)) {
        this.listService.openItem(listItem);
      } else {
        const isCheckedOut: boolean = listItem['STATUS'] === 3;
        const checkOutDate: string = isCheckedOut && listItem['checkout'] ? listItem['checkout']['CHECKOUT_DATE'] : '';
        const checkOutName: string = isCheckedOut && listItem['checkout'] ? listItem['checkout']['TYPIST_ID'] : '';
        const isCheckedOutByMe: boolean = checkOutName.toUpperCase() === Util.RestAPI.getUserID();
        const isCheckoutNeeded: boolean = Util.RestAPI.hasAutoCheckoutCheckin();
        const version = 'C';
        const path: string = Util.RestAPI.hasPFTA() && isCheckoutNeeded ? 'temp' : null;
        const notifyBody: string = this.localizer.getTranslation('FORMS.LOCAL.OPEN.ERROR_OPEN', [listItem.DOCNUM, listItem.DOCNAME, checkOutName, Util.Transforms.formatDate(checkOutDate)]);
        if (!isCheckedOut) {
          Util.RestAPI.checkoutDocument(listItem, version).then(success => {
            if (success) {
              Util.RestAPI.viewFile(listItem, version, null, path, false, true);
            } else {
              Util.Notify.warning(this.localizer.getTranslation('FOLDER_ACTIONS.OPEN'), notifyBody);
            }
          });
        } else if (isCheckedOutByMe) {
          Util.RestAPI.viewFile(listItem, version, null, path, false, true);
        } else {
          Util.Notify.warning(this.localizer.getTranslation('FOLDER_ACTIONS.OPEN'), notifyBody);
        }
      }
    }
  }

  private displayMiniStatus(item: ListItem): boolean {
    return Util.RestAPI.displayMiniStatus(item, null);
  }

  private formatStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatStatusIcon(item, null);
  }

  private iconAltTextFromDesc(item: ListItem): string {
    return Util.Transforms.iconAltTextFromDesc(item);
  }

  private formatStatusText(item: ListItem): string {
    return Util.RestAPI.formatStatusText(item);
  }
}

class HeroButton {
  label: string;
  icon?: string;
  value?: number;
  cmd: string;
  desc?: ListItem;
  header: string;
}
