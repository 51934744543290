
<div [ngClass]="{ mobile: ui>=2, phone: ui==2||ui==4, ios: iOS, android: android, firefox: firefox, safari: safari, edge: edge, ie: ie, oai:officeAddin, oaid: officeAddinDesktop,
                  mainfield: !field.isExtension, extensionfield: field.isExtension, readonly: formReadonly, heading: field.fldtype === 'text' || field.fldtype === 'box',
                  listfield: field.fldtype === 'list', indialog: !!inDialog, innotify: !!inNotify, columnview: formLayout === 'column', header: formLayout === 'header', first:first }"
     [ngSwitch]="field.controltype" [formGroup]="form">

  <label *ngIf="hasLabel()" attr.for="{{field.name}}">
    <span class="required" *ngIf="field.isRequired && !viewReadonly">*</span>
    <ng-template [ngIf]="field.fldtype !== 'push'">
      <br *ngIf="field.label===' '"/>
      <span *ngIf="field.label!==' '" [title] = "stripLabel(field.label)">{{stripLabel(field.label)}}</span>
   </ng-template>
    <span *ngIf="field.fldtype === 'push'" >{{stripLabel(field.checkLabel)}}</span>
  </label>

  <ng-template [ngSwitchCase]="'edittext'">
    <ng-template [ngIf]="!viewReadonly">
      <input #editInput [edx-validate-onblur]="wantsValidateOnBlur()" [placeholder]="placeholders('TEXT', field.isExtension)" [formControlName]="field.name" [id]="field.name"
          [type]="inputType()" [maxlength]="maxLen()" [required]="field.isRequired ? true : null" autocomplete="off" [title]="getDescriptionForTooltip()"
          class="edx_editable_input" [style.height] ="height" [ngClass]="{haslookup:  hasLookup(), copy: parent.formKind == 'profile_copy', savesearch: field.name=='save_search_as' || field.name=='update_saved_search' }"
          (focus)="editTextFocus($event)" (blur)="editTextBlur($event)" (keyup)="editTextKeyUp($event)" (paste)="editTextPaste($event)">
      <ul *ngIf="(inFocus || usingLookupData) && suggestions.length>0" #dropList class="dropdown-list" [style.top]="ulTop" [style.left]="ulLeft">
        <li *ngFor="let item of suggestions" class="list-item" [ngClass]="{selected: item===currentItem, separator: item.id==='-'}" (mousedown)="itemSelected(item, $event)">
          <ng-template [ngIf]="item.id !== '-'">
            <div class="dropdown-delete" (mousedown)="deleteSuggestion(item, $event)"></div>
            <div>{{item.desc}}</div>
            <div class="iddiv"> {{item.id}}</div>
          </ng-template>
        </li>
      </ul>
    </ng-template>
    <edx-read-only-text-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [inDialog]="inDialog" [columnView]="formLayout==='column'" [hasExtension]="extensionShown" [fullWidth]="!hasLabel()" [toolTip]="getDescriptionForTooltip()" [rerender]="rerender"></edx-read-only-text-field>
  </ng-template>
  <ng-template [ngSwitchCase]="'textarea'">
    <textarea *ngIf="!viewReadonly" placeholder="{{placeholders('TEXTAREA', field.isExtension)}}" [formControlName]="field.name" [id]="field.name"
        rows="{{field.rows}}" [readonly]="viewReadonly ? true : null" spellcheck="false"
        [maxlength]="maxLen()" [required]="field.isRequired ? true : null">
    </textarea>
    <edx-read-only-text-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [inDialog]="inDialog" [columnView]="formLayout==='column'" [toolTip]="getDescriptionForTooltip()"></edx-read-only-text-field>
  </ng-template>

  <ng-template [ngSwitchCase]="'editnumber'">
    <edx-numeric-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [parent]="this" [formReadonly]="formReadonly" [placeholder]="placeholders('NUMBER', field.isExtension)" [rerender]="rerender" [columnView]="formLayout === 'column'" [toolTip]="getDescriptionForTooltip()"></edx-numeric-field>
    <edx-numeric-field *ngIf="!viewReadonly" [field]="field" [form]="form" [parent]="this" [formReadonly]="formReadonly" [placeholder]="placeholders('NUMBER', field.isExtension)" [rerender]="rerender" [columnView]="formLayout === 'column'" [toolTip]="getDescriptionForTooltip()"></edx-numeric-field>
   </ng-template>
  
  <ng-template [ngSwitchCase]="'editdate'">
    <div [formGroup]="form" *ngIf="!viewReadonly" class="controlcontainer datecontrol">
      <input *ngIf="hasDatePicker()" #datePicker id="{{'dateField_' + field.name}}" type="date" class="datepicker" (change)="setDateFormat($event,field.name)" [min]="field.minDate" [max]="field.maxDate">
      <input placeholder="{{placeholders('DATE', field.isExtension)}}" [formControlName]="field.name"
          [id]="field.name" [ngClass]="{nodatepicker:!hasDatePicker(),datefields:ui!=0 || !(safari || officeAddinDesktop)}" (blur)="editTextBlur($event)" (change)="dateInputChanged($event)" [required]="field.isRequired ? true : null">
      <div *ngIf="!viewReadonly && hasLookup()" (click)="doLookup($event)" class="lookupbutton datelookupbutton" [ngClass]="{ readonly: viewReadonly, nodatepicker:!hasDatePicker()}" title="{{this.localizer.getTranslation('TOOLTIP.SELECT_LOOKUP',[field.name])}}" id="{{'edx_lkp_btn_'+field.name}}"></div>
      <div class="errormessage dateerror">{{field.errormessage}}</div>
    </div>
    <edx-read-only-text-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [inDialog]="inDialog" [columnView]="formLayout==='column'" [hasExtension]="extensionShown" [fullWidth]="!hasLabel()" [toolTip]="getDescriptionForTooltip()"></edx-read-only-text-field>
  </ng-template>

  <edx-checkbox-field *ngSwitchCase="'checkbox'" [field]="field" [form]="form" [formReadonly]="formReadonly" [parent]="this" [inDialog]="inDialog" [columnView]="formLayout === 'column'" [rerender]="rerender"></edx-checkbox-field>

  <edx-select-field *ngSwitchCase="'combo'" [field]="field" [form]="form" [parent]="this" [formControlName]="field.name" [items]="field.selectionMap" [rerender]="rerender"
        [columnView]="formLayout === 'column'" [initialPlaceHolder]="placeholders('SELECT', field.isExtension)" [formReadonly]="formReadonly" [inDialog]="inDialog" [inHeader]="formLayout==='header'" [inNotify]="inNotify" [fullWidth]="single && !extensionShown">
  </edx-select-field>

  <span *ngSwitchCase="'push'">
    <button *ngIf="!formReadonly && !bgImage" class="secondary" [ngClass]="{mobile:ui>=2, oai:officeAddin, header:formLayout==='header'}" [name]="field.name" (click)="buttonClicked($event)" id="{{field.name}}">{{field.label}}</button>
    <div *ngIf="!formReadonly && !!bgImage" class="buttoniconic" [title]="field.label" (click)="buttonClicked($event)" id="{{field.name}}" [style.backgroundImage]="bgImage" (mouseover)="imgButtonHover()" (mousedown)="imgButtonDown()" (mouseleave)="imgButtonDefault()"></div>
  </span>

  <ng-template [ngSwitchCase]="'radiogroup'">
    <div *ngIf="field.isCheckboxGroup&&!viewReadonly" class="radio-group">
      <div *ngFor="let button of field.buttonMap">
        <input type="text" value="{{button.value}}" [formControlName]="field.name" class="checkbox" [ngClass]="{checked:isCheckboxChecked(button.value)}" [readonly]="isCheckboxReadonly(button.value)" (click)="checkboxChanged(button.value)">
        <span class="checklabel" (click)="checkboxChanged(button.value)">{{button.display}}</span>
      </div>
    </div>
    <edx-radio-group *ngIf="!field.isCheckboxGroup&&!viewReadonly" [field]="field" [form]="form" [parent]="this" [formReadonly]="formReadonly" [inDialog]="inDialog" [inNotify]="inNotify" [columnView]="formLayout === 'column'" [hasLabel]="hasLabel()"></edx-radio-group>
    <edx-read-only-text-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [inDialog]="inDialog" [columnView]="formLayout==='column'" [hasExtension]="extensionShown" [fullWidth]="!hasLabel()" [toolTip]="getDescriptionForTooltip()"></edx-read-only-text-field>
  </ng-template>

  <edx-link-field *ngSwitchCase="'link'" [formControlName]="field.name" [field]="field" [form]="form"></edx-link-field>

  <edx-group-box-field *ngSwitchCase="'box'" [field]="field" [form]="form" [inDialog]="inDialog" [formReadonly]="formReadonly" [parent]="parent" [formLayout]="formLayout" [rerender]="rerender" [showExtras]="showExtras"></edx-group-box-field>

  <div *ngSwitchCase="'text'" class="divider">{{field.defaultValue}}</div>

  <div *ngSwitchCase="'guidetext'" class="guide">{{field.defaultValue}}</div>

  <edx-form-field-list *ngSwitchCase="'list'" [field]="field" [parent]="parent" [inDialog]="inDialog" [showExtras]="showExtras" [columnView]="formLayout==='column'" [rerender]="rerender"></edx-form-field-list>

  <edx-progress-field *ngSwitchCase="'progress'" [field]="field" [form]="form"></edx-progress-field>

  <edx-chooser-field *ngSwitchCase="'chooser'" [formControlName]="field.name" [field]="field" [form]="form" [parent]="this" [columnView]="formLayout==='column'"></edx-chooser-field>
   
  <div *ngIf="!viewReadonly && hasLookup() && field.controltype !== 'editdate'" (click)="doLookup($event)" class="lookupbutton" [ngClass]="{ readonly: viewReadonly }" title="{{this.localizer.getTranslation('TOOLTIP.SELECT_LOOKUP',[field.name])}}" id="{{'edx_lkp_btn_'+field.name}}"></div>

  <edx-form-wrapper *ngIf="parent && parent.showInlineForm(field)" [desc]="null" [kind]="parent.inlineForm(field)" [inNotify]="true" [selections]="null" [formData]="null" [inlineParent]="parent"></edx-form-wrapper>

  <div class="errormessage" *ngIf="!viewReadonly && field.controltype !== 'editnumber' && field.controltype !== 'editdate'">{{field.errormessage}}</div>
  
  <div class="statusmessage" *ngIf="!!field.statusMessage && !viewReadonly && field.controltype !== 'editnumber' && field.controltype !== 'editdate'">{{field.statusMessage}}</div>

</div>
