import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Util, UserInterface } from '../utils/utils.module';

@Component({
  selector: 'edx-refresher',
  styleUrls: ['refresher.component.scss'],
  template: `
    <div class="refresh" [ngClass]="{mobile:ui>=2, dark:dark, mini:mini}" (click)="refreshClicked($event)" title="{{name}}"></div>
  `
})
export class RefresherComponent {
  @Output() private refresh: EventEmitter<void> = new EventEmitter<void>();
  @Input() public mini?: boolean;
  @Input() public dark?: boolean;
  @Input() public name?: string;
  public ui: UserInterface = Util.Device.ui;

  public refreshClicked(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.refresh.emit();
  }
}
