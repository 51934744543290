import { Component, Input, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Util, UserInterface } from '../utils/utils.module';
import { BaseDesc } from '../models/base';
import { SelectItem } from '../models/form-field';
import { ListItem, ListInterface, FilterListInterface } from '../models/list-item';
import { ColFormat } from '../models/column';
import { CommandHandler } from '../models/command-handler';
import { ListService } from '../services/list.service';
import { LocalizeService } from '../services/localize.service';
import { ListTableComponent, ListTableParent } from './list-table.component';
import { AppComponent } from '../app.component';

@Component ({
  selector: 'edx-user-groups-picker',
  styleUrls: [ 'list-user-groups-picker.component.scss' ],
  providers: [ListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="container" [ngClass]="{phone:isPhoneLook, usersshown:showUsers}">
      <div class="grouplist">
        <div class="listtitle">
          <span>{{groupsTitle}}</span>
        </div>
        <edx-list-table #grouptable class="listcontainer" [desc]="desc" [parent]="this" [leadingColums]="leadingColums"></edx-list-table>
      </div>
      <div class="userslist">
        <div class="listtitle">
          <span>{{usersTitle}}</span>
          <button *ngIf="showResetBtn" class="secondary reset" [ngClass]="{mobile: ui>=2, oai:officeAddin}" (click)="reset()">{{this.localizer.getTranslation('REFINE_SEARCH.CLEAR')}}</button>
        </div>
        <edx-list-table #usertable class="listcontainer" [desc]="userDesc" [parent]="this" [leadingColums]="leadingColums"></edx-list-table>
      </div>
    </div>
    `
})
export class ListUserGroupPickerComponent extends ListInterface implements OnInit, ListTableParent, CommandHandler {
  public isPhoneLook: boolean;
  public showUsers: boolean;
  public groupsTitle: string;
  public userDesc: BaseDesc;
  public showResetBtn = false;
  public usersTitle: string;
  public leadingColums: number[] = [ColFormat.SELECTOR, ColFormat.OBJTYPE];
  protected ui: UserInterface;
  protected officeAddin: boolean;
  private allGroupItemsIn = false;
  private allUserItemsIn = false;
  private listFilter = '';
  private usersFilter: string = null;
  private searchFilter: string = null;
  private app: AppComponent;
  private groupSelItems: SelectItem[];
  private userSelItems: SelectItem[];
  private previousGroupItem: ListItem;
  @Input() desc?: BaseDesc;
  @Input() filterForm: FilterListInterface;
  @Input() disableList: ListItem[] = null;
  @ViewChild('grouptable') private groupTable: ListTableComponent;
  @ViewChild('usertable') private userTable: ListTableComponent;

  constructor(protected localizer: LocalizeService, protected changeRef: ChangeDetectorRef) {
    super();
    this.ui = Util.Device.ui;
    this.officeAddin = Util.Device.bIsOfficeAddin;
    this.groupsTitle = this.localizer.getTranslation('SECURITY.USER_GROUP.GROUPS');
    this.usersTitle = this.localizer.getTranslation('SECURITY.USER_GROUP.USERS');
    this.isPhoneLook = Util.Device.isPhoneLook();
    this.showUsers = !this.isPhoneLook;
    this.app = Util.RestAPI.getAppComponent();
  }

  ngOnInit(): void {
    if (!this.desc) {
      this.desc = {id:'_GROUPS_ENABLED',type:'lookups',lib:Util.RestAPI.getPrimaryLibrary()};
    }
    this.userDesc = {id:'_GROUP_MANAGER',type:'lookups',lib: this.desc.lib || Util.RestAPI.getPrimaryLibrary()};
    const setFilterList = () => {
      const items: SelectItem[] = !!this.filterForm ? this.filterForm.getList() : null;
      if (!!items && !!items.length) {
        this.groupSelItems = items.filter(i => i.value.indexOf('GROUP')!==-1);
        this.userSelItems = items.filter(i => i.value.indexOf('GROUP')===-1);
        if (this.isPhoneLook) {
          this.filterForm.setList(this.groupSelItems);
        }
      } else {
        setTimeout(() => {
          setFilterList();
        }, 200);
      }
    };
    setFilterList();
  }

  private reset(): void {
    this.groupTable.setOpenedItem(null);
    this.userTable.clearList();
    this.usersTitle = this.localizer.getTranslation('SECURITY.USER_GROUP.USERS');
    this.searchFilter = null;
    this.usersFilter = null;
    this.filterForm.clear();
    this.showResetBtn = false;
  }

  public getSelections(): ListItem[] {
    const groupItems: ListItem[] = this.groupTable.getSelections();
    const userItems: ListItem[] = this.userTable.getSelections();
    return groupItems ? groupItems.concat(userItems) : userItems;
  }

  // ListTableParent
  public selectionsUpdated(table: ListTableComponent): void {
    const groupItems: any[] = this.groupTable.getSelections();
    const userItems: any[] = this.userTable.getSelections();
    if (groupItems.length || userItems.length) {
      this.okDisabled = false;
    } else {
      this.okDisabled = true;
    }
  }

  public handleFilterChange(searchFilter: string, selKey: string): boolean {
    let rc = false;
    // Search filter determines the target list to search. If the focus was set
    // on a dufferent target, adjust it to point to the correct target list
    if (searchFilter) {
      if (searchFilter.includes('GROUP')) {
        selKey = 'GROUP_ID';
      } else if (searchFilter.includes('USER')) {
        selKey = 'USER_ID';
      }
    }
    if (!selKey || !searchFilter) {
      selKey = this.filterForm['sel'] ? this.filterForm['sel'].value : 'GROUP_ID';
      searchFilter = this.usersFilter;
      if (!!this.previousGroupItem) {
        this.groupTable.setOpenedItem(this.previousGroupItem);
        if (!this.isPhoneLook) {
          this.showResetBtn = true;
        }
        this.usersTitle = this.localizer.getTranslation('SECURITY.USER_GROUP.USERS') + ' ' + this.localizer.getTranslation('SECURITY.USER_GROUP.IN_GROUP', [this.previousGroupItem['GROUP_NAME']]);
      }
    }
    if (selKey==='GROUP_ID' || selKey==='GROUP_NAME') {
      rc = this.groupTable.handleFilterChange(searchFilter, selKey);
      this.usersTitle = this.localizer.getTranslation('SECURITY.USER_GROUP.USERS');
      this.searchFilter = null;
      this.usersFilter = null;
      this.userTable.clearList();
    } else {
      this.searchFilter = searchFilter;
      if (this.usersFilter) {
        if (searchFilter) {
          searchFilter += ' and ' + this.usersFilter;
        } else {
          searchFilter = this.usersFilter;
        }
      }
      if (searchFilter) {
        rc = this.userTable.handleFilterChange(searchFilter, selKey);
      } else {
        this.userTable.clearList();
      }
    }
    return rc;
  }

  public getFilterColumnsFromSchema(): any[] {
    const groupCols: any[] = !!this.groupTable ? this.groupTable.getFilterColumnsFromSchema() : null;
    const userCols: any[] = !!this.userTable ? this.userTable.getFilterColumnsFromSchema() : null;
    return !!groupCols && !!userCols ? groupCols.concat(userCols) : null;
  }

  public handleListItemClick(table: ListTableComponent, item: ListItem, event: Event, property: string): boolean {
    if (table===this.groupTable && property!=='select') {
      const groupItem: any = item;
      const newFilter: string = 'GROUP_ID='+groupItem.GROUP_ID;
      if (newFilter!==this.usersFilter) {
        this.usersFilter = newFilter;
        this.previousGroupItem = item;
        this.groupTable.setOpenedItem(item);
        this.userTable.handleFilterChange(this.usersFilter + (this.searchFilter ? (' and ' + this.searchFilter) : ''), 'GROUP_ID');
        if (this.filterForm && !this.searchFilter) {
          this.filterForm.clear();
          this.filterForm.setSelKey('USER_ID');
        }
        this.showUsers = true;
        this.usersTitle = this.localizer.getTranslation('SECURITY.USER_GROUP.USERS') + ' ' + this.localizer.getTranslation('SECURITY.USER_GROUP.IN_GROUP',[groupItem.GROUP_NAME]);
        if (this.isPhoneLook) {
          this.filterForm.setList(this.userSelItems);
          this.app.pushCommandHandler(this, 'popupok', this.localizer.getTranslation('FORMS.BUTTONS.CANCEL'), this.localizer.getTranslation('METADATA.SECURITY_ACTIONS.ADD'), this.localizer.getTranslation('FORMS.BUTTONS.OK'));
        } else {
          this.showResetBtn = true;
        }
      }
      return true;
    }
    return false;
  }

  public canSelectListItem(table: ListTableComponent, item: ListItem): boolean {
    let rc = true;
    if (this.disableList) {
      if (table===this.groupTable) {
        rc = !this.disableList.find(i => i['USER_ID'] === item['GROUP_ID']);
      } else {
        rc = !this.disableList.find(i => i['USER_ID'] === item['USER_ID']);
      }
    }
    return rc;
  }

  public commandEnabled(cmd: string): boolean {
    let enabled = false;
    switch (cmd) {
    case 'popupok':
      if (this.okDisabled) {
        break;
      }
      // fall thru
    case 'back':
      enabled = this.showUsers; break;
    }
    return enabled;
  }

  public doCommand(cmd: string): boolean {
    switch (cmd) {
    case 'back':
      if (this.showUsers) {
        this.showUsers = false;
        this.usersFilter = null;
        this.filterForm.setSelKey('GROUP_ID');
        this.filterForm.setList(this.groupSelItems);
        this.changeRef.markForCheck();
        history.go(-1);
        return true;
      }
      break;
    case 'popupok':
      if (this.showUsers && !this.okDisabled) {
        this.showUsers = false;
        history.go(-1);
        setTimeout(() => {
          this.app.cordovaRight();
        }, 300);
        return true;
      }
      break;
    }
    return false;
  }

  public getGroupTable(): any {
    if (!!this.groupTable) {
      return this.groupTable;
    }
    return null;
  }
}
