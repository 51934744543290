import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/';
import * as microsoftTeams from '@microsoft/teams-js';

declare let Office;
let sbTeamsLoaded = false;

const launch = () => {
  enableProdMode();
  platformBrowserDynamic().bootstrapModule(AppModule);
};

const noOfficeLaunch = () => {
  setTimeout(() => {
    (window as any).Office = undefined;
    Office = undefined;
    launch();
  }, 100);
};

const loadOffice = () => {
  const script = document.createElement('script');
  script.onerror = (error) => {
    document.head.removeChild(script);
    noOfficeLaunch();
  };
  script.onload = () => {
    if (window.hasOwnProperty('Office')) {
      // Application-specific initialization code goes into a function that is
      // assigned to the Office.initialize event and runs after the Office.js initializes.
      // Bootstrapping of the AppModule must come AFTER Office has initialized.
      Office.onReady().then(info => {
        if (!!info && !!info.platform) {
          launch();
        } else {
          noOfficeLaunch();
        }
      });
    } else {
      launch();
    }
  };
  script.src = 'https://appsforoffice.microsoft.com/lib/1.1/hosted/office.debug.js';
  document.head.appendChild(script);
};

setTimeout(() => {
  // calling microsoftTeams.initialize(callback) outside of teams does NOT call callback so timeout
  if (!sbTeamsLoaded) {
    loadOffice();
  }
  sbTeamsLoaded = true;
}, 500);
microsoftTeams.initialize(() => {
  sbTeamsLoaded = true;
  noOfficeLaunch();
});
