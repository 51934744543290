import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { SchemaService, SchemaDef } from '../services/schema.service';
import { LocalizeService } from '../services/localize.service';
import { BaseDesc } from '../models/base';
import { Util } from '../utils/utils.module';
import { ListItem } from '../models/list-item';
import { SecurityControl } from '../models/security-control';
import { ColFormat, ColumnDesc } from '../models/column';

@Component({
  selector: 'edx-custom-security',
  styleUrls: ['custom-security.component.scss'],
  template: `
    <div *ngIf="!!schema" class="container">
      <ng-template ngFor let-col [ngForOf]="schema.columns" let-j="index">
        <div *ngIf="!!getColumnHeading(j)" class="topheadinglabel" [style.borderBottom]="'solid 3px '+getColumnHeading(j).bottomcolor">{{this.localizer.getTranslation(getColumnHeading(j).label)}}</div>
        <div *ngIf="col.format===6" class="status">
          <img *ngIf="getTrusteeAccess(j-1)=='2'" class="rightsicon" src="assets/images/col_hdr_deny24.svg" alt="{{this.localizer.getTranslation('METADATA.FOOTER.SECURITY.DENY')}}" (click)="setTrusteeAccess(j-1,2)" >
          <img *ngIf="getTrusteeAccess(j-1)=='1'" class="rightsicon" src="assets/images/checkmark_green24.svg" alt="{{this.localizer.getTranslation('METADATA.FOOTER.SECURITY.ALLOW')}}" (click)="setTrusteeAccess(j-1,1)" >
          <img *ngIf="getTrusteeAccess(j-1)=='0'" class="rightsicon" src="assets/images/col_hdr_inherit24.svg" alt="{{this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.INHERIT')}}" (click)="setTrusteeAccess(j-1,0)" >
          <span>{{this.localizer.getTranslation(col.label)}}</span>
        </div>
      </ng-template>
    </div>
  `
})
export class CustomSecurityComponent implements OnInit {
  @Output() customSecurityChange: EventEmitter<SecurityControl> = new EventEmitter<SecurityControl>();
  @Input() desc: BaseDesc;
  @Input() listItem: ListItem;
  public schema: SchemaDef = null;
  private colHeadingTable: number[] = [];

  constructor(private schemaService: SchemaService, private localizer: LocalizeService) {
  }

  ngOnInit(): void {
    if (!!this.desc) {
      this.schema = this.schemaService.getSchema('ACCESS_CONTROL', this.desc.lib || Util.RestAPI.getPrimaryLibrary(), null, null);
      const nCols = this.schema.columns.length;
      let groupNum = 0;
      this.colHeadingTable = this.schema.columns.map(c => undefined);
      for (let i=0; i<nCols; i++) {
        const col = this.schema.columns[i];
        if (col.format === ColFormat.RIGHTS) {
          if ((groupNum % 3) === 0) {
            this.colHeadingTable[i] = 1 + (groupNum/3);
          }
          ++groupNum;
        }
      }
    }
  }

  protected getPropertyString(property: string): string {
    return !!this.listItem ? Util.RestAPI.getPropValue(this.listItem, property) as string : undefined;
  }

  public getTrusteeAccess(trusteeRightId: number): number {
    return Util.RestAPI.getTrusteeAccess(this.listItem, trusteeRightId);
  }

  public getColumnHeading(colIndex: number): any {
    return this.schema.columnHeadings[this.colHeadingTable[colIndex]];
  }

  public setTrusteeAccess(nMask: number, trusteeCurrentStatus: number): void {
    if (!!this.listItem && !!this.desc) {
      const security: SecurityControl = Util.RestAPI.setTrusteeAccess(this.desc, this.listItem, nMask, trusteeCurrentStatus);
      if (!!security) {
        this.customSecurityChange.emit(security);
      }
    }
  }
}
