<ng-template #expandedSectionTemplate let-estShown="estShown" let-section="section">
  <ng-template [ngIf]="estShown">
    <div class="section expanded">
      <div class="inputwrapper">
        <input type="TEXT" [id]="'input_'+section.id" [name]="section.id" [placeholder]="placeholderForSection(section)" (keyup)="sectionSearchKeyup($event, section)">
        <div class="nameclear" (click)="toggleSectionSearch(section)"></div>
      </div>
      <div class="sectionbody">
        <div *ngIf="section.loading" class="facet"><edx-spinner [mini]="true"></edx-spinner></div>
        <ng-template [ngIf]="!!section.items && !!section.items.length">
          <div *ngFor="let item of section.items" class="facet" [ngClass]="{readonly:item.readOnly}">
            <input type="checkbox" [id]="facetKey(item)" (click)="facetClicked($event, item)" class="checkbox" [ngClass]="{checked: item.checked}">
            <span for [id]="facetKey(item)" (click)="facetClicked($event, item)">{{facetKeyName(item, section)}}</span>
            <div *ngIf="facetValue(item) !== -1" class="facetcount">{{facetValue(item)}}</div>
          </div>
        </ng-template>
        <div *ngIf="!section.loading && (!section.items || !section.items.length)" class="facet">{{this.localizer.getTranslation('PLACEHOLDER.NO_ITEMS')}}</div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #sectionsTemplate let-stShown="stShown">
  <ng-template [ngIf]="stShown">
    <button *ngIf="showReset() && ui>=2 && !!sections" class="mobile secondary right top" [ngClass]="{readonly:disableReset(), oai:officeAddin}" (click)="reset()" [disabled]="disableReset()">{{clearStr}}</button>
    <div *ngFor="let section of sections" class="section">
      <div class="sectionheader">
        <div class="section-title">{{section.title}}</div>
      </div>
      <div class="sectiontoggle" [ngClass]="{open:section.isOpen, closed:!section.isOpen}" (click)="toggleSection(sections.indexOf(section))"></div>
      <ng-template [ngIf]="section.id!='datefacets'">
        <div *ngIf="section.isOpen && !section.isSearchOpen" class="sectionsearch" (click)="toggleSectionSearch(section)"></div>
        <div *ngIf="section.isOpen && section.isSearchOpen" class="inputwrapper">
          <input type="TEXT" [id]="'input_'+section.id" [name]="section.id" [placeholder]="placeholderForSection(section)" (keyup)="sectionSearchKeyup($event, section)">
          <div class="nameclear" (click)="toggleSectionSearch(section)"></div>
        </div>
      </ng-template>
      <div *ngIf="section.isOpen || section.id=='datefacets'" class="sectionbody" [ngClass]="{edx_hidden:!section.isOpen, opening:section.isOpening, closing: section.isClosing}" (animationend)="animationComplete(section)">
        <ng-template [ngIf]="section.id!='datefacets'">
          <div *ngIf="section.loading" class="facet">
            <edx-spinner [mini]="true"></edx-spinner>
          </div>
          <ng-template [ngIf]="!!section.items && !!section.items.length">
            <ng-template ngFor let-item let-i="index" [ngForOf]="section.items">
              <div *ngIf="!section.moreLess || section.moreShown || i<section.lessLen || item.checked" class="facet" [ngClass]="{readonly:item.readOnly}">
                <input type="checkbox" [id]="facetKey(item)" (click)="facetClicked($event, item)" class="checkbox" [ngClass]="{checked: item.checked}">
                <span for [id]="facetKey(item)" (click)="facetClicked($event, item)">{{facetKeyName(item, section)}}</span>
                <div *ngIf="facetValue(item) !== -1" class="facetcount">{{facetValue(item)}}</div>
              </div>
            </ng-template>
            <div *ngIf="!!section.moreLess" class="moreless" (click)="toggleExpandedSection(section)">{{section.moreLess}}</div>
          </ng-template>
          <div *ngIf="!section.loading && (!section.items || !section.items.length)" class="facet">{{this.localizer.getTranslation('PLACEHOLDER.NO_ITEMS')}}</div>
        </ng-template>
        <edx-dynamic-form *ngIf="section.id=='datefacets'" #dynamicform [ngClass]="{edx_hidden:!section.isOpen}" [formKind]="'profile_query_datefacets'" [layout]="formLayout" [formTemplate]="facetFormTemplate" [inDialog]="true" [desc]="desc" [controller]="this"></edx-dynamic-form>
      </div>
    </div>
  </ng-template>
</ng-template>

<div *ngIf="ui<2 && isOpen" class="sidebar-container">
  <div *ngIf="!!expandedSection" class="headerbody expanded">
    <div class="sidebar-header">
      <div class="sidebar-header-backarrow" (click)="toggleExpandedSection(expandedSection)"></div>
      <div class="sidebar-title">{{expandedSection.title}}</div>
    </div>
    <ng-container *ngTemplateOutlet="expandedSectionTemplate;context:{estShown:true,section:expandedSection}"></ng-container>
  </div>
  <div class="headerbody" [ngClass]="{hidden:!!expandedSection, hiding:expandedSection&&expandedSection.isExpanding, showing:expandedSection&&expandedSection.isContracting}" (animationend)="animationComplete(expandedSection)">
    <div class="sidebar-header">
      <div class="sidebar-title">{{title}}</div>
    </div>
    <div *ngIf="!loading" class="sidebar-content">
      <div *ngIf="showFilters">
        <edx-dynamic-form *ngIf="!canHaveFacets" #dynamicform [formKind]="kind" [layout]="formLayout" [formTemplate]="formTemplate" [inDialog]="true" [desc]="desc" [controller]="this"></edx-dynamic-form>
        <ng-container *ngTemplateOutlet="sectionsTemplate;context:{stShown:true}"></ng-container>
      </div>
      <div *ngIf="!showFilters || (canHaveFacets && !sections)">
        <div class="no-filters">{{noFiltersStr}}</div>
      </div>
      <div *ngIf="showFilters">
        <div class="gradient-strip"></div>
      </div>
    </div>
  </div>
  <div class="sidebar-footer">
    <button [ngClass]="{readonly: okDisabled}" class="primary" [disabled]="okDisabled" (click)="search()">{{applyStr}}</button>
    <button class="secondary right" [ngClass]="{readonly:disableReset()}" (click)="reset()" [disabled]="disableReset()">{{clearStr}}</button>
  </div>
  <edx-spinner *ngIf="loading"></edx-spinner>
</div>
<ng-template [ngIf]="ui>=2 && isOpen">
  <edx-popup #formDialog [desc]="desc" [callback]="this" [kind]="kind" [title]="title" [ok]="applyStr" [okDisabled]="okDisabled">
    <div *ngIf="!loading" class="sidebar-container mobile">
      <div class="sidebar-content mobile">
        <ng-template [ngIf]="showFilters">
          <ng-template [ngIf]="!canHaveFacets">
            <button *ngIf="showReset()" class="mobile secondary right bottom" [ngClass]="{readonly:disableReset(), oai:officeAddin}" (click)="reset()" [disabled]="disableReset()">{{clearStr}}</button>
            <edx-dynamic-form #dynamicform [formKind]="formDialog.kind" [layout]="formLayout" [formTemplate]="formTemplate" [inDialog]="true" [data]="formDialog.formData" [desc]="formDialog.desc" [controller]="this"></edx-dynamic-form>
          </ng-template>
          <ng-container *ngTemplateOutlet="sectionsTemplate;context:{stShown:true}"></ng-container>
        </ng-template>
        <ng-template [ngIf]="!showFilters || (canHaveFacets && !sections)">
          <div class="no-filters">{{noFiltersStr}}</div>
        </ng-template>
      </div>
    </div>
  </edx-popup>
  <edx-spinner *ngIf="loading"></edx-spinner>
</ng-template>