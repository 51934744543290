import { Component, Input, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { MenuService } from '../services/menu.service';
import { SelectItem } from '../models/form-field';
import { LocalizeService } from '../services/localize.service';
import { ListService } from '../services/list.service';
import { Util } from '../utils/utils.module';
import { SearchMenuComponent } from './search-menu.component';
import { SegmentComponent } from './segment.component';
import { SearchWhere } from '../models/command-handler';

@Component({
  selector: 'edx-search-mobile',
  styleUrls: ['search-mobile.component.scss'],
  template: `
    <div class="contentpanel" [edx-file-drop-target]="this">
      <div class="formlist">
        <form action="." class="search-header" (submit)="submit()">
          <input #edxSearchText placeholder="{{searchPlaceHolder}}" name="search" type="search" id="edxSearchText" [ngClass]="{headersearchtext:true, container:searchWhere!==0}" (keyup)="searchTextEntered($event)">
          <div *ngIf="iOS && showClearIcon" class="searchclear" [ngClass]="{container:searchWhere!==0}" (click)="clearSearch()" [title]="this.localizer.getTranslation('TOOLTIP.CLEAR_KEYWORDS')"></div>
          <div *ngIf="canSearchWhere()" class="select-wrapper">
            <label attr.for="edx_search_where">{{this.localizer.getTranslation("GLOBAL_SEARCHSCOPE.SEARCH_WHERE")}}</label>
            <edx-select id="edx_search_where" class="right" [items]="whereSelectItems" [value]="searchWhere" (change)="searchWhereChanged($event)"></edx-select>
          </div>
          <div class="select-wrapper">
            <label attr.for="edx_search_option">{{this.localizer.getTranslation("GLOBAL_SEARCHSCOPE.SEARCH_SCOPE")}}</label>
            <edx-select id="edx_search_option" class="right" [items]="scopeSelectItems" [value]="searchOption" (change)="searchOptionChanged($event)"></edx-select>
          </div>
        </form>
        <div class="search-header more">
          <div class="text">{{this.localizer.getTranslation("GLOBAL_SEARCHSCOPE.MORE_SEARCHES")}}</div>
        </div>
        <ul>
          <li (click)="doCommand('savedsearches')" id="edx_search_savedsearches">
            <div class="icon savedsearch"></div>
            <div class="text">{{this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.SAVED_SEARCHES')}}</div>
            <div class="icon chevron"></div>
          </li>
          <li (click)="doCommand('profilesearch')" id="edx_search_profile">
            <div class="icon profilesearch"></div>
            <div class="text">{{this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PROFILE_SEARCH')}}</div>
            <div class="icon chevron"></div>
          </li>
          <li (click)="doCommand('advancedsearch')" id="edx_search_advanced">
            <div class="icon advancedsearch"></div>
            <div class="text">{{this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.ADVANCED_SEARCH')}}</div>
            <div class="icon chevron"></div>
          </li>
        </ul>
      </div>
    </div>
  `
})
export class SearchMobileComponent extends SearchMenuComponent implements OnInit, OnDestroy {
  public static baseClassInputs: string[] = ['searchWhere','searchPlaceHolder','searchOption'];
  @ViewChild('edxSearchText') protected edxSearchText: ElementRef;
  @Output() searchWhereChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() searchWhere?: number = 0;
  @Input() searchPlaceHolder?: string;
  @Input() searchOption?: string = 'FULLTEXT_CONTENT_PROFILE';
  public showClearIcon = false;
  public scopeSelectItems: SelectItem[] = [];
  public whereSelectItems: SelectItem[] = [];
  public iOS: boolean;

  constructor(public localizer: LocalizeService, protected menuService: MenuService, protected cdr: ChangeDetectorRef, protected listService: ListService, protected location: Location) {
    super(localizer, menuService, cdr, listService);
  }

  ngOnInit(): void {
    this.iOS = Util.Device.bIsIOSDevice;
    if (this.callback && this.callback.setTextInputElement) {
      const setIt = () => {
        if (!!this.edxSearchText) {
          this.callback.setTextInputElement(this.edxSearchText);
        } else {
          setTimeout(setIt, 100);
        }
      };
      setIt();
    }
    this.setSearchResources();
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    if (this.callback && this.callback.setTextInputElement) {
      this.callback.setTextInputElement(null);
    }
  }

  protected initMenuItems(): void {
    // do not call super
    this.scopeSelectItems = [];
    for (const itemDef of this.menuDef.items) {
      this.scopeSelectItems.push({value:itemDef.cmd, display:this.localizer.getTranslation(itemDef.name, itemDef.nameargs)});
    }
    if (this.canSearchWhere()) {
      this.whereSelectItems = [
        {value:SearchWhere.Container, display:this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.THIS_FOLDER')},
        {value:SearchWhere.ContainerPlusSubs, display:this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.THIS_FOLDER_SUBS')},
        {value:SearchWhere.Global, display:this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PROFILE_AND_CONTENT_SHORT')}
      ];
    } else {
      this.whereSelectItems = [];
    }
  }

  public canSearchWhere(): boolean {
    return Util.RestAPI.canSearchWhere();
  }

  protected clearSearch(): void {
    if (this.edxSearchText && this.edxSearchText.nativeElement) {
      this.edxSearchText.nativeElement.value = '';
      this.showClearIcon = false;
      this.edxSearchText.nativeElement.focus();
    }
    if (this.callback && this.callback.textCleared) {
      this.callback.textCleared();
    }
  }

  public searchTextEntered(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.submit();
    } else if (this.callback && this.callback.textEntered) {
      this.showClearIcon = true;
      this.callback.textEntered(event);
    }
  }

  public searchWhereChanged(seg: SegmentComponent): void {
    this.searchWhere = seg.value;
    this.searchWhereChange.emit(seg.value);
  }

  public searchOptionChanged(seg: SegmentComponent): void {
    this.searchOption = seg.value;
    this.setSearchResources();
    this.callback.checkCommand(this.searchOption, true);
  }

  protected setSearchResources(): void {
    switch (this.searchOption) {
      case 'FULLTEXT_PROFILE':
        this.searchPlaceHolder = this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PLACE_HOLDER.PROFILE');
        break;
      case 'FULLTEXT_CONTENT_PROFILE':
        this.searchPlaceHolder = this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PLACE_HOLDER.PROFILE_AND_CONTENT');
        break;
      case 'FULLTEXT_EASY_SEARCH':
        this.searchPlaceHolder = this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PLACE_HOLDER.CONTENT');
        break;
      default:
        this.searchPlaceHolder = this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PLACE_HOLDER.PROFILE');
        break;
    }
  }

  public submit(): boolean {
    if (this.isOpen) {
      this.removeMenu();
      setTimeout(() => {
       this.callback.doCommand('search');
      }, 300);
    }
    return false;
  }

  protected removeMenu(): void {
    this.callback.doCommand('cordovaLeft');
    super.removeMenu();
  }
}
