import { Component, Input, OnChanges, SimpleChange, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Util, UserInterface } from '../utils/utils.module';
import { BaseDesc, ListData } from '../models/base';
import { LocalizeService } from '../services/localize.service';
import { ListItem, ListState, ListInterface } from '../models/list-item';
import { ListService } from '../services/list.service';
import { DataService } from '../services/data.service';
import { SecurityControl, AccessLevel, AccessRights } from '../models/security-control';

@Component ({
  providers: [ListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``
})
export class ListBaseComponent extends ListInterface implements OnChanges {
  public static baseClassInputs: string[] = ['desc','params','pageSizeIncremental'];
  public ui: UserInterface;
  public officeAddin: boolean;
  public reloading = true;
  public allItemsIn = false;
  protected set: any = {};
  protected list: ListItem[] = [];
  protected ascending: string = null;
  protected descending: string = null;
  protected uploadingList: ListItem[] = [];
  protected loadMore: string = null;
  protected security: SecurityControl = null;
  protected bIsPaged = false;
  @Input() desc?: BaseDesc;
  @Input() params?: string = null;
  @Input() pageSizeIncremental?: number = 0;

  constructor(protected listService: ListService, protected dataService: DataService, protected localizer: LocalizeService, protected changeRef: ChangeDetectorRef) {
    super();
    this.ui = Util.Device.ui;
    this.officeAddin = Util.Device.bIsOfficeAddin;
    this.loadMore = this.localizer.getTranslation('PAGINATOR.LOAD_MORE');
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    const chng: any = changes['desc'];
    if (chng) {
      this.reloading = true;
      if (!!this.desc && (this.desc as any).params !== 'security') {
        this.set = this.getInitialSet() || {};
        this.list = this.getInitialList() || [];
        if (this.list.length && !this.set.total) {
          this.set.total = this.list.length;
        }
        this.security = null;
        this.setSorting();
      }
      setTimeout(() => {
        if (!this.list || !this.list.length) {
          const lastListState = this.dataService.getListState(this.desc);
          if (!!lastListState) {
            const start: number = this.bIsPaged ? lastListState.start : 0;
            let max: number = Util.RestAPI.getDefualtMaxItems();
            if (!this.bIsPaged && lastListState.start) {
              max += lastListState.start;
            }
            this.ascending = lastListState.ascending;
            this.descending = lastListState.descending;
            this.loadList(true, start, max);
          } else if (!!this.desc && !this.desc.id && (this.desc as any).params === 'security') {
            this.list = [];
            this.set = {total: 0};
            this.reloading = false;
            this.allItemsIn = true;
          } else {
            this.loadList();
          }
        } else {
          this.reloading = false;
          this.allItemsIn = true;
        }
        this.changeRef.markForCheck();
      }, 1);
    }
  }

  protected setSorting(): void {
    const loginReply = Util.RestAPI.getLoginReply();
    if (this.params === 'history' || this.desc.type === 'activities') {
      this.setSortParams(null, 'START_DATE');
    } else if (this.desc.type==='flexfolders' || this.desc.type==='workspaces') {
      this.setSortParams('DOCNAME', null);
    } else if (this.desc.type === 'searches') {
      //Fetch the searches with most recent at the top
      this.setSortParams(null, !this.desc.id ? 'SYSTEM_ID' : (loginReply.SEARCH_CAPABILITIES && loginReply.SEARCH_CAPABILITIES.SUMMARY === 'Y') ? 'relevance' : 'LAST_EDIT_DATE');
    } else if (this.desc.type === 'fileplans') {
        //setting sort order for filepart contents.
      if ((!!this.desc['NodeLevel'] && this.desc['NodeLevel'] === 'File Part') || (Util.isRMFilePart(this.desc))) {
        this.setSortParams(null,'LAST_EDIT_DATE');
      } else {
        //For terms,fileplans,setting the sort order to "docname" which is mapped to displayname in UI.
        this.setSortParams('DOCNAME', null);
      }
    } else if (this.desc.type === 'requests') {
      this.setSortParams(null, 'RQ.PD_REQUEST_DATE');
    } else if (!Util.isExternalLib(this.desc.lib) && !this.ascending && !this.descending) {
      this.descending = 'LAST_EDIT_DATE';
    }
  }

  protected getInitialList(): ListItem[] {
    return null;
  }

  protected getInitialSet(): any {
    return null;
  }

  protected handleError(error: any): void {
    Util.Notify.warning(this.localizer.getTranslation('GENERIC_ERRORS.LIST_NO_LOAD'),error);
    console.log('can not load list: ' + this.desc.type + ' ' + this.desc.id + ' ' + (error.message || error));
    this.reloading = false;
    this.list = [];
    this.listReplaced();
    this.changeRef.markForCheck();
  }

  protected setSortParams(ascending: string, descending: string) {
    this.ascending = ascending || null;
    this.descending = descending || null;
  }

  private loadListPromise(desc: any, params: string, forceReload: boolean=false, ascending?: string, descending?: string, start: number=0, max?: number, postData?: any, initialList?: any): Promise<ListItem[]> {
    const loadingDesc: any = Object.assign(this.desc);
    this.reloading = true;
    if (!forceReload && this.pageSizeIncremental > 0) {
      start = this.list.length || 0;
      max = this.pageSizeIncremental;
    }
    this.changeRef.markForCheck();
    return this.listService.getListData(loadingDesc,this.params,forceReload,ascending,descending,start,max,postData,initialList).then((data) => {
      // check for race condition that our desc has not changed after we requested the load from the list service
      // this can happen on a slow link and an impatient user that cancels a list load by going back to the previous folder
      // eg: in public folders click folder Sub to nav to and load it, then go back to public folders before the loading of Sub returns
      // Public will then request and load before the loading of sub returns and the wrong or mixed contents may be seen
      if (this.desc && this.desc.id===loadingDesc.id && this.desc.lib===loadingDesc.lib && this.desc.type===loadingDesc.type) {
        this.set = data.set;
        if (this.set.rights) {
          if ((this.set.rights&AccessRights.ACCESS_EDIT_CONTENT) && this.set.readonly==='Y') {
            this.set.rights = this.set.rights & ~AccessRights.ACCESS_EDIT_CONTENT;
          }
          this.security = new SecurityControl(this.set.rights);
        }
        if (!forceReload && this.pageSizeIncremental > 0) {
          this.list = this.list ? this.list.concat(data.list) : data.list;
        } else {
          this.list = data.list;
        }
        this.allItemsIn = this.list.length >= this.set.total;
        if ((this.desc as any).DOCNAME && this.params==='versions') {
          for (const item of this.list) {
            if (!item.DOCNAME) {
              item.DOCNAME = (this.desc as any).DOCNAME;
            } else {
              break;
            }
          }
        }
      }
      this.changeRef.markForCheck();
      return this.list;
    }).catch((error) => {
      this.handleError(error);
      return this.list;
    });
  }

  public loadList(forceReload: boolean=false, start?: number, max?: number, postData?: any, initilaList?: any): void {
    this.loadListPromise(this.desc, this.params, forceReload, this.ascending, this.descending, start, max, postData, initilaList).then(list => {
      this.listReplaced();
    });
  }

  public loadListContent(data: ListData): void {
    if (data) {
      this.list = data.list;
      this.set = data.set;
      this.listReplaced();
    }
  }

  public clearList(): void {
    this.listService.clearListData();
    this.set = {};
    this.list = [];
    this.listReplaced();
  }

  public reloadList(): void {
    this.set.total = 0;
    this.list = [];
    this.loadList(true);
  }

  public fetchMoreItems(): void {
    this.loadList(false);
  }

  public applyFilters(filters: any): void {
    let filterArgs = '';
    const keys: string[] = Object.keys(filters);
    for (const key of keys) {
      filterArgs += key+'='+filters[key]+' and ';
    }
    if (filterArgs.length) {
      filterArgs = filterArgs.substr(0,filterArgs.length-5);  // remove last and
      this.listService.filter = filterArgs;
      this.reloadList();
    }
  }

  public spinnerOn(): void {
    this.reloading = true;
    this.changeRef.markForCheck();
  }

  public spinnerOff(): void {
    this.reloading = false;
    this.changeRef.markForCheck();
  }

  public listReplaced(): void  {
    this.reloading = false;
  }

  public isReloading(): boolean  {
    return this.reloading;
  }

  // if no security is available (i.e. we are in a pseudo-container) open all rights.
  public containerRights(): SecurityControl {
    let rc = this.security;
    if (!this.reloading && this.security === null) {
      rc = new SecurityControl(AccessLevel.ACCESS_LEVEL_FULL_RM);
    }
    return rc;
  }

  public getListTotal(): number {
    let total = 0;
    if (this.set.total) {
      total = this.set.total;
    }
    return total;
  }

  public getSet(): any {
    return this.set;
  }

  public getList(): ListItem[] {
    return this.list;
  }

  public getSortKey(): string {
    return !!this.ascending ? ('ascending='+this.ascending) : !!this.descending ? ('descending='+this.descending) : null;
  }

  public resetSortParams(): void {
    this.setSortParams(null, 'LAST_EDIT_DATE');
  }

  public getListState(): ListState {
    return new ListState(this.ascending, this.descending, this.set.start || 0);
  }

  public listItemChanged(data: any): void {
    if (data && data.set && data.list && data.list.length===1) {
      const newItem: ListItem = new ListItem(data.list[0]);
      const curItem: ListItem = this.list.find(i => i.id===newItem.id && i.type===newItem.type && i.lib===newItem.lib);
      if (curItem) {
        this.reloadList();
      }
    }
  }

  public canOpenListItem(listItem: ListItem): boolean {
    if (listItem && !!listItem.type && listItem.type !== 'libraries' && this.desc && this.desc.type !== 'requests' && listItem['ITEM_TYPE'] !== 'M' && !(!!listItem['PD_PART_STATUS'] && listItem['PD_PART_STATUS'].startsWith('Destroy'))) {
      if (Util.isContainer(listItem.type)) {
        return true;
      } else {
        if ((listItem['STATUS']!=='19' && listItem['READ_ONLY']!=='Y' && !(listItem['checkout'] && listItem['checkout']['TYPIST_ID'].toUpperCase() !== Util.RestAPI.getUserID())) || Util.Device.bIsElectron || Util.RestAPI.hasPFTA()) {
          const itemRights: SecurityControl = new SecurityControl(this.containerRights() ? this.containerRights().access : AccessLevel.ACCESS_LEVEL_FULL);
          if (listItem['%SECURITY']) {
            const itemAccess: number = +listItem['%SECURITY'];
            if (itemAccess > 0) {
              itemRights.access &= itemAccess;
            }
          }
          return itemRights.canViewDocument;
        }
      }
    }
    return false;
  }

  public openListItem(listItem: ListItem, version?: string, internal?: boolean, forceOpenOrView?: boolean): void {
    if (this.canOpenListItem(listItem)) {
      const isDownloads: boolean = Util.isSharedDownloads(this.desc);
      const isImports: boolean = Util.isSharedImports(this.desc);
      if (listItem['ITEM_TYPE'] === 'M' || listItem['STATUS'] === '18') {
        const notifyBody: string = listItem['STATUS'] === '18' ? this.localizer.getTranslation('FORMS.LOCAL.OPEN.ERROR_OPEN_CONTENT_DELETED', [listItem.id, listItem['DOCNAME']]) : this.localizer.getTranslation('FORMS.LOCAL.OPEN.ERROR_OPEN_PAPER');
        Util.Notify.warning(this.localizer.getTranslation('FOLDER_ACTIONS.OPEN'), notifyBody);
      } else {
        if ((isDownloads || isImports) && (forceOpenOrView || Util.isUnmangedFile(listItem))) {
          const fullPath: string = listItem['fullPath'];
          if (fullPath && fullPath.toUpperCase().endsWith('.DRF')) {
            Util.RestAPI.openDRFItem(listItem);
          } else if (forceOpenOrView) {
            if (listItem['ver']) {
              version = 'version_label' + listItem['ver'];
            } else if (!version) {
              version = 'C';
            }
            Util.RestAPI.viewFile(listItem, version, null, null, internal);
          }
          // can not preview an unmanaged file so do nothing
        } else if (listItem.type === 'urls' || Util.isContainer(listItem.type) || (Util.isExternalLib(listItem.lib) && !!(listItem as any).url)) {
          this.listService.openItem(listItem);
        } else {
          if (Util.Device.isMobile() && !forceOpenOrView) {
            // do not call base class as it will download files we always want preview
            this.listService.openMetadata(this.list, this.set, this.containerRights(), listItem, Util.RestAPI.canShowPreview() ? 'preview' : null);
          } else {
            const alwaysCheckoutDocumentOnOpen: boolean = Util.RestAPI.hasAutoCheckoutCheckin() && !internal;
            const rights: SecurityControl = Util.RestAPI.getRightsForItem(listItem);
            const canItemBeCheckout: boolean = rights.canEditContent && (listItem['type'] === 'documents') && listItem['STATUS'] === '0' && listItem['READONLY'] !== 'Y';
            const forceDL = alwaysCheckoutDocumentOnOpen && !canItemBeCheckout;
            if (forceDL || listItem['STATUS']==='19' || listItem['READ_ONLY']==='Y' || listItem['STATUS']==='3') {
              const title = this.localizer.getTranslation('FOLDER_ACTIONS.OPEN');
              const body = this.localizer.getTranslation(listItem['STATUS']==='3' ? 'FOLDER_ACTIONS.OPEN_CHECKED_OUT' : 'FOLDER_ACTIONS.OPEN_READ_ONLY', [listItem['DOCNAME']]);
              Util.Notify.confirm(title,body,null,null,true,true,true).then(confirmed => {
                if (confirmed && confirmed.confirm) {
                  Util.RestAPI.viewOrOpenFiles([listItem], !!version?[version]:undefined, internal || forceDL);
                }
              });
            } else {
              Util.RestAPI.viewOrOpenFiles([listItem], !!version?[version]:undefined, internal);
            }
          }
        }
      }
    }
  }

  public listItemClicked(listItem: ListItem, event: Event, internal?: boolean, forceOpenOrView?: boolean): void {
    this.openListItem(listItem, null, internal, forceOpenOrView);
  }

  public checkOutAndOpenFile(listItem: ListItem, version: string, path: string): void {
    Util.RestAPI.checkoutDocument(listItem, version).then(success => {
      if (success) {
        Util.RestAPI.viewFile(listItem, version, null, path, false, true);
      }
    });
  }

  public uploadFiles(items: any[], intoThisFolder?: ListItem): void {
    const files: File[] = typeof items[0] !== 'string' ? items : null;
    const filePaths: string[] = typeof items[0] === 'string' ? items : null;
    Util.RestAPI.uploadFilesWithUI(files, filePaths, null, intoThisFolder ? intoThisFolder : this.desc);
  }
}
