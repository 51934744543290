/*

  See icclient-settings.ts in models for data structure.

*/
import { Injectable } from '@angular/core';
import { Util } from '../utils/utils.module';

const kRoot = 'settings/icclient';

@Injectable()
export class SettingsService {

  public get(path: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      Util.RestAPI.get(kRoot, path, null, {observe:'response'}).subscribe(res => {
        if (res.status===200) {
          const data = !!res.body ? res.body.data : null;
          resolve(data);
        } else {
          resolve(null);
        }
      }, reject);
    });
  }

  public put(path: string, data: any): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      Util.RestAPI.put(kRoot, data, path, null, {observe:'response'}).subscribe(res => {
        if (res.status===200) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, reject);
    });
  }

  public delete(path: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      if (!!path) {
        Util.RestAPI.delete(kRoot, path).subscribe(res => {
          resolve(true);
        }, reject);
      } else {
        reject('Cannot delete root');
      }
    });
  }
}
