import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';

import { Util, UserInterface } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';
import { ListInterface, FilterListInterface } from '../models/list-item';
import { SelectItem } from '../models/form-field';
import { SelectComponent } from './select.component';
import { _Transforms } from '../utils/transforms';

@Component({
  selector: 'edx-search-filter',
  styleUrls: ['search-filter.component.scss' ],
  template: `
    <form *ngIf="!loading" class="edx-form indialog" [ngClass]="{mobile:ui>=2, lookup:desc.type==='lookups'}">
      <ng-template [ngIf]="desc.type==='lookups'">
        <label *ngIf="ui<2" for="sel">
          <span>{{searchBy}}</span>
        </label>
        <edx-select #sel [value]="selValue" [items]="items" class="spaced edx-select" [ngClass]="{right:ui>=2}" [justButton]="ui>=2" (change)="selChanged($event)" [id]="'edx_form_list_filter_select'"></edx-select>
      </ng-template>
      <input #searchField [id]="globalSearch?'edx_list_box_global_search':'edx_list_box_search'" placeholder="{{searchPlaceHolder}}" type="search" name="search" [ngClass]="{spaced:ui<2&&!asFilter, wide:ui>=2||asFilter}" [(ngModel)]="searchValue" (change)="fitlerTextChanged($event)" (search)="searchClicked()"/>
      <button [id]="globalSearch?'edx_list_btn_global_search':'edx_list_btn_search'" class="secondary spaced" [ngClass]="{notvisible:ui>=2 && desc.type==='lookups'}" (click)="searchClicked()">{{search}}</button>
    </form>
  `
})
export class SearchFilterComponent extends FilterListInterface implements OnInit, OnDestroy {
  @Input() searchValue?: string = '';
  @Input() selValue?: any;
  @Input() primaryValue?: string;
  @Input() primaryKey?: string;
  @Input() noWildcard?: boolean = false;
  @Input() lookupIsNumeric?: boolean = false;
  @Input() desc?: any = {};
  @Input() list: ListInterface;
  @Input() globalSearch?: boolean = false;
  @Input() asFilter?: boolean = false;
  @ViewChild(SelectComponent) private sel: SelectComponent;
  @ViewChild('searchField') private searchField: ElementRef;
  public searchPlaceHolder: string;
  public search = '';
  public ui: UserInterface;
  public loading = true;
  private items: SelectItem[] = [];
  private searchBy = '';
  private transforms: _Transforms = Util.Transforms;
  private boundKeyUp: any;
  private lastSearchTime: number = new Date().valueOf();

  constructor(private localizer: LocalizeService) {
    super();
    this.searchBy = this.localizer.getTranslation('FORMS.LOOKUPS.SEARCH_BY');
    this.search = this.localizer.getTranslation('FORMS.LOOKUPS.SEARCH');
    this.ui = Util.Device.ui;
  }

  ngOnInit() {
    this.boundKeyUp = this.keyUp.bind(this);
    this.search = this.asFilter ? this.localizer.getTranslation('TOOLTIP.FILTER') : this.localizer.getTranslation('FORMS.LOOKUPS.SEARCH');
    addEventListener('keyup', this.boundKeyUp, true);
    if (this.desc && this.desc.type==='lookups') {
      this.loadFilterCols(true);
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    if (!!this.boundKeyUp) {
      removeEventListener('keyup', this.boundKeyUp);
      this.boundKeyUp = null;
    }
  }

  private keyUp(event: KeyboardEvent) {
    if (event.which===13 && this.searchField && this.searchField.nativeElement === event.target) {
      event.preventDefault();
      event.stopPropagation();
      this.searchClicked();
    }
  }

  private loadFilterCols(pageLoad?: boolean): void {
    const cols: any = this.list ? this.list.getFilterColumnsFromSchema() : null;
    if (cols) {
      const items: SelectItem[] = [];
      const nCols: number = cols.length;
      for (let i=0; i<nCols; i++) {
        const col = cols[i];
        if (!!col) {
          let label: string;
          if (col.label) {
            label = col.label;
          } else if (col.text) {
            label = col.text;
          } else {
            label = col.property;
          }
          if (Util.RestAPI.restAPIVersion() >= 0x00160500) {
            if (!Util.FieldMappings.isLookupSecondaryKey(col.property)) {
              const selector: string = Util.FieldMappings.getLookupSearchSelectorText(col.property);
              if (selector) {
                label = this.localizer.getTranslation(selector);
              }
              items.push({display: label, value: col.property});
            }
          } else {
            items.push({display: label, value: col.property});
          }
          if (i===0 && !this.selValue) {
            this.selValue = col.property;
          }
        }
      }
      this.items = items;
      this.lastSearchTime = 0; // force change
      this.postChange(pageLoad);
      this.loading = false;
      setTimeout(() => {
        this.updatePlaceHolder();
      }, 10);
    } else {
      setTimeout(() => {
        this.loadFilterCols(pageLoad);
      }, 100);
    }
  }

  private postChange(pageLoad?: boolean): void {
    const now = new Date().valueOf();
    const elapsedTime = now - this.lastSearchTime;
    if (elapsedTime > 1000) {
      if (this.globalSearch) {
        this.list.handleFilterSearch(this.searchValue);
      } else {
        let searchFilter: string = null;
        let selKey: string = this.sel && this.sel.value ? this.sel.value : this.selValue ? this.selValue : 'DOCNAME';
        const parentLookupKey: string = this.primaryKey ? this.primaryKey : '';
        const parentLookupValue: string = this.primaryValue ? this.primaryValue : '';
        let lookupSearchValue: string = this.searchValue ? this.searchValue : '';
        const multiValueLookup: boolean = lookupSearchValue.indexOf('|') > 0 ? true : false;
        let secondarySelKey: string = null;
        if (Util.RestAPI.restAPIVersion() >= 0x00160500) {
          secondarySelKey = Util.FieldMappings.getLookupSecondaryKey(selKey);
          // Check whether SecondarySelKey is a correct description field on the profile form, otherwise return the mapped decsription field
          if (this.list.getField && !this.list.getField(secondarySelKey)) {
            const secondarySelKey2 = Util.FieldMappings.getLookupColumnPropName(secondarySelKey);
            if (!!this.list.getField(secondarySelKey2)) {
              secondarySelKey = secondarySelKey2;
            }
          }
        }
        if (lookupSearchValue === '' && !this.lookupIsNumeric) {
          this.noWildcard = false;
        } else {
          // Leave as-is since it is not going to be used for the filter
          if (!multiValueLookup) {
            lookupSearchValue = this.transforms.validateQueryValue(lookupSearchValue,'');
          }
        }
        if (this.list && (selKey || !this.sel)) { // only lookups have a select field
          if (selKey) {
            if (parentLookupKey && parentLookupValue && this.items.length) {
              // Primary parameter should come from the Parent field.
              searchFilter = parentLookupKey + '=' + parentLookupValue;
              if (!pageLoad) {
                if (secondarySelKey) {
                  searchFilter += ' and ' + selKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*') + ' or ' + secondarySelKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*');
                } else if (!lookupSearchValue || !this.lookupIsNumeric) {
                  searchFilter += ' and ' + selKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*');
                }
              }
            } else if (!pageLoad && lookupSearchValue && !multiValueLookup) {
              if (Util.FieldMappings.isLookupColumnPropName(selKey)) {
                const columnProp = Util.FieldMappings.getLookupColumnPropName(selKey);
                selKey = columnProp;
              }
              // If no lookupSearchValue then avoid searchFilter since wildcard as search value
              // On loading the lookup dialog, avoid searchFilter
              // does not work in all cases!? Compliments od DM Server oddity (yet another!!)
              if (secondarySelKey) {
                searchFilter = selKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*') + ' or ' + secondarySelKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*');
              } else {
                searchFilter = selKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*');
              }
            }
          }
          if (this.list.handleFilterChange(searchFilter, this.selValue || selKey) && this.sel) {
            this.sel.value = '';
          }
        }
      }
    }
    this.lastSearchTime = now;
  }

  private searchClicked(): void {
    const groupTable = this.list.getGroupTable();
    if (!!groupTable) {
      groupTable.setOpenedItem(null);
      groupTable.parent.usersTitle = this.localizer.getTranslation('SECURITY.USER_GROUP.USERS');
      groupTable.parent.showResetBtn = false;
    }
    this.postChange();
  }

  private fitlerTextChanged(event: Event): void {
    this.noWildcard = false;
  }

  private updatePlaceHolder(): void {
    if (this.desc && this.desc.type==='lookups' && this.sel && this.sel.value) {
      this.searchPlaceHolder = null;
      const item: SelectItem = this.sel.getItems().find(i => i.value===this.sel.value);
      if (item && item.display) {
        this.searchPlaceHolder = this.searchBy + ' ' + item.display;
      }
    }
  }

  private selChanged(sel: SelectComponent): void {
    this.updatePlaceHolder();
  }

  // *** FilterListInterface ***
  public clear(): void {
    this.searchValue = '';
  }

  public setSelKey(selKey: string): void {
    this.selValue = selKey;
    setTimeout(() => {
      this.updatePlaceHolder();
    }, 1);
  }

  public setList(items: SelectItem[]): void {
    this.items = items;
    setTimeout(() => {
      this.updatePlaceHolder();
    }, 1);
  }

  public getList(): SelectItem[] {
    return this.items;
  }

  public updateCols(): void {
   this.list.updateView();
   this.loadFilterCols();
 }
}
