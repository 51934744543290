import { Input, Component, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AbstractValueAccessor, MakeProvider } from './abstract-value-accessor';
import { FormField } from '../models/form-field';

@Component({
  selector: 'edx-link-field',
  providers: [MakeProvider(LinkFieldComponent)],
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
  <div class="textfield fullwidth" [formGroup]="form" [title]="value">
    <a *ngIf="isFile()" target="_blank" download [href]="value">{{field.checkLabel}}</a>
    <a *ngIf="!isFile()" target="_blank" [href]="value">{{field.checkLabel}}</a>
  </div>
`
})
export class LinkFieldComponent extends AbstractValueAccessor implements OnChanges {
  @Input() field: FormField;
  @Input() form: FormGroup;

  ngOnChanges() {
    this.value = this.form.controls[this.field.name].value;
  }

  public isFile(): boolean {
    return this.field.format === 'download';
  }
}
