/**
 * Created by kevin on 2016-10-20.
 *
 * Implements a filter sidebar component as an accessory to a file/search list
 *
 */

import { Component, OnChanges, Input } from '@angular/core';
import { BaseDesc } from '../models/base';
import { ListItem } from '../models/list-item';
import { Util } from '../utils/utils.module';

@Component({
  selector: 'edx-filelist-sidebar',
  styleUrls: [ 'filelist-sidebar.component.scss' ],
  template: `
    <div class="filelistcontainer" [ngClass]="{disabled:disabled}">
      <div *ngFor="let listitem of list; let i=index" [ngClass]="{ fileitem: true, selected: (selectIndex===i) }" (click)="selectFile(i)">
        <img class="fileicon" src="{{icons[i]}}" alt="{{altTexts[i]}}">
        <img *ngIf="displayMiniStatus(listitem)" class="overlaystatusicon" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}">
        <div class="filename">{{listitem.DOCNAME}}</div>
        <div *ngIf="(selectIndex===i)" class="selectarrow"></div>
      </div>
    </div>
  `
})
export class FilelistSidebarComponent implements OnChanges {
  @Input() receiver: FilelistReceiver;
  @Input() list: ListItem[] = [];
  @Input() selectIndex?: number = 0;
  @Input() rerender = 0;
  @Input() desc: BaseDesc;
  @Input() disabled?: boolean = false;
  private icons: string[] = [];
  private altTexts: string[] = [];

  ngOnChanges() {
    this.getIcons();
  }

  private getIcons(): void {
    if (this.list) {
      this.icons = [];
      this.altTexts = [];
      for (const item of this.list) {
        this.icons.push(Util.Transforms.iconUrlFromDesc(item));
        this.altTexts.push(Util.Transforms.iconAltTextFromDesc(item));
      }
    }
  }

  private selectFile(index: number): void {
    if (!this.disabled) {
      this.selectIndex = index;
      this.receiver.fileSelected(index);
    }
  }

  private displayMiniStatus(item: ListItem): boolean {
    return Util.RestAPI.displayMiniStatus(item, this.desc);
  }

  private formatStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatStatusIcon(item, this.desc);
  }

  private formatStatusText(item: ListItem): string {
    return Util.RestAPI.formatStatusText(item);
  }

  public disable(disable: boolean): void {
    this.disabled = disable;
  }
}

export interface FilelistReceiver {
  fileSelected(index: number): void;
}
