<div #container class="container" [ngClass]="{edx_hidden:hitPreviewShown&&!hitPreviewHiding&&!hitPreviewShowing, hasfooter:hasFooter, hasheader:hasHeader, tablet:tablet, indialog:hasFootprint, oai:isOfficeAddin, ie_edge:ieEdge, dragover:!dragOverItem&&dragover, readonly:!!schema && schema.readOnly}" (click)="forceRender($event)">
  <div *ngIf="hasHeader" class="header" id="edx_mobile_list_header">
    <div>
      <div class="header-count">{{countInfo()}}</div>
      <div *ngIf="!isSelectMode() && showFilterIcon()" class="toucharea right" (click)="filterClicked($event)" id="edx_hdr_icon_filter" [title]="this.localizer.getTranslation('TOOLTIP.FILTER')">
        <div class="toucharea-inner">
          <div class="icon filter" [ngClass]="{filters:filters}"></div>
        </div>
      </div>
    </div>
    <div *ngIf="!isSelectMode()">
      <div *ngIf="sortColumnName(true)" class="toucharea nomax" (click)="sortClicked($event)" id="edx_hdr_sort_column">
        <div class="toucharea-inner">
          <div *ngIf="sortColumnName(false)" class="icon arrow" [ngClass]="{reverse:sortIsReverse()}"></div>
          <div class="textheader">{{sortColumnName(true)}}</div>
        </div>
      </div>
      <edx-refresher *ngIf="!searchCriteria" id="edx-list-refresh" class="refresher" [ngClass]="{filters:showFilterIcon()}" (refresh)="reloadList()" [name]="reloadStr" [dark]="isOfficeAddin" [mini]="true"></edx-refresher>
      <edx-menu *ngIf="!hasFootprint && sortColumnName(true) && showMenuIcons()" [menuID]="31" [callback]="parent" [forceMenuButton]="true" class="inline sortcol"></edx-menu>
      <edx-menu *ngIf="!hasFootprint && showColumnChooser() && showMenuIcons()" [menuID]="16" [callback]="parent" [forceMenuButton]="true" class="inline right gear"></edx-menu>
      <edx-menu *ngIf="!hasFootprint && showConfigRed() && showMenuIcons()" [menuID]="42" [callback]="parent" [forceMenuButton]="true" class="inline right gear"></edx-menu>      
    </div>
    <div *ngIf="isSelectMode()">
      <div class="toucharea" (click)="cancelSelect($event)" id="edx_hdr_btn_selectcancel">
        <div class="toucharea-inner">
          <div class="textheader">{{this.localizer.getTranslation('FORMS.BUTTONS.CANCEL')}}</div>
        </div>
      </div>
      <div class="textheader center">{{formatNumberSelected()}}</div>
      <div class="toucharea right" (click)="selectUnselectAll()" id="edx_hdr_btn_selectclear">
        <div class="toucharea-inner">
          <div class="textheader">{{selectUnselectAllText}}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="filters || searchCriteria" class="filtered-header">
    <div class="toucharea">
      <div class="toucharea-inner">
        <div class="filters">{{filters || searchCriteria}}</div>
      </div>
    </div>
    <div *ngIf="filters" class="toucharea right" (click)="clearFilter($event)" id="edx_hdr_btn_filterclear" [title]="this.localizer.getTranslation('REFINE_SEARCH.CLEAR')">
      <div class="toucharea-inner">
        <div class="filters">{{this.localizer.getTranslation('REFINE_SEARCH.CLEAR')}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="infoMessage" class="item info-message">{{infoMessage}}</div>
  <div #listContainer class="listcontainer" [ngClass]="{ios:iOS, filters:filters || searchCriteria, schemalayout:useSchemaCols()}" [edx-gesture-longpress]="this" [edx-gesture-swipe]="this" [edx-gesture-doubletap]="this" [edx-drag-target]="this" (scroll)="scrollList($event, listContainer)" id="edx_mobile_list_container">
    <div *ngIf="!reloading && !hitPreviewShown && (!getList() || !getList().length)"><div class="item nohits">{{noHits}}</div></div>
    <ng-template ngFor let-item [ngForOf]="getList()" let-i="index">
      <div [id]="'edx_li_'+i" class="item" [ngClass]="{selected:selections.indexOf(item)>=0, dropfolder:dropFolder(item), dragover:dragOverItem==item, folder:renderItemAsFolder(item), bb:!item.preview, threerows:hasThirdRow(item)}" (mouseover)="isOfficeAddin?enableHoverMenu(i):undefined" (mouseleave)="isOfficeAddin?disableHoverMenu(i):undefined" (click)="itemClicked(item, $event)">
        <div *ngIf="hasSelectCheckboxes()" class="selector" [ngClass]="{related:schemaId==='RELATED', disabled:!canSelectItem(item)}" (click)="selectorClick(item, $event)"></div>
        <ng-template [ngIf]="schemaId==='RELATED'">
          <div class="iconwrapper related" [ngClass]="{edx_circle:isSelectMode()&&!officeAddin, indent:isSelectMode()&&!officeAddin}">
            <img class="icon" [ngClass]="{indent:isSelectMode() &&!officeAddin && selections.indexOf(item)==-1}" src="{{getIconSrc(item)}}" alt="{{formatTypeText(item)}}">
          </div>
        </ng-template>
        <ng-template [ngIf]="showIconWrapper(item)">
          <div class="iconwrapper" [ngClass]="{edx_circle:isSelectMode()&&!officeAddin, indent:isSelectMode()&&!officeAddin, edx_hidden:!canSelectItem(item)}">
            <ng-template [ngIf]="showIcon(item) && schemaId!=='VERSIONS'">
              <img class="icon" [ngClass]="{indent:isSelectMode() &&!officeAddin && selections.indexOf(item)==-1}" src="{{getIconSrc(item)}}" alt="{{formatTypeText(item)}}">
              <img *ngIf="displayStatusIcon(item)" class="statusicon" src="{{formatStatusIcon(item)}}" alt="{{formatStatusText(item)}}">
            </ng-template>
            <ng-template [ngIf]="showIcon(item) && schemaId=='VERSIONS'">
              <div class="icontext">{{item['VERSION_LABEL']}}</div>
              <img class="icon versions" [ngClass]="{indent:isSelectMode() &&!officeAddin && selections.indexOf(item)==-1}" src="{{getIconSrc(item)}}" alt="{{formatTypeText(item)}}">
              <img *ngIf="displayStatusIcon(item)" class="statusicon" src="{{formatStatusIcon(item)}}" alt="{{formatStatusText(item)}}">
            </ng-template>
          </div>
        </ng-template>
        <div *ngIf="renderItemWithSchema(item)" class="schemawrapper">
          <div *ngFor="let col of schema.columns; let j=index" class="schemarow">
            <ng-template [ngIf]="showColForItem(col, item)">
              <div class="label">{{col.label}}</div>
              <span [ngSwitch]="col.format">
                <div *ngSwitchCase="2" class="name" [title]="formatString(item,col.property)">{{formatString(item,col.property)}}</div>
                <div *ngSwitchCase="3" class="name">{{formatDate(item, col.property)}}</div>
                <div *ngSwitchCase="5" class="name">{{formatStatusText(item)}}</div>
                <div *ngSwitchCase="7" class="name">{{formatPath(item[col.property])}}</div>
                <div *ngSwitchCase="9" class="name">{{formatAction(item)}}</div>
                <div *ngSwitchCase="12" class="name">{{formatEnum(item,col.property,j)}}</div>
                <img *ngSwitchCase="13" class="image" src="listitem[col.property]">
                <div *ngSwitchCase="14" class="selector schemacb" [ngClass]="{on:!!item[col.property], readonly:col.readonly||isReadonly(i,col.property)}" (click)="checkboxClick(item,col.property,$event)"></div>
                <div *ngSwitchDefault class="name">{{formatString(item,col.property)}}</div>
              </span>
            </ng-template>
          </div>
          <div *ngIf="showExtrasRow(item)" class="schemarow">
            <div class="label">{{labelExtrasRow(item)}}</div>
            <div class="name">{{formatExtrasRow(item)}}</div>
          </div>
        </div>
        <ng-template [ngIf]="!renderItemWithSchema(item)">
          <div *ngIf="schemaId==='WHERE_USED' && hasSelectCheckboxes()" class="selector" (click)="selectorClick(item, $event)"></div>
          <div *ngIf="schemaId==='WHERE_USED' && isContainer(item)" class="iconwrapper" [ngClass]="{edx_circle:showIconWrapperSelector(item), indent:showIconWrapperSelector(item)}">
            <img *ngIf="!showIconWrapperSelector(item)" class="icon" src="{{getIconSrc(item)}}" alt="{{formatTypeText(item)}}">
          </div>
          <div class="textwrapper" [ngClass]="{oneline:isOneLine(),versions:schemaId==='VERSIONS'}">
            <ng-template [ngIf]="schemaId!='SECURITY'">
              <div class="name" title={{getDetails(item)}}>{{getDetails(item)}}</div>
              <div class="inline other">{{getOther1(item)}}</div>
              <ng-template [ngIf]="!!getOther2(item)">
                <div *ngIf="!isOneLine()" class="inline bullet">â¢</div>
                <div class="inline other">{{getOther2(item)}}</div>
              </ng-template>
              <ng-template [ngIf]="hasThirdRow(item)">
                <div></div>
                <div class="inline other">{{getOther3(item)}}</div>
                <div *ngIf="!tablet && !!getOther4(item)" class="inline bullet">â¢</div>
                <div *ngIf="!!getOther4(item)" class="inline other">{{getOther4(item)}}</div>
              </ng-template>
            </ng-template>
            <ng-template [ngIf]="schemaId=='SECURITY'">
              <div class="name inline security" [ngClass]="{ios:iOS}">{{getDetails(item)}}</div>
              <div class="select-wrapper" [ngClass]="{oneline:isOneLine()}">
                <edx-select [id]="i" [items]="rightsValues" [value]="setDisplayAccessLevel(item['rights'])" (change)="setTemplatedRights($event, i)" [disabled]="isReadonly(i,'rights')" [inAccessRights]="true"></edx-select>
              </div>
            </ng-template>
          </div>
        </ng-template>
        <edx-inline-action-bar *ngIf="inlineActionMenuId!=-1" [resid]="inlineActionMenuId" [shown]="item==hoverListItem" [target]="inlineActionTarget" [onWhite]="isOfficeAddin"></edx-inline-action-bar>
      </div>
      <div *ngIf="!!item.preview" class="hitpreview" (click)="itemClicked(item, $event)">
        <edx-list-item-preview [desc]="item"></edx-list-item-preview>
      </div>
    </ng-template>
  </div>
</div>
<div *ngIf="hitPreviewShown" class="hitpreviewpopup" [ngClass]="{shown: hitPreviewShown, hiding: hitPreviewHiding, showing: hitPreviewShowing}" (animationend)="hitPreviewAnimationComplete()">
  <div class="hitpreviewheader">
    <div class="hitpreviewtitle">
      {{hitPreviewData?hitPreviewData.DOCNAME:""}}
      <div class="rowicon close" (click)="hitPreviewLeave($event)"></div>
    </div>
    <div class="hitpreviewpager">
      <span>{{hitPreviewData?this.localizer.getTranslation('PAGINATOR.OF_PAGES',[hitPreviewData.page,hitPreviewData.totalpages]):""}}</span>
      <div class="hitpreviewbuttons">
        <div class="rowicon prev" (click)="showHitPreview(null, -1)"></div>
        <div class="rowicon next" (click)="showHitPreview(null, 1)"></div>
      </div>
    </div>
  </div>
  <div class="hitpreviewcontainer">
    <div class="hitpreviewbody">
      <edx-spinner *ngIf="hitPreviewLoading"></edx-spinner>
     <edx-list-item-preview *ngIf="hitPreviewData" [data]="hitPreviewData.runs"></edx-list-item-preview>
    </div>
  </div>
</div>
<edx-popup #pickerPopup *ngIf="!!customSecurityItem" [callback]="this" [title]="this.localizer.getTranslation('SECURITY.LEVELS.CUSTOM')" [okDisabled]="false">
  <edx-custom-security [desc]="desc" [listItem]="customSecurityItem" (customSecurityChange)="customSecurityChange($event)"></edx-custom-security>
</edx-popup>
<edx-spinner *ngIf="reloading"></edx-spinner>