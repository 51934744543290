import { Component, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';

import { Util } from '../utils/utils.module';
import { ListItem } from '../models/list-item';
import { LocalizeService } from '../services/localize.service';
import { SchemaService } from '../services/schema.service';
import { ColFormat, ColumnDesc } from '../models/column';
import { DataService } from '../services/data.service';
import { LookupService } from '../services/lookup.service';
import { ListService } from '../services/list.service';
import { ListTableComponent } from './list-table.component';

@Component ({
  selector: 'edx-list-summary',
  styleUrls: [ 'list-table.component.scss', 'list-summary.component.scss' ],
  providers: [ListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ListTableComponent.baseClassInputs,
  template: `
    <div class="summary" [ngClass]="{slidright: hitPreviewShown, slidingleft: hitPreviewHiding, slidingright: hitPreviewShowing}">
      <div class="header" id="edx_summary_list_header">
        <div class="selector" (click)="headerSelectClick()" [ngClass]="{headerselect:!!getList().length, partialselect:!noneSelected, allselect:allSelected}"></div>
        <div *ngIf="sortColumnName()" class="inline sorter" id="edx_hdr_sort_column">
          <div class="inline icon arrow" [ngClass]="{reverse:sortIsReverse()}" (click)="sortClicked($event)"></div>
          <div class="name sortname">{{sortColumnName()}}</div>
          <edx-menu [menuID]="31" [callback]="parent" [forceMenuButton]="true" class="inline sortcol"></edx-menu>
        </div>
        <div class="headerexpand" [ngClass]="{allexpand: allExpanded}" title="{{seeAllStr}}" (click)="headerExpandClick($event)"></div>
      </div>
      <div #container class="container" [ngClass]="{dragover:dragover}" [edx-drag-target]="this">
        <div #listContainer class="listcontainer" id="edx_summary_list_container">
          <div *ngIf="!reloading && (!getList() || !getList().length)"><div class="item nohits">{{noHits}}</div></div>
          <ng-template ngFor let-listitem [ngForOf]="getList()" let-i="index">
            <div [id]="i" class="item" [ngClass]="{selected:selections.indexOf(listitem)>=0, disabled:!isSelectable(listitem), dropfolder:dropFolder(listitem), dragover:dragOverItem===listitem, folder:isContainer(listitem)}" (mouseover)="enableHoverMenu(i)" (mouseleave)="disableHoverMenu(i)">
              <div class="selector" [ngClass]="{disabled:!canSelectItem(listitem)}" (click)="selectorClick(listitem, $event)"></div>
              <div class="appicon" (click)="listItemClicked(listitem, $event)">
                <edx-spinner *ngIf="listitem.id==='' && listitem.lib==='' " [mini]="true"></edx-spinner>
                <img *ngIf="listitem.id!=='' || listitem.lib!==''" class="rowicon clickable" src="{{formatTypeIcon(listitem)}}" alt="{{formatTypeText(listitem)}}" (click)="listItemClick(listitem, $event, 'APP_ID')">
                <img *ngIf="displayMiniStatus(listitem, 'APP_ID')" class="overlaystatusicon clickable" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}" (click)="listItemClick(listitem, $event, 'APP_ID')">
              </div>
              <div class="maincontent">
                <div class="inlineactionwrapper">
                  <edx-inline-action-bar *ngIf="inlineActionMenuId!==-1" [resid]="inlineActionMenuId" [shown]="listitem===hoverListItem" [target]="inlineActionTarget"></edx-inline-action-bar>
                </div>
                <div class="name" (click)="listItemClicked(listitem, $event)">{{formatString(listitem,'DOCNAME')}}</div>
                <div class="hitpreview">
                  <edx-list-item-preview [desc]="listitem"></edx-list-item-preview>
                </div>
              </div>
              <div class="extracontent">
                <span *ngIf="extraCols.length">
                  <ng-template ngFor let-col [ngForOf]="extraCols" let-iCol="index">
                    <div *ngIf="isColumnShown(listitem, iCol)" class="extrarow">
                      <div class="label">{{col.label}}</div>
                      <span [ngSwitch]="col.format">
                        <div *ngSwitchCase="2" class="name">{{formatString(listitem,col.property)}}</div>
                        <div *ngSwitchCase="3" class="name">{{formatDate(listitem, col.property)}}</div>
                        <div *ngSwitchCase="5" class="status">
                          <img *ngIf="displayStatusIcon(listitem)" class="statusicon" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}">
                          <div class="name" [ngClass]="{italic:statusTextItalic(listitem), red:statusTextRed(listitem), blue:statusTextBlue(listitem)}">{{formatStatusText(listitem)}}</div>
                        </div>
                        <div *ngSwitchCase="7" class="name">{{formatPath(listitem[col.property])}}</div>
                        <div *ngSwitchCase="9" class="name">{{formatAction(listitem)}}</div>
                        <div *ngSwitchCase="11" class="name relevance"><edx-progress [value]="formatProgress(listitem)"></edx-progress></div>
                        <div *ngSwitchCase="12" class="name">{{formatEnum(listitem,col.property,iCol)}}</div>
                        <div *ngSwitchDefault class="name">{{formatString(listitem,col.property)}}</div>
                      </span>
                    </div>
                  </ng-template>
                </span>
              </div>
              <div class="expander" [ngClass]="{expanded:isExpanded(listitem)}" (click)="expanderClick(listitem, $event)" title="{{seeMoreStr}}"></div>
            </div>
          </ng-template>
        </div>
      </div>
      <edx-spinner *ngIf="reloading"></edx-spinner>
    </div>
    <div *ngIf="hitPreviewShown" class="hitpreviewpopup" [ngClass]="{shown: hitPreviewShown, hiding: hitPreviewHiding, showing: hitPreviewShowing}" (mouseleave)="hitPreviewLeave($event)" (animationend)="hitPreviewAnimationComplete()">
      <div class="hitpreviewheader">
        <div class="hitpreviewtitle">{{hitPreviewData?hitPreviewData.DOCNAME:""}}</div>
        <div class="hitpreviewpager">
          <span>{{getPageOfPages()}}</span>
          <div class="hitpreviewbuttons">
            <div class="rowicon prev" (click)="showHitPreview(null, -1)"></div>
            <div class="rowicon next" (click)="showHitPreview(null, 1)"></div>
          </div>
        </div>
      </div>
      <div class="hitpreviewcontainer">
        <div class="hitpreviewbody">
          <edx-spinner *ngIf="hitPreviewLoading"></edx-spinner>
        <edx-list-item-preview *ngIf="hitPreviewData" [data]="hitPreviewData.runs"></edx-list-item-preview>
        </div>
      </div>
    </div>
  `
})
export class ListSummaryComponent extends ListTableComponent {
  @ViewChild('container') private container: ElementRef;
  @ViewChild('listContainer') private listContainer: ElementRef;
  @Input() schemaLayout = false;
  @Output() actionHandler: EventEmitter<string> = new EventEmitter<string>();
  public seeAllStr: string = this.localizer.getTranslation('TOOLTIP.SEE_ALL');
  public seeMoreStr: string = this.localizer.getTranslation('TOOLTIP.SEE_MORE');
  private extraColsShown = 3;
  private extraCols: ColumnDesc[] = [];

  constructor(protected listService: ListService, protected changeDetector: ChangeDetectorRef, protected schemaService: SchemaService,
              protected localizer: LocalizeService, protected dataService: DataService, protected lookupService: LookupService) {
    super(listService, changeDetector, schemaService, localizer, dataService, lookupService);
  }

  public getPageOfPages(): string {
    return this.hitPreviewData?this.localizer.getTranslation('PAGINATOR.OF_PAGES',[this.hitPreviewData.page,this.hitPreviewData.totalpages]):'';
  }

  private isContainer(item: ListItem): boolean {
    return Util.isContainer(item.type);
  }

  private isColumnShown(item: ListItem, iCol: number): boolean {
    return iCol<3 || this.expandedItems.indexOf(item)>=0;
  }

  private isExpanded(item: ListItem): boolean {
    return this.expandedItems.indexOf(item)>=0;
  }

  private sortClicked(event: Event): void {
    this.headerColumnClick(event, this.getSortCol());
    this.changeDetector.markForCheck();
  }

  private sortIsReverse(): boolean {
    return !!this.ascending;
  }

  public sortColumnName(): string {
    if (this.schema) {
      const sortCol: string = this.getSortCol();
      if (sortCol) {
        let column: ColumnDesc = this.schema.columns.find(c => c.property===sortCol);
        if (!column) {
          column = this.schema.columns.find(c => c.property===this.schema.sortAscending || c.property===this.schema.sortDescending);
        }
        if (column) {
          return column.label;
        }
      }
    }
    return null;
  }

  protected descChanged(newDesc: any): void {
    super.descChanged(newDesc);
    this.layoutComplete = false;
    this.setVisibleColumnCount();
  }

  protected setVisibleColumnCount(): void {
    if (!this.layoutComplete) {
      const isSearch: boolean = this.desc && this.desc.id.startsWith('evaluation');
      const isSearchSaved: boolean = this.desc && this.desc.type === 'searches' && !isNaN(parseInt(this.desc.id));
      this.extraCols = [];
      if (this.schema && this.schema.columns) {
        let col = this.schema.columns.find(c => c.property === 'relevance');
        if (!!col) {
          this.extraCols.push(col);
        }
        for (col of this.schema.columns) {
          if (!!col.label && col.property !== 'relevance' && col.property !== 'APP_ID' && col.property !== 'DOCNAME' && col.format !== ColFormat.EXPANDER && col.format !== ColFormat.SELECTOR) {
            this.extraCols.push(col);
          }
        }
      }
      this.changeDetector.markForCheck();
      this.layoutComplete = true;
    }
  }

  protected getContainerElement(): HTMLElement {
    return this.container ? this.container.nativeElement : null;
  }

  protected getListElement(): HTMLElement {
    return this.listContainer ? this.listContainer.nativeElement : null;
  }

  protected getDragOverInfo(event: any): {dragChanged: boolean; rowDocName: string} {
    let target: any = event.target;
    let dragChanged = false;
    let rowDocName: string;

    while (target && !target.classList.contains('item')) {
      target = target.parentElement;
    }
    if (target) {
      if (this.dragoverTR !== target) {
        this.dragLeave(null, null);
        this.dragoverTR = target;
        if (!target.classList.contains('dropfolder')) {
          dragChanged = true;
          this.dragover = this.dragoverHasFiles;
        } else {
          const index: number = Array.prototype.indexOf.call(this.dragoverTR.parentNode.children, this.dragoverTR);
          if (index>=0 && index<this.list.length) {
            this.dragOverItem = this.list[index];
            rowDocName = this.dragOverItem.DOCNAME;
          }
          this.dragover = false;
          dragChanged = true;
          this.dragoverTR.classList.add('dragover');
        }
      }
    } else {
      dragChanged = true;
    }
    return {dragChanged, rowDocName};
  }

  public commandEnabled(cmd: string): boolean {
    if (cmd.startsWith('list_choosecol_')) {
      if (!this.set || !this.set.limitedsorting || this.set.limitedsorting.indexOf(cmd.substr(15))!==-1) {
        return true;
      } else {
        return false;
      }
    }
    return super.commandEnabled(cmd);
  }

  public doCommand(cmd: string): boolean {
    if (cmd.startsWith('list_choosecol_')) {
      if (this.descending) {
        this.descending = cmd.substr(15);
      } else if (this.ascending) {
        this.ascending = cmd.substr(15);
      }
      this.resort(1);
      return true;
    }
    return super.doCommand(cmd);
  }
}
