import { Component, Input, Output, OnInit, OnChanges, EventEmitter, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

import { BaseDesc } from '../models/base';
import { SelectItem } from '../models/form-field';
import { AccessRights, SecurityControl } from '../models/security-control';
import { Util } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';
import { ListBaseComponent } from '../lists/list-base.component';
import { DropDown } from './select.component';

const kRLKey = 'edx_recent_locations';
const kChooser = 'edx_choose_container';
const kChooseFP = 'edx_choose_fp';

@Component({
  selector: 'edx-location-chooser',
  styleUrls: ['select.component.scss'],
  template: `
    <div #sel class="edx_select indialog" [ngClass]="{open:isOpen, phone:isPhone}" (click)="toggleMenuOpen($event)" tabindex="0">
      <div class="text">{{currentItem ? currentItem.display : value}}</div>
    </div>
    <div *ngIf="isOpen" class="overlay" (click)="toggleMenuOpen($event)"></div>
    <ul #ul [ngClass]="{edx_hidden:!isOpen, right:ulRight}" [style.top]="ulTop" [style.left]="ulLeft" [style.width]="ulWidth">
      <li *ngFor="let item of _items" [ngClass]="{selected:item===currentItem, separator:item.separator, disabled:item.disabled||item.separator, heading:item.heading}" (click)="itemSelected(item, $event)" [title]="getTitle(item)">
        <div *ngIf="!item.separator" class="text">{{item.display}}</div>
      </li>
    </ul>
  `
})
export class LocationChooserComponent extends DropDown implements OnInit, OnChanges {
  @Input('items') set setter(items: SelectItem[]) {
 this._items = items;
}
  @Input() desc?: BaseDesc = null;
  @Output() change: EventEmitter<BaseDesc> = new EventEmitter<BaseDesc>();
  @ViewChild('ul') private ul: ElementRef;
  @ViewChild('sel') private sel: ElementRef;
  private pickFileParts = false;
  public value: string = null;
  public currentItem: SelectItem = null;

  constructor(protected cdr: ChangeDetectorRef, private localizer: LocalizeService) {
    super(cdr);
  }

  ngOnInit() {
    if (!!this.desc && !!this.desc['PD_FILEPT_NO']) {
      this.pickFileParts = true;
    }
  }

  ngOnChanges() {
    const deflib = Util.RestAPI.getPrimaryLibrary();
    this._items = [];
    this.currentItem = null;
    this.addRecentItems();
    if (this.isSaveToContainer()) {
      const itemInList = this.descItemInItems();
      if (!itemInList) {
        if (!this._items.length) {
          this._items.push({display: this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.RECENT_CONTAINERS'), value: '', heading: true});
        } else if (!this._items[0].heading) {
          this._items.splice(0,0,{display: this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.RECENT_CONTAINERS'), value: '', heading: true});
        }
        this._items.splice(1,0,this.descToSelect());
        this.currentItem = this._items[1];
      } else {
        this.currentItem = itemInList;
      }
    }
    if (this._items.length) {
      this._items.push({display: '', value: '', separator: true});
    }
    this._items.push({display: this.localizer.getTranslation('NAVBAR.PRIMARY'), value: JSON.stringify({id:'recentedits',type:'folders',lib:deflib})});
    if (!this.currentItem) {
      this.currentItem = this._items[this._items.length-1];
    }
    this._items.push({display: this.localizer.getTranslation('FORMS.PLACEHOLDERS.CONTAINER'), value: kChooser});
    if (this.pickFileParts) {
      this._items.push({display: this.localizer.getTranslation('FORMS.LOCAL.COPY.SELECT_FILEPART'), value: kChooseFP});
    }
    this.value = this.currentItem.value;
  }

  protected getNativeUL(): HTMLUListElement {
    return !!this.ul ? this.ul.nativeElement : null;
  }

  @HostListener('keyup', ['$event'])
  protected onKeyup(event: KeyboardEvent): boolean {
    return super.onKeyup(event);
  }

  private isSaveToContainer(): boolean {
    let allowed = false;
    if (!!this.desc) {
      const primaryLib = Util.RestAPI.getPrimaryLibrary();
      const isPrimaryLib = !this.desc.lib || this.desc.lib.toUpperCase() === primaryLib.toUpperCase();
      if (!isPrimaryLib) {
        const libRights = Util.RestAPI.getLibraryEffectiveRights(this.desc.lib);
        allowed = libRights['ALLOW_DOC_CREATE'] === 'Y';
      } else {
        allowed = true;
      }
      allowed = allowed && (Util.isSaveToLocation(this.desc) || (!isPrimaryLib && this.desc.id==='recentedits'));  // recenentedits is the root of the lib in the chooser
      if (allowed) {
        let contanerRights = this.desc.type === 'workspaces' ? this.desc['%EFFECTIVE_RIGHTS'] : this.desc['%SECURITY'];
        if (!contanerRights) {
          const curlistComponent: ListBaseComponent = Util.RestAPI.getCurListComponent();
          const securityControl = !!curlistComponent ? curlistComponent.containerRights() : new SecurityControl();
          contanerRights = securityControl.access;
        }
        if (!!contanerRights) {
          const containerAccess = +contanerRights;
          if (Util.isRMFilePart(this.desc)) {
            if (this.desc['PD_ACTIVE_STATUS'] === 'C' && !(containerAccess & AccessRights.ACCESS_CAN_ASSIGN_TO_FILEPART)) {
              allowed = false;
            }
          } else if (!(containerAccess & AccessRights.ACCESS_EDIT_CONTENT)) {
            allowed = false;
          }
          return allowed;
        }
      }
      return allowed;
    }
  }

  private getTitle(item: SelectItem): string {
    if (!!item.value) {
      try {
        const listItem = JSON.parse(item.value);
        if (!!listItem && !!listItem['pickerpath']) {
          return listItem['pickerpath'];
        }
      } catch (e) {}
    }
    return '';
  }

  private saveRecents(): void {
    const locs: any[] = [];
    for (const item of this._items) {
      if (!item.heading && !item.disabled && !item.separator && item.value !== kChooser && item.value !== kChooseFP) {
        const desc: any = JSON.parse(item.value);
        if (desc.id !== 'recentedits' && desc.type !== 'fileplans') {
          locs.push(desc);
        }
      }
    }
    if (locs.length) {
      localStorage.setItem(kRLKey, JSON.stringify(locs));
    }
  }

  private descToSelect(): SelectItem {
    if (!!this.desc) {
      const item: any = this.desc;
      const name = (item.DOCNAME || item.name) + (item.PD_FILE_NAME || '');
      return {display: name, value: JSON.stringify({id:this.desc.id, type:this.desc.type, lib:this.desc.lib, DOCNAME:name, pickerpath:(this.desc as any).pickerpath})};
    }
    return null;
  }

  private descItemInItems(): SelectItem {
    const itemInList = this._items.find(i => {
      if (!!i.value && i.value !== kChooser && i.value !== kChooseFP) {
        const item = JSON.parse(i.value);
        if (Util.isSameDesc(item, this.desc)) {
          return item;
        }
      }
      return null;
    });
    return itemInList;
  }

  private addRecentItems(): void {
    const recentStr: string = localStorage.getItem(kRLKey);
    const recent: any[] = !!recentStr ? JSON.parse(recentStr) : [];
    let bAddedTitle = false;
    for (const loc of recent) {
      if (!Util.isSameDesc(loc, this.desc) && !Util.isExternalLib(loc.lib)) {
        if (!bAddedTitle) {
          bAddedTitle = true;
          this._items.push({display: this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.RECENT_CONTAINERS'), value: '', heading: true});
        }
        this._items.push({display: loc.DOCNAME, value: JSON.stringify(loc)});
      }
    }
  }

  public toggleMenuOpen(event: Event): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.checkLayout(!!this.sel ? this.sel.nativeElement : null , !!this.ul ? this.ul.nativeElement : null);
    } else {
      this.saveRecents();
      this.menuClosing();
    }
    super.toggleMenuOpen(event);
  }

  protected itemSelected(item: SelectItem, event: Event): boolean {
    if (this.isOpen) {
      if (!!item && this.currentItem !== item) {
        const updateFromItem = (itemInList: SelectItem) => {
          if (!!itemInList) {
            const index = this._items.indexOf(itemInList);
            this._items.splice(index,1);
          }
          if (!this._items[0].heading) {
            this._items.splice(0,0,{display: this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.RECENT_CONTAINERS'), value: '', heading: true});
          }
          this._items.splice(1,0,this.descToSelect());
          this.currentItem = this._items[0];
          if (this._items.length === 10) {
            this._items.splice(6,1);
          }
          this.value = this.currentItem.value;
          this.change.emit(this.desc);
        };
        const postPick = ((list: any[], success: boolean) => {
          if (success && list && list.length) {
            if (item.value===kChooseFP) {
              this.desc = Util.deepCopy(list[0]);
            } else {
              this.desc = ({id:list[0].id || 'recentedits', type:list[0].type || 'folders', lib:list[0].lib, DOCNAME:list[0].DOCNAME || list[0].name, pickerpath:list[0].pickerpath} as any);
            }
            updateFromItem(this.descItemInItems());
            this.saveRecents();
          }
        });
        if (item.value===kChooser) {
          Util.RestAPI.pickFolders(null, false, true, true, postPick);
        } else if (item.value===kChooseFP) {
          Util.RestAPI.pickFilepart(postPick);
        } else if (!!item.value) {
          this.desc = JSON.parse(item.value);
          updateFromItem(item);
        }
      }
      this.toggleMenuOpen(event);
    }
    return false;
  }
}
