import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavItem } from './models/nav-item';
import { MenuItem, MenuItemDesc } from './models/menu-item';
import { MenuService, MenuId, MenuDef } from './services/menu.service';
import { NavService } from './services/nav.service';
import { LocalizeService } from './services/localize.service';
import { Util, UserInterface } from './utils/utils.module';

@Component({
  selector: 'edx-app-nav-sidebar',
  styleUrls: ['app-nav-sidebar.component.scss'],
  template: `
  <div *ngIf="expanded || isShowing || isHiding" class="container-mask" (click)="dismiss($event)">
  </div>
  <div class="container" [ngClass]="{mobile:ui>=2, oai:isOfficeAddin, edgeie:isEdgeIE, expanded:expanded, showing:isShowing, hiding:isHiding}" (animationend)="sidebarAnimationComplete()">
    <div *ngIf="ui>=2" class="welcome">
      <div class="name">{{welcome}}</div>
    </div>
    <ul>
      <ng-template [ngIf]="!!startItem">
        <li>
          <div class="startitem" title="{{startItem.name | localize}}" (click)=navItemClicked(startItem) id="{{getNavItemDisplayId(startItem)}}">
            <img class="icon" src="{{getNavIconSrc(startItem)}}" alt="{{getNavIconText(startItem)}}">
            <div class="name">{{startItem.name}}</div>
          </div>
          <div class="separator"></div>
        </li>
      </ng-template>
      <ng-template [ngIf]="menuItems && menuItems.length">
        <li *ngFor="let item of menuItems">
          <div *ngIf="!item.separator" class="item" title="{{item.name | localize}}" (click)=menuItemClicked(item) id="{{'edx_navmenu_'+item.cmd}}">
            <img *ngIf="item.iconic" class="icon" src="{{getMenuIconSrc(item)}}" alt="{{getMenuIconText(item)}}">
            <div class="name">{{item.name | localize}}</div>
          </div>
          <div *ngIf="item.separator" class="separator"></div>
        </li>
        <li *ngIf="navItems.length && !menuItems[menuItems.length-1].separator"><div class="separator"></div></li>
      </ng-template>
      <ng-template [ngIf]="navItems.length">
        <li *ngFor="let item of navItems" [ngSwitch]="item.type">
          <div *ngSwitchDefault class="item" title="{{item.name | localize}}" (click)=navItemClicked(item) id="{{getNavItemDisplayId(item)}}">
            <img class="icon" src="{{getNavIconSrc(item)}}" alt="{{getNavIconText(item)}}">
            <div class="name">{{item.name}}</div>
            <img *ngIf="checkIt(item)" class="icon" src="assets/images/checkmark.svg"/>
          </div>
          <div *ngSwitchCase="'separator'" class="separator"></div>
          <div *ngSwitchCase="'heading'" class="heading">
            <div class="name" title="{{item.name | localize}}">{{item.name}}</div>
            <div class="libkind" title="{{getLibKind(item)}}">{{getLibKind(item)}}</div>
          </div>
        </li>
      </ng-template>
      <ng-template [ngIf]="menuItemsPostNav && menuItemsPostNav.length">
        <li><div class="separator"></div></li>
        <li *ngFor="let item of menuItemsPostNav">
          <div *ngIf="!item.separator" class="item" title="{{item.name | localize}}" (click)=menuItemClicked(item) id="{{'edx_navmenu_'+item.cmd}}">
            <img *ngIf="item.iconic" class="icon" src="{{getMenuIconSrc(item)}}" alt="{{getMenuIconText(item)}}">
            <div class="name">{{item.name | localize}}</div>
          </div>
          <div *ngIf="item.separator" class="separator"></div>
        </li>
      </ng-template>
    </ul>
  </div>
  `
})
export class AppNavSidebarComponent implements OnInit, OnDestroy {
  public ui: UserInterface;
  public isOfficeAddin: boolean;
  public isEdgeIE: boolean;
  public expanded = false;
  public isShowing = false;
  public isHiding = false;
  public menuItems: MenuItemDesc[] = [];
  public navItems: NavItem[] = [];
  public menuItemsPostNav: MenuItemDesc[] = [];
  private navLoc = '';
  private routerSubscription: Subscription;
  private remote: string;
  private primary: string;
  private welcome: string;
  private startDesc: any = null;
  public startItem: NavItem = null;

  constructor(private menuService: MenuService, private navService: NavService, private localizer: LocalizeService, private router: Router) {
    this.ui = Util.Device.ui;
    this.isOfficeAddin = Util.Device.bIsOfficeAddin;
    this.isEdgeIE = Util.Device.bIsEdge  || Util.Device.bIsIE;
    this.remote = localizer.getTranslation('NAVBAR.REMOTE');
    this.primary = localizer.getTranslation('FORMS.LOCAL.PREFERENCES.PRIMARY');
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    const menuID: number = this.ui>=2 ? (Util.Device.bIsOfficeAddin ? MenuId.MENU_OAI_HAMBURGER : MenuId.MENU_MOBILE_HAMBURGER) : -1;
    const menuDef: MenuDef = menuID<0 ? null : this.menuService.getMenu(menuID);
    this.menuItems = !!menuDef ? menuDef.items : [];
    if (menuID===MenuId.MENU_MOBILE_HAMBURGER) {
      if (!Util.Device.bIsCordova && (Util.Device.bIsOfficeAddin || !Util.Device.isMobile())) {
        this.menuItems = this.menuItems.filter(item => item.cmd!=='downloads' && item.cmd!=='imports');
      }
    }
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navLoc = this.router.url;
        if (this.navLoc===Util.RestAPI.getHomeURL()) {
          const waitLogin = () => {
            if (Util.RestAPI.isLoggedIn()) {
              if (!this.navItems.length) {
                this.navService.getItems().then(this.setItems.bind(this));
              }
            } else {
              setTimeout(waitLogin, 100);
            }
          };
          waitLogin();
        } else if (this.navLoc===Util.RestAPI.getLoginURL()) {
          this.welcome = null;
          this.navItems = [];
          this.navService.reset();
          if (this.expanded) {
            this.toggle();
          }
        }
      }
    });
  }

  private setItems(items: NavItem[]): void {
    const firstExtApp = !!items ? items.find(navItem => Util.isExternalLib(navItem.lib)) : null;
    const prefsMenuItemDesc = !!this.menuItems ? this.menuItems.find(item => item.cmd==='preferences') : null;
    if (!!firstExtApp && !!prefsMenuItemDesc && Util.Device.isMobile() && !Util.Device.bIsOfficeAddin) {
      const prefsIndex: number = this.menuItems.indexOf(prefsMenuItemDesc);
      const postItems = this.menuItems.slice(prefsIndex);
      this.menuItemsPostNav = [].concat(postItems);
      this.menuItems.splice(prefsIndex,this.menuItems.length - prefsIndex);
      this.navItems = items.filter(navItem => Util.isExternalLib(navItem.lib));
    } else if (this.ui<2 || Util.Device.bIsOfficeAddin) {
      this.navItems = items;
    }
    if (Util.Device.bIsOfficeAddin) {
      const startUpLocStr: string = Util.RestAPI.getPreference('edx_start_location');
      if (startUpLocStr && startUpLocStr.length) {
        let startUpDesc;
        try {
          startUpDesc = JSON.parse(startUpLocStr);
        } catch (e) {
          startUpDesc = null;
        }
        this.startLocChanged(startUpDesc);
      }
    }
  }

  private getNavIconSrc(item: NavItem): string {
    return Util.Transforms.titleIconUrlFromDesc(item);
  }

  private getNavIconText(item: NavItem): string {
    return Util.Transforms.titleIconTextFromDesc(item);
  }

  private getMenuIconSrc(item: MenuItem): string {
    return 'assets/images/' + item.icon;
  }

  private getMenuIconText(item: MenuItem): string {
    return Util.Transforms.iconAltTextFromDesc(item);
  }

  private getLibKind(item: NavItem): string {
    return item.name===Util.RestAPI.getPrimaryLibrary() ? this.primary : Util.isExternalLib(item.lib) ? '' : this.remote;
  }

  private checkIt(item: NavItem): boolean {
    return (item.id==='0' && item.type==='libraries');
  }

  private navItemClicked(item: NavItem): void {
    if (this.expanded) {
      this.toggle();
    }
    if (item.type==='libraries') {
      if (item.id!=='0') {
        this.navItems = [];
        this.navService.changingPrimaryLibraries();
        Util.RestAPI.setPrimaryLibrary(item.lib);
      }
    } else {
      this.navService.openItem(item);
    }
  }

  private menuItemClicked(item: MenuItem): void {
    if (this.expanded) {
      this.toggle();
    }
    Util.RestAPI.getAppComponent().doCommand(item.cmd);
  }

  private getNavItemDisplayId(item: NavItem): string {
    const itemId: string = item['type'] + (item['lib'] ? '_' + item['lib'] : '') + (item['id'] ? '_' + item['id'] : '');
    return 'edx_navitem_' + itemId;
  }

  private dismiss(event: Event): void {
    if (this.expanded) {
      this.isShowing = false;
      this.isHiding = true;
      event.stopPropagation();
    }
  }

  public toggle(event?: Event): void {
    if (this.expanded) {
      this.isShowing = false;
      this.isHiding = true;
    } else {
      if (!this.welcome) {
        this.welcome = this.localizer.getTranslation('NAVBAR.WELCOME',[Util.RestAPI.getUserFullName()]);
      }
      this.isShowing = true;
      this.isHiding = false;
    }
  }

  public sidebarAnimationComplete(): void {
    if (this.isHiding) {
      this.expanded = false;
    } else if (this.isShowing) {
      this.expanded = true;
    }
    this.isShowing = false;
    this.isHiding = false;
  }

  public changePrimaryLibrary(lib?: string): void {
    this.navItems = [];
    if (lib) {
      this.navService.changingPrimaryLibraries();
      Util.RestAPI.setPrimaryLibrary(lib);
    } else {
      Util.RestAPI.delete('/settings/tiles').toPromise().then(response => {
        this.navService.changingPrimaryLibraries();
        Util.RestAPI.reloadTiles();
        this.reset();
      }).catch(error => {
        Util.Notify.warning(this.localizer.getTranslation('RAPI_ERRORS.0'),error);
      });
    }
  }

  public reconnected(): void {
    this.navItems = [];
    this.navService.getItems().then(this.setItems.bind(this));
  }

  public reset(): void {
    this.navService.reset();
    this.reconnected();
  }

  public startLocChanged(desc: any): void {
    if (this.startDesc) {
      this.startItem = null;
      this.startDesc = null;
      }
    if (!!desc) {
      this.startDesc = desc;
      this.startItem = new NavItem({
        id: desc.id,
        type: desc.type,
        lib: desc.lib,
        name: desc.DOCNAME || desc.name,
        imgPath: desc.imgPath
        });
      }
  }
}
