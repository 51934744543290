import {  Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Util, UserInterface } from './utils/utils.module';
import { LocalizeService } from './services/localize.service';
import { FormWrapperComponent } from './forms/form-wrapper.component';
import { DialogKind } from './models/form-field';

enum ToggleState { none=0,startclose=1,finishclose=2,startopen=3,justclose=4 }

@Component({
  selector: 'edx-app-notify-dialog',
  styleUrls: ['app-notify-dialog-component.scss'],
  template: `
  <div #toggleStateBtn class="togglestatebtn" (click)="doToggleState($event)"></div>
  <div *ngIf="blocking && (shown || isShowing || isHiding)" class="container-mask" [ngClass]="{edx_hidden:hidden}"></div>
  <div class="container" [ngClass]="{edx_hidden:hidden,mobile:ui>1,shown:shown,showing:isShowing,hiding:isHiding,success:kind===1,info:kind===2,warning:kind===3,progress:kind===4,error:kind===5,confirm:kind===6}" (animationend)="notifyAnimationComplete()">
    <div class="header" [ngClass]="{subform:subFormData}" id="edx_notify_header">
      <div class="icon">
        <edx-spinner *ngIf="kind===4" [mini]="true"></edx-spinner>
      </div>
      <div class="title" [ngClass]="{progress:kind===4}">{{title}}</div>
      <div *ngIf="!showConfirm()" class="close" (click)="doClose($event)" id="edx_notify_btn_close"></div>
    </div>
    <div *ngIf="lines" class="body" id="edx_notify_body">
      <div *ngFor="let line of lines" class="line">
        <span *ngIf="lineIsLink(line)">
          <br>
          <a target="_blank" [href]="getLinkFromLine(line)" (click)="openLink(getLinkFromLine(line), getLinkTitleFromLine(line), $event)">{{getLinkTitleFromLine(line)}}</a>
        </span>
        <span *ngIf="!lineIsLink(line)">{{line}}</span>
      </div>
    </div>
    <edx-form-wrapper *ngIf="subFormName" #subFormWrapper [desc]="null" [kind]="subFormName" [inNotify]="true" [selections]="null" [formData]="subFormData"></edx-form-wrapper>
    <div *ngIf="showConfirm()" class="buttons">
      <button *ngIf="cancelBtn" [ngClass]="{mobile:ui>=2, oai:officeAddin, secondary:kind!==3, primary:kind===3}" (click)="confirmCancel()" id="edx_notify_btn_cancel">{{cancel}}</button>
      <button *ngIf="oKBtn" [ngClass]="{mobile:ui>=2, oai:officeAddin, primary:kind!==3, secondary:kind===3, readonly:(subFormWrapper && subFormWrapper.okDisabled)}" (click)="confirmOK()" [disabled]="subFormWrapper && subFormWrapper.okDisabled" id="edx_notify_btn_ok">{{ok}}</button>
    </div>
  </div>
  `
})
export class AppNotifyDialogComponent implements OnInit, OnDestroy {
  @ViewChild('subFormWrapper') private subFormWrapper: FormWrapperComponent;
  @ViewChild('toggleStateBtn') private toggleStateBtn: ElementRef;
  public shown = false;
  public isShowing = false;
  public isHiding = false;
  public blocking = false;
  public hidden: boolean;  // this is temporary
  public title = '';
  public lines: string[] = null;
  public subFormData: any = {};
  public kind: DialogKind = DialogKind.none;
  public ui: UserInterface;
  public officeAddin: boolean;
  public subFormName: string = null;
  private togglingCloseOpen: ToggleState = ToggleState.none;
  private ok = '';
  private cancel = '';
  private oKBtn = false;
  private cancelBtn = false;
  private userConfirmed: boolean = undefined;
  private isDynamicComponent = false;

  constructor(private localizer: LocalizeService) {
    this.ui = Util.Device.ui;
    this.officeAddin = Util.Device.bIsOfficeAddin;
  }

  @HostListener('document:keyup', ['$event'])
  keyDown(event: KeyboardEvent) {
    if (this.shown) {
      if (event.keyCode===13) {
        this.confirmOK();
      } else if (event.which===27) {
        this.confirmCancel();
      } else if (event.which===38) {
        if (++this.kind>6) {
          this.kind = 1;
        }
      }
    }
  }

  ngOnInit() {
    Util.Notify.setAppNotifyDialog(this);
    this.ok = this.localizer.getTranslation('FORMS.BUTTONS.OK');
    this.cancel = this.localizer.getTranslation('FORMS.BUTTONS.CANCEL');
  }

  ngOnDestroy() {
    Util.Notify.setAppNotifyDialog(null);
  }

  public showConfirm(): boolean {
    return this.oKBtn || this.cancelBtn;
  }

  public doToggleState(event: Event): void {
    if (this.togglingCloseOpen===ToggleState.startclose || this.togglingCloseOpen===ToggleState.justclose) {
      if (this.togglingCloseOpen===ToggleState.justclose) {
        this.togglingCloseOpen = ToggleState.none;
      }
      this.doClose();
    } else if (this.togglingCloseOpen===ToggleState.finishclose) {
      this.togglingCloseOpen = ToggleState.startopen;
      this.isShowing = true;
    }
  }

  private lineIsLink(line: string): boolean {
    return !!line && line.startsWith('$edx_link:');
  }

  private getLinkFromLine(line: string): string {
    if (this.lineIsLink(line)) {
      let link: string = line.substr(10);
      const linkAndTitle: string = link;
      const endOfLink: number = linkAndTitle.indexOf('$edx_link_title:');
      if (endOfLink >=0 ) {
        link = link.substr(0, endOfLink);
      }
      return link;
    }
    return null;
  }

  private getLinkTitleFromLine(line: string): string {
    if (this.lineIsLink(line)) {
      const link: string = line.substr(10);
      const endOfLink: number = link.indexOf('$edx_link_title:');
      if (endOfLink >=0 ) {
        return link.substr(endOfLink+16);
      }
      return link;
    }
    return null;
  }

  private openLink(url: string, name: string, event: Event): void {
    Util.Help.openURL(url, name, event);
    setTimeout(() => {
      this.doClose();
    }, 1);
  }

  public doShow(kind: DialogKind, blocking: boolean, cancelBtn: boolean, oKBtn: boolean, title: string, body: any, okText: string, subFormData: any): Promise<any> {
    this.kind = kind;
    this.title = title;
    this.blocking = blocking;
    this.cancel = kind===DialogKind.progress ? this.localizer.getTranslation('FORMS.BUTTONS.CLOSE') : this.localizer.getTranslation('FORMS.BUTTONS.CANCEL');
    this.cancelBtn = cancelBtn;
    this.oKBtn = oKBtn;
    this.subFormData = subFormData;
    this.subFormName = null;
    this.lines = null;
    if (body && typeof body==='string') {
      if (body.startsWith(Util.kLocalFormPrefix)) {
       this.subFormName = body;
     } else {
       this.lines = [];
       const lines: string[] = body.split('\n');
       for (const line of lines) {
         this.lines.push(line);
       }
     }
    }
    if (this.showConfirm()) {
      this.userConfirmed = undefined;
      this.ok = okText ? okText : this.localizer.getTranslation('FORMS.BUTTONS.OK');
    } else {
      this.userConfirmed = true;
    }
    return new Promise<any>((resolve, reject) => {
      const waitFunc = () => {
        this.togglingCloseOpen = ToggleState.none;
        if (this.userConfirmed!==undefined) {
          if (this.kind===DialogKind.success) {
            setTimeout(() => {
              this.togglingCloseOpen = ToggleState.justclose;
              this.toggleStateBtn.nativeElement.click();
            }, 2500);
          }
          resolve({confirm:this.userConfirmed, data:this.subFormWrapper ? this.subFormWrapper.getValue() : {}});
        } else {
          setTimeout(waitFunc,500);
        }
      };
      const waitToggleFunc = () => {
        if (this.togglingCloseOpen===ToggleState.startopen && this.shown) {
          setTimeout(waitFunc,1);
        } else {
          setTimeout(waitToggleFunc,100);
        }
      };
      if (this.shown) {
        this.togglingCloseOpen = ToggleState.startclose;
        this.toggleStateBtn.nativeElement.click();
        setTimeout(waitToggleFunc, 100);
      } else {
        this.isShowing = true;
        setTimeout(waitFunc,1);
      }
    });
  }

  public notifyAnimationComplete(): void {
    if (this.isHiding) {
      this.shown = false;
      Util.RestAPI.getAppComponent().dialogClosed();
    } else if (this.isShowing) {
      this.shown = true;
    }
    this.isShowing = false;
    this.isHiding = false;
    if (this.togglingCloseOpen===ToggleState.startclose) {
      this.togglingCloseOpen = ToggleState.finishclose;
      this.toggleStateBtn.nativeElement.click();
    }
  }

  public confirmCancel(): void {
    this.userConfirmed = false;
    this.doClose();
  }

  public confirmOK(): void {
    this.userConfirmed = true;
    this.doClose();
  }

  public doClose(event?: Event): void {
    if (this.shown) {
      this.isHiding = true;
    }
    if (this.isShowing) {
      setTimeout(() => {
        if (this.isShowing) {
          // can happen if we abort and opening and the animation callback does not run
          this.isShowing = false;
          if (this.shown) {
            this.isHiding = true;
          }
        }
      }, 1000);
    }
  }

  public showHide(show: boolean): void {
    this.hidden = !show;
  }

  public updateFormData(formData: any): void {
    if (this.shown) {
      this.subFormData = formData;
    }
  }

  public updateTitle(title: string): void {
    this.title = title;
  }
}
