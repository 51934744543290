<ng-template #searchListTemplate let-searchTemplateShown="searchTemplateShown">
  <ng-template [ngIf]="searchTemplateShown">
    <div class="search-content">
      <ng-template [ngIf]="!loadingSearches">
        <li *ngIf="tabId==='recent' && !!savedSearchesData.list && !!savedSearchesData.list.length" (click)="clearRecent($event)">
          <img class="search-icon" src="assets/images/formfield_clear.svg" alt="{{this.localizer.getTranslation('FORMS.BUTTONS.CLEAR_ALL')}}">
          <div class="text clear">{{this.localizer.getTranslation('FORMS.BUTTONS.CLEAR_ALL')}}</div>
        </li>
        <li *ngFor="let search of savedSearchesData.list" class="searchitem" (click)="executeSearch(search, $event)">
          <img *ngIf="search['UNSAVED']!=='Y'" class="search-icon" src="assets/images/mime_saved_search.svg" alt="{{search.DESCRIPTION}}">
          <img *ngIf="search['UNSAVED']==='Y'" class="search-icon" src="assets/images/mime_unsaved_search.svg" alt="{{search.DESCRIPTION}}">
          <div class="text">{{search.DESCRIPTION}}</div>
        </li>
        <li *ngIf="!loadingSearches && (!savedSearchesData.list || !savedSearchesData.list.length)" class="searchitem">
          <div class="text center">{{this.localizer.getTranslation('HERO.SECTIONS.NO_ITEMS')}}</div>
        </li>
      </ng-template>
      <edx-spinner *ngIf="loadingSearches" class="spinner" [mini]="true"></edx-spinner>
    </div>
  </ng-template>
</ng-template>

<ng-template #optionsTemplate let-optionsTemplateShown="optionsTemplateShown">
  <ng-template [ngIf]="optionsTemplateShown">
    <div class="searchheader" [ngClass]="{first: !mobile}">
      <div class="search-title">{{this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.SEARCH_SCOPE')}}</div>
    </div>
    <li *ngFor="let item of items" class="searchitem" [ngClass]="{separator:item.separator, hidden:!item.enabled}" (click)="execute(items.indexOf(item), $event)">
      <div *ngIf="item.checkable" class="checkmark" [ngClass]="{checked:item.checked}" (click)="execute(items.indexOf(item), $event)"></div>
      <div class="text">{{item.name}}</div>
    </li>
    <div class="searchheader">
      <div class="search-title">{{this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.MORE_SEARCHES')}}</div>
    </div>
    <li *ngFor="let item of moreSearches" class="searchitem">
      <div>
        <div class="checkmark"></div>
        <div id="item.id" class="text" [ngClass]="{link: item.isLink, graytext: !item.isLink}" (click)="doCommand(item.id, item)">{{item.resource}}</div>
      </div>
    </li>
    <div class="searchheader">
      <div class="search-title">{{this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.LIBRARIES_TO_SEARCH')}}</div>
    </div>
    <div id="librariesList" class="libraries-list">
      <ng-template ngFor let-item [ngForOf]="libraries">
        <li *ngIf="item.DISABLED!='Y'" class="searchitem">
          <input type="checkbox" [id]="item.LIBRARY_NAME" (click)="doCommand('libraries', item)" class="checkbox" [ngClass]="{checked: item.checked}">
          <span for [id]="item.LIBRARY_NAME" class="text lib-text" (click)="doCommand('libraries', item)">{{item.LIBRARY_NAME}} </span>
        </li>
      </ng-template>
    </div>
  </ng-template>
</ng-template>

<div class="menubutton menubuttonsearch" [ngClass]="{mobile: mobile}" (click)="toggleMenuOpen($event)">
  <div class="dropdowncaret" [ngClass]="{rotateIcon:isOpen}" title="{{this.localizer.getTranslation('TOOLTIP.SEARCH_OPTIONS')}}"></div>
  <div *ngIf="isOpen" class="menublocker" [ngClass]="{blocktop:drawUp, blockright:drawToRight}"></div>
</div>
<div *ngIf="isOpen" class="menu-overlay" (click)="overlayClick($event)"></div>
<ul *ngIf="isOpen" class="search-dropdown" [ngClass]="{opening: isOpening, closing: isClosing, drawright: drawToRight, drawup: drawUp, mobile: mobile}"
    (animationend)="transitionComplete()">
  <div class="leftbody">
    <div class="tabcontainer">
      <edx-tab-selector [receiver]="this" [tabdefs]="tabList"></edx-tab-selector>
    </div>
    <div [ngSwitch]="tabId" class="dropdown">
      <ng-template [ngSwitchCase]="'recent'">
        <ng-container *ngTemplateOutlet="searchListTemplate;context:{searchTemplateShown:true}"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'saved'">
        <ng-container *ngTemplateOutlet="searchListTemplate;context:{searchTemplateShown:true}"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'options'">
        <ng-container *ngTemplateOutlet="optionsTemplate;context:{optionsTemplateShown:true}"></ng-container>
      </ng-template>
    </div>
  </div>
  <div *ngIf="!mobile" class="rightbody">
    <div class="dropdown">
      <ng-container *ngTemplateOutlet="optionsTemplate;context:{optionsTemplateShown:true}"></ng-container>
    </div>
  </div>
</ul>
