<ng-template #searchTemplate [ngIf]="!!sections" let-searchTemplateShown="searchTemplateShown" let-disabled="disabled">
<ng-template [ngIf]="searchTemplateShown">
 <div *ngIf="showSearchBar || searchbarShowing || searchbarHiding" class="header-search-bar" [ngClass]="{showing:searchbarShowing,hiding:searchbarHiding,shown:showSearchBar,disabled:disabled}" (animationend)="searchbarAnimationComplete()">
   <input #searchTextInput placeholder="{{searchPlaceHolder}}" type="text" autocomplete="off" id="edxSearchText" [disabled]="disabled" class="headersearchtext" [ngClass]="{container:showSearchWhere}" (keyup)="searchTextEntered($event)" (blur)="searchTextBlur($event)">
   <div *ngIf="showClearIcon" [ngClass]="{searchclear:true, container:showSearchWhere}" (click)="clearSearch()" title = "{{this.localizer.getTranslation('TOOLTIP.CLEAR_KEYWORDS')}}"></div>
   <ng-template [ngIf]="showSearchWhere">
     <div class="search-separator left"></div>
     <edx-menu #searchWhereMenu [menuID]="35" [callback]="this" class="search-where-menu"></edx-menu>
     <div class="header-search-where" [ngClass]="{global:searchWhere===0, onelevel:searchWhere===2}" title="{{'GLOBAL_SEARCHSCOPE.SEARCH_WHERE' | localize}}" (click)="searchWhereButtonClicked($event)"></div>
   </ng-template>
   <div class="search-separator"></div>
   <ul *ngIf="searchSuggestionsShown" class="searchsuggestions" id="edx_search_suggestions">
     <ng-template [ngIf]="!loadingSuggestionsCount">
       <li *ngFor="let suggestion of searchSuggestions" [ngClass]="{selected:suggestion===curSearchSuggestion}" (click)="suggestionClicked(suggestion)">
         <div>{{suggestion}}</div>
       </li>
     </ng-template>
     <div *ngIf="loadingSuggestionsCount" class="loading">
       <edx-spinner [mini]="true"></edx-spinner>
     </div>
   </ul>
   <edx-search-menu *ngIf="!disabled" [callback]="this" #searchMenu [app]="app" class="search-menu"></edx-search-menu>
 </div>
</ng-template>
</ng-template>
<div *ngIf="(ui>=2) && maskHeaderCount" class="header-mask" [ngClass]="{cordova:ui>3}"></div>
<button *ngIf="ui==1 || ui>=4" #cordovaCmdButton class="cordovacmdbutton" (click)="doCordavaCommand()"></button>
<div class="header" [ngClass]="{web:ui==0,desktop:ui==1,mobile:ui>1,phone:ui==2||ui==4,cordova:ui>3,oai:isOfficeAddin,oaidbutton:hasOfficeInfoButton}">
 <ng-template [ngIf]="ui<4">
   <div *ngIf="showBack()" class="toucharea left" (click)="leftClicked($event)" id="edx_hdr_btn_left">
     <div class="toucharea-inner">
       <div [ngClass]="{iconic:!getLeftText(),headertext:getLeftText(),back:!getLeftText()}">{{getLeftText()}}</div>
     </div>
   </div>
   <div *ngIf="showMobileHamburger()" class="toucharea left" [ngClass]="{hasback:showBack()}" (click)="hamburgerClicked($event)" id="edx_hdr_hamburger_menu">
     <div class="toucharea-inner">
       <div class="iconic hamburger"></div>
     </div>
   </div>
   <div *ngIf="ui<2" class="header-hamburger" title="{{'HEADER.MAIN' | localize}}" [ngClass]="{disabled:navDisabledCount}" (click)="!navDisabledCount?hamburgerClicked($event):null" id="edx_app_nav"></div>
   <div class="header-center" *ngIf="displayBranding()" [ngClass]="{dimmed:showSearchBar}">
     <div class="header-branding">
       <div *ngIf="ui==0||ui==1" class="header-logo"></div>
       <div *ngIf="ui!=0&&ui!=1" class="header-app-name">{{getTitle()}}</div>
     </div>
   </div>
   <div *ngIf="ui>=2" class="toucharea right" [ngClass]="{disabled:!rightEnabled()}" (click)="rightClicked($event)" id="edx_hdr_btn_right">
     <div class="toucharea-inner">
       <div [ngClass]="{iconic:!getRightText(),headertext:getRightText(),action:showRightActionIcon()}" [style.backgroundImage]="getRightBGIcon()">{{getRightText()}}</div>
     </div>
   </div>
   <edx-menu *ngIf="ui>=2 && getActionMenuID()>=0" #actionMenu [menuID]="getActionMenuID()" [callback]="getCurHdrCommandHandler()" [menuTitle]="getActionMenuTitle()" [menuIcon]="getActionMenuIcon()"></edx-menu>
 </ng-template>
 <ng-template [ngIf]="ui>=2">
   <ng-container *ngTemplateOutlet="searchTemplate;context:{searchTemplateShown:true, disabled:navDisabledCount}"></ng-container>
 </ng-template>
 <ng-template [ngIf]="ui<2">
   <edx-menu *ngIf="ui==0" #userMenu [menuID]="7" [callback]="this" class="user-menu"></edx-menu>
   <div *ngIf="ui==0" class="header-user" title="{{'HEADER.SETTINGS' | localize}}" (click)="userButtonClicked($event)"></div>
   <div class="header-help" title="{{'HEADER.HELP' | localize}}" (click)="helpButtonClicked($event)"></div>
   <ng-container *ngTemplateOutlet="searchTemplate;context:{searchTemplateShown:true, disabled:navDisabledCount}"></ng-container>
   <div class="refresh-wrapper header-ring"><edx-refresher class="refresher" id="edx-hdr-refresh" (refresh)="refresh()" [name]="reloadStr"></edx-refresher></div>
 </ng-template>
 <ng-template [ngIf]="ui>=4">
   <edx-menu *ngIf="getActionMenuID()>=0" #actionMenu [menuID]="getActionMenuID()" [callback]="getCurHdrCommandHandler()" [menuTitle]="getActionMenuTitle()" [menuIcon]="getActionMenuIcon()"></edx-menu>
 </ng-template>
</div>
