import { Input, Component, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AbstractValueAccessor, MakeProvider } from './abstract-value-accessor';
import { FormField } from '../models/form-field';

@Component({
  selector: 'edx-read-only-text-field',
  providers: [MakeProvider(ReadOnlyTextFieldComponent)],
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
  <div [formGroup]="form" class="textfield" [ngClass]="{indialog:inDialog, columnview:columnView, fullwidth:fullWidth, hasextension:hasExtension}" [title]="toolTip">
    <ng-template ngFor let-line [ngForOf]="lines" let-i="index">
      <br *ngIf="i>0">
      <span>{{line}}</span>
    </ng-template>
  </div>
`
})
export class ReadOnlyTextFieldComponent extends AbstractValueAccessor implements OnChanges {
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() inDialog?: boolean = false;
  @Input() columnView?: boolean = false;
  @Input() toolTip?: string = '';
  @Input() fullWidth?: boolean = false;
  @Input() hasExtension?: boolean = false;
  @Input() rerender = 0;
  public lines: string [];

  ngOnChanges() {
    this.value = this.form.controls[this.field.name].value;
    if (this.field.controltype==='textarea') {
      this.lines = this.value.split('\n');
    } else if (this.field.controltype==='radiogroup') {
      const button = this.field.buttonMap.find(b => b.value === this.value);
      this.lines = !!button ? [button.display] : [this.value];
    } else if (this.field.name === 'DOCNUM' && isNaN(+this.value)) {
      this.lines = [];
    } else {
      this.lines = [this.value];
    }
  }
}
