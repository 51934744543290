import { Component, Input, ElementRef, ViewChild, OnInit } from '@angular/core';

import { Tile } from '../models/tile';
import { TileService } from '../services/tile.service';
import { LocalizeService } from '../services/localize.service';
import { ListTileComponent, ListTilesContainerInterface } from '../lists/list-tile.component';
import { HeroTileComponent } from './hero-tile-component';
import { MenuId } from '../services/menu.service';
import { Util } from '../utils/utils.module';
import { CommandHandler } from '../models/command-handler';
import { ListItem } from '../models/list-item';
import { FormService } from '../services/form.service';

@Component({
  selector: 'edx-tile',
  styleUrls: ['tile.component.scss' ],
  template: `
    <div *ngIf="desc.index >= 0" #elRef class="tile" [edx-draggable]="this" [edx-drag-target]="this"
        [ngClass]="{hero:desc.name==='hero',oneXone:desc.size===1,oneXtwo:desc.size===2,twoXone:desc.size===3,twoXtwo:desc.size===4,dragHole:desc.name==='edx-drag-hole',phone:isPhoneLook(),ios:isiOS,oai:isOfficeAddin,officeapp:isOfficeApp,selected:selected}">
      <div *ngIf="desc.name!=='hero' && desc.name!=='edx-drag-hole'" title="{{desc.tooltip}}" (click)="doHdrClick($event)" class="tile-header"
          [ngClass]="{folder:isFolder(),flexfolder:isFlexFolder(),workspace:isWorkspace(),search:isSearch(),recentedits:isRecentEdits(),downloads:isDownloads(),imports:isImports(),fileplans:isFilePlans(),requests:isRequests(),activities:isActivities()}">
        <img class="tile-header-icon" src="{{iconUrl}}" alt="{{iconText}}" (mouseover)="showExpand($event)" (mouseleave)="hideExpand($event)">
        <div *ngIf="!isOfficeAddin" class="tile-title" title="{{desc.tooltip}}" (mouseover)="showExpand($event)" (mouseleave)="hideExpand($event)">{{desc.name}}</div>
        <edx-menu *ngIf="!isPhoneLook()" [menuID]="tileMenuId" [callback]="this" [forceMenuButton]="!isPhoneLook()" class="menu-button" id="{{ tileId +'_actions' }}"></edx-menu>
      </div>
      <div *ngIf="showList" class="tile-list" [ngClass]="{dragover:(dragover && !listTile.dragFolderHilited)}">
        <edx-list-tile #listTile [desc]="desc" [tcc]="parent"></edx-list-tile>
      </div>
      <edx-hero-tile #heroTile *ngIf="!isPhoneLook() && desc.name==='hero'" [showDragOver]="dragover"></edx-hero-tile>
    </div>
  `
})

export class TileComponent implements CommandHandler, OnInit {
  public tileMenuId: MenuId = 1;
  public dragover = false;
  public didADrag = false;
  public iconUrl: string;
  public iconText: string;
  private showList: boolean;
  private isiOS: boolean = Util.Device.bIsIOSDevice;
  private isOfficeAddin: boolean = Util.Device.bIsOfficeAddin;
  private isOfficeApp: boolean = Util.Device.bIsOfficeAddinDesktop;
  @ViewChild('elRef') elRef: ElementRef;
  @ViewChild('listTile') listTile: ListTileComponent;
  @ViewChild('heroTile') heroTile: HeroTileComponent;
  @Input() desc: Tile;
  @Input() parent: TilesContainerInterface;
  @Input() tileId: string;
  @Input() selected: boolean;

  constructor(private tileService: TileService, private localizer: LocalizeService, private formService: FormService) {
  }

  ngOnInit(): void {
    this.iconUrl = Util.Transforms.tileIconUrlFromDesc(this.desc);
    this.iconText = Util.Transforms.titleIconTextFromDesc(this.desc);
    this.showList = this.desc && !this.isPhoneLook() && this.desc.name!=='hero';
  }

  private isPhoneLook(): boolean {
    return Util.Device.isPhoneLook();
  }
  private isFolder(): boolean {
    return this.desc.type==='folders' && !this.isRecentEdits() && !this.isDownloads() && !this.isImports();
  }
  private isFlexFolder(): boolean {
    return this.desc.type==='flexfolders';
  }
  private isWorkspace(): boolean {
   return this.desc.type==='workspaces';
  }
  private isSearch(): boolean {
   return this.desc.type==='searches';
  }
  private isRecentEdits(): boolean {
    return this.desc.id==='recentedits';
  }
  private isDownloads(): boolean {
    return this.desc.id==='downloads';
  }
  private isImports(): boolean {
    return this.desc.id==='imports';
  }
  private isFilePlans(): boolean {
    return this.desc.type==='fileplans';
  }
  private isRequests(): boolean {
    return this.desc.type==='requests';
  }
  private isActivities(): boolean {
    return this.desc.type==='activities';
  }

  private showExpand(event: MouseEvent): void {
    if (!Util.Device.isMobile()) {
      this.iconUrl = 'assets/images/maximize.svg';
    }
  }

  private hideExpand(event: MouseEvent): void {
    this.iconUrl = Util.Transforms.tileIconUrlFromDesc(this.desc);
    this.iconText = Util.Transforms.titleIconTextFromDesc(this.desc);
  }

  public refresh(): void {
    if (this.listTile) {
      this.listTile.reloadList();
    } else if (this.heroTile) {
      this.heroTile.reloadList();
    }
  }

  doHdrClick(event) {
    if (!this.didADrag) {
      if (this.desc.name==='hero') {
      } else {
        setTimeout(() => {
          this.tileService.openTile(this.desc);
        }, 500);
        if (this.parent) {
          this.parent.tileOpened(this);
        }
      }
    }
    this.didADrag = false;
  }
  doChangeSize() {
    this.desc.size = this.desc.size===1 ? 2 : 1;
    event.stopPropagation();
  }
  doHideTile() {
    this.tileService.hideTile(this.desc);
    return false;
  }
  canDrag(x: number, y: number, element: ElementRef): boolean {
    if (!Util.Device.bIsTouchDevice && (this.desc.name==='hero' || (x>40 && x<(element.nativeElement.offsetWidth-40) && y<60))) {
      return true;
    }
    return false;
  }
  dragStarted(element: ElementRef) {
    if (this.parent) {
      this.parent.tileDragStarted(this,element);
    }
  }
  dragMoved(x: number, y: number, element: ElementRef) {
    if (this.parent) {
      this.parent.tileDragMoved(this,x,y,element);
    }
  }
  dragEnded(event: any, element: ElementRef) {
    if (this.parent) {
      this.parent.tileDragEnded(this,event,element);
    }
    this.didADrag = true;
    //stupid kludge to get around a chrome bug that will always open the last dragged tile
    setTimeout(() => {
      this.didADrag = false;
    },250);
  }
  acceptDrag(event: any, element: ElementRef): boolean {
    const isFileDropTarget: boolean = Util.isUploadTarget(this.desc);
    if (this.heroTile || (isFileDropTarget && (!this.listTile || this.listTile.getSet().readonly!=='Y'))) {
      if (Util.dragHasFiles(event.dataTransfer)) {
        this.dragover = true;
        return true;
      } else if (!this.heroTile && isFileDropTarget && Util.dragHasJSON(event.dataTransfer)) {
        // No external files but we allow drag and drop of DM documents into folders
        this.dragover = true;
        return true;
      }
    }
    return false;
  }
  dragOver(event: any, element: ElementRef): boolean {
    const overFolder: ListItem = this.listTile ? this.listTile.overDragFolder() : null;
    let isFileDropTarget: boolean = Util.isUploadTarget(overFolder || this.desc);
    const isCreateRefTarget: boolean = Util.isSaveToLocation(overFolder || this.desc);
    if (!overFolder) {
      if (Util.dragHasFiles(event.dataTransfer)) {
        if (this.dragover && !this.heroTile) {
          Util.RestAPI.showDropBanner(this.desc.name);
        } else {
          Util.RestAPI.showDropBanner('TILE_NAMES.RECENTLY_EDITED');
        }
      } else if (isCreateRefTarget && Util.dragHasJSON(event.dataTransfer)) {
        Util.RestAPI.showDropBanner(this.desc.name, 'HEADER.DRAG_CREATE_REF');
      } else {
        isFileDropTarget = false;
      }
    }
    return isFileDropTarget || isCreateRefTarget;
  }
  dragLeave(event: any, element: ElementRef): void {
    this.dragover = false;
  }
  drop(event: any, element: ElementRef): void {
    const overFolder: ListItem = this.listTile ? this.listTile.overDragFolder() : null;
    let intoThisFolder: any = overFolder || this.desc;
    const isReadOnly: any = this.listTile ?  this.listTile.getSet().readonly==='Y' : false;
    const isFileDropTarget: boolean = !!this.heroTile || (Util.isUploadTarget(intoThisFolder) && !isReadOnly);
    const isCreateRefTarget: boolean = Util.isSaveToLocation(overFolder || this.desc) || Util.isExternalSaveToLocation(overFolder || this.desc);
    const hasFiles: boolean = Util.dragHasFiles(event.dataTransfer);
    const items: DataTransferItem[] = event.dataTransfer && event.dataTransfer.items ? event.dataTransfer && event.dataTransfer.items : null;
    const doDrop = () => {
      Util.getDropFiles(event.dataTransfer).then((fileItems: any[]) => {
        if ((fileItems && fileItems.length) || Util.Device.bIsOfficeAddin) {
          if (this.listTile) {
            if (!this.listTile.overDragFolder()) {
              this.listTile.uploadFiles(fileItems);
            }
          } else if (this.heroTile || Util.Device.bIsOfficeAddin) {
            intoThisFolder = this.heroTile ? {type: 'folders', id: 'recentedits', lib: ''} : this.desc;
            const files: File[] = (!!fileItems && fileItems.length && typeof fileItems[0] !== 'string') ? fileItems : null;
            const filePaths: string[] = (!!fileItems && fileItems.length && typeof fileItems[0] === 'string') ? fileItems : null;
            Util.RestAPI.uploadFilesWithUI(files, intoThisFolder, null, filePaths);
          }
        }
      });
    };
    if (isFileDropTarget) {
      if (hasFiles) {
        doDrop();
      } else if ((isCreateRefTarget || Util.dragHasPromisedFile(event.dataTransfer)) && Util.dragHasJSON(event.dataTransfer)) { // No external files but we allow drag and drop of DM documents into folders
        const name: string = intoThisFolder.name || intoThisFolder['DOCNAME'];
        let notifyTitle: string = this.localizer.getTranslation('FOLDER_ACTIONS.ADDEDED_TO_SINGLE', [name]);
        let jsonItem: DataTransferItem;
        if (!!items) {
          for (const item of items) {
            if (item.kind === 'string' && item.type === 'text/json') {
              jsonItem = item;
              break;
            }
          }
        }
        jsonItem.getAsString(itemJson => {
          const item: any = itemJson ? JSON.parse(itemJson) : null;
          if (item) {
            if (Util.isExternalLib(item.lib)) {
              if (!!item['checkout']) {
                const listItem: ListItem = new ListItem(item);
                Util.RestAPI.setCurList([listItem],null);
                Util.RestAPI.getAppComponent().doCommand('checkin');
              } else {
                // Drag and drop of an original external file being saved to eDocs
                const dataFiles = [{name:item['DOCNAME'],url:item['url'] || item['locationUrl'],externalName:item['DOCNAME'],size:Number(item.size)}];
                Util.RestAPI.uploadFilesWithUI(null,null,dataFiles,intoThisFolder);
              }
            } else if (Util.isExternalLib(this.desc.lib) && !Util.isExternalLib(item.lib)) {
              const checkoutItem: ListItem = new ListItem(item);
              if (item['STATUS'] !== '3') {
                //
                //This will pop up the checkout form with location chooser
                //Util.RestAPI.setCurList([checkoutItem],null);
                //Util.RestAPI.getAppComponent().doCommand('checkout');
                //
                // The following wll just prompt for confirmation to checkout to selected external location/folder
                const kind = 'profile';
                this.formService.getFormData(intoThisFolder, kind, null).then((formData: any): void => {
                  const ref: any = formData;
                  if (!!ref) {
                    const queryargs = null;
                    const params = 'profile';
                    const sendRaw = false;
                    notifyTitle = this.localizer.getTranslation('DOC_STATUS.CHECKEDOUT');
                    const notifyBody: string = checkoutItem['DOCNAME'];
                    const serverData: any = {};
                    serverData['$edx_folder_loc'] = intoThisFolder['id'];
                    serverData['%STATUS'] = '%LOCK_FOR_CHECKOUT';
                    serverData._restapi = {};
                    serverData._restapi['ref'] = ref;
                    const version = !!checkoutItem['VERSION_ID'] ? checkoutItem['VERSION_ID']: 'C';
                    serverData._restapi['src'] = {
                      ver: version,
                      id: checkoutItem.id,
                      type: checkoutItem.type,
                      lib: checkoutItem.lib
                    };
                    const title = this.localizer.getTranslation('FOLDER_ACTIONS.CHECK_OUT');
                    const body = this.localizer.getTranslation('FOLDER_ACTIONS.CHECK_OUT_TO', [checkoutItem['DOCNAME'], ref['DOCNAME']]);
                    Util.Notify.confirm(title,body,null,null,true,true,true).then(confirmed => {
                      if (confirmed && confirmed.confirm) {
                        this.formService.putToServer(checkoutItem, serverData, params, queryargs, sendRaw, notifyTitle, notifyBody);
                      }
                    });
                  }
                });
              }
            } else {
              Util.RestAPI.post(item, intoThisFolder, 'references').toPromise().then((response) => {
                if (this.listTile) {
                  this.listTile.reloadList();
                }
                  Util.Notify.success(notifyTitle);
              }).catch(error => {
                Util.Notify.warning(this.localizer.getTranslation('RAPI_ERRORS.0'), error);
              });
            }
          }
        });
      }
    } else if (hasFiles) {
      // drop in recent edits
      doDrop();
    }
    this.dragover = false;
    this.parent.dropHandled();
  }

  // menu interface methods
  doCommand(cmd: string): boolean {
    const handled = false;

    switch (cmd) {
      case 'remove':
        this.doHideTile();
        break;
      case 'large':
      case 'small':
        if ((cmd==='large' && this.desc.size!==2) || (cmd==='small' && this.desc.size!==1)) {
          this.desc.size = cmd==='large' ? 2 : 1;
          this.parent.tileChangedSize(this);
          const savedDesc: Tile = this.desc;
          this.desc = new Tile(this.desc);
          setTimeout(() => {
            // force the change detector to run by giving the list a new desc as just changing a field will not run the change detector
            this.desc = savedDesc;
          }, 1);
        }
        break;
      case 'rename':
        const title: string = this.localizer.getTranslation('TILE_MENU.RENAME');
        const okBtn: string = this.localizer.getTranslation('FOLDER_ACTIONS.RENAME');
        const formData: any = { DOCNAME: this.desc.name};
        Util.Notify.info(title,'__local_rename',okBtn,formData,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            const name: string = confirmed.data.DOCNAME;
            if (name && name.length) {
              Util.Notify.success(this.localizer.getTranslation('FOLDER_ACTIONS.RENAMED_SINGLE',[this.desc.name, name]));
              this.desc.name = name;
              this.tileService.tilesChanged();
            }
          }
        });
    }
    return handled;
  }

  commandEnabled(cmd: string): boolean {
    let enabled = false;
    if (!Util.RestAPI.offline()) {
      switch (cmd) {
        case 'remove':
          enabled = true;
          break;
        case 'rename':
          enabled = this.desc.id!=='recentedits' &&
                    this.desc.id!=='checkedout' &&
                    (this.desc.type!=='flexfolders' || this.desc.id.length) &&
                    (this.desc.type!=='searches' || this.desc.id.length) &&
                    (this.desc.type!=='workspaces' || this.desc.id.length) &&
                    (this.desc.type!=='folders' || this.desc.id!=='public');
          break;
        case 'large':
          enabled = (this.desc.size === 1);
          break;
        case 'small':
          enabled = (this.desc.size === 2);
          break;
      }
      return enabled;
    }
  }

  public listItemChanged(data: any): void {
    if (this.listTile) {
      this.listTile.listItemChanged(data);
    } else if (this.heroTile) {
      this.heroTile.reloadList();
    }
  }

  public getElement(): HTMLElement {
    return this.elRef ? this.elRef.nativeElement : null;
  }
}

export interface TilesContainerInterface extends ListTilesContainerInterface {
  tileDragStarted(tile: TileComponent, element: ElementRef): void;
  tileDragMoved(tile: TileComponent, x: number, y: number, element: ElementRef): void;
  tileDragEnded(tile: TileComponent, event: any, element: ElementRef): void;
  tileChangedSize(tile: TileComponent): void;
  tileOpened(tile: TileComponent);
}
