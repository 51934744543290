import { Injectable } from '@angular/core';

import { Util } from '../utils/utils.module';
import { LocalizeService } from './localize.service';
import { FormResult } from '../models/command-handler';

declare const Word: any;

type tUserXMLRecord = (type: string, data: any) => void;

@Injectable()
export class OOxmlService {

  constructor(private localizer: LocalizeService) {
  }

  public async addFooter(desc: any): Promise<void> {
    if (Util.Device.bIsOfficeAddinWord) {
      return this.addWordFooter(desc);
    }
    return Promise.resolve();
  }

  public needsDialog(): boolean {
    const footerOptionsStr = Util.RestAPI.getPreference('edx_footer_options');
    const footerOptions = !!footerOptionsStr ? JSON.parse(footerOptionsStr) : {};
    if (footerOptions['auto'] === '1' || footerOptions['dont_show'] === '1') {
      return false;
    }
    return true;
  }

  private async addWordFooter(desc: any): Promise<void> {
    if (Util.Device.bIsOfficeAddinWord) {
      await Word.run(async (context) => {
        const curXML = await this.getWordFooter(context);
        if (!curXML || curXML.indexOf(kDMMarker) === -1) {
          const userXML = await this.getUserFooterXML(desc);
          if (!!userXML) {
            const footer = context.document.sections.getFirst().getFooter('Primary');
            footer.insertOoxml(userXML, 'Replace');
            await context.sync();
          }
        }
      });
    }
    return Promise.resolve();
  }

  private async getWordFooter(context: any): Promise<string> {
    const footer = context.document.sections.getFirst().getFooter('Primary');
    const footerXML = footer.getOoxml();
    await context.sync();
    return footerXML.value;
  }

  private async getUserFooterXML(desc: any): Promise<string> {
    const footerOptionsStr = Util.RestAPI.getPreference('edx_footer_options');
    const footerOptions = !!footerOptionsStr ? JSON.parse(footerOptionsStr) : {};
    if (footerOptions['auto'] === '1') {
      return Promise.resolve(this.createUserFooterXML(footerOptions['xml'], desc));
    } else if (footerOptions['dont_show'] !== '1') {
      return await this.runUserXMLForm(desc);
    }
    return Promise.resolve(null);
  }

  private createUserFooterXML(userDMxml: string, desc: any): string {
    let xml: string = null;
    const sections = {};
    try {
      this.forEachUserPart(userDMxml, (type: string, data: any) => {
        sections[type] = data;
      });
      const keys = Object.keys(sections);
      const nKeys = keys.length;
      if (nKeys === 0) {
        return null;
      }
      keys.sort((a: string, b: string) => {
        if (a === 'FONT') {
          return -1;
        } else if (b === 'FONT') {
          return 1;
        } else if (a === 'PAGENUMBERS') {
          return -1;
        } else if (b === 'PAGENUMBERS') {
          return 1;
        } else {
          return 0;
        }
      });
      const fontData = !!sections['FONT'] && !!sections['FONT']['data'] ? sections['FONT']['data'] : !!sections['FONT'] ? sections['FONT'] : {STYLE:'Calibri',SIZE:'11'};
      const versionData = sections['DOCVERSION'];
      const docNumData = sections['DOCNUMBER'];
      xml = kPkgOuterStart + kDocumentStart + `<w:bookmarkStart w:id="0" w:name="${kDMMarker}" />`;
      for (const type of keys) {
        const data = sections[type];
        if ((type === 'DOCVERSION' && !!versionData && versionData['ALIGN'] === 'Tab' && !!docNumData) || type === 'FONT') {
          continue;
        }
        if (type === 'DATE') {
          data.text = '';
        }
        if (type === 'PAGENUMBERS') {
          xml += `
            <w:p>
              <w:pPr><w:framePr w:wrap="around" w:vAnchor="text" w:hAnchor="margin" w:xAlign="right" w:y="1" /></w:pPr>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:t xml:space="preserve">${data.TEXT1||''}</w:t></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:fldChar w:fldCharType="begin" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:instrText xml:space="preserve"> PAGE \* Arabic \* MERGEFORMAT </w:instrText></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:fldChar w:fldCharType="separate" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:fldChar w:fldCharType="end" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:t xml:space="preserve">${data.TEXT2||''} </w:t></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:fldChar w:fldCharType="begin" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:instrText xml:space="preserve"> NUMPAGES \* Arabic \* MERGEFORMAT </w:instrText></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:fldChar w:fldCharType="separate" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:fldChar w:fldCharType="end" /></w:r>
            </w:p>`;
        } else if (type === 'DOCNUMBER' && !!versionData && versionData['ALIGN'] === 'Tab') {
          xml += `
            <w:p>
              <w:pPr><w:ind w:right="360" /><w:tabs><w:tab w:val="center" w:pos="4680" /></w:tabs></w:pPr>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:t>${data.text}${this.descDataForType(desc, 'DOCNUMBER')}</w:t></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:tab /><w:t>${versionData.text}${this.descDataForType(desc, 'DOCVERSION')}</w:t></w:r>
            </w:p>`;
        } else {
          xml += `
            <w:p>
              <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE)*2}" /></w:rPr><w:t>${data.text}${this.descDataForType(desc, type)}</w:t></w:r>
            </w:p>`;
        }
      }
      xml += `<w:bookmarkEnd w:id="0" />` + kDocumentEnd;
      return xml + kPkgOuterEnd;
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  private async runUserXMLForm(desc: any): Promise<string> {
    return await Util.RestAPI.runLocalForm(null, 'footer_options', this.localizer.getTranslation('FORMS.LOCAL.FOOTER_OPTIONS.FOOTER_OPTIONS'), null, false).then((result: FormResult) => {
      if (result.success && !!result.data && !!result.data.xml) {
        return this.createUserFooterXML(result.data.xml, desc);
      }
      return null;
    });
  }

  private forEachUserPart(userDMxml: string, cb: tUserXMLRecord): void {
    const parts = userDMxml.split('</');
    let nParts = parts.length;
    if (nParts>1) {
      parts.splice(nParts-1, 1);
      --nParts;
      let i: number;
      for (i=1; i<nParts; i++) {
        const raIndex = parts[i].indexOf('>');
        parts[i] = parts[i].substring(raIndex+1);
      }
      for (i=0; i<nParts; i++) {
        const data = {};
        const term = parts[i];
        const laIndex = term.indexOf('<');
        const raIndex = term.indexOf('>');
        const spIndex = term.indexOf(' ');
        const type = term.substring(laIndex+1, spIndex);
        data['text'] = term.substring(raIndex+1);
        const argStr = term.substring(spIndex+1, raIndex);
        const argStrLen = argStr.length;
        let iArgStr = 0;
        let curArgStr = argStr;
        while (iArgStr < argStrLen) {
          const eqIndex = curArgStr.indexOf('=');
          if (eqIndex<0) {
            return;
          }
          const key = curArgStr.substring(0,eqIndex).trim();
          iArgStr += eqIndex+1;
          curArgStr = curArgStr.substr(eqIndex+1);
          let quoteIndex = curArgStr.indexOf('\'');
          if (quoteIndex<0) {
            return;
          }
          iArgStr += quoteIndex+1;
          curArgStr = curArgStr.substr(quoteIndex+1);
          quoteIndex = curArgStr.indexOf('\'');
          if (quoteIndex<0) {
            return;
          }
          data[key] = curArgStr.substr(0, quoteIndex).replace(/["']/g, '');
          iArgStr += quoteIndex+1;
          if (iArgStr < argStrLen) {
            curArgStr = curArgStr.substr(quoteIndex+1);
          }
        }
        cb(type, data);
      }
    }
  }

  private descDataForType(desc: any, type: string): string {
    switch (type) {
      case 'DATE': return desc['LAST_EDIT_DATE'] || new Date().toString();
      case 'DOCNUMBER': return desc['DOCNUM'] || desc['id'] || '';
      case 'DOCVERSION': return desc['VERSION_LABEL'] || desc['VERSION_ID'] || desc['vers'] || 'R';
      case 'DOCNAME': return desc['DOCNAME'] || '';
      case 'DOCAUTHOR': return desc['AUTHOR_NAME'] || Util.RestAPI.getUserFullName();
      case 'DOCTYPE': return desc['DOCTYPE'] || desc['TYPE_ID'] || '';
      case 'DOCABSTRACT': return desc['ABSTRACT'] || desc['DESCRIPTION'] || '';
      case 'DOCAPPLICATION': return desc['DOCAPPLICATION'] || desc['APP_ID'] || '';
      case 'DOCLIB': return desc['lib'] || Util.RestAPI.getPrimaryLibrary();
      case 'DOCCLIENTID': return desc['CLIENT_ID'] || '';
      case 'DOCCLIENTNAME': return desc['CLIENT_NAME'] || desc['X7316'] || '';
      case 'DOCMATTERID': return desc['MATTER_ID'] || '';
      case 'DOCMATTERNAME': return desc['MATTER_NAME'] || desc['X8809'] || '';
    }
    return '';
  }
}

const kDMMarker = `eDOCS_Footer`;
const kPkgOuterStart = `<pkg:package xmlns:pkg="http://schemas.microsoft.com/office/2006/xmlPackage"><pkg:part pkg:name="/_rels/.rels" pkg:contentType="application/vnd.openxmlformats-package.relationships+xml" pkg:padding="512"><pkg:xmlData><Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships"><Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="word/document.xml"/></Relationships></pkg:xmlData></pkg:part>`;
const kPkgOuterEnd = `</pkg:package>`;
const kDocumentStart = `<pkg:part pkg:name="/word/document.xml" pkg:contentType="application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml"><pkg:xmlData><w:document xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main"><w:body>`;
const kDocumentEnd = `</w:body></w:document></pkg:xmlData></pkg:part>`;

