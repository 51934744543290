
    <div class="window-modal" [ngClass]="{ web:ui==0, desktop:ui==1, mobile:ui>=2, oai: isOfficeAddin }" id="edx_window_modal_metadata">
      <div *ngIf="ui<2 || ((ui==3 || ui==5) && !portrait)" class="sidebar open" [style.width]="getSidebarWidth()">
        <ng-template [ngIf]="ui<2">
          <div class="header backheader" (click)="disabledNav?null:navBack()" [ngClass]="{disabled:disabledNav}" title="{{this.localizer.getTranslation('TOOLTIP.GO_BACK')}}">
            <img class="backarrow" src="assets/images/arrow_back.svg">
            <div class="headertext">{{name}}</div>
          </div>
        </ng-template>
        <div *ngIf="ui>=2" class="header"></div>
        <edx-filelist-sidebar #filelist [receiver]="this" [list]="fileList" [selectIndex]="selectIndex" [desc]="desc" [rerender]="rerender" [disabled]="disabledNav"></edx-filelist-sidebar>
      </div>
      <div class="splitter-outter" [edx-draggable]="this">
        <div class="splitter-inner"></div>
      </div>
      <div class="body">
        <div class="header" id="edx_window_modal_metadata_header">
          <img class="headericon" src="{{headerIcon}}" alt="{{headerText}}">
          <div class="headertextwrapper">
            <div #headerTextDiv class="headertext" title="{{getName()}}" [style.font-size]="headerFontSize">{{getName()}}</div>
          </div>
          <div *ngIf="headerMenuID && !disabledNav" [ngClass]="{headermenu:ui<2, toucharea:ui>=2,right:ui>=2}">
            <edx-menu #menuDiv [menuID]="headerMenuID" [callback]="this" [hideIfEmpty]="true" [forceMenuButton]="ui>=2"></edx-menu>
          </div>
        </div>
        <div *ngIf="ui<2" class="tabcontainer">
          <edx-tab-selector #tabselector [receiver]="this" [tabdefs]="tabList" [disable]="disabledNav"></edx-tab-selector>
        </div>
        <edx-action-bar *ngIf="ui<2 && actionId!=-1" #actionbar [menuID]="actionId" [target]="this" ></edx-action-bar>
        <div #contentPanel class="contentpanel" [ngClass]="{actionbar: actionId!=-1}" [ngSwitch]="tabId">
          <ng-template [ngSwitchCase]="'profile'">
            <edx-form-wrapper #formWrapper [kind]="'profile'" [desc]="currentFile" [readOnly]="true" [refresh]="profileRefresh" (notifyNameChanged)='onNameChanged($event)' (notifyShareChanged)='onShareChanged($event)'></edx-form-wrapper>
          </ng-template>
          <ng-template [ngSwitchCase]="'preview'">
            <div class="preview-iframe" [style.transform]="previewIFrameTransform">
              <iframe #previewIFrame class="preview-iframe"></iframe>
            </div>
            <div *ngIf="showIframeCover()" class="preview-iframe-cover edx_transparent" [edx-gesture-pinch]="this" [style.transform]="previewIFrameTransform"></div>
            <edx-spinner *ngIf="previewIFrameLoading"></edx-spinner>
          </ng-template>
          <ng-template [ngSwitchCase]="'viewer'">
            <div class="preview-iframe" [ngClass]="{hide:bravaEnabled}" [style.transform]="previewIFrameTransform">
              <iframe #previewIFrame class="preview-iframe"></iframe>
            </div>
            <div *ngIf="showIframeCover()" class="preview-iframe-cover edx_transparent" [edx-gesture-pinch]="this" [style.transform]="previewIFrameTransform"></div>
            <edx-spinner *ngIf="previewIFrameLoading"></edx-spinner>
            <div *ngIf="bravaEnabled" data-dojo-type="dijit/layout/BorderContainer" data-dojo-props="design:'sidebar', gutters:true, liveSplitters:false" id="borderContainer">
              <div data-dojo-type="dijit/layout/ContentPane" data-dojo-props="splitter:false, region:'center'" class="bravacontentpane" id="viewerContainer"></div>
            </div>
          </ng-template>
          <ng-template [ngSwitchCase]="'versions'">
            <ng-template [ngIf]="ui<2">
              <div class="list footer">
                <edx-list-table #listtable [schemaId]="'VERSIONS'" [params]="'versions'" [parent]="this" [desc]="currentFile"></edx-list-table>
              </div>
              <edx-list-info-footer [displayItems]="footerItems" [heading]="footerHeading"></edx-list-info-footer>
            </ng-template>
            <ng-template [ngIf]="ui>=2">
              <edx-list-mobile #listtable [schemaId]="'VERSIONS'" [params]="'versions'" [parent]="this" [desc]="currentFile" [hasHeader]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this" (actionHandler)="actionHandler($event)" [splitView]="splitView"></edx-list-mobile>
            </ng-template>
          </ng-template>
          <ng-template [ngSwitchCase]="'attachments'">
            <ng-template [ngIf]="ui<2">
              <div class="list footer">
                <edx-list-table #listtable [schemaId]="'ATTACHMENTS'" [params]="'attachments'" [parent]="this" [desc]="currentFile"></edx-list-table>
              </div>
              <edx-list-info-footer [displayItems]="footerItems" [heading]="footerHeading"></edx-list-info-footer>
            </ng-template>
            <ng-template [ngIf]="ui>=2">
              <edx-list-mobile #listtable [schemaId]="'ATTACHMENTS'" [params]="'attachments'" [parent]="this" [desc]="currentFile" [hasHeader]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this" (actionHandler)="actionHandler($event)" [splitView]="splitView"></edx-list-mobile>
            </ng-template>
          </ng-template>
          <ng-template [ngSwitchCase]="'history'">
            <ng-template [ngIf]="ui<2">
              <div class="list">
                <edx-list-table #listtable [schemaId]="'HISTORY'" [params]="'history'" [parent]="this" [desc]="currentFile" [pageSizeIncremental]="20"></edx-list-table>
              </div>
            </ng-template>
            <ng-template [ngIf]="ui>=2">
              <edx-list-mobile #listtable [schemaLayout]="true" [schemaId]="'HISTORY'" [params]="'history'" [parent]="this" [desc]="currentFile" [pageSizeIncremental]="20" [splitView]="splitView"></edx-list-mobile>
            </ng-template>
          </ng-template>
          <ng-template [ngSwitchCase]="'whereused'">
            <ng-template [ngIf]="ui<2">
              <div class="list">
                <edx-list-table #listtable [schemaId]="'WHERE_USED'" [params]="'references'" [parent]="this" [desc]="currentFile"></edx-list-table>
              </div>
            </ng-template>
            <ng-template [ngIf]="ui>=2">
              <edx-list-mobile #listtable [schemaLayout]="true" [schemaId]="'WHERE_USED'" [params]="'references'" [parent]="this" [desc]="currentFile" [hasHeader]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this" (actionHandler)="actionHandler($event)" [splitView]="splitView"></edx-list-mobile>
            </ng-template>
          </ng-template>
          <ng-template [ngSwitchCase]="'related'">
            <ng-template [ngIf]="ui<2">
              <div class="list">
                <edx-list-table #listtable [schemaId]="'RELATED'" [params]="'associations'" [parent]="this" [desc]="currentFile" [pageSizeIncremental]="25"></edx-list-table>
              </div>
            </ng-template>
            <ng-template [ngIf]="ui>=2">
              <edx-list-mobile #listtable [schemaLayout]="true" [schemaId]="'RELATED'" [params]="'associations'" [parent]="this" [desc]="currentFile" [hasHeader]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this" (actionHandler)="actionHandler($event)" [splitView]="splitView"></edx-list-mobile>
            </ng-template>
           </ng-template>
           <ng-template [ngSwitchCase]="'security'">
             <div class="list form footer" [ngClass]="{nomargin:isWorkspaceOnMobile}">
              <ng-template [ngIf]="!isWorkspaceOnMobile">
                <edx-form-wrapper #headerWrapper (notifyPermissionChanged)='onPermissionChanged($event)' [desc]="currentFile" [kind]="'__local_permissions_selector'" [layout]="'header'" (notifySecurityDirty)="onSecurityDirty($event, true)"></edx-form-wrapper>
              </ng-template>
              <edx-list-security #security *ngIf="isRestricted || securityListVisible" [isReadOnly]="isReadOnly" [showFooter]="true" [kind]="list_PRIVATE" [desc]="currentFile" [splitView]="splitView" [inDialog]="false" [autoAdd]="autoAddUsers" (notifySecurityDirty)="onSecurityDirty($event, false)" (notifySecurityReadOnly)="onSecurityReadOnly($event)" ></edx-list-security>
              <edx-spinner *ngIf="settingSecurity"></edx-spinner>
            </div>
           </ng-template>
        </div>
        <edx-action-bar *ngIf="ui>=2 && actionId!=-1" #actionbar [menuTitle]="getName()" [menuIcon]="headerIcon" [menuID]="actionId" [target]="this" ></edx-action-bar>
      </div>
    </div>
