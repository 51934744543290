import { Component } from '@angular/core';
import { WindowModalComponent } from './windows/window-modal.component';
import { AppHeaderComponent } from './app-header.component';
import { AppCommandsComponent } from './app-commands.component';
import { AppBreadcrumbsComponent } from './app-breadcrumbs.component';
import { CommandHandler, FormResult } from './models/command-handler';
import { BaseDesc } from './models/base';
import { ListItem } from './models/list-item';
import { DataFile, tFilePickCB, tListPickCB } from './models/file-form-info';
import { Tile } from './models/tile';

/*
  this component is a bit of a pain, because we CAN NOT include Util as it
  would be a circular reference and ng would freak out.
*/

@Component({
  selector: 'edx-app',
  styleUrls: [ 'app.component.scss' ],
  template: `
    <div class="app-body" [ngClass]="{oai:isOfficeAddin,mobile:ui>=2}">
      <edx-app-header [app]="this" [navsidebar]="nsb" [target]="currentWindow" id="edx_app_header"></edx-app-header>
      <edx-app-nav-sidebar #nsb id="edx_app_nav_menu"></edx-app-nav-sidebar>
      <div class="edx-app-container" [ngClass]="{ios: iOS}" [style.height]="headerHeight" id="edx_app_container">
        <edx-app-breadcrumbs [app]="this" id="edx_app_breadcrumbs"></edx-app-breadcrumbs>
        <edx-spinner *ngIf="starting"></edx-spinner>
        <router-outlet></router-outlet>
        <router-outlet name="tcc_outlet"></router-outlet>
        <div id="edx_anim_screen"></div>
        <edx-app-commands [app]="this"></edx-app-commands>
        <div *ngIf="uploadMessage" class="dropmessage">{{uploadMessage}}</div>
      </div>
      <edx-app-notify-dialog id="edx_app_notify_dialog"></edx-app-notify-dialog>
    </div>
    <div *ngIf="appModalShown" class="appmodal-mask"></div>
  `
})
export class AppComponent {
  public currentWindow: WindowModalComponent = null;
  public appModalShown = false;
  public headerHeight: string = null;
  public uploadMessage: string = null;
  public ui = 0;
  public iOS = false;
  public isOfficeAddin: boolean;
  public desc: BaseDesc = null;
  public starting = true;
  private commandsComponent: AppCommandsComponent = null;
  private appHeader: AppHeaderComponent = null;
  private breadcrumbs: AppBreadcrumbsComponent = null;
  private headerMasked = false;
  private navDisabled = false;

  public registerHeader(theHeader: AppHeaderComponent) {
    this.appHeader = theHeader;
    if (this.breadcrumbs) {
      this.appHeader.setAppBreadcrumbs(this.breadcrumbs);
    }
  }

  public registerBreadcrumbs(breadcrumbs: AppBreadcrumbsComponent) {
    this.breadcrumbs = breadcrumbs;
    if (this.appHeader) {
      this.appHeader.setAppBreadcrumbs(this.breadcrumbs);
    }
  }

  public registerCommands(theCommands: AppCommandsComponent) {
    this.commandsComponent = theCommands;
  }

  public registerWindow(newWindow: WindowModalComponent, url: string) {
    // if no previous window, do nothing - first window will zoom itself
    if (this.currentWindow && this.appHeader) {
      if (this.appHeader.getBreadcrumbs().contains(url)) {
        this.animateNavBack(newWindow);
      } else {
        this.animateNavForward(newWindow);
      }
    }
    this.appHeader.setSearchScope();
    this.currentWindow = newWindow;
  }

  public deregisterWindow(deadWindow: WindowModalComponent) {
    if (this.currentWindow === deadWindow) {
      this.currentWindow = null;
    }
  }

  public cordovaNavHome(): void {
    this.appHeader.postCordovaCommand('home');
  }

  public cordovaReloadTiles(): void {
    this.appHeader.postCordovaCommand('reloadTiles');
  }

  public cordovaLeft(): void {
    this.appHeader.postCordovaCommand('cordovaLeft');
  }

  public cordovaRight(): void {
    this.appHeader.postCordovaCommand('cordovaRight');
  }

  public cordovaNavToUrl(url: string): void {
    this.appHeader.postCordovaCommand('cordovaNavToUrl', url);
  }

  public cordovaShowSystemNotification(): void {
    this.appHeader.postCordovaCommand('showSystemNotification');
  }

  public navBack(): void {
    if (this.appHeader && this.appHeader.getBreadcrumbs()) {
    this.appHeader.getBreadcrumbs().navBack();
    }
  }

  public removeClone(): void {
    const container: Element = document.getElementById('edx_anim_screen');
    container.innerHTML = '';
    container.className = '';
  }

  // create a clone of current window and make it 'close'
  public animateNavBack(newWindow: WindowModalComponent): void {
    const container: Element = document.getElementById('edx_anim_screen');
    const windowEl: Node = document.getElementsByClassName('window_modal')[0];
    if (windowEl) {
      const clone: Node = windowEl.cloneNode(true);
      container.appendChild(clone);
      container.firstElementChild.addEventListener('animationend', () => {
        this.removeClone();
      });
      container.firstElementChild.classList.add('close_tile');
    }
    if (newWindow === this.currentWindow) {
      newWindow.clearIsOpening();
    }
  }

  public animateNavForward(newWindow: WindowModalComponent): void {
    const that: AppComponent = this;
    const container: Element = document.getElementById('edx_anim_screen');
    const windowEl: Node = document.getElementsByClassName('window_modal')[0];
    if (windowEl) {
      const clone: Node = windowEl.cloneNode(true);
      container.appendChild(clone);
      container.firstElementChild.addEventListener('animationend', () => {
        this.removeClone();
      });
      container.firstElementChild.classList.add('fade_tile');
    }
    newWindow.setIsOpening();
  }

  public doCommand(cmd: string, data?: any): boolean {
    if (cmd && cmd.startsWith('hdr_')) {
      return this.appHeader.doCommand(cmd, data);
    }
    return this.commandsComponent.doCommand(cmd, data);
  }

  public copyDescChanged(desc: any): void {
    this.commandsComponent.copyDescChanged(desc);
  }

  public pickGroupsUsers(callback: any, disableList?: ListItem[], title?: string): void {
    this.commandsComponent.pickGroupsUsers(callback, disableList, title);
  }

  public pickFiles(extensions: string, callback: tFilePickCB): void {
    this.commandsComponent.pickFiles(extensions, callback);
  }

  public clickPickFiles(extensions?: string): void {
    this.commandsComponent.clickPickFiles(extensions);
  }

  public pickFolders(desc: any, multiple: boolean, allowLibrary: boolean, locations: boolean, callback: any): void {
    this.commandsComponent.pickFolders(desc, multiple, allowLibrary, locations, callback);
  }

  public pickFilepart(callback: any): void {
    this.commandsComponent.pickFilepart(callback);
  }

  public pickFromLookups(id: string, callback: any, title?: string): void {
    this.commandsComponent.pickFromLookups(id, callback, title);
  }

  public pickFromDownloads(callbackList: tListPickCB, callbackFiles: tFilePickCB, title?: string): void {
    this.commandsComponent.pickFromDownloads(callbackList, callbackFiles, title);
  }

  public createContainer(type: string, desc: any): Promise<FormResult> {
    return this.commandsComponent.createContainer(type, desc);
  }

  public runCustomForm(formData: any, vendor, formName: string, title: string, okTitle: string, showExtras: boolean): Promise<FormResult> {
    return this.commandsComponent.runCustomForm(formData, vendor, formName, title, okTitle, showExtras);
  }

  public runLocalForm(formData: any, formName: string, title: string, okTitle: string, showExtras: boolean): Promise<FormResult> {
    return this.commandsComponent.runLocalForm(formData, formName, title, okTitle, showExtras);
  }

  public importListItems(items: ListItem[]): void {
    this.commandsComponent.importListItems(items);
  }

  public uploadFilesWithUI(files: File[], filePaths?: string[], dataFiles?: DataFile[], intoThisFolder?: any, formData?: any): void {
    this.commandsComponent.uploadFilesWithUI(files, filePaths, dataFiles, intoThisFolder, formData);
  }

  public uploadDone(error?: string): void {
    this.commandsComponent.uploadDone(error);
  }

  public changePrimaryLibrary(lib?: string): void {
    this.appHeader.changePrimaryLibrary(lib);
  }

  public getDefaultTiles(): Tile[] {
    return this.appHeader.getDefaultTiles();
  }

  public getTiles(): Tile[] {
    return this.appHeader.getTiles();
  }

  public setTiles(tiles: Tile[]): void {
    this.appHeader.setTiles(tiles);
  }

  public resetTiles(): void {
    this.appHeader.resetTiles();
  }

  public tilesChanged(): void {
    this.appHeader.tilesChanged();
  }

  public isPermaTile(tile: Tile): boolean {
    return this.appHeader.isPermaTile(tile);
  }

  public canSearchWhere(): boolean {
    return this.appHeader.canSearchWhere();
  }

  public refreshSearchesOnSearchMenu() {
    if (this.appHeader.searchMenu) {
    this.appHeader.searchMenu.loadSavedSearches();
    }
  }

  public focusSearchBar(): boolean {
    return this.appHeader.focusSearchBar();
  }

  public curUrlChanged(url: string): void {
    this.appHeader.curUrlChanged(url);
  }

  public curWindowChangedTitle(title: string, oldTitle: string): void {
    this.appHeader.curWindowChangedTitle(title, oldTitle);
  }

  public setHeaderTitle(title: string): void {
    this.appHeader.setTitle(title);
  }

  public setHeaderLeftText(leftText: string): void {
    this.appHeader.setLeftText(leftText);
  }

  public setHeaderRightText(rightText: string, cmd: string): void {
    this.appHeader.setRightText(rightText, cmd);
  }

  public setHeaderRightIcon(rightIcon: string, cmd: string): void {
    this.appHeader.setRightIcon(rightIcon, cmd);
  }

  public setActionMenu(commandHandler: CommandHandler, id: number, title: string, icon: string): void {
    this.appHeader.setActionMenu(commandHandler, id, title, icon);
  }

  public pushCommandHandler(commandHandler: CommandHandler, command: string, leftText: string, title: string, rightText: string, actionMenuID: number=-1, actionMenuTitle?: string, actionMenuIcon?: string): void {
    this.appHeader.pushCommandHandler(commandHandler, command, leftText, title, rightText, actionMenuID, actionMenuTitle, actionMenuIcon);
  }

  public popCommandHandler(last: boolean): void {
    this.appHeader.popCommandHandler();
    if (last) {
      this.dialogClosed();
    }
  }

  public nullTopCommandHandler(): void {
    this.appHeader.nullTopCommandHandler();
  }

  public routeChanged(): void {
    this.appHeader.routeChanged();
  }

  public setAppModal(modal: boolean): void {
    this.appModalShown = modal;
  }

  public isAppModal(): boolean {
    return this.appModalShown;
  }

  public maskHeader(mask: boolean): void {
    this.appHeader.maskHeader(mask);
  }

  public isHeaderMasked(): boolean {
    return this.appHeader.isHeaderMasked();
  }

  public isNavDisabled(): boolean {
    return this.appHeader.isNavDisabled();
  }

  public reconnected(): void {
    this.appHeader.reconnected();
  }

  public offlineStateChange(offline: boolean): void {
    this.appHeader.offlineStateChange(offline);
  }

  public dialogClosed(): void {
    if (this.currentWindow) {
      this.currentWindow.gotFocus();
    }
  }

  public acceptFileDrag(): boolean {
    if (!this.currentWindow && this.appHeader && this.appHeader.getBreadcrumbs()) {
      return this.appHeader.getBreadcrumbs().acceptFileDrag();
    }
    return false;
  }

  public blockForDirty(handler: CommandHandler, enable: boolean, cmd?: string): void {
    cmd = cmd || 'save';
    if (this.ui===2 || this.ui===4) {
      if (enable) {
        this.setActionMenu(handler, -1, this.currentWindow.getName(), null);
        this.setHeaderRightText('FOLDER_ACTIONS.SAVE', cmd);
        this.setHeaderLeftText('FORMS.BUTTONS.CANCEL');
      } else {
        this.setActionMenu(null, -1, null, null);
        this.setHeaderRightText(null, null);
        this.setHeaderLeftText(null);
      }
    } else if (this.ui===3 || this.ui===5) {
      if (this.headerMasked !== enable) {
        this.maskHeader(enable);
        this.headerMasked = enable;
      }
    } else {
      if (this.navDisabled !== enable) {
        this.appHeader.disableNav(enable);
        this.navDisabled = enable;
      }
    }
  }
}
