/*
 Help System integration to OTHHUrlBuilder and OT oline help

*/
declare let OTHHUrlBuilder;
import { AWWebView, AWPage } from 'appworks-js';
import { _Device } from './device';
import { LocalizeService } from '../services/localize.service';

export class _Help {
  private othh1: any = null;
  private version: string = null;
  private device: _Device = null;
  private localizer: LocalizeService;

  constructor(version: string, device: _Device) {
    this.version = version;
    this.device = device;
  }

  public setLocalizer(localizer: LocalizeService): void {
    this.localizer = localizer;
  }

  public show(): void {
    if (!this.othh1) {
      this.othh1 = new OTHHUrlBuilder({
        product: 'dmsu', //optional, default product
        version:   this.version, //optional, default version
      });
    }
    const type: string = this.device.ui<2 ? 'ofh1' : 'mhp';
    //Basic call, using default product and version, and adding module and type
    const url: string = this.othh1.buildHelpUrl(this.device.getUserLanguage(false),
      {
        documents: [
          {	module: 'dmsu-h-ugd', version:   this.version ,topic: 'top-of-doc'}
        ]
      },
      {
        type
      }
    );
    this.openURL(url, this.localizer.getTranslation('HEADER.HELP'));
  }

  public openURL(url: string, name?: string, event?: Event): void {
    if (url) {
      if (this.device.bIsElectron) {
        const webView: AWWebView = new AWWebView();
        webView.open(url);
      } else if (this.device.bIsCordova) {
        const awPage: AWPage = new AWPage(pageResult => {}, error => {});
        awPage.openModalExternalWebView(url, name, this.localizer.getTranslation('FORMS.BUTTONS.CANCEL'), null);
      } else {
        window.open(url, '_blank');
      }
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }
}
