import { Component, Input, OnInit } from '@angular/core';
import { MenuService, MenuId, MenuDef } from '../services/menu.service';
import { MenuItem } from '../models/menu-item';
import { LocalizeService } from '../services/localize.service';
import { CommandHandler } from '../models/command-handler';
import { Util, UserInterface } from '../utils/utils.module';

/**
 * Created by kevin on 2016-10-21.
 *
 * Implements a popup iconic action bar
 *
 */

@Component({
  selector: 'edx-inline-action-bar',
  styleUrls: ['inline-action-bar.component.scss'],
  template: `
    <div *ngIf="isShown()" class="inline-action-bar" [ngClass]="{mobile:ui>=2 && !isOfficeAddin, oai:isOfficeAddin, showing:showing, hiding:hiding, onwhite:onWhite}" (animationend)="animationComplete()">
      <ng-template ngFor let-item [ngForOf]="items">
        <div *ngIf="item.enabled" class="action-item" (click)="itemClick(items.indexOf(item), $event)">
          <img  src="{{item.icon}}" class="action-icon" title="{{item.name}}">
        </div>
      </ng-template>
    </div>
  `
})
export class InlineActionBarComponent implements OnInit {
  private _shown = false;
  private showing = true;
  private hiding = false;
  private ui: UserInterface;
  private isOfficeAddin: boolean = Util.Device.bIsOfficeAddin;
  private hasEnabledItems = false;
  private menuDef: MenuDef;
  private items: MenuItem[] = [];
  @Input() onWhite?: boolean = false;
  @Input() resid: MenuId;
  @Input() target: CommandHandler;
  @Input('shown')
  set setter(value: boolean) {
    this.showing = value;
    this.hiding = this._shown && !value;
    this._shown = value;
  }

  constructor(private menuService: MenuService, private localizer: LocalizeService) {
    this.ui = Util.Device.ui;
  }

  ngOnInit(): void {
    this.menuDef = this.menuService.getMenu(this.resid);
    this.initActionItems();
  }

  public updateActionItems(): void {
    this.hasEnabledItems = false;
    for (const menuItem of this.items) {
      menuItem.enabled = true;
      if (this.target.commandEnabled) {
        menuItem.enabled = this.target.commandEnabled(menuItem.cmd);
      }
      if (menuItem.enabled) {
        this.hasEnabledItems = true;
      }
    }
  }

  public initActionItems(): void {
    this.items = [];
    for (const itemDef of this.menuDef.items) {
      const item: MenuItem = new MenuItem(itemDef);
      item.enabled = true;
      item.name = this.localizer.getTranslation(item.name);
      if (!!item.icon && !item.icon.startsWith('http') && !item.icon.startsWith('assets/images/')) {
        item.icon = 'assets/images/' + item.icon;
      }
      this.items.push(item);
    }
  }

  private itemClick(itemIndex: number, event: Event): void {
    if (itemIndex >= 0 && itemIndex < this.items.length) {
      if (this.target) {
        this.target.doCommand(this.items[itemIndex].cmd);
      }
    }
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private animationComplete(): void {
    if (this.showing) {
      this.showing = false;
    }
    if (this.hiding) {
      this.hiding = false;
    }
  }

  public isShown(): boolean {
    return this.hiding || (this._shown && this.hasEnabledItems);
  }
}
