import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { Util, UserInterface } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';
import { SelectItem } from '../models/form-field';
import { SelectComponent } from '../widgets/select.component';
import { oAuth2Service } from '../services/oauth2.service';

@Component({
  selector: 'edx-form-login',
  styleUrls: ['form-login.component.scss' ],
  template: `
    <form class="edx-form edx-form-login" [ngClass]="{mobile:ui>=2, phone:ui==2||ui==4, oai:isOfficeAddin}" (ngSubmit)="onSubmit()">
      <fieldset>
        <div *ngIf="showServer" class="form-group">
          <input type="text" class="form-control" placeholder="{{this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.SERVER')}}" id="server" required [(ngModel)]="server" name="server" (blur)="serverChanged($event)">
          <div class="ctrl-btn invalid-btn"></div>
          <div class="ctrl-btn clear-btn" [ngClass]="{shown:!!server}" (click)="clear($event,'server')"></div>
        </div>
        <ng-template [ngIf]="showUserPswd">
          <div class="form-group">
            <input #userInput type="text" spellcheck="false" class="form-control" placeholder="{{this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.USERID')}}" id="userid" required [(ngModel)]="userid" name="userid">
            <div class="ctrl-btn invalid-btn"></div>
            <div class="ctrl-btn clear-btn" [ngClass]="{shown:!!userid}" (click)="clear($event,'userid')"></div>
          </div>
          <div class="form-group">
            <input type="password" spellcheck="false" class="form-control" placeholder="{{this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.PASSWORD')}}" id="password" required [(ngModel)]="password" name="password">
            <div class="ctrl-btn invalid-btn"></div>
            <div class="ctrl-btn clear-btn" [ngClass]="{shown:!!password}" (click)="clear($event,'password')"></div>
          </div>
        </ng-template>
        <div *ngIf="showLibraries" class="form-group">
          <edx-select [items]="libsList" [value]="curLib" (change)="libChanged($event)"></edx-select>
        </div>
        <div class="bottom-container">
          <edx-select *ngIf="showInterfaces" class="inlineselect" [items]="uiList" [value]="ui" (change)="uiLookChanged($event)"></edx-select>
          <button *ngIf="showTestUpload" class="login-btn" (click)="testUploadForm($event)">Upload</button>
          <button type="submit" class="primary login-btn" [ngClass]="{dimmed:!canLogin(), oai:isOfficeAddin}">{{loginBtnText}}</button>
          <div *ngIf="ui==0&&allowAnonymous" class="guest-btn"><span (click)="guestSubmit($event)" class="guest-link">{{loginGuestBtnText}}</span></div>
          <edx-spinner [ngClass]="{edx_hidden:!loggingIn}" class="spinner"></edx-spinner>
        </div>
      </fieldset>
    </form>
    <div class="login-copyright" [ngClass]="{oai:isOfficeAddin, mobile:ui>=2, phone:ui==2||ui==4, servershown:showServer}">{{copyrightText}}</div>
  `
})
export class FormLoginComponent implements OnInit {
  @ViewChild('userInput') private userInput: ElementRef;
  public server: string;
  public userid: string;
  public password: string;
  public ui: UserInterface = Util.Device.ui;
  public isOfficeAddin: boolean = Util.Device.bIsOfficeAddin;
  public loginBtnText: string = this.localizer.getTranslation('SETTINGS.LOGIN');
  public copyrightText: string = this.localizer.getTranslation('SETTINGS.COPYRIGHT', [Util.Device.year]);
  public loginGuestBtnText: string = this.localizer.getTranslation('SETTINGS.GUEST_LOGIN');
  public loggingIn = false;
  public libsList: SelectItem[];
  public uiList: SelectItem[];
  public curLib = '';
  public showUserPswd = false;
  public showServer = false;
  public showLibraries = false;
  public showInterfaces = false;
  public showTestUpload = false;
  public allowAnonymous = false;
  private ssoData: any = null;
  private loggedIn = false;

  constructor(protected router: Router, public localizer: LocalizeService, private oauth2Service: oAuth2Service) {
    Util.RestAPI.getSiteConfig().then(data => {
      if (data.restapi && data.restapi.length) {
        this.server = data.restapi;
        this.retrieveLibraries(false).then(() => {}, err => {});
      } else {
        this.showUserPswd = true;
      }
      if (!!data.showserver) {
        this.showServer = true;
      }
      if (!!data.allowAnonymous) {
        this.allowAnonymous = true;
      }
      if (!!data.showlibraries) {
        this.showLibraries = true;
      }
    }, err => {
      this.showUserPswd = true;
    });
    this.uiList = [
      {display: 'web', value: UserInterface.web},
      {display: 'desktop', value: UserInterface.desktop},
      {display: 'phone browser', value: UserInterface.phone},
      {display: 'tablet browser', value: UserInterface.tablet},
      {display: 'phone no hdr', value: UserInterface.phone_cordova},
      {display: 'tablet no hdr', value: UserInterface.tablet_cordova}
    ];
  }

  ngOnInit() {
    Util.RestAPI.setLibraries(null);
    setTimeout(() => {
      if (!!this.userInput) {
        this.userInput.nativeElement.focus();
      }
    }, 1);
  }

  private uiLookChanged(selectComponent: SelectComponent): void {
    Util.Device.ui = this.ui = parseInt(selectComponent.value);
    switch (this.ui) {
      case UserInterface.web:
        localStorage.removeItem('desktop_look');
        break;
      case UserInterface.desktop:
        localStorage.setItem('desktop_look', 'desktop');
        break;
      case UserInterface.phone:
        localStorage.setItem('desktop_look', 'phone');
        break;
      case UserInterface.tablet:
        localStorage.setItem('desktop_look', 'tablet');
        break;
      case UserInterface.phone_cordova:
        localStorage.setItem('desktop_look', 'phone_cordova');
        break;
      case UserInterface.tablet_cordova:
        localStorage.setItem('desktop_look', 'tablet_cordova');
        break;
    }
    location.reload();
  }

  private retrieveLibraries(changed: boolean): Promise<void> {
    Util.RestAPI.setLibraries(null);
    Util.RestAPI.setBaseURL(this.server);
    this.libsList = [];
    return new Promise<void>((resolve, reject) => {
      Util.RestAPI.get('libraries',null,'configuration').subscribe((data: any) => {
        const libraries: string[] = !!data && !!data['libraries'] ? data['libraries'] : data;
        this.ssoData = !!data && !!data['authentication'] && data['authentication'].oidc_enabled ? data['authentication'] : null;
        if (!!libraries) {
          if (this.showLibraries) {
            this.libsList = libraries.map(l => ({value:l, display:l}));
          }
          const prefsLib = Util.RestAPI.getPreference('primary');
          if (!!prefsLib) {
            this.curLib = prefsLib;
          } else {
            this.curLib = libraries[0];
          }
          Util.RestAPI.setLibraries(libraries);
          this.showUserPswd = (!!Util.RestAPI.getSsoAuthErr() || !this.ssoData) && (!this.ssoData || !this.ssoData.oidc_required);
          if (changed) {
            this.oauth2Service.serverChanged();
          }
          resolve();
        } else {
          const err = {error:{rapi_code: 1}};
          this.handleError(err);
          reject(err);
        }
      }, err2 => {
        this.handleError(err2);
        reject(err2);
      });
    });
  }

  private isValid(): boolean {
    return !!this.server && ((!!this.userid && !!this.password) || !!this.ssoData);
  }

  public canLogin(): boolean {
    return this.isValid() && !!Util.RestAPI.getPrimaryLibrary() && !this.loggedIn && !this.loggingIn;
  }

  public handleError(error: any) {
    this.loggingIn = false;
    this.loginBtnText = this.localizer.getTranslation('SETTINGS.LOGIN');
    Util.Notify.error(this.localizer.getTranslation('SETTINGS.LOGIN'), error);
  }

  private continueSubmit(guestconnect: boolean=false): void {
    if (this.loggedIn) {
      this.loggingIn = false;
      this.loginBtnText = this.localizer.getTranslation('SETTINGS.LOGIN');
      Util.RestAPI.loadHome().then(success => {
        if (!success) {
          this.loggedIn = false;
        }
      });
    } else if (!!this.ssoData) {
      localStorage.setItem('edx_session_url', this.server);
      this.oauth2Service.login(this.ssoData).then((token: string) => {
        Util.RestAPI.setSSOAccessToken(token);
        Util.RestAPI.postGetLibs(null, success => {
          if (!success) {
            this.oauth2Service.reset();
            this.handleError({error:{rapi_code: 1}});
            this.ssoData = null;
            this.showUserPswd = true;
          }
        });
      }, err => {
        this.ssoData = null;
        this.showUserPswd = true;
        setTimeout(() => {
         this.continueSubmit(guestconnect);
        }, 1);
      });
    } else if (!Util.RestAPI.getPrimaryLibrary()) {
      this.retrieveLibraries(true).then(() => {
        setTimeout(() => {
          this.continueSubmit(guestconnect);
        }, 1);
      }, err => {});
    } else {
      let data = {};
      if (guestconnect) {
        data = {
          guestconnect: true,
          library: Util.RestAPI.getPrimaryLibrary()
        };
      } else {
        data = {
          userid: this.userid,
          password: this.password,
          library: Util.RestAPI.getPrimaryLibrary()
        };
      }
      Util.RestAPI.post('/connect',Util.RestAPI.addTZInfoToLogin(data)).subscribe((loginReply: any) => {
        this.loggedIn = true;
        Util.RestAPI.setLoginReply(loginReply);
        setTimeout(() => {
          this.continueSubmit(guestconnect);
        }, 1);
      }, err => {
        if (Util.RestAPI.handleLoginError(err)) {
          setTimeout(() => {
            this.continueSubmit(guestconnect);
          }, 2500);
        }
        this.handleError(err);
      });
    }
  }

  public serverChanged(event: Event): void {
    this.retrieveLibraries(true).then(() => {}, err => {});
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public libChanged(sel: SelectItem): void {
    this.curLib = sel.value;
    Util.RestAPI.setPrimaryLibrary(this.curLib, false);
  }

  public onSubmit(): void {
    if (this.isValid()) {
      if (!this.server || (!this.server.startsWith('https://') && (!this.server.startsWith('http://') || Util.Device.bIsOfficeAddin))) {
        Util.Notify.warning(this.localizer.getTranslation('SETTINGS.LOGIN'),this.localizer.getTranslation(Util.Device.bIsOfficeAddin ? 'SETTINGS.MUST_BE_HTTPS' : 'SETTINGS.MUST_BE_HTTP'));
      } else {
        this.loggingIn = !this.loggingIn;
        this.loginBtnText = this.loggingIn ? this.localizer.getTranslation('FORMS.BUTTONS.CANCEL') : this.localizer.getTranslation('SETTINGS.LOGIN');
        if (this.loggingIn) {
          this.continueSubmit();
        } else {
          location.reload();
        }
      }
    }
  }

  public guestSubmit(event?: Event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!this.server || (!this.server.startsWith('https://') && (!this.server.startsWith('http://') || Util.Device.bIsOfficeAddin))) {
      Util.Notify.warning(this.localizer.getTranslation('SETTINGS.LOGIN'),this.localizer.getTranslation('SETTINGS.MUST_BE_HTTP'));
    } else {
      this.loggingIn = !this.loggingIn;
      Util.RestAPI.setBaseURL(this.server);
      if (this.loggingIn) {
        this.continueSubmit(true);
      } else {
        location.reload();
      }
    }
  }

  public clear(event: Event, field: string): void {
    switch (field) {
    case 'server':
      this.server = null;
      break;
    case 'userid':
      this.userid = null;
      break;
    case 'password':
      this.password = null;
      break;
    }
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private testUploadForm(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    const nWordDocs = 10;
    const items: any[] = [];
    for (let i=0; i<nWordDocs; i++) {
      items.push({APP_ID:'MS WORD', type:'documents', DOCNAME:'Word Doc '+i, $edx_progress:Math.random(), size:Math.round(100000 * Math.random())});
    }
    Util.Notify.progress('Upload','__local_filetransfer',null,{ DOCUMENTS:items },false,false,false).then(confirmed => {});
  }
}
