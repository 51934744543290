import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { WindowModalComponent } from './window-modal.component';
import { Util } from '../utils/utils.module';
import { AppComponent } from '../app.component';
import { TabItem, TabReceiver } from '../models/tab-item';
import { ListItem } from '../models/list-item';
import { CommandHandler } from '../models/command-handler';
import { SelectItem } from '../models/form-field';
import { LocalizeService } from '../services/localize.service';
import { SettingsService } from '../services/settings.service';
import { FormWrapperComponent } from '../forms/form-wrapper.component';
import { TabSelectorComponent } from '../widgets/tab-selector.component';
import { SelectComponent } from '../widgets/select.component';
import { FilelistReceiver } from '../widgets/filelist-sidebar.component';
import { ListTableParent, ListTableComponent } from '../lists/list-table.component';
import { SelectFieldComponent } from '../forms/select-field.component';

@Component({
  selector: 'edx-window-modal-preferences',
  styleUrls: [ 'window-modal.component.scss', 'window-modal-metadata.component.scss', 'window-modal-preferences.component.scss' ],
  templateUrl: 'window-modal-preferences.component.html'
})
export class WindowModalPreferencesComponent extends WindowModalComponent implements OnInit, OnDestroy, TabReceiver, CommandHandler, FilelistReceiver, ListTableParent {
  @ViewChild('formWrapper') formWrapper: FormWrapperComponent;
  @ViewChild('tabSelector') tabSelector: TabSelectorComponent;
  public tabId = 'general';
  public showSpinner = true;
  private appComponent: AppComponent = Util.RestAPI.getAppComponent();
  private primaryLib: string = Util.RestAPI.getPrimaryLibrary();
  private urlLoaded = false;
  private showSave = false;
  private showReset = false;
  private resetTiles = false;
  private curAppIDIndex = 0;
  private appIDItems: ListItem[] = [];
  private listItems: ListItem[] = [];
  private hoverItem: ListItem;
  private libSelItems: SelectItem[];
  private appIDSelItems: SelectItem[];
  private loginLibSelItems: SelectItem[];
  private curAppID: string;
  private forLibStr: string;
  private forLoginLibStr: string;
  private forPrimaryStr: string;
  private resetHomeTilesStr: string;
  private resetNote: string;
  private resetStr: string;
  private saveStr: string;
  private formDefaults: any = {};
  private defaultTiles: any = {};
  private tabList: TabItem[] = [
    { title: 'FORMS.LOCAL.PREFERENCES.GENERAL', id: 'general' },
    { title: 'FORMS.LOCAL.PREFERENCES.LIBRARIES', id: 'libraries' },
    { title: 'FORMS.LOCAL.PREFERENCES.HOME_TILES', id: 'tiles' },
    { title: 'PROFILE_DEFAULTS.DEFAULT_SETTINGS', id: 'profile_defaults' }
  ];

  constructor(protected location: Location, protected router: Router, protected route: ActivatedRoute, private localizer: LocalizeService, private settingsService: SettingsService) {
    super(location,router,route);
    this.forLibStr = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.FOR_LIB_SHORT');
    this.forLoginLibStr = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.LOGIN_LIB');
    this.resetHomeTilesStr = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET_TILES');
    this.resetNote = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET_NOTE');
    this.saveStr = this.localizer.getTranslation('FOLDER_ACTIONS.SAVE');
    this.resetStr = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET');
  }

  ngOnInit() {
    const waitForAppIds = () => {
      const primaryAppIDs = Util.RestAPI.getAppIDs(this.primaryLib);
      if (!!primaryAppIDs && Object.keys(primaryAppIDs).length) {
        let libs: any[] = Util.RestAPI.getLibraries();
        this.showSpinner = false;
        this.libSelItems = [];
        this.formDefaults = {};
        if (!!libs && !!libs.length) {
          for (const lib of libs) {
            if (lib.DISABLED === 'N') {
              const libNameUC: string = lib.LIBRARY_NAME.toUpperCase();
              this.libSelItems.push({value:libNameUC,display:lib.LIBRARY_NAME});
              this.formDefaults[libNameUC] = {};
            }
          }
        }
        libs = Util.RestAPI.getLibraries(true);
        this.loginLibSelItems = [];
        if (!!libs && !!libs.length) {
          for (const lib of libs) {
            const label: string = lib.LIBRARY_NAME;
            const name: string = encodeURIComponent(label);
            if (lib.DISABLED !== 'Y' || lib.only_root === 'Y') {
              this.loginLibSelItems.push({value: name ,display: name});
            }
          }
        }
        if (this.urlLoaded) {
          this.libChanged(null);
        }
        this.forPrimaryStr = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.FOR_LIB', [Util.RestAPI.getPrimaryLibrary()]);
        if (this.showReset) {
          this.enableReset();
        }
      } else {
        setTimeout(waitForAppIds, 100);
      }
    };
    if (!Util.RestAPI.isGuestUser()) {
      super.ngOnInit();
      waitForAppIds();
      this.getDefaultTiles();
    } else {
      Util.Notify.error(this.localizer.getTranslation('RAPI_ERRORS.13'));
      this.appComponent.navBack();
    }
  }

  ngOnDestroy() {
    this.enableSave(false);
    if (this.formWrapper) {
      this.formWrapper.popupCancel();
    }
    this.appComponent.deregisterWindow(this);
  }

  private getDefaultTiles(): void {
    const defaultTiles: any[] = Util.RestAPI.getDefaultTiles();
    if (!!defaultTiles) {
      for (const tile of defaultTiles) {
        this.defaultTiles['tile__' + tile.id + '__' + tile.type + '__' + tile.lib] = tile.index === -1 ? '0' : '1';
      }
      if (Util.Device.isMobileDevie()) {
        this.defaultTiles['tile__downloads__folders__' + this.desc.lib] = '1';
      }
    }
  }

  private okEnabled(event: any): void {
    this.enableSave(event.dirty && event.enable);
  }

  private enableSave(enable: boolean): void {
    if (!enable) {
      this.checkForDirty(true);
    }
    if (this.showSave !== enable) {
      this.showSave = enable;
      this.appComponent.blockForDirty(this, enable);
      if (!enable && this.showReset) {
        this.enableReset();
      }
    }
  }

  private enableReset(): void {
    if (!Util.Device.isTabletLook()) {
      Util.RestAPI.getAppComponent().setActionMenu(this, -1, this.getName(), null);
      Util.RestAPI.getAppComponent().setHeaderRightText(this.resetStr, 'reset');
    }
  }

  private checkForDirty(enableReset?: boolean): void {
    let showReset = false;
    switch (this.tabId) {
      case 'general':
        if (!!this.formWrapper && this.formWrapper.kind === '__local_preferences_general' && !!this.formWrapper.scriptData && !!this.formWrapper.dynamicForm) {
          const initData = this.formWrapper.scriptData['PREF_GENERAL'];
          if (!!initData) {
            const formData = this.formWrapper.dynamicForm.getAllData();
            for (const key of Object.keys(initData)) {
              if (key in formData && initData[key] !== formData[key]) {
                showReset = true;
                break;
              }
            }
          }
        }
        break;
      case 'tiles':
        if (!!this.formWrapper && this.formWrapper.kind === '__local_preferences_tiles' && !!this.formWrapper.formData && !!this.defaultTiles) {
          const tileIds = Object.keys(this.defaultTiles);
          if (Util.Device.isPhoneLook()) {
            tileIds.splice(0, 1);
            if (!Util.Device.bIsOfficeAddin) {
              const checkoutIndex = tileIds.findIndex(t => t.indexOf('checkedout') !== -1);
              if (checkoutIndex !== -1) {
                tileIds.splice(checkoutIndex, 1);
              }
            }
          }
          if (tileIds.length !== Object.keys(this.formWrapper.formData).length) {
            showReset = true;
          } else {
            for (const id of tileIds) {
              if (!(id in this.formWrapper.formData) || this.defaultTiles[id] !== this.formWrapper.formData[id]) {
                showReset = true;
                break;
              }
            }
          }
        }
        break;
      case 'profile_defaults':
        showReset = true;
        break;
    }
    this.showReset = showReset;
    if (enableReset && this.showReset) {
      this.enableReset();
    }
  }

  private libChanged(selectComponent: SelectComponent): void {
    if (!!selectComponent) {
      this.desc.lib = selectComponent.value;
    }
    const appIDs = Util.RestAPI.getAppIDs(this.desc.lib);
    const formPaperApp = appIDs.find(a => a.APP_ID === '%FORM_PAPER_APPLICATION');
    this.appIDItems = [new ListItem({id:this.desc.lib,type:'documents',lib:this.desc.lib,APP_ID:'%PRIMARY_FORM',DOCNAME:this.localizer.getTranslation('PROFILE_DEFAULTS.PRIMARY')}), new ListItem({id:'123',type:'searches',lib:'',APP_ID:'SEARCH',DOCNAME:this.localizer.getTranslation('HEADER.SEARCH')})];
    for (const appID of appIDs) {
      if (appID.editable && !this.appIDItems.find(i => i.APP_ID === appID.APP_ID || appID.APP_ID === 'DEFAULT' || appID.APP_ID === '%PRIMARY_FORM' || (appID.APP_ID.toUpperCase() === 'PAPER' && !!formPaperApp))) {
        this.appIDItems.push(new ListItem({id:'',type:(appID.APP_ID==='FOLDER'?'folders':'documents'),lib:'',APP_ID:appID.APP_ID,DOCNAME:appID.APP_ID==='%FORM_PAPER_APPLICATION'?this.localizer.getTranslation('FORMS.BUTTONS.PAPER'):appID.APP_ID}));
      }
    }
    this.curAppIDIndex = 0;
    this.appIDSelItems = this.appIDItems.map(aii => ({value:aii.APP_ID,display:aii.DOCNAME}) as SelectItem);
    this.loadList();
    this.settingsService.get('preferences/formdefaults').then(data => {
      if (!!data) {
        for (const lib of this.libSelItems) {
          if (!data[lib.value]) {
            data[lib.value] = {};
          }
        }
        this.formDefaults = data;
        const libUC = this.desc.lib.toLocaleUpperCase();
        this.listItems.forEach(f => {
          if (this.formDefaults[libUC][this.curAppID] === f['DOCNAME']) {
            f['default_form'] = true;
          } else {
            f['default_form'] = false;
          }
        });
      }
    }, err => {});
  }

  private logInlibChanged(selectComponent: SelectFieldComponent): void {
    if (selectComponent.value !== Util.RestAPI.getPrimaryLibrary()) {
      const title: string = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.SWITCHING_LIB');
      const body: string = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.SWITCHING_WARN');
      const okBtn: string = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.CHANGE_LIB');
      Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
        if (confirmed && confirmed.confirm) {
          this.primaryLib = selectComponent.value;
          setTimeout(() => {
            this.doCommand('save');
          }, 100);
        } else {
          this.primaryLib = '';
          setTimeout(() => {
            this.primaryLib = Util.RestAPI.getPrimaryLibrary();
          }, 100);
        }
      });
    }
  }

  private appIDChanged(selectComponent: SelectComponent): void {
    const index = this.appIDItems.findIndex(a => a.APP_ID===selectComponent.value);
    if (index !== -1) {
      this.curAppIDIndex = index;
      this.loadList();
    }
  }

  private loadList(): void {
    if (this.tabId === 'libraries') {
      this.loadLibrariesList();
    } else if (this.tabId === 'profile_defaults') {
      this.loadFormList();
    }
  }

  private loadLibrariesList(): void {
    const hiddenRemotes: string[] = Util.RestAPI.getPreference('hidden_remote_libs') as any as string[];
    this.listItems = [];
    const libs: any[] = Util.RestAPI.getLibraries();
    const searchLibs: any[] = Util.RestAPI.getSelectedLibraries();
    if (!!libs && !!libs.length) {
      for (const lib of libs) {
        if (lib.DISABLED === 'N') {
          const searchIndex = searchLibs.findIndex(l => l.LIBRARY_NAME===lib.LIBRARY_NAME);
          const item: any = {lib:lib.LIBRARY_NAME,type:'library',id:lib.LIBRARY_NAME,primary:lib.isPrimary,remote:!lib.isPrimary,search:searchIndex>=0};
          if (item.remote && !!hiddenRemotes && hiddenRemotes.indexOf(item.lib)>=0) {
            item.remote = false;
          }
          this.listItems.push(item);
        }
      }
    }
  }

  private loadFormList(): void {
    this.curAppID = this.appIDSelItems[this.curAppIDIndex].value;
    if (this.curAppIDIndex===0) {
      this.listItems = Util.RestAPI.getProfileForms(this.desc.lib).filter(f => f['%FORM_APPLICATION'] === '%PRIMARY_FORM');
    } else if (this.curAppIDIndex===1) {
      this.listItems = Util.RestAPI.getSearchForms();
    } else {
      const curAppID = this.appIDItems[this.curAppIDIndex];
      this.listItems = Util.RestAPI.getProfileFormsForApp(curAppID.APP_ID, this.desc.lib);
    }
    this.listItems.forEach(f => {
      f['DOCNAME'] = f['%FORM_NAME'];
      if (!!this.formDefaults && !!this.formDefaults[this.desc.lib] && this.formDefaults[this.desc.lib][this.curAppID] === f['DOCNAME']) {
        f['default_form'] = true;
      } else {
        f['default_form'] = false;
      }
    });
    const lib = this.desc.lib;
    this.desc = {id:'',type:'',lib:''};
    setTimeout(() => {
     this.setDesc(lib);
    }, 100);
  }

  private setDesc(lib: string): void {
    this.desc = ({id:'preferences',type:'preferences',lib,imgPath:'assets/images/preferences.svg'} as any);
  }

  protected loadURL(url: string): void {
    // block the base class from loading the url
    this.appComponent.registerWindow(this, url);
    this.setDesc(Util.RestAPI.getPrimaryLibrary().toUpperCase());
    if (!this.showSpinner) {
      this.libChanged(null);
    }
    this.urlLoaded = true;
  }

  public initialList(table: ListTableComponent): ListItem[] {
    return this.listItems;
  }

  public initialSet(table: ListTableComponent): any {
    return {total:this.listItems.length};
  }

  public hoverItemUpdated(table: ListTableComponent): void {
    this.hoverItem = table.getHoverItem();
  }

  public handleListItemClick(table: ListTableComponent, item: ListItem, event: Event, property: string): boolean {
    if (this.ui >= 2 && this.tabId === 'profile_defaults') {
      this.hoverItem = item;
      this.doCommand('edit');
      return true;
    }
    return false;
  }

  public checkboxClick(table: ListTableComponent, item: ListItem, property: string): void {
    if (this.tabId === 'libraries') {
      if ((property === 'search' && !item['search'])
          || (property === 'search' && item['search'] && table['list'].filter(tableitem => tableitem['search']).length > 1)
          || (property === 'remote' && item.lib.toUpperCase() !== this.desc.lib.toUpperCase())) {
        if (!!item[property]) {
          item[property] = false;
        } else {
          item[property] = true;
        }
        this.enableSave(true);
        table.changedSchema();
      }
    } else if (this.tabId === 'profile_defaults') {
      if (property==='default_form') {
        const libFormDefs = this.formDefaults[this.desc.lib];
        if (libFormDefs[this.curAppID] === item['DOCNAME']) {
          libFormDefs[this.curAppID] = null;
        } else {
          libFormDefs[this.curAppID] = item['DOCNAME'];
        }
        const notifyTitle: string = this.localizer.getTranslation('PROFILE_DEFAULTS.DEFAULT_SETTINGS');
        this.settingsService.put('preferences/formdefaults', this.formDefaults).then(success => {
          if (success) {
            const bodyKey = !!libFormDefs[this.curAppID] ? 'PROFILE_DEFAULTS.FORM_DEFAULT_SAVED' : 'PROFILE_DEFAULTS.FORM_DEFAULT_REMOVED';
            const appIDName = this.curAppID==='%PRIMARY_FORM' ? this.localizer.getTranslation('PROFILE_DEFAULTS.PRIMARY') : this.curAppID;
            Util.Notify.success(notifyTitle, this.localizer.getTranslation(bodyKey, [item['DOCNAME'], appIDName]));
            this.listItems.forEach(f => {
             delete f[property];
            });
            item[property] = !!libFormDefs[this.curAppID];
            table.changedSchema();
          } else {
            Util.Notify.warning(notifyTitle, this.localizer.getTranslation('PROFILE_DEFAULTS.FORM_DEFAULT_NOT_SAVED'));
          }
        }, error => {
          Util.Notify.warning(notifyTitle, error);
        });
      }
    }
  }

  public tabSelected(id: string): void {
    this.tabId = id;
    this.loadList();
    this.enableSave(false);
    if (this.formWrapper) {
      this.formWrapper.popupCancel();
    }
    setTimeout(() => {
      this.checkForDirty();
      if (this.showReset) {
        this.enableReset();
      } else {
        Util.RestAPI.getAppComponent().setHeaderRightText(null, null);
      }
    }, 100);
  }

  public tabEnabled(id: string): boolean {
    return true;
  }

  public fileSelected(index: number): void {
    // load a file from the list into the content panel
    if (index >= 0 && index < this.appIDItems.length) {
      this.curAppIDIndex = index;
      this.loadList();
    }
  }

  private cancelHdrBtnClick(): void {
    this.showSpinner = true;
    setTimeout(() => {
      if (this.tabId === 'libraries') {
        this.libChanged(null);
      }
      this.enableSave(false);
      this.showSpinner = false;
    }, Util.kPopupDismissMS);
  }

  private rightHdrBtnClick(): void {
    if (this.showSave) {
      this.doCommand('save');
    } else if (this.showReset) {
      this.doCommand('reset');
    }
  }

  public doCommand(cmd: string): boolean {
    switch (cmd) {
      case 'save':
      case 'popupok':
        if (this.tabId === 'libraries') {
          const formDataStr = localStorage.getItem('$edx_preferences');
          let formData: any = {};
          if (!!formDataStr) {
            try {
              formData = JSON.parse(formDataStr);
            } catch (e) {
              formData = {};
            }
          }
          if (this.primaryLib !== Util.RestAPI.getPrimaryLibrary()) {
            if (!formData['old_primary']) {
              formData['old_primary'] = Util.RestAPI.getPrimaryLibrary();
            }
            formData['primary'] = this.primaryLib;
            localStorage.setItem('$edx_preferences', JSON.stringify(formData));
            setTimeout(() => {
              Util.RestAPI.changePrimaryLibrary(this.primaryLib);
            }, Util.kPopupDismissMS);
          } else {
            const libs: any[] = Util.RestAPI.getLibraries();
            const selectedLibs: any[] = Util.RestAPI.getSelectedLibraries();
            formData['hidden_remote_libs'] = [];
            for (const item of this.listItems) {
              const lib = libs.find(l => l.LIBRARY_NAME===item.lib);
              const selectedLib = selectedLibs.find(l => l.LIBRARY_NAME===item.lib);
              if (!!item['search']) {
                if (!selectedLib) {
                  lib.checked = true;
                  Util.RestAPI.setSelectedLibraries(lib);
                }
              } else {
                if (!!selectedLib) {
                  selectedLib.checked = false;
                  Util.RestAPI.setSelectedLibraries(selectedLib);
                }
              }
              if (!item['remote'] && !item['primary']) {
                formData['hidden_remote_libs'].push(item.lib.toUpperCase());
              }
            }
            const latestLibs: any[] = Util.RestAPI.getSelectedLibraries();
            if (!!latestLibs) {
              formData['$edx_selected_libraries'] = latestLibs.map(lib => lib.LIBRARY_NAME).join(',');
            }
            localStorage.setItem('$edx_preferences', JSON.stringify(formData));
            setTimeout(() => {
              Util.RestAPI.remoteLibrariesChanged();
            }, Util.kPopupDismissMS);
          }
        } else if (this.tabId === 'tiles' && this.resetTiles) {
          Util.RestAPI.changePrimaryLibrary(null);
          setTimeout(() => {
            if (this.formWrapper) {
              this.formWrapper.popupCancel();
            }
            Util.RestAPI.navBack();
          }, Util.kPopupDismissMS);
          return true;
        }
        if (this.formWrapper) {
          this.formWrapper.popupOK();
        }
        Util.RestAPI.navBack();
        return true;
      case 'edit_mini':
      case 'edit':
        if (!!this.hoverItem) {
          const curAppID = this.appIDItems[this.curAppIDIndex];
          if (curAppID.APP_ID !== 'SEARCH') {
            Util.RestAPI.getAppComponent().doCommand('editprofdefs_' + this.hoverItem['DOCNAME'] + '___' + curAppID.APP_ID + '___' + this.desc.lib + '___' + Util.RestAPI.getAppIDDescription(curAppID.APP_ID));
          }
          this.hoverItem = null;
        }
        break;
      case 'cancel':
        if (this.showSave) {
          this.cancelHdrBtnClick();
        } else {
          if (this.formWrapper) {
            this.formWrapper.popupCancel();
          }
          Util.RestAPI.navBack();
          return true;
        }
        break;
      case 'reset':
        const title: string = this.tabId==='tiles' ? this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET_TILES') : this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET');
        const body: string = this.tabId==='tiles' ? this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET_WARN') : this.tabId==='profile_defaults' ? this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET_FORM_DEFS') :  this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET_WARN_GENERAL');
        const okBtn: string = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET');
        Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            this.showReset = false;
            if (Util.Device.isPhoneLook()) {
              Util.RestAPI.getAppComponent().setHeaderRightText(null, 'reset');
            }
            if (this.tabId==='tiles') {
              this.resetTiles = true;
              setTimeout(() => {
               this.doCommand('save');
              }, 100);
            } else if (this.tabId==='profile_defaults') {
              this.settingsService.delete('preferences/formdefaults').then(success => {
                const keys = !!this.formDefaults ? Object.keys(this.formDefaults) : null;
                if (!!keys && keys.length) {
                  for (const key of keys) {
                    this.formDefaults[key] = {};
                  }
                }
                this.libChanged(null);
                Util.Notify.success(title, this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET_FORM_DEFS_SUCCESS'));
              }, err => {
                Util.Notify.error(title,err);
              });
            } else {
              const savedDesc = this.desc;
              localStorage.clear();
              this.desc = {id:'', type:'', lib:''};
              this.formWrapper.setLoading(true);
              Util.Notify.success(title, this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.RESET_WARN_GENERAL_SUCCESS'));
              setTimeout(() => {
                this.desc = savedDesc; this.formWrapper.setLoading(false);
              }, 300);
            }
          }
        });
        break;
    }
    return false;
  }

  public commandEnabled(cmd: string): boolean {
    switch (cmd) {
      case 'save':
        return this.showSave;
      case 'popupok':
      case 'reset':
        return this.showReset;
      case 'edit_mini':
      case 'edit':
        const curAppID = this.appIDItems[this.curAppIDIndex];
        return curAppID.APP_ID !== 'SEARCH';
    }
    return false;
  }
}
