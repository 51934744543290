import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { Util } from '../utils/utils.module';
import { ListItem, ListState } from '../models/list-item';
import { ColFormat, ColumnDesc } from '../models/column';
import { SecurityControl, AccessLevel } from '../models/security-control';
import { CommandHandler } from '../models/command-handler';
import { LocalizeService } from '../services/localize.service';
import { SchemaService } from '../services/schema.service';
import { DataService } from '../services/data.service';
import { LookupService } from '../services/lookup.service';
import { ListService } from '../services/list.service';
import { ListTableComponent } from './list-table.component';
import { GestureKind, GestureListener } from '../directives/gesture.directive';
import { SelectComponent } from '../widgets/select.component';
import { PopupCallback } from '../widgets/popup.component';

enum ViewKind { Plain=0, SingleSelect=1, Select=2 }
const kSelectOnCLickShemas = ['SECURITY','HISTORY','VERSIONS','ATTACHMENTS','FILEPLAN_REQUESTS'];

@Component ({
  selector: 'edx-list-mobile',
  styleUrls: [ 'list-mobile.component.scss' ],
  providers: [ListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ListTableComponent.baseClassInputs,
  templateUrl: 'list-mobile.component.html'
})
export class ListMobileComponent extends ListTableComponent implements OnInit, OnDestroy, GestureListener, CommandHandler, PopupCallback {
  public iOS: boolean = Util.Device.bIsIOSDevice;
  public ieEdge: boolean = Util.Device.bIsEdge || Util.Device.bIsIE;
  public tablet: boolean = Util.Device.isTabletLook();
  public isOfficeAddin: boolean = Util.Device.bIsOfficeAddin;
  public searchCriteria: string = null;
  public selectUnselectAllText: string;
  public customSecurityItem: ListItem = null;
  public customSecurity: SecurityControl = null;
  private bHasMaskedHeader = false;
  private routerSubscription: Subscription;
  @ViewChild('container') private container: ElementRef;
  @ViewChild('listContainer') private listContainer: ElementRef;
  @Input() splitView?: boolean = false;
  @Input() filters: string;
  @Input() hasFooter = false;
  @Input() hasHeader = false;
  @Input() schemaLayout = false;
  @Input() viewKind: number = ViewKind.Plain;
  @Output() actionHandler: EventEmitter<string> = new EventEmitter<string>();

  constructor(protected listService: ListService, protected changeDetector: ChangeDetectorRef, protected schemaService: SchemaService,
              protected localizer: LocalizeService, protected dataService: DataService, protected lookupService: LookupService, private router: Router) {
    super(listService, changeDetector, schemaService, localizer, dataService, lookupService);
    this.selectUnselectAllText = this.localizer.getTranslation('FORMS.BUTTONS.CLEAR_ALL');
    this.bIsPaged = false;
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const navLoc: string = this.router.url;
        const decodedUrl: string = Util.RestAPI.decodeChildRouteURL(navLoc);
        const desc: any = Util.RestAPI.getDescFromURL(decodedUrl);
        const hash: string = navLoc.lastIndexOf('#')!==-1? navLoc.substr(navLoc.lastIndexOf('#')) : null;
        if (this.isSelectMode() && this.desc && desc && !this.officeAddin) {
          if (desc.id===this.desc.id && desc.type===this.desc.type && !hash) {
            if (!this.bHasMaskedHeader) {
              Util.RestAPI.maskHeader(true);
              this.bHasMaskedHeader = true;
            }
          } else {
            if (this.bHasMaskedHeader) {
              Util.RestAPI.maskHeader(false);
              this.bHasMaskedHeader = false;
            }
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.isSelectMode() && this.bHasMaskedHeader && !this.officeAddin) {
      Util.RestAPI.maskHeader(false);
      this.bHasMaskedHeader = false;
    }
    super.ngOnDestroy();
  }

  protected descChanged(newDesc: any): void {
    if (newDesc && (newDesc.type==='lookups' || Util.isExternalLib(newDesc.lib))) {
      this.schemaLayout = true;
    }
    if (this.isSelectMode() && !(newDesc.type==='folders' && (newDesc.id==='downloads' || newDesc.id==='imports'))) {
      this.cancelSelect();
    }
    super.descChanged(newDesc);
    setTimeout(() => {
      if (this.viewKind===ViewKind.SingleSelect && this.parent && this.parent.selectionsUpdated) {
        this.parent.selectionsUpdated(this);
      }
    }, 300);
  }

  protected setTemplatedRights(selectComponent: SelectComponent, itemIndex: number): void {
    if (parseInt(selectComponent.value) === AccessLevel.ACCESS_LEVEL_CUSTOM) {
      this.customSecurityItem = this.list[itemIndex];
    } else {
      super.setTemplatedRights(selectComponent, itemIndex);
    }
  }

  private customSecurityChange(security: SecurityControl): void {
    this.customSecurity = security;
  }

  private isContainer(item: ListItem): boolean {
    return Util.isContainer(item.type);
  }

  public useSchemaCols(): boolean {
    return (this.schemaLayout || this.schemaService.hasCustomSchema(this.schemaId,this.desc.lib,this.schemaForm)) && this.desc && this.schema && !!this.schema.columns && this.schema.columns.length>0;
  }

  private useHardSchemaCols(): boolean {
    return this.schemaLayout && this.desc && this.schema && !!this.schema.columns && this.schema.columns.length>0;
  }

  private renderItemWithSchema(item: ListItem): boolean {
    if (this.useSchemaCols()) {
      if (this.schemaId === 'RELATED') {
        return true;
      }
      return !this.isContainer(item);
    }
    return false;
  }

  private renderItemAsFolder(item: ListItem): boolean {
    if (this.useSchemaCols() && this.schemaId === 'RELATED') {
      return false;
    }
    return this.isContainer(item);
  }

  private hasThirdRow(item: ListItem): boolean {
    return !this.useSchemaCols() && !!this.desc && this.schemaId !== 'ACTIVITY' && this.schemaId !== 'ACTIVITY_AUTHOR' && (Util.isContainer(this.desc.type) || this.showExtrasRow(item)) || this.schemaId === 'VERSIONS';
  }

  private hasSelectCheckboxes(): boolean {
    let hasCheckbox: boolean = (this.isOfficeAddin || (this.desc && this.desc.type === 'lookups')) && this.desc && (this.desc.type !== 'flexfolders' || !!this.desc.id) && this.viewKind !== ViewKind.SingleSelect;
    if ((hasCheckbox && this.isOfficeAddin && this.desc && this.desc.type === 'fileplans' && (this.schemaId !== 'FILEPLAN_DOCUMENT' && this.schemaId !== 'FILEPLAN_FILEPART')) || (!!this.schema && !!this.schema.notSelectable && this.schema.notSelectable === true)) {
      hasCheckbox = false;
    }
    return hasCheckbox;
  }

  public getListState(): ListState {
    let index = 0;
    if (this.hoverItemIndex !== -1) {
      index = this.hoverItemIndex;
    } else if (this.selections.length) {
      const first: ListItem = this.selections[0];
      index = this.list.indexOf(first);
    }
    return new ListState(this.ascending, this.descending, this.set.start || 0, index);
  }

  public scrollList(event: any, fileList: any): void {
    if (!this.allItemsIn && !this.reloading && this.list.length>0 && fileList) {
      let loadMore  = false;
      const reverse = !!this.ascending;
      if (reverse) {
        loadMore = fileList.scrollTop < (fileList.scrollHeight - fileList.clientHeight + 90);
      } else {
        loadMore = fileList.scrollTop > (fileList.scrollHeight - fileList.clientHeight - 90);
      }
      if (loadMore) {
        this.pageSizeIncremental = this.listService.max;
        this.fetchMoreItems();
      }
    }
  }

  private isExternalAppFolder(item: ListItem): boolean {
    return Util.isExternalLib(this.desc.lib) && Util.isContainer(item.type);
  }

  private itemClicked(item: ListItem, event: Event): boolean {
    const bNonSelectModeSelectAnywayList = !!this.schemaId && kSelectOnCLickShemas.indexOf(this.schemaId) >= 0;
    let handled = false;
    if ((this.viewKind===ViewKind.SingleSelect || this.viewKind === ViewKind.Plain) && this.parent && this.parent.handleListItemClick) {
      handled = this.parent.handleListItemClick(this, item, event, 'APP_ID');
    }
    if (!handled) {
      const fullPath: string = item['fullPath'];
      if (this.isSelectMode() || ((bNonSelectModeSelectAnywayList || this.useHardSchemaCols()) && !this.isExternalAppFolder(item))) {
        if (!this.isSelectMode() && this.useHardSchemaCols()) {
          this.clearSelection();
        }
        this.selectorClick(item, event);
      } else {
        if ((Util.isSharedDownloads(this.desc) || Util.isSharedImports(this.desc)) && Util.isUnmangedFile(item)) {
          // can not preview an unmanaged file so do nothing unless it is a drf
          if (fullPath && fullPath.toUpperCase().endsWith('.DRF')) {
            Util.RestAPI.openDRFItem(item);
          }
        } else if (this.viewKind===ViewKind.Plain && (item.type==='urls' || Util.isContainer(item.type))) {
          this.listService.openItem(item);
        } else if (this.isOfficeAddin && Util.RestAPI.hasPFTA() && this.canOpenListItem(item)) {
          this.listItemClicked(item, event, false, true);
        } else {
          // do not call base class as it will download files
          this.listService.openMetadata(this.list, this.set, this.containerRights(), item, Util.RestAPI.canShowPreview() ? 'preview' : null);
          if (this.viewKind===ViewKind.SingleSelect) {
            this.clearSelection();
            this.selectorClick(item, event);
          }
        }
      }
    }
    return true;
  }

  private getItemFromElement(element: any): any {
    do {
      if (element.classList.contains('item')) {
        return element;
      }
      element = element.parentElement;
    } while (element);
    return null;
  }

  private srollItemIntoView(target: any): void {
    const lcEl = this.listContainer.nativeElement;
    const listTop: number = lcEl.offsetTop;
    const listHeight: number = lcEl.offsetHeight;
    const currentScrollTop: number = lcEl.scrollTop;
    const itemTop = target.offsetTop;
    const itemHeight: number = target.offsetHeight;
    if ((itemTop - listTop) < currentScrollTop) {
      lcEl.scrollTop = itemTop - listTop;
    } else if (((itemTop - listTop) + itemHeight) > (currentScrollTop+listHeight)) {
      lcEl.scrollTop = itemTop + itemHeight - listTop - listHeight;
    }
  }

  protected selectorClick(item: ListItem, event: Event, target?: any): void {
    target = target || (event ? event.target : null);
    super.selectorClick(item, event, target);
    this.updateSelectString();
    if (Util.Device.bIsOfficeAddin && (!this.formType || this.formType.startsWith('profile_query') || this.formType === '__local_activitysearch')) {
      if (!this.noneSelected) {
        this.enterSelect();
      } else {
        this.cancelSelect();
      }
    }
    setTimeout(() => {
      if (!this.noneSelected && target) {
        target = this.getItemFromElement(target);
        if (target) {
          setTimeout(() => {
            this.srollItemIntoView(target);
          }, 1);
        }
      }
    }, 1);
  }

  protected canShowHoverMenu(): boolean {
    if (super.canShowHoverMenu()) {
      return !this.isSelectMode();
    }
    return false;
  }

  protected updateSelectString(): void {
    super.updateSelectString();
    if (this.allSelected) {
      this.selectUnselectAllText = this.localizer.getTranslation('FORMS.BUTTONS.CLEAR_ALL');
    } else {
      this.selectUnselectAllText = this.localizer.getTranslation('PLACEHOLDER.SELECT_ALL');
    }
  }

  private isOneLine(): boolean {
    if (this.tablet && !this.splitView && this.container && this.container.nativeElement && this.container.nativeElement.offsetWidth) {
      return this.container.nativeElement.offsetWidth >= 384;
    }
    return false;
  }

  private isSelectMode(): boolean {
    return this.viewKind === ViewKind.Select;
  }

  private filterClicked(event: Event): void {
    this.actionHandler.emit('showfilter');
  }

  private clearFilter(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.actionHandler.emit('clearfilter');
    this.reloadList();
  }

  private sortClicked(event: Event): void {
    this.headerColumnClick(event, (this.getSortCol() || this.getExternalSortCol(true)));
    this.changeDetector.markForCheck();
  }

  private sortIsReverse(): boolean {
    return !!this.ascending;
  }

  private sortColumnName(checkExternal?: boolean): string {
    if (this.schema) {
      let sortCol: string = this.getSortCol();
      if (!sortCol && checkExternal && Util.RestAPI.isExternalLib(this.desc.lib)) {
        sortCol = this.getExternalSortCol();
      }
      if (sortCol) {
        let column: ColumnDesc = this.schema.columns.find(c => c.property===sortCol);
        if (!column) {
          column = this.schema.columns.find(c => c.property===this.schema.sortAscending || c.property===this.schema.sortDescending);
        }
        if (column) {
          return column.label;
        }
      }
    }
    return null;
  }

  private showMenuIcons(): boolean {
    if (this.schemaId==='VERSIONS' || this.schemaId==='SECURITY' || this.schemaId==='WHERE_USED') {
      return false;
    }
    return true;
  }

  private showColumnChooser(): boolean {
    return !!this.desc && !Util.RestAPI.isExternalLib(this.desc.lib) && this.desc.id !== 'checkedout' && this.desc.id !== 'public' && ((this.desc.type === 'searches' || this.desc.type === 'folders' || this.desc.type === 'workspaces' || Util.isRMFilePart(this.desc)) && !!this.desc.id);
  }

  private showFilterIcon(): boolean {
    // splitView means we are tablet left side of a right preview so no filtering
    return !this.splitView && this.canHaveFilters();
  }

  private showConfigRed(): boolean {
    return !!this.desc && this.desc.id === 'recentedits' && this.desc.type === 'folders';
  }

  private showColForItem(col: ColumnDesc, item: ListItem): boolean {
    let value: any = Util.RestAPI.getPropValue(item, col.property);
    if (col.property === 'PARENTMAIL_ID') {
      return false;
    }
    if (col.format === ColFormat.STATUS) {
      if (typeof value === 'number') {
        value = value.toString();
      }
      if (value === '0' && this.schemaId === 'VERSIONS') {
        return false;
      }
    }
    if (value === '0' && this.schemaId === 'HISTORY') {
      return true;
    }
    return !!col.label && (!!value || col.format===ColFormat.CHECKBOX);
  }

  private showIcon(item: ListItem): boolean {
    if ((this.isSelectMode() && this.selections.indexOf(item)>=0 && !this.officeAddin) || (this.schemaId!=='SECURITY' || this.officeAddin)) {
      if (this.schemaId!=='ATTACHMENTS' || !!this.getIconSrc(item)) {
        return true;
      }
    }
    return false;
  }

  private showIconWrapper(item: ListItem): boolean {
    return !this.useHardSchemaCols() || Util.isExternalLib(this.desc.lib);
  }

  private getDateCol(): string {
    if (this.schemaId === 'CHECKED_OUT') {
      return 'CHECKOUT_DATE';
    } else if (this.schemaId === 'ACTIVITY' || this.schemaId === 'ACTIVITY_AUTHOR') {
      return 'START_DATE';
    }
    return 'LAST_EDIT_DATE';
  }

  private getDetails(item: ListItem): string {
    if (this.schemaId==='VERSIONS' || this.schemaId === 'ATTACHMENTS') {
      return this.formatDate(item, 'LASTEDITDATE');
    } else if (this.schemaId === 'ACTIVITY') {
      return this.formatAction(item);
    } else if (this.schemaId==='SECURITY' && item['USER_ID']) {
      return item['USER_ID'];
    }
    if (!item.DOCNAME && !!item['lang_key_DOCNAME'] && this.localizer.translationExists(item['lang_key_DOCNAME'])) {
      item.DOCNAME = this.localizer.getTranslation(item['lang_key_DOCNAME']);
    }
    return item.DOCNAME;
  }

  private getOther1(item: ListItem): string {
    let str: string;
    if (item.type==='urls') {
      str = item['URL_ADDRESS'];
    } else if (this.schemaId==='VERSIONS' || this.schemaId==='ATTACHMENTS') {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.VERSIONS.AUTHOR') + ': ' + item['AUTHOR_ID'];
    } else if (this.desc && this.desc.type==='flexfolders') {
      const secure: string = item['HAS_SECURITY']==='1' ? this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.PRIVATE') : this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.PUBLIC');
      str = this.localizer.getTranslation('METADATA.TABS.SECURITY') + ': ' + secure;
    } else if (this.desc && this.desc.type==='searches' && !this.desc.id) {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.SYSTEM_ID') + ': ' + item['SYSTEM_ID'];
    } else if (item.type==='fileplans') {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.SYSTEM_ID') + ': ' + item['SYSTEM_ID'];
    } else {
      const date: string = this.formatDate(item, this.getDateCol());
      const subKey = (this.schemaId === 'ACTIVITY' || this.schemaId === 'ACTIVITY_AUTHOR') ? 'HISTORY.DATE' : 'DEFAULT.MOD';
      str =  this.localizer.getTranslation('COLUMN_HEADINGS.' + subKey) + ': ' +  date;
    }
    return str;
  }

  private getOther2(item: ListItem): string {
    let str: string;
    if (item.type==='urls' || this.schemaId === 'ACTIVITY' || this.schemaId === 'ACTIVITY_AUTHOR') {
      str = null;
    } else if (this.schemaId==='VERSIONS' || this.schemaId==='ATTACHMENTS') {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.VERSIONS.TYPIST') + ': ' +  item['TYPIST_ID'];
    } else if (this.desc &&  (item.type==='fileplans' || (this.desc.type==='flexfolders' && !this.desc.id) || (this.desc.type==='flexfolders' && this.desc.id) || (this.desc.type==='searches' && !this.desc.id))) {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.LIBRARY') + ': ' +  item['lib'];
    } else if (!!item['DOCNUM']) {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.DOCID') + ': ' + item['DOCNUM'];
      if ((item as any).ver) {
        str += ' v'+(item as any).ver;
      }
    }
    return str;
  }

  private getOther3(item: ListItem): string {
    let str: string;
    if (this.showExtrasRow(item)) {
      return this.labelExtrasRow(item) + this.formatExtrasRow(item);
    } else if (!this.desc || item.type==='urls' || item.type==='fileplans' || ((this.desc.type==='flexfolders' || this.desc.type==='searches') && !this.desc.id)) {
      str = null;
    } else if (this.schemaId==='VERSIONS') {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.SIZE') + ': ' + item['FILE_SIZE'];
    } else {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.AUTHOR') + ': ' + (item['AUTHOR_ID'] || item['TYPIST_ID'] || Util.RestAPI.getUserID());
    }
    return str;
  }

  private getOther4(item: ListItem): string {
    let str: string;
    if (this.showExtrasRow(item)) {
      return null;
    } else if (!this.desc || item.type==='urls' || item.type==='fileplans' || ((this.desc.type==='flexfolders' || this.desc.type==='searches') && !this.desc.id)) {
      str = null;
    } else if (this.schemaId==='VERSIONS') {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.VERSIONS.STATUS') + ': '+ this.formatStatusText(item);
    } else {
      str = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.LIBRARY') + ': ' + item['lib'];
    }
    return str;
  }

  private getIconSrc(item: ListItem): string {
    if (this.isSelectMode() && !this.officeAddin && this.selections.indexOf(item)>=0) {
      return 'assets/images/action_select24.svg';
    } else if (this.schemaId==='ATTACHMENTS' || this.schemaId === 'VERSIONS') {
      const iconPath = Util.RestAPI.getIconForFile('tmp.'+(item as any).FILE_EXTENSION);
      if (!!iconPath) {
        return iconPath;
      }
      return null;
    }
    return this.formatTypeIcon(item);
  }

  private showIconWrapperSelector(item: ListItem): boolean {
    return this.schemaId==='WHERE_USED' && this.isSelectMode() && !this.officeAddin && this.selections.indexOf(item)===-1;
  }

  private formatNumberSelected(): string {
    return this.localizer.getTranslation('HEADER.NUM_SELECTED',[this.selections.length.toString()]);
  }

  private selectUnselectAll(): void {
    this.headerSelectClick(); // toggle
    this.updateSelectString();
    if (this.selections.length===0 && this.isOfficeAddin) {
      this.cancelSelect();
    }
  }

  private setSelectMode(set: boolean): void {
    this.viewKind = set ? ViewKind.Select : ViewKind.Plain;
    if (set) {
      this.updateSelectString();
    } else {
      this.clearSelection();
    }
    if (!this.officeAddin && ((!set && this.bHasMaskedHeader) || (set && !this.bHasMaskedHeader))) {
      Util.RestAPI.maskHeader(set);
      this.bHasMaskedHeader = set;
    }
    this.changeDetector.markForCheck();
  }

  private cancelSelect(): void {
    this.setSelectMode(false);
  }

  private enterSelect(): void {
    this.setSelectMode(true);
  }

  public forceRender(event: Event): void {
    if (Util.Device.bIsCordova && this.reloading) {
      this.reloading = false;
      this.changeRef.markForCheck();
    }
  }

  private countInfo(): string {
    return this.reloading ? '-' : this.localizer.getTranslation('PAGINATOR.OF_ITEMS', [this.list.length, (this.set.total || 0)]);
  }

  protected getDragOverInfo(event: any): {dragChanged: boolean; rowDocName: string} {
    const target: any = this.getItemFromElement(event.target);
    let top: number;
    const height: number = null;
    let dragChanged = false;
    let rowDocName: string;

    if (target) {
      if (this.dragoverTR !== target) {
        dragChanged = true;
        this.dragoverTR = target;
        this.dragOverItem = null;
        if (this.dragoverTR.classList.contains('dropfolder')) {
          this.dragoverTR.classList.add('dragover');
          const index: number = this.getDragOverIndex();
          if (index !== null) {
            this.dragOverItem = this.list[index];
            rowDocName = this.dragOverItem.DOCNAME;
          }
        }
      }
    } else if (!Util.RestAPI.dropBannerShown()) {
      dragChanged = true;
    }
    return {dragChanged, rowDocName};
  }

  public listReplaced(): void  {
    const oldViewKind: ViewKind = this.viewKind;
    this.searchCriteria = null;
    super.listReplaced();
    if (oldViewKind===ViewKind.Select && this.viewKind!==ViewKind.Select) {
      this.setSelectMode(true);
    }
    if (!this.filters && this.set.search && this.set.search.criteria) {
      const keys: string[] = Object.keys(this.set.search.criteria);
      for (const key of keys) {
        if (key.startsWith('FULLTEXT')) {
          this.searchCriteria = this.localizer.getTranslation('REFINE_SEARCH.SEARCH_TERM', [this.set.search.criteria[key]]);
          break;
        }
      }
    }
    if (Util.Device.bIsCordova) {
      setTimeout(() => {
        const event: Event = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
        this.reloading = true;
        this.container.nativeElement.dispatchEvent(event);
      }, 1);
    }
    const lastListState = this.dataService.getListState(this.desc);
    if (!!lastListState && lastListState.index) {
      setTimeout(() => {
        const element = document.getElementById('edx_li_'+lastListState.index);
        if (element) {
          this.srollItemIntoView(element);
        }
      }, 100);
    }
  }

  public clearSelection(): void {
    if (this.viewKind === ViewKind.Select) {
      this.viewKind = ViewKind.Plain;
      if (this.bHasMaskedHeader) {
        Util.RestAPI.maskHeader(false);
        this.bHasMaskedHeader = false;
      }
    }
    super.clearSelection();
  }

  public commandEnabled(cmd: string): boolean {
    if (cmd.startsWith('list_choosecol_')) {
      const colID: string = cmd.substr(15);
      if (!this.set || !this.set.limitedsorting || this.set.limitedsorting.indexOf(colID)!==-1) {
        if (!!this.desc && this.desc.id === 'public' && colID==='STATUS') {
          return false;
        }
        const colDesc: ColumnDesc = this.getColDesc(colID);
        if (!!colDesc && colDesc.nonsortable) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    }
    switch (cmd) {
    case 'list_selectmode':
      return this.schemaHasCol('select');
    case 'list_selectall':
      return this.isSelectMode();
    }
    return false;
  }

  public doCommand(cmd: string): boolean {
    if (cmd.startsWith('list_choosecol_')) {
      if (this.descending) {
        this.descending = cmd.substr(15);
      } else if (this.ascending) {
        this.ascending = cmd.substr(15);
      }
      if (Util.RestAPI.isExternalLib(this.desc.lib) && !this.getSortCol()) {
        this.setSortParams(null, cmd.substr(15));
      }
      this.resort(1);
      return true;
    }
    switch (cmd) {
    case 'list_selectmode':
      this.setSelectMode(!this.isSelectMode());
      return true;
    case 'list_selectmode_on':
      this.setSelectMode(true);
      return true;
    case 'list_selectmode_off':
      this.setSelectMode(false);
      return true;
    case 'list_selectall':
      this.headerSelectClick();
      return true;
    }
    return false;
  }

  private canHandleGesture(): boolean {
    // Deleted items cannot modify any self list items
    if (this.desc && !!this.desc['STATUS'] && this.desc['STATUS'] === '18') {
      return false;
    } else {
      return true;
    }
  }

  public handleGesture(gesture: GestureKind, elem: ElementRef, target: any): boolean {
    if (!this.officeAddin && this.canHandleGesture()) {
      const itemEl: any = Util.findSelfOrParentWithClass(target, 'item');
      const itemIndex: number = !!itemEl && !!itemEl.id && itemEl.id.startsWith('edx_li_') ? parseInt(itemEl.id.substr(7)) : NaN;
      if (!isNaN(itemIndex)) {
        const item: ListItem = this.list[itemIndex];
        if (this.inlineActionTarget && this.viewKind !== ViewKind.SingleSelect) {
          if (gesture===GestureKind.LongPress) {
            if (this.schemaHasCol('select') && !this.isSelectMode()) {
              this.enterSelect();
              this.hideHoverMenu(itemIndex);  // just incase
              setTimeout(() => {
                if (item && this.selections.indexOf(item)===-1) {
                  this.selectorClick(item, null, target); // select if not selected
                }
              }, 500);
              return true;
            }
          } else if (gesture===GestureKind.SwipeLeft) {
            this.hoverItemIndex = itemIndex;
            this.showHoverMenu();
            return true;
          } else if (gesture===GestureKind.SwipeRight) {
            this.hideHoverMenu(itemIndex);
            return true;
          }
        } else if (gesture===GestureKind.DoubleTap) {
            this.listItemDblClick(item, null);
          return true;
        }
      }
    }
    return false;
  }

  // **** PopupCallback implementation
  popupCancel(): void {
    this.customSecurityItem = null;
    this.customSecurity = null;
    this.changeDetector.markForCheck();
  }

  popupOK(): void {
    // CustomSecurity Item is already updated with the latest security
    this.popupCancel();
  }
}
