/**
 * Created by kevin on 2016-11-10.
 */

import { Input, Component, OnChanges, OnDestroy, ElementRef, ViewChild, HostListener, AfterViewChecked, ChangeDetectorRef, SimpleChange } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AWFileSystem, AWContacts } from 'appworks-js';
import { FormFieldDesc, FormField, FormUtils } from '../models/form-field';
import { Util, UserInterface, OrientationListener } from '../utils/utils.module';
import { BaseDesc } from '../models/base';
import { ColFormat } from '../models/column';
import { PopupCallback } from '../widgets/popup.component';
import { ListTableComponent, ListTableParent } from '../lists/list-table.component';
import { LocalizeService } from '../services/localize.service';
import { ListSecurityComponent } from '../lists/list-security.component';
import { ListItem } from '../models/list-item';
import { SecurityControl, AccessLevel } from '../models/security-control';
import { ListService } from '../services/list.service';
import { LookupService, CacheItem } from '../services/lookup.service';
import { ListFolderPickerComponent } from '../lists/list-folder-picker.component';
import { FileDropTargetDirective } from '../directives/drag-target.directive';

export interface FormController {
  fieldChanged?(field: FormField, control: AbstractControl, formFields: FormField[]): boolean;
  buttonClicked?(field: FormField, control: AbstractControl, formFields: FormField[]): void;
  uploadFiles?(files: File[], filePaths?: string[]): void;
  hasUploadFiles?(): boolean;
  fileNameForUpload?(): string;
  appIDForUpload?(): string;
  enableOK?(enable: boolean, inital?: boolean): void;
  getRightForm?(): DynamicFormComponent;
  getHeaderForm?(): DynamicFormComponent;
  getFormName?(): string;
  prefillInitialValuesForForm?(): void;
  allowFirstFocus?(): boolean;
  layoutChanged?(layout: string): void;
  securityDirty?(dirty: boolean): void;
}
@Component({
  selector: 'edx-dynamic-form',
  styleUrls: ['dynamic-form.component.scss'],
  template:  `
    <form #formEl *ngIf="form" novalidate [formGroup]="form" [ngClass]="{edx_hidden:!formIsVisible(), indialog:inDialog, innotify:inNotify, readonly:readOnly, columnview:formLayout==='column', header:formLayout==='header', mobile:ui>=2, phone: ui===2||ui===4, profile:isProfileForm(), inlineparent:inlineParent, fullheight:!!fileDropTarget, persmisionsselector:isPersmisionsSelector, oai:isOfficeAddin, choosershidden:choosersHidden}" [edx-file-drop-target]="fileDropTarget">
      <ng-template ngFor let-field [ngForOf]="fields" let-isFirst="first">
        <div edx-dynamic-form-row *ngIf="canShowField(field)" [field]="field" [form]="form" [formLayout]="formLayout" [showExtras]="extrasShown" [fullWidth]="fullWidth" [inlineForm]="!!inlineParent"
          [inDialog]="inDialog" [inNotify]="inNotify" [parent]="this" [formReadonly]="readOnly" [single]="isSingleField(field)" [first]="isFirst" [rerender]="useForceRender(field)"
          [ngClass]="{header:formLayout==='header', transparent:!field.isVisible}"></div>
      </ng-template>
    </form>
    <div [style.height]="standInBodyHeight + 'px'"></div>
    <edx-popup *ngIf="lookupShown" [callback]="this" [kind]="'list_1'" [title]="lookupTitle" [ok]="lookupOKTitle" [okDisabled]="lookupOKDisabled" [headerform]="true">
      <edx-search-filter secondheader [desc]="lookupDesc" [noWildcard]="lookupNoWildcard" [lookupIsNumeric]="lookupIsNumeric" [primaryKey]="lookupPrimaryKey" [primaryValue]="lookupPrimaryValue" [selValue]="lookupInitalKey" [searchValue]="lookupInitalSearch" [list]="lookupTable"></edx-search-filter>
      <edx-list-mobile #lookupTable *ngIf="ui>=2" [selectedLookupValues]="lookupInitalSearch" [desc]="lookupDesc" [lookupForm]="lookupForm" [lookupKey]="lookupInitalKey" [searchLookup]="fieldPrimaryKey" [parent]="this" [leadingColums]="lookupLeadingColums" [formType]="formKind" [hasFootprint]="true" [viewKind]="!!lookupLeadingColums?0:1"></edx-list-mobile>
      <edx-list-table #lookupTable *ngIf="ui<2" [selectedLookupValues]="lookupInitalSearch" [lookupKey]="lookupInitalKey" [searchLookup]="fieldPrimaryKey" [isParentEmpty] = "isLookupParentEmpty" [desc]="lookupDesc" [lookupForm]="lookupForm" [parent]="this" [leadingColums]="lookupLeadingColums" [formType]="formKind"></edx-list-table>
    </edx-popup>
    <edx-popup #pickerPopup *ngIf="securityShown" [callback]="this" [kind]="securityKind" [width]="dialogWidth" [title]="securityTitle" [ok]="securityOK" [okDisabled]="false">
      <edx-list-security #security [kind]="pickerPopup.kind" [desc]="securityDesc" [securityList]="securityList" [inDialog]="true" [massProfile]="bMassProfileUpdate" [(trusteesDirective)]="trusteesDirective"></edx-list-security>
    </edx-popup>
    <edx-popup #pickerPopup *ngIf="fileplansShown" [callback]="this" [kind]="'list_2_single_filepart'" [width]="dialogWidth" [desc]="fileplansDesc" [title]="fileplansTitle" [ok]="fileplansOK" [okDisabled]="pickerOKDisabled()" [headerform]="false" [headerformOnRight]="false" [levelDropdownOnRight] = "false">
      <edx-list-folder-picker #fileplans *ngIf="fileplansDesc.type==='fileparts'" [kind]="pickerPopup.kind" [desc]="fileplansDesc" [disableList]="pickerDisableList" id="edx_list_picker"></edx-list-folder-picker>
    </edx-popup>
    <edx-spinner *ngIf="formLayout!=='header' && loadingCount>0"></edx-spinner>
  `
})
export class DynamicFormComponent implements OnChanges, OnDestroy, AfterViewChecked, PopupCallback, ListTableParent, OrientationListener {
  @ViewChild('lookupTable') private lookupTable: ListTableComponent;
  @ViewChild('formEl') formEl: ElementRef;
  @ViewChild('security') private security: ListSecurityComponent;
  @ViewChild('fileplans') private fileplans: ListFolderPickerComponent;
  @Input() desc: BaseDesc;
  @Input() data: any = {};
  @Input() formTemplate: any;
  @Input() inDialog?: boolean = false;
  @Input() inNotify?: boolean = false;
  @Input() readOnly?: boolean = false;
  @Input() choosersHidden?: boolean = false;
  @Input() formKind?: string = null;
  @Input() createType?: string = null;
  @Input() controller?: FormController;
  @Input() layout?: string = null;
  @Input() inlineParent?: DynamicFormComponent = null;
  @Input() fileDropTarget?: FileDropTargetDirective = null;
  public form: FormGroup;
  public fullWidth = false;
  public standInBodyHeight = 0;
  public loadingCount = 0;
  public formLayout: string;
  public lookupShown = false;
  public securityShown = false;
  public fileplansShown = false;
  public trusteesDirective = 'replace';
  protected userSecurity: SecurityControl = null;
  protected extrasShown = false;
  private fields: FormField[];
  private lookupField: FormField = null;
  private lookupDesc: BaseDesc = {lib:'',id:'',type:'lookups'};
  private lookupForm = '';
  private lookupTitle = '';
  private lookupOKTitle = '';
  private lookupInitalSearch = '';
  private fieldPrimaryKey = '';
  private isLookupParentEmpty = false;
  private lookupInitalKey: string;
  private lookupPrimaryKey: string;
  private lookupPrimaryValue: string;
  private lookupNoWildcard = false;
  private lookupIsNumeric = false;
  private lookupOKDisabled = true;
  private lookupLeadingColums: number[] = null;
  private formScrollTop = 0;
  private electronDialogShown = false;
  private _placeholders: any;
  private _errors: any;
  private initialValue: any;
  private layoutComplete = false;
  private securityDesc: any = {};
  private securityKind = '';
  private securityTitle = '';
  private securityOK = '';
  private securityList: ListItem[];
  private bMassProfileUpdate = false;
  private dialogWidth: number = Util.kMaxPopupDialogWidth;
  private savedFormData: any = null;
  private blockValidation = 0;
  private savedFilteredCriteria: any = {};
  private ui: UserInterface;
  private isOfficeAddin: boolean;
  private inlineFormField: FormField = null;
  private isPersmisionsSelector = false;
  private fileplansDesc: any = {};
  private fileplansTitle = '';
  private fileplansOK = '';
  private pickerForm: string = null;
  private pickerLeadingColums: number[] = null;
  private pickerDisableList: ListItem[] = null;

  constructor(protected listService: ListService, private lookupService: LookupService, private localizer: LocalizeService, private cdr: ChangeDetectorRef) {
    this._placeholders = {
      readwrite: {
        TEXT: this.localizer.getTranslation('FORMS.PLACEHOLDERS.TEXT'),
        TEXTAREA: this.localizer.getTranslation('FORMS.PLACEHOLDERS.TEXTAREA'),
        NUMBER: this.localizer.getTranslation('FORMS.PLACEHOLDERS.NUMBER'),
        SELECT: this.localizer.getTranslation('FORMS.PLACEHOLDERS.SELECT'),
        DATE: this.localizer.getTranslation('FORMS.PLACEHOLDERS.DATE'),
        FILE: this.localizer.getTranslation('FORMS.PLACEHOLDERS.FILE'),
        FOLDER: this.localizer.getTranslation('FORMS.PLACEHOLDERS.FOLDER'),
        EMAIL: this.localizer.getTranslation('FORMS.PLACEHOLDERS.EMAIL'),
        MESSAGE: this.localizer.getTranslation('FORMS.PLACEHOLDERS.MESSAGE'),
        ADVSEARCH_INPUT: this.localizer.getTranslation('FORMS.PLACEHOLDERS.ADVANCESEARCH_INPUT') // for input fields in advancesearch.
      },
      readonly: {
        TEXT: '',
        TEXTAREA: '',
        NUMBER: '',
        SELECT: '',
        DATE: '',
        FILE: '',
        FOLDER: ''
      }
    };
    this._errors = {
      MISSING: this.localizer.getTranslation('FORMS.ERRORS.MISSING'),
      HIGH: this.localizer.getTranslation('FORMS.ERRORS.HIGH'),
      LOW: this.localizer.getTranslation('FORMS.ERRORS.LOW'),
      LONG: this.localizer.getTranslation('FORMS.ERRORS.LONG'),
      LOOKUP: this.localizer.getTranslation('FORMS.ERRORS.LOOKUP'),
      FILE_PICKER: this.localizer.getTranslation('FORMS.ERRORS.FILE_PICKER'),
      EMAIL: this.localizer.getTranslation('FORMS.ERRORS.EMAIL'),
      DOMAIN: this.localizer.getTranslation('FORMS.ERRORS.DOMAIN'),
      FOLDER_PICKER: this.localizer.getTranslation('FORMS.ERRORS.FOLDER_PICKER'),
      INVALID_NAME: this.localizer.getTranslation('FORMS.ERRORS.INVALID_NAME'),
      INVALID_DOCNUM: this.localizer.getTranslation('FORMS.ERRORS.INVALID_DOCNUM'),
      INVALID_DATE: this.localizer.getTranslation('FORMS.ERRORS.INVALID_DATE')
    };
    this.lookupOKTitle = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
    this.ui = Util.Device.ui;
    this.isOfficeAddin = Util.Device.bIsOfficeAddin;
    this.formLayout = (this.ui<2 || this.readOnly) ? 'page' : 'column';
    Util.Device.registerOrientationListener(this);
  }

  ngOnDestroy() {
    Util.Device.deregisterOrientationListener(this);
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if ((!!changes['formTemplate'] || !!changes['data']) && !!this.formTemplate && this.formTemplate.defs) {
      ++this.blockValidation;
      this.initialValue = {};
      let rights = 255;
      if (!!this.data) {
        if (this.data['rights']) {
          rights = this.data['rights'];
        } else if (this.data['%EFFECTIVE_RIGHTS']) {
          rights = this.data['%EFFECTIVE_RIGHTS'];
        }
        if (this.formKind.startsWith('profile') && !this.createType && !!this.data['DOCUMENTS'] && this.data['DOCUMENTS'].length > 1) {
          this.bMassProfileUpdate = true;
        }
      }
      this.isPersmisionsSelector = this.formTemplate.name.startsWith('permissions_selector');
      if (!this.userSecurity) {
        this.userSecurity = new SecurityControl(rights);
      } else {
        this.userSecurity.access = rights;
      }
      if (!this.userSecurity.canEditProfile) {
        this.readOnly = true;
      } else if (!this.userSecurity.canControlAccess) {
        if (this.isPersmisionsSelector) {
          this.readOnly = true;
        }
      }
      if (!!changes['formTemplate']) {
        if (this.formTemplate.extrasshown && !this.extrasShown) {
          this.extrasShown = true;
        }
        this.fullWidth = this.formTemplate.fullwidth;
        this.createFieldsFromTemplate(this.formTemplate.defs);
        this.form.setValue(this.initialValue);
      }
      if (this.data) {
        if (this.desc && this.desc.type === 'workspaces') {
          if (!this.data['X1237']) {
            this.data['X1237'] = this.data['DOCNAME'];
          }
        }
        if (this.formKind === 'profile' || this.formKind === 'profile_copy' || this.formKind === 'profile_savetoedocs') {
          if (this.createType === 'documents' || this.createType === 'folders' || this.createType === 'workspaces') {
            this.data['LASTEDITDATE'] = Util.Transforms.currentDateTime();
            this.data['LAST_EDIT_DATE'] = Util.Transforms.currentDateTime();
            this.data['CREATION_DATE'] = Util.Transforms.currentDateTime();
            this.data['LAST_PRF_EDIT_DATE'] = Util.Transforms.currentDateTime();
            this.data['LAST_EDITED_BY'] = '';
            this.data['LAST_EDIT_ID'] = '';
            this.data['LAST_PROF_EDIT_ID'] ='';
            this.data['LAST_PRF_EDITED_BY'] ='';
            this.data['TYPIST_ID'] ='';
            this.data['STATUS'] = '';

            const loginReply: any = Util.RestAPI.getLoginReply();
            if (loginReply && loginReply['EFFECTIVE_RIGHTS']['VIEW_UNSECURED'] === 'N') {
              this.data['SECURITY'] = '1';
            }
          } else {
            Util.FieldMappings.forEachTemplateField(this.fields, false, (field): boolean => {
              if (field.controltype === 'editdate') {
                const name: string = field.name;
                const value: string = this.data[name];
                if (!!value) {
                  this.data[name] = this.formatDate(value, field.datatype==='1');
                }
              }
              return true;
            });
          }
        }
        this.updateFormData(this.data);
      }
      if (this.formKind.startsWith('profile')) {
        if (this.createType) {
          if ((this.createType === 'workspaces') && (this.desc.id === '')) {
            this.updateControlValue('SECURITY', '1', true);
          }
          const keys: string[] = this.initialValue ? Object.keys(this.initialValue) : [];
          for (const fieldName of keys) {
            const field: any = fieldName !== 'DOCNAME' && fieldName !== 'X1237' && !fieldName.startsWith('$edx') ? this.getField(fieldName) : null;
            if (field && field.isRequired) {
              const control: FormControl = this.form.controls[fieldName] as FormControl;
              if (control && control.value && control.value.length) {
                control.markAsDirty();
              }
            }
          }
          if (this.createType === 'documents' && this.data['PD_FILEPT_NO']) {
            const fp_control: FormControl = this.form.controls['PD_FILEPT_NO'] as FormControl;
            if (fp_control) {
              fp_control.markAsDirty();
            }
          }
        }/* else if (!!this.data['DOCUMENTS'] && this.data['DOCUMENTS'].length > 1) {
          this.bMassProfileUpdate = true;
        }*/
        // To set the current user id for created by only when creating documents,folders,workspaces and while copying
        if ((this.formKind === 'profile_copy' || this.createType === 'folders' || this.createType === 'documents' || this.createType === 'workspaces') && this.formKind.startsWith('profile') && !this.formKind.startsWith('profile_query')) {
          this.updateControlValue('TYPIST_ID', Util.RestAPI.getUserID(), true);
        }
        if (this.createType === 'folders') {
          this.updateControlValue('APP_ID', 'FOLDER', true);
          this.updateControlValue('FULLTEXT', 'Y', true);
        } else if (this.createType === 'documents') {
          this.fillNameAndAppID();
          this.updateControlValue('FULLTEXT', 'Y', true);
        }
        if (this.savedFormData && !!changes['formTemplate'] && !! changes['data']) {
          const keys: string[] = Object.keys(this.savedFormData);
          for (const key of keys) {
            if (this.formKind === 'profile_query' || (!!Util.FieldMappings.templateField(this.formTemplate.defs, key) && (!this.getControlValue(key) || key === 'RETENTION'))) {
              this.updateControlValue(key, this.savedFormData[key], true);
            }
          }
          this.savedFormData = null;
        }
        if (this.controller && this.controller.prefillInitialValuesForForm) {
          this.controller.prefillInitialValuesForForm();
        } else if (this.formKind === 'profile_query') {
          // To set the default value for search in dropdown.
          this.updateControlValue('SEARCH_IN', '2', true);
        }
        if (this.formKind==='profile_copy' || (this.ui>=2 && this.readOnly && this.formKind.startsWith('profile'))) {
          this.setFieldVisibility('DOCNAME', false);
        }
      }
      if (this.formKind === '__local_editsecurity') {
        // Marka a hidden security field as dirty so that we can enable the OK button
        const dummyField_control: FormControl = this.form.controls['SECURITY'] as FormControl;
        if (dummyField_control) {
          dummyField_control.markAsDirty();
        }
      }
      setTimeout(() => {
        --this.blockValidation;
        if (this.controller) {
          if (this.controller.enableOK) {
            setTimeout(() => {
              this.controller.enableOK(this.form.valid, this.form.dirty);
            }, 1);
          }
          if (this.controller.allowFirstFocus && this.controller.allowFirstFocus()) {
            setTimeout(() => {
              this.focusOnFirstEmptyEditable();
            }, 500);
          }
        }
      }, 1);
    }
    this.setLayoutMode();
    if (this.desc) {
      this.lookupDesc.lib = this.desc.lib;
    }
    this.cdr.markForCheck();
  }

  ngAfterViewChecked(): void {
    if (!this.layoutComplete) {
      setTimeout(() => {
        this.setLayoutMode();
      }, 1);
    }
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.layoutComplete = false;
    this.setLayoutMode();
  }

  public deviceDidRotate(isPortrait: boolean): void {  // work around iOS/Android cordova crap
    this.cdr.markForCheck();
  }

  private prepareDialogOnDialog(): void {
    if (Util.Device.bIsIOSDevice && !Util.Device.isPhoneLook() && this.formEl && this.formEl.nativeElement) {
      const ele = this.formEl.nativeElement;
      let parentHeight = 0;
      let parent: any = ele.parentElement;
      while (parent) {
        const classList = parent.classList;
        if (classList && classList.contains('body')) {
          parentHeight = parent.clientHeight;
          break;
        }
        parent = parent.offsetParent ? parent.offsetParent : parent.parentElement;
      }
      this.standInBodyHeight = parentHeight;
    }
  }

  private pickerOKDisabled(): boolean {
    return (this.fileplans) ? this.fileplans.okDisabled : false;
  }

  public placeholders(readonly: boolean, extension?: boolean): any {
    if (extension) {
      return '';
    }
    if (readonly) {
      return this._placeholders.readonly;
    } else {
      return this._placeholders.readwrite;
    }
  }

  public setEditable(editable: boolean): void {
    if (this.readOnly===editable) {
      this.readOnly = !editable;
      // loop through all fields and disable/enable controls
      if (!!this.fields && this.fields.length) {
        Util.FieldMappings.forEachTemplateField(this.fields, false, (field): boolean => {
          const control: AbstractControl = this.getControl(field.name);
          if (control) {
            if (this.readOnly || field.isReadonly) {
              control.disable();
            } else {
              control.enable();
            }
          }
          return true;
        });
        this.onResize();
        this.cdr.markForCheck();
      }
    }
  }

  public setFieldEditable(fieldName: string, editable: boolean): void {
    const field: FormField = this.getField(fieldName);
    if (!!field && field.isReadonly === editable) {
      field.isReadonly = !editable;
      const control: AbstractControl = this.getControl(field.name);
      if (control) {
        if (this.readOnly || field.isReadonly) {
          control.disable();
        } else {
          control.enable();
        }
      }
      this.onResize();
      this.cdr.markForCheck();
    }
  }

  public fillNameAndAppID(): void {
    const fileName: string = this.controller.fileNameForUpload ? this.controller.fileNameForUpload() : null;
    if (!(!!this.savedFormData && this.savedFormData['DOCNAME']) && fileName) {
      this.updateControlValue('DOCNAME', fileName, true);
    }
    let appID: string = null;
    if (!!this.data['STORAGE'] && this.data['STORAGE']==='P') {
      appID = 'Paper';
    } else {
      appID = this.controller.appIDForUpload ? this.controller.appIDForUpload() : 'DEFAULT';
    }
    if (appID && appID.length) {
      const appIDs: string[] = appID.split(Util.RestAPI.kMultiFileSeparator);
      const nAppIDs: number = appIDs.length;
      let knownAppID: string = null;
      for (let i=0; i<nAppIDs; i++) {
        if (appIDs[i] !== 'DEFAULT') {
          knownAppID = appIDs[i];
          break;
        }
      }
      if (knownAppID) {
        for (let i=0; i<nAppIDs; i++) {
          if (appIDs[i] === 'DEFAULT') {
            appIDs[i] = knownAppID;
          }
        }
        appID = appIDs.join(Util.RestAPI.kMultiFileSeparator);
      }
    }
    if (appID.indexOf('DEFAULT') === -1 && appID.indexOf(Util.RestAPI.kMultiAppIdSeparator) === -1) {
      this.updateControlValue('APP_ID', appID, true);
    } else {
      // force the control to be required
      const appIDField: FormField = this.getField('APP_ID');
      if (appIDField) {
        if (!appIDField.isRequired) {
          appIDField.isRequired = true;
        }
        if (appIDField.isReadonly) {
          appIDField.isReadonly = false;
        }
      }
      const control: FormControl = this.form.controls['APP_ID'] as FormControl;
      if (control) {
        control.enable();
      }
      this.updateControlValue('APP_ID', '', true);
    }
    if (this.data) {
      this.data['APP_ID'] = appID;
    }
  }

  protected formatDate(dateStr: string, dateOnly?: boolean): string {
    return Util.Transforms.formatDate(dateStr,dateOnly);
  }

  private formIsVisible(): boolean {
    return this.form && (!this.lookupShown || !Util.Device.isPhoneLook()) && (!this.fileplansShown || !(Util.Device.bIsIOSDevice && !Util.Device.isPhoneLook()));
  }

  private isSingleField(field: FormField): boolean {
    const extFieldType = !!field.fields && field.fields.length===1 ? field.fields[0].controltype : null;
    return this.isPersmisionsSelector || (!!field.name && field.name.startsWith('$edx_email')) || (this.fields.length===1 && (!field.hasExtension || (extFieldType === 'push' && field.controltype === 'push')));
  }

  public canShowField(field: FormField): boolean {
    if (this.formKind.startsWith('profile_query')) {
      return field.isVisible;
    } else if (this.formKind.startsWith('profile')) {
      const lib = this.desc ? this.desc.lib : Util.RestAPI.getPrimaryLibrary();
      let bCanShow: boolean = field.isVisible && (field.isRequired || field.isReadonly || field.isEnabled) && (!field.isLocked || field.isCombo || field.isEnabled);
      if (bCanShow && field.controltype==='box' && field.fields) {
        bCanShow = false;
        for (const subfield of field.fields) {
          if (subfield.isVisible && (subfield.isRequired || subfield.isReadonly || subfield.isEnabled)) {
            bCanShow = true;  // at lease one renderable sub field
            break;
          }
        }
      }
      return bCanShow;
    }
    return field.isVisible;
  }

  public calcBorders(field: FormField): number {
    let borders = 0;
    // set boxBorders to 1 for top and 2 for bottom if last previous visible field was a box or no previous field then no top else if is last field then no bottom
    if (field.controltype === 'box' && field.isVisible && field.fields && field.fields.length) {
      const findIt = (fields: FormField[]) => {
        let previousVisField: FormField = null;
        const nFields: number = fields.length;
        // find out if this is the last shown field
        for (let i=0; i<nFields; i++) {
          const curField = fields[i];
          if (field === curField) {
            let nextVisSibling: FormField;
            for (let j=i+1; j<nFields; j++) {
              if (this.canShowField(fields[j])) {
                nextVisSibling = fields[j];
                break;
              }
            }
            if (!!previousVisField) {
              borders = previousVisField.controltype === 'box' ? (!nextVisSibling ? 0 : 2) : (!nextVisSibling ? 1 : 3);
            } else {
              borders = !nextVisSibling ? 0 : 2;
            }
            break;
          } else if (this.canShowField(curField)) {
            previousVisField = curField;
            if (curField.fields && curField.fields.indexOf(field) !== -1) {
              findIt(curField.fields);
            }
          }
        }
      };
      findIt(this.fields);
    }
    return borders;
  }

  public isGroupInGroup(field: FormField): boolean {
    let parentGroup: FormField = null;
    const findIt = (fields: FormField[]) => {
      const nFields: number = fields.length;
      // find out if this is the last shown field
      for (let i=0; i<nFields; i++) {
        const curField = fields[i];
        if (field === curField) {
          break;
        } else if (curField.fields) {
          if (curField.fields.indexOf(field) !== -1) {
            parentGroup = curField;
            break;
          } else {
            findIt(curField.fields);
          }
        }
        if (!!parentGroup) {
          break;
        }
      }
    };
    findIt(this.fields);
    return !!parentGroup;
  }

  public isInitiallyOpen(field: FormField): boolean {
    return this.getSetGroupBoxOpen(false, field);
  }

  public groupBoxOpenToggled(field: FormField, open: boolean): void {
    this.getSetGroupBoxOpen(true, field, open);
  }

  private getSetGroupBoxOpen(set: boolean, field: FormField, open?: boolean): boolean {
    const key: string = 'edx_fos_'+this.formKind+'_'+this.getFormName()+'_'+this.fields.indexOf(field);
    let isOpen: boolean;
    if (set) {
      localStorage.setItem(key, open?'true':'false');
      isOpen = open;
    } else {
      const value: string = localStorage.getItem(key);
      isOpen = value !== 'false';
    }
    return isOpen;
  }

  public focusOnFirstEmptyEditable(): void {
    const textareas = this.formEl && this.formEl.nativeElement ? this.formEl.nativeElement.getElementsByTagName('textarea') : null;
    const textarea: HTMLInputElement = this.findFirstFocusableInputInForm(textareas);
    const inputs = this.formEl && this.formEl.nativeElement ? this.formEl.nativeElement.getElementsByClassName('edx_editable_input') : null;
    const input: HTMLInputElement = this.findFirstFocusableInputInForm(inputs);
    if (input && !textarea) {
      input.focus();
    } else if (!input && textarea) {
      textarea.focus();
    } else if (input && textarea) {
      const textTop = this.getInputTopOffsetInForm(textarea);
      const inputTop = this.getInputTopOffsetInForm(input);
      if (textTop > inputTop) {
        input.focus();
      } else {
        textarea.focus();
      }
    }
  }

  private findFirstFocusableInputInForm(inputs: any): HTMLInputElement {
    let input: HTMLInputElement = null;
    const nInputs: number = inputs ? inputs.length : 0;
    for (let i=0; i<nInputs; i++) {
      input = inputs[i] as HTMLInputElement;
      if (!input.disabled && !input.value) {
        if (Util.Device.isMobile() && !Util.Device.bIsOfficeAddin) {
          const field = this.getField(input.name);
          if (!!field && field.isRequired) {
            break;
          }
        } else {
          break;
        }
      }
      input = null;
    }
    return input;
  }

  private getInputTopOffsetInForm(input: any): number {
    let top = 0;
    let element = input;
    while (element) {
        top += element.offsetTop  || 0;
        const classList = element.classList;
        if (classList && (classList.contains('popup'))) {
          break;
        }
        element = element.offsetParent ? element.offsetParent : element.parentElement;
    }
    return top;
  }

  private setLayoutMode(): void {
    if (this.layout) {
      this.formLayout = this.layout;  // programmer override
      if (this.controller && this.controller.layoutChanged) {
        this.controller.layoutChanged(this.formLayout);
      }
      this.layoutComplete = true;
    } else if (!this.layoutComplete) {
      if (this.formEl && this.formEl.nativeElement && this.formEl.nativeElement.offsetWidth) {
        const width: number = this.formEl.nativeElement.offsetWidth;
        const minWidth = 640;
        let layout: string = width > minWidth ? 'page' : 'column';
        if (this.inlineParent && this.inlineParent.formLayout) {
          layout = this.inlineParent.formLayout;  // use parent
        }
        if (layout !== this.formLayout) {
          this.formLayout = layout;
          if (this.controller && this.controller.layoutChanged) {
            this.controller.layoutChanged(this.formLayout);
          }
          this.cdr.markForCheck();
        }
        this.layoutComplete = true;
      }
    }
  }

  private isProfileForm(): boolean {
    return FormUtils.isProfileForm(this.formKind);
  }

  private isSearchForm(): boolean {
    return FormUtils.isSearchForm(this.formKind);
  }

  private updateFormData(data: any, blockValidation?: boolean): void {
    if (blockValidation) {
      ++this.blockValidation;
    }
    this.form.patchValue(data);
    if (blockValidation) {
      --this.blockValidation;
    }
  }

  private createFieldHook(field: FormField): void {
    const appID = !!this.data ? this.data['APP_ID'] : null;
    const hiddenTypes = ['%FORM_PAPER_APPLICATION','%PRIMARY_FORM','PAPER','Paper','DEFAULT'];
    if (this.formKind.startsWith('profile_editdefs_') && !!appID && field.name==='APP_ID' && hiddenTypes.indexOf(appID)>=0) {
      field.isVisible = false;
    }
  }

  public createFieldsFromTemplate(fields: FormFieldDesc[]): void {
    const isProfileForm: boolean = this.isProfileForm();
    const isSearchForm: boolean = this.isSearchForm();
    const isExtForm: boolean = this.formKind.startsWith(Util.kCustomFormPrefix);
    const group: any = {};
    const extensionFields: FormField[] = [];
    this.fields = [];
    this.form = null;
    if (fields && fields.length) {
      fields.forEach(field => {
        // create single FormField and add it to the fields array
        let bAddField = false;
        const newField: FormField = new FormField(field, this.formKind);
        this.createFieldHook(newField);
        if ((!isSearchForm && !isExtForm && !newField.lookup && !!Util.FieldMappings.fieldForDescription(newField)) || newField.extensionParent) {
          extensionFields.push(newField);
        } else if ((!(field.flags & 0x00400000) || !!field.mvinfo || field.name === '%KEYWORD') && field.fldtype !== 'buffer' && field.fldtype !== 'line' && !(isProfileForm && ((field.name === 'TRUSTEES' && field.fldtype ==='push') || (!isSearchForm && field.name === 'SECURITY' && field.fldtype === 'checkbox'))) && !(field.fldtype ==='push' && this.readOnly) && field.name!=='EMAIL_BOX') {
          bAddField = true;
        }
        if ((field.name === 'DOCNAME' && this.formKind !== '__local_copy' && this.desc && this.desc['STATUS'] === '3') || (!isSearchForm && field.name === 'APP_ID')) {
          newField.isReadonly = true;
        }
        // create single FormControl and add to group
        if (!field.fields || field.fields.length === 0) {
          if (newField.controltype !== 'push') {
            const control: FormControl = new FormControl(field.name, this.getValidator(newField), this.getAsyncValidator(newField));
            if (this.readOnly || newField.isReadonly) {
              control.disable();
            }
            this.initialValue[field.name] = (!isSearchForm) && field.value ? field.value : '';
            group[field.name] = control;
            //Display the document number field in the default fields list for search forms
            if (newField.isRequired || (isSearchForm && field.name === 'DOCNUM')) {
              newField.isRequired = !isSearchForm && !this.bMassProfileUpdate;
            } else if (isProfileForm && (this.ui<2 || field.name !== 'DOCNUM' || !this.readOnly)) {
              newField.isOption = true;
            }
          } else if (isProfileForm) {
            newField.isOption = true;
          }
          if (newField.lookup && this.data && this.data[newField.name]) {
            newField.defaultValue = this.data[newField.name];
            if (newField.controltype === 'editdate') {
              if (newField.defaultValue.substring(0,4) === '1753') {
                newField.defaultValue = '';
              }
            }
          }
        } else {
          const createSubs = (aNewField: FormField, aField: FormFieldDesc): boolean => {
            // loop through subfields and similarly create fields and controls
            const subFields: FormField[] = [];
            let nRequiredSubFields = 0;
            let rc = true;
            aField.fields.forEach(subfield => {
              // create FormField for each item
              if ((field.flags & 0x00400000) || (isProfileForm && ((subfield.name === 'TRUSTEES' && subfield.fldtype ==='push') || (subfield.name === 'SECURITY' && subfield.fldtype === 'checkbox')))) {
                return false;
              }
              const newSubField: FormField = new FormField(subfield, this.formKind);
              this.createFieldHook(newSubField);
              if ((!isSearchForm && !!Util.FieldMappings.fieldForDescription(newSubField)) || newSubField.extensionParent) {
                extensionFields.push(newSubField);
              } else {
                subFields.push(newSubField);
              }
              if ((subfield.name === 'DOCNAME' && this.desc && this.desc['STATUS'] === '3') || (!isSearchForm && subfield.name === 'APP_ID')) {
                newSubField.isReadonly = true;
              } else if (subfield.name === 'PD_VREVIEW_DATE') {
                newSubField.isReadonly = false;
              }
              if (!subfield.fields || subfield.fields.length === 0) {
                // create FormControl and add to outer group
                if (newSubField.controltype !== 'push') {
                  const control: FormControl = new FormControl(subfield.name, this.getValidator(newSubField), this.getAsyncValidator(newSubField));
                  if (this.readOnly || newSubField.isReadonly) {
                    control.disable();
                  }
                  this.initialValue[subfield.name] = !isSearchForm && subfield.value ? subfield.value : '';
                  group[subfield.name] = control;
                  if (newSubField.isRequired || (isSearchForm && newSubField.name === 'DOCNUM')) {
                    newSubField.isRequired = !isSearchForm && !this.bMassProfileUpdate;
                    ++nRequiredSubFields;
                  } else if (isProfileForm) {
                    newSubField.isOption = true;
                  }
                } else if (isProfileForm) {
                  newSubField.isOption = true;
                }
                if (newSubField.lookup && this.data && this.data[newSubField.name]) {
                  newSubField.defaultValue = this.data[newSubField.name];
                  if (newSubField.controltype === 'editdate') {
                    if (newSubField.defaultValue.substring(0,4) === '1753') {
                      newSubField.defaultValue = '';
                    }
                  }
                }
              } else {
                rc = createSubs(newSubField, subfield);
              }
            });
            if (nRequiredSubFields===0 && isProfileForm) {
              aNewField.isOption = true;
            }
            aNewField.fields = subFields;
            if (subFields.length===0) {
              rc = false;
            }
            return rc;
          };
          bAddField = createSubs(newField, field);
        }
        if (bAddField) {
          this.fields.push(newField);
        }
      });
    }
    if (extensionFields.length>0) {
      this.fullWidth = false;
      const descriptionForFieldList = extensionFields.filter(f => !!f.descriptionForField);
      const extParentFieldList = extensionFields.filter(f => !!f.extensionParent);
      const mappedFieldList = extensionFields.filter(f => !f.descriptionForField && !f.extensionParent);
      let extField: FormField;
      let parentField: FormField;
      for (extField of descriptionForFieldList) {
        parentField = this.getField(Util.FieldMappings.fieldForDescription(extField));
        if (parentField) {
          parentField.addExtensionField(extField, true);
        }
      }
      for (extField of extParentFieldList) {
        parentField = this.getField(extField.extensionParent);
        if (parentField) {
          parentField.addExtensionField(extField, extField.isReadonly);
        }
      }
      for (extField of mappedFieldList) {
        parentField = this.getField(Util.FieldMappings.fieldForDescription(extField));
        if (parentField && (!parentField.fields || !parentField.fields.length)) {
          parentField.addExtensionField(extField, true);
        }
      }
    }
    if (isProfileForm) {
      let control: FormControl;
      if (!isSearchForm) {
        // add in an invisible security field (for profile forms only) and form_name field for both profile and search forms.
        control = new FormControl('SECURITY');
        // security value should be null as the security field is a tristate checkbox in search forms and not an invisible field.
        this.initialValue['SECURITY'] = null;
        group['SECURITY'] = control;
      }
      control = new FormControl('form_name');
      this.initialValue['form_name'] = this.getFormName();
      group['form_name'] = control;
    }
    this.form = new FormGroup(group);
  }

  protected getFormName(): string {
    let formName: string =  this.controller && this.controller.getFormName  ? this.controller.getFormName() : this.desc['FORM_NAME'];

    if (!formName) {
      formName = 'DEFAULT_PROFILE';
      const defProfFrom: any = this.formKind.startsWith('profile_query') ? Util.RestAPI.getDefaultSearchForm() : Util.RestAPI.getDefaultProfileForm();
      if (defProfFrom && defProfFrom.id) {
        formName = defProfFrom.id;
      }
    }
    return formName;
  }

  public useForceRender(field: FormField): number {
    if (field.lookupData !== null || field.rerender !== field.lastRerenderIndex) {
      if (field.rerender === field.lastRerenderIndex) {
        ++field.rerender;
      }
      return field.rerender;
    }
    return 0;
  }

  public needsAsyncValidator(field: FormField): boolean {
    const isSearchForm: boolean = this.isSearchForm();
    // To validate CHECKIN_LOCATION
    if (!isSearchForm && field.lookup && !field.mvinfo && (!field.lookup.startsWith('$edx_') || field.name === '%CHECKIN_LOCATION')) {
      return true;
    } else {
      return false;
    }
  }

  protected getValidator(field: FormField): ValidatorFn {
    if (!this.needsAsyncValidator(field)) {
      const that: DynamicFormComponent = this;
      return (c: FormControl): ValidationErrors => {
        if (this.blockValidation===0) {
          return that.validateField(c, field);
        }
      };
    }
    return null;
  }

  protected getAsyncValidator(field: FormField): AsyncValidatorFn {
    if (this.needsAsyncValidator(field)) {
      return (control: FormControl): Promise<ValidationErrors> => {
        if (this.blockValidation===0 && !control.pristine && control.dirty) {
          const value: any = control.value;
          if (value !== null && value !== undefined && field.defaultValue!==value) {
            if (field.name === '%CHECKIN_LOCATION') {
              return this.validatePath(control, field, value);
            } else {
              return this.validateLookup(control, field, value);
            }
          }
        }
        return new Promise(resolve => {
          resolve(this.finishValidateField(field, null, null));
        });
      };
    }
    return null;
  }

  protected validatePath(control: FormControl, field: FormField, value: any): Promise<ValidationErrors> {
    // To validate if valid location is provided.
    // first check sync if we have any value
    const error: ValidationErrors = this.validateField(control, field);
    const isDir: boolean = field.lookup ===  '$edx_folder_picker' ? true:false;
    if (error) {
      return new Promise(resolve => {
        resolve(error);
      });
    }
    // now ask RestAPI if the value is a good path
    return Util.RestAPI.isValidPath(value,isDir).then(valid => {
      let message: string = null;
      let result: any = null;
      if (valid && !isDir) {
        if (!(this.controller && this.controller.hasUploadFiles && this.controller.hasUploadFiles())) {
          valid = false;
        }
      }
      if (!valid) {
        if (field.lookup === '$edx_file_picker') {
          message = this._errors.FILE_PICKER;
        } else if (field.lookup === '$edx_folder_picker') {
          message = this._errors.FOLDER_PICKER;
        }
        result = { INVALID: message };
        setTimeout(() => {
          this.cdr.markForCheck();
        }, 1);
      }
      return this.finishValidateField(field, message, result);
    });
  }

  protected setParentFilter(field: FormField): string {
    let filter = '';
    let and = '';
    const lookupFormName: string = this.getFormName();
    while (field) {
        const parentField: FormField = this.getField(field.parentField);
        if (parentField) {
          let parent_filter = null;
          const control: FormControl = this.form.controls[parentField.name] as FormControl;
          if (control && control.value) {
            // Must url encode % sign because if the form starts with valid hex values url encoder will
            // ignore the % sign and assume that is already encoded !!!
            // Encode all string values to be able search successfully,when we have special characters in string values.
            parent_filter = '%25' + Util.Transforms.validateQueryValue(lookupFormName) + '.' + Util.Transforms.validateQueryValue(parentField.name) + '=' +  Util.Transforms.validateQueryValue(control.value);
            }
          if (parent_filter) {
            filter = filter + and + parent_filter;
            and = '%20and%20';
          }
        }
        field = parentField;
    }
    return filter;
  }

  protected validateLookup(control: FormControl, field: FormField, value: any): Promise<ValidationErrors> {
    const lookupFormName: string = this.getFormName();
    const fieldPrimaryKey: string = this.getKey(field);
    const fieldSecondaryKey: string = field.descriptionField;
    const lib: string = this.desc && this.desc.lib ? this.desc.lib : Util.RestAPI.getPrimaryLibrary();
    let query = '';
    let queryValue = '';
    let message: string = this._errors.LOOKUP;
    const result: any = { LOOKUP: message };
    const valueIsNumeric: boolean = Number.isInteger(Math.floor(value));
    if (valueIsNumeric) {
      value = value.toString();
    }
    if (!control.value || control.value === field.lastLookupValue) {
      return new Promise(resolve => {
       resolve(this.finishValidateField(field, null, null));
      });
    }
    if (valueIsNumeric) {
      queryValue = Util.Transforms.validateQueryValue(value);
    } else {
      queryValue = Util.Transforms.validateQueryValue(value,'*');
    }
    query = 'max=25&ascending=' + fieldPrimaryKey;
    const otherfilter = this.setParentFilter(field);
    ++this.blockValidation;
    this.setLoading(true);
    return this.lookupService.validate(lookupFormName, lib, field.lookup, fieldPrimaryKey, queryValue, this.formKind, otherfilter, query).then(data => {
      this.setLoading(false);
      // included data.set.total instead of nValues in the condition to check if atlease one value is returned from server.
      if (data && data.set && data.set.total > 0) {
        field.defaultValue = null;
        field.lookupData = [];
        for (const item of data.list) {
          if (item['DISABLED'] === '1' || item['DISABLED'] === 'Y') {
            continue;
          }
          let description: string = item[fieldSecondaryKey];
          if (description === null) {
            description =  Util.FieldMappings.descriptionValueForField(item, field);
          }
          const lookupItem = new CacheItem(item[fieldPrimaryKey], description, item);
          field.lookupData.push(lookupItem);
          if (data.list.length === 1) {
            this.scanFieldsForRevalidatonOrEvaluation(item,field.name);
          }
        }
        if (!field.scheduledForRevalidation) {
          this.clearFieldValueAndDescOfChildren(field);
        }
        setTimeout(() => {
          --this.blockValidation;
          this.cdr.markForCheck();
        }, 1);
        if (data.set.total === 0) {
          return this.finishValidateField(field, message, result);
        } else {/* TODO: Ron fix or remove in 16.6
          setTimeout(() => {
            this.focusOnFirstEmptyEditable();
          }, 1);*/
          field.lastLookupValue = control.value;
          return this.finishValidateField(field, null, null);
        }
      } else {
        field.defaultValue = null;
        field.lookupData = [];
        field.lastLookupValue = null;
        setTimeout(() => {
          --this.blockValidation;
          this.cdr.markForCheck();
        }, 1);
        message = this._errors.LOOKUP;
        return this.finishValidateField(field, message, result);
      }
    });
  }

  // the universal validator function
  protected validateField(control: FormControl, field: FormField): ValidationErrors {
    let value: any = control.value;
    let result: any = null;
    let message: string = null;
    const isSearchForm: boolean = this.isSearchForm();
    if (field.isRequired) {
      if (!value) {
        message = this._errors.MISSING;
        result = { MISSING: message };
        field.autoValidated = false;
        this.clearFieldDesc(field);
        // Clear any dependents of this master parent
        this.clearFieldValueAndDescOfDependents(field);
        this.clearFieldValueAndDescOfChildren(field);
      } else if (field.name==='DOCNAME') {
        // check for multiple files being uploaded. the field max is then the individual file name lenghts
        const names: string[] = value.split(Util.RestAPI.kMultiFileSeparator);
        for (const name of names) {
          if (!Util.Transforms.isValidDocName(name)) {
            message = this._errors.INVALID_NAME;
            result = { INVALID: message };
          }
        }
      }
    }
    if (field.controltype === 'editdate') {
      if (field.isRequired && !value) {
        message = this._errors.MISSING;
        result = { MISSING: message };
      } else if (value) {
        value = value.toUpperCase();
        if (!Util.Transforms.isSpecialDateFormat(value)) {
          let dmFormatedDate = false;
          const splitStr: string = Util.Transforms.splitableDateFormat(value);
          const secondIsNumeric: boolean = Util.Transforms.splitableDateFormatSecondValueIsNumeric(value);
          const parts: string[] = splitStr ? value.split(splitStr) : [value];
          const nParts: number = parts.length;
          let startDate = '';
          if ((isSearchForm && nParts>0 && nParts<3) || nParts===1) {
            for (let i=0; i<nParts; i++) {
              const part: string = parts[i];
              const partUppper = part.toUpperCase();
              let partDate = '';
              if ((i===0 && partUppper==='%TODAY' && splitStr.toUpperCase()===' MINUS ') || (i>0 && partUppper==='%TODAY' && splitStr.toUpperCase()===' TO ')) {
                dmFormatedDate = true;
              } else {
                dmFormatedDate = false;
                if (i===0 && partUppper.startsWith('%TODAY MINUS ')) {
                  try {
                    dmFormatedDate = Util.isInteger(partUppper.split('%TODAY MINUS ')[1]);
                  } catch (e) { }
                } else if (i===0 || !secondIsNumeric) {
                  const appleComptibleDateStr: string = part.replace(/[-]/g, '/');
                  const newParts: string[] = appleComptibleDateStr.split('/');
                  if (newParts.length===3 && newParts[0].length===4 && newParts[1].length>=1 && newParts[1].length<3 && newParts[2].length>=1 && newParts[2].length<3) {
                    try {
                      const day: number = parseInt(newParts[2]);
                      const nDaysInMonth: number = new Date(parseInt(newParts[0]), parseInt(newParts[1]), 0).getDate();
                      dmFormatedDate = !isNaN(Date.parse(appleComptibleDateStr)) && day>0 && day<=nDaysInMonth;
                    } catch (e) { }
                  }
                  if (dmFormatedDate) {
                    // Get the proper date value and validate against min and max dates
                    partDate = newParts[0] + '-' + ('0' + newParts[1]).slice(-2) + '-' + ('0' + newParts[2]).slice(-2);
                    if (partDate < field.minDate || partDate > field.maxDate) {
                      dmFormatedDate = false;
                    }
                  }
                } else {
                  try {
                    dmFormatedDate = Util.isInteger(part);
                  } catch (e) { }
                }
              }
              // For the date range, end date should be greater than start date
              if (nParts > 1 && splitStr.toUpperCase() === ' TO ' && partUppper.indexOf('%TODAY') === -1 && dmFormatedDate) {
                if (i === 0) {
                  startDate = partDate || part;
                } else if (i === 1 && ((partDate || part) < startDate)) {
                  dmFormatedDate = false;
                }
              }
              if (!dmFormatedDate) {
                break;
              }
            }
          }
          if (!dmFormatedDate) {
            message = this._errors.INVALID_DATE;
            result = { INVALID: message };
          }
        }
      }
    }
    if (!isSearchForm && field.maxChars > 0 && value && value.length > field.maxChars) { // to allow multiple lookup values in profile search forms.
      if (field.name==='DOCNAME') {
        // check for multiple files being uploaded. the field max is then the individual file name lengths
        const names: string[] = value.split(Util.RestAPI.kMultiFileSeparator);
        for (const name of names) {
          if (name.length > field.maxChars) {
            message = this._errors.LONG;
            result = { LONG: message };
            break;
          }
        }
      } else {
        message = this._errors.LONG;
        result = { LONG: message };
      }
    }
    if (isSearchForm && field.name==='DOCNUM' && value.length > 0) {
      const values: string[] = value.toUpperCase().split(' TO ');
      for (const val of values) {
        const filter = /^(\d+(\,|\;|\t|\ )?)+$/;
        if (!filter.test(val)) {
          message = this._errors.INVALID_DOCNUM;
          result = { INVALID: message };
          break;
        }
      }
    }
    if (!!value && field.name.startsWith('$edx_email')) {
      const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      const EmailIds: string[] = value.replace(/\s/g, '').split(',');
      for (const email of EmailIds) {
        if (!filter.test(email)) {
          message = this._errors.EMAIL;
          result = { INVALID: message };
        } else {
          // Now check the domain white-list if applicable
          if (this.formTemplate.name === 'shareonedrive') {
            const lib: string = this.data['DOCUMENTS'][0].lib;
            const extAppInfo: any = Util.RestAPI.findExternalApp(lib);
            if (!!extAppInfo['domainswhitelist']) {
              const domain: string = email.split('@')[1];
              if (extAppInfo['domainswhitelist'].indexOf(domain) === -1) {
                message = this._errors.DOMAIN;
                result = { INVALID: message };
              }
            }
          }
        }
      }
    }
    if (field.minVal >= 0 && !isNaN(Number(value)) && Number(value) < field.minVal) {
      message = this._errors.LOW;
      result = { LOW: message };
    }
    if (field.maxVal > 0 && !isNaN(Number(value)) && Number(value) > field.maxVal) {
      message = this._errors.HIGH;
      result = { HIGH: message };
    }
    return this.finishValidateField(field, message, result);
  }

  protected finishValidateField(field: FormField, message: string, result: any): ValidationErrors {
    field.errormessage = message;
    // for the date range fields, get the parent (inlineParent) form and enable ok button
    if (field.controltype.indexOf('date') >= 0 && !!this.inlineParent) {
      if (this.inlineParent.form && this.inlineParent.controller.enableOK) {
        setTimeout(() => {
          // pass on the child form valid status
          this.inlineParent.controller.enableOK(this.form.valid, this.inlineParent.form.dirty);
        }, 1);
      }
    } else if (this.form && this.controller.enableOK) {
      setTimeout(() => {
        this.controller.enableOK(this.form.valid, this.form.dirty);
      }, 1);
    }
    return result;
  }

  public dynamicExtrasToggled(shown: boolean): void {
    this.extrasShown = shown;
    this.cdr.markForCheck();
    if (shown) {
      ++this.blockValidation;
      setTimeout(() => {
        --this.blockValidation;
      }, 5);
    }
  }

  public doEditSecurity(): void {
    if (this.data && ((this.data.type === 'documents') || (this.data.type === 'workspaces') || (this.data.type === 'workspaces') || (this.data.type === 'folders') || (this.data.type === 'flexfolders'))) {
      this.securityDesc = Util.deepCopy(this.desc);  // deep copy as we are going to set type next and it would wack the desc pointer's type
      this.securityDesc.params = 'security';
      if (this.bMassProfileUpdate) {
        this.securityDesc.id = '';
        this.trusteesDirective = 'replace';
      }
    } else {
      // to get profile default trustees, we need formname and appID.
      this.securityDesc = { id:'0', type:this.createType?this.createType:this.bMassProfileUpdate?'mass_profile':'', params:'security', lib:Util.RestAPI.getPrimaryLibrary(), isWorkspace:(this.createType === 'workspaces')?1:0, formName : this.data['FORMNAME'], appId: this.data['APP_ID'] };
      if (this.form.controls['AUTHOR_ID']) {
        this.securityDesc['AUTHOR_ID'] = this.form.controls['AUTHOR_ID'].value;
      }
    }
    this.securityKind = 'list_PRIVATE';
    this.securityTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SECURITY');
    this.securityOK = this.localizer.getTranslation('FORMS.BUTTONS.OK');
    this.securityShown = true;
    this.cdr.markForCheck();
  }

  public setLookupFilter(field: FormField): void {
    if (field.parentField) {
      const parentControl: FormControl = this.form.controls[field.parentField] as FormControl;
      if (parentControl) {
        if (!parentControl.value) {
          if (this.formKind === 'profile') {
            this.isLookupParentEmpty = true;
          }
        }
      }
      const filter = this.setParentFilter(field);
      if (filter) {
        const index = filter.lastIndexOf('=');
        this.lookupPrimaryKey   = filter.substring(0,index);
        this.lookupPrimaryValue = filter.substring(index+1);
      }
    }
  }

  public doLookup(field: FormField,isLookupBtnClicked?: boolean): void {
    if (Util.Device.isPhoneLook()) {
      const formBody: HTMLElement = document.getElementById('edx_form_body');
      this.formScrollTop = !!formBody ? formBody.scrollTop : 0;
    }
    if (field.name === 'PD_FILEPT_NO') {
      this.fileplansTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SELECT_FILEPART');
      this.fileplansOK = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
      let library = '';
      if (!!this.lookupDesc && !!this.lookupDesc.lib) {
        library = this.lookupDesc.lib;
      }
      this.fileplansDesc = { id:'', type:'fileparts', lib:library, DOCNAME:this.localizer.getTranslation('FOLDER_ACTIONS.CONTAINERS') };
      this.fileplansShown = true;
      this.lookupField = field;
      this.prepareDialogOnDialog();
    } else {
      this.isLookupParentEmpty = false;
      this.lookupIsNumeric = false;
      this.lookupPrimaryValue = null;
      this.lookupNoWildcard = false;
      this.lookupInitalSearch = '';
      this.lookupField = field;
      this.lookupInitalKey = this.getKey(field);
      this.lookupOKDisabled = true;
      const isSearchForm: boolean = this.formKind.startsWith('profile_query');
      if (field.lookup==='$edx_email_picker') {
        this.chooseEmail(field);
      } else if (field.lookup==='$edx_file_picker') {
        this.chooseFile(field);
      } else if (field.lookup==='$edx_folder_picker') {
        this.chooseDir(field);
      } else if (field.lookup==='$edx_date_range') {
        this.chooseDateRange(field);
      } else {
        const lookupFormName: string = this.getFormName();
        const control: AbstractControl = this.form.controls[field.name];
        if (field.controltype === 'editnumber') {
          this.lookupIsNumeric = true;
          if (control && control.valid && control.value) {
            this.lookupInitalSearch = control.value.toString();
          } else {
            this.lookupNoWildcard = true;
          }
        } else {
          // If lookup button is clicked,instead of tabing,show all the Matters.
          if (control && control.value && control.value !== 'DEFAULT') {
            // Request for fetching lookups for multi valued lookup fields.
            // Replace separator ; with |
            if (control.value.indexOf(';') > 0) {
              // Multivalue lookup field re-selection can be done only for elemental fiels
              // As the parentFilter is encoded value, '%7C' is used for '|'
              const parentFilter: string = this.setParentFilter(field);
              if (!parentFilter || (!!parentFilter && parentFilter.indexOf('%7C') === -1)) {
                this.lookupInitalSearch = control.value.replace(/[;]/g,'|');
              }
            } else {
              // Validated fields should return all values of the lookup and select the single value
              this.lookupInitalSearch = control.value;
            }
          }
        }
        this.lookupInitalSearch = !!this.lookupInitalSearch ? this.lookupInitalSearch.toUpperCase() : '';
        this.setLookupFilter(field);
        this.lookupDesc.id = field.lookup;
        this.lookupForm = lookupFormName;
        this.fieldPrimaryKey = this.getKey(this.lookupField);
        this.lookupTitle = this.localizer.getTranslation('FORMS.LOOKUPS.TITLE', [field.label]);
        this.lookupLeadingColums = (this.formKind.startsWith('profile_query') || field.mvinfo || this.lookupField.name === '%KEYWORD') ? [ColFormat.SELECTOR] : null;
        if (this.createType === 'documents' && this.formKind === 'profile' && field.name === 'APP_ID' && field.defaultValue.indexOf('|') !== -1) {
          this.lookupPrimaryKey = 'APP_ID';
          this.lookupPrimaryValue = field.defaultValue;
        }
        this.lookupShown = true;
        ++this.blockValidation;
      }
    }
  }

  public hasLookupTable(lookup: string): boolean {
    let lib: string = !!this.desc ? this.desc.lib : null;
    if (!lib && this.data && this.data['DOCUMENTS'] && this.data['DOCUMENTS'].length) {
      lib = this.data['DOCUMENTS'].lib;
    }
    if (!lib) {
      lib = Util.RestAPI.getPrimaryLibrary();
    }
    if (lib) {
      lib = lib.toUpperCase();
    }
    return !!Util.RestAPI.getLookupTable(lookup, lib, this.getFormName());
  }

  private getFileExtensions(forElectron: boolean): any {
    let rc: any = null;
    if (this.data && this.data.FILE_EXTENSION) {
      const altExt: string = Util.Transforms.alternateExtension(this.data.FILE_EXTENSION);
      if (forElectron) {
        const exts: string[] = [this.data.FILE_EXTENSION];
        if (altExt) {
          exts.push(altExt);
        }
        rc = exts;
      } else {
        let exts: string = '.' + this.data.FILE_EXTENSION;
        if (altExt) {
          exts += ', .' + altExt;
        }
        rc = exts;
      }
    }
    return rc;
  }

  private gotFiles(files: File[]): void {
    const count = files ? files.length : 0;
    if (files && count) {
      if (this.lookupField) {
        const fieldSJON: any = {};
        let fileName: string;
        for (let i=0; i<count; i++) {
          const file: File = files[i];
          if (i===0) {
            fileName = file.name;
          } else {
            fileName += Util.RestAPI.kMultiFileSeparator + file.name;
          }
        }
        fieldSJON[this.lookupField.name] = fileName;
        this.updateFormData(fieldSJON, true);
        setTimeout(() => {
          this.controller.enableOK(this.form.valid, this.form.dirty);
        }, 1);
      }
      if (this.controller && this.controller.uploadFiles) {
        this.controller.uploadFiles(files);
      }
    }
  }

  private chooseDirOrFile(dir: boolean, field: FormField): void {
    const options: any = {};
    const fs: AWFileSystem = Util.Device.bIsElectron ? new AWFileSystem() : null;
    const control: AbstractControl = this.form.controls[field.name];
    if (control && control.value && control.value!=='DEFAULT') {
      options.defaultPath = control.value;
    }
    this.electronDialogShown = true;
    Util.RestAPI.setAppModal(true);
    const success = (result) => {
      this.electronDialogShown = false;
      Util.RestAPI.setAppModal(false);
      if (result && result.length) {
        this.updateControlValue(field.name, result[0], true);
        if (this.controller && this.controller.uploadFiles) {
          this.controller.uploadFiles(null, result);
        }
      }
    };
    const errorFunc = (err) => {
      this.electronDialogShown = false;
      Util.RestAPI.setAppModal(false);
    };
    if (dir) {
      if (Util.Device.bIsElectron) {
        fs.showDirSelector(options, success, errorFunc);
      } else {
        Util.RestAPI.showDirSelectorWithPFTA(options).then(success);
      }
    } else {
      options.multiSelections = true;
      const exts: string[] = this.getFileExtensions(true) as string[];
      if (exts) {
        options.filters = [{name:this.localizer.getTranslation('TOOLTIP.FILTER'), extensions:exts}];
      }
      fs.showFileSelector(options, success, errorFunc);
    }
  }

  private chooseFile(field: FormField): void {
    if (Util.Device.bIsElectron && !this.electronDialogShown) {
      this.chooseDirOrFile(false, field);
    } else if (Util.Device.bIsCordova) {
      const gotPaths = (filePaths: string[]) => {
        if (filePaths.length) {
          this.updateControlValue(field.name, filePaths[0], true);
          if (this.controller && this.controller.uploadFiles) {
            this.controller.uploadFiles(null, filePaths);
          }
          if (this.lookupField) {
            setTimeout(() => {
              this.controller.enableOK(this.form.valid, this.form.dirty);
            }, 1);
          }
        }
      };
      Util.RestAPI.pickFromDownloads((list: any, success: boolean) => {
        if (success && list && list.length) {
          const filePaths: string[] = [];
          for (const item of list) {
            const fileName: string = Util.Transforms.lastPathComponent(item.fullPath);
            if (fileName) {
              filePaths.push(fileName);
            }
          }
          gotPaths(filePaths);
        }
      }, (files: File[], paths: string[], success) => {
        if (success) {
          if (files) {
            this.gotFiles(files);
          } else if (paths) {
            gotPaths(paths);
          }
        }
      });
    } else {
      const exts: string = this.getFileExtensions(false) as string;
      Util.RestAPI.pickFiles(exts, (files: File[], paths: string[], success: boolean) => {
        if (success && !!files) {
          this.gotFiles(files);
        }
      });
    }
  }

  private chooseDir(field: FormField): void {
    if ((Util.Device.bIsElectron || Util.RestAPI.pftaVersion() >= 0x00160600) && !this.electronDialogShown) {
      this.chooseDirOrFile(true, field);
    }
  }

  private chooseEmail(field: FormField): void {
    if (Util.Device.bIsCordova && !this.electronDialogShown) {
      const contacts: AWContacts = new AWContacts(contact => {
        let prefEmail: string = null;
        const emails: any[] = contact && contact.emails ? contact.emails : null;
        if (emails && emails.length) {
          for (const email of emails) {
            if (email.pref) {
              prefEmail = email.value;
              break;
            }
          }
          if (!prefEmail) {
            prefEmail = emails[0].value;
          }
        }
        if (prefEmail) {
          this.updateControlValue(field.name, prefEmail, true);
          this.fieldChanged(field);
        }
      }, error => {});
      contacts.pickContact();
    }
  }

  private chooseDateRange(field: FormField): void {
    if (this.inlineFormField) {
      this.inlineFormField = null;
    } else {
      this.inlineFormField = field;
    }
    this.markForCheck();
  }

  public showInlineForm(field: FormField): boolean {
    return this.inlineFormField === field;
  }

  public inlineForm(field: FormField): string {
    if (field.controltype.indexOf('date')>=0) {
      return '__local_date_range';
    }
    return null;
  }

  public updateInlineParent(value: any, closeInlineForm: boolean): void {
    this.updateControlValue(this.inlineFormField.name, value, true);
    this.fieldChanged(this.inlineFormField);
    this.markForCheck();
    if (closeInlineForm) {
      this.inlineFormField = null;
    }
  }

  public inlineDateRangeChanged(dateRange: string, closeInlineForm: boolean): void {
    const datePicker = document.getElementById('dateField_' + this.inlineFormField.name) as HTMLInputElement;
    if (dateRange && datePicker && !Util.Transforms.isSpecialDateFormat(dateRange)) {
      const splitStr: string = Util.Transforms.splitableDateFormat(dateRange);
      const parts: string[] = splitStr ? dateRange.split(splitStr) : [dateRange];
      if (!Util.Transforms.isSpecialDateFormat(parts[0])) {
        datePicker.value = parts[0];
      }
    }
    this.updateInlineParent(dateRange, closeInlineForm);
  }

  public inlineFormFieldChanged(form: DynamicFormComponent, field: FormField, control: AbstractControl): void {
    const value: any = control.value;
    if (this.inlineFormField.controltype.indexOf('date')>=0) {
      const dateRange = Util.Transforms.valueToDateRange(value, Util.RestAPI.restAPIVersion()>0x00160700);
      this.inlineDateRangeChanged(dateRange, ['D1', 'W1', 'W2', 'M1', 'M3', 'Y1', 'Y3'].indexOf(value)!==-1);
    } else {
      this.updateInlineParent(value, true);
    }
  }

  public updateControlValue(name: string, value: any, makeDirty?: boolean, translate?: boolean, transArgs?: string[], autoValidated?: boolean): void {
    if (this.form) {
      const control: FormControl = this.form.controls[name] as FormControl;
      // in case the element is the focus we must blur it so validation will run
      const field: FormField = this.getField(name);
      const nativeEl = document.getElementById(name);
      if (nativeEl && nativeEl === document.activeElement && !autoValidated) {
        nativeEl.blur();
      }
      const newValue: any = {};
      if (translate) {
        value = this.localizer.getTranslation(value, transArgs);
      }
      if (field && field.fldtype === 'edit' && (field.datatype === '1' || field.datatype === '8')) {
        value = Util.Transforms.utcTimeRangeToLocalDate(value);
      }
      newValue[name] = value;
      if (name === 'AUTHOR_ID' && this.desc && this.desc['DOCNUM'] && this.desc.type === 'workspaces') {
        if (!control || (control && control.value !== value)) {
          this.desc['NEW_AUTHOR_ID'] = value;
        }
      }
      this.updateFormData(newValue);
      if (makeDirty) {
        if (control) {
          control.markAsDirty();
        }
      }
      if (!!field && ((field.fldtype==='edit' && field.isReadonly) || field.fldtype==='list' || field.fldtype==='checkbox')) {
        ++field.rerender;
      }
      this.cdr.markForCheck();
      if (this.blockValidation && this.controller.enableOK) {
        setTimeout(() => {
          this.controller.enableOK(this.form.valid, this.form.dirty);
        }, 1);
      }
    }
  }

  public getFieldValue(name: string): any {
    let value: any = null;
    if (this.form) {
      value = this.form.value[name];
      if (!value) {
        if (!!this.form.controls[name]) {
          value = this.form.controls[name].value;
        }
        if (!value) {
          value = this.data[name];
        }
      }
    }
    return value;
  }

  // interface FormController
  public fieldChanged(field: FormField): boolean {
    const control: AbstractControl = this.form.controls[field.name];
    if (this.inlineParent) {
      if (field.scriptTrigger || field.visibilityTriggers) {
        this.controller.fieldChanged(field, control, this.fields);
      }
      this.inlineParent.inlineFormFieldChanged(this, field, control);
    } else if (this.controller && this.controller.fieldChanged) {
      if ((!control.value && (field.isRequired || field.controltype === 'editdate'))) {
        const formControl: FormControl = this.form.controls[field.name] as FormControl;
        this.validateField(formControl, field);
        if (field.scriptTrigger || field.visibilityTriggers) {
          return this.controller.fieldChanged(field, control, this.fields);
        }
        return true;
      } else if (control && control.dirty && ((field.scriptTrigger || field.visibilityTriggers) || control.value!=='DEFAULT' && (control.value || field.isTriStateCheckbox) && (control.touched || field.isCombo || field.isTriStateCheckbox || field.controltype==='radiogroup' || field===this.inlineFormField))) {
        return this.controller.fieldChanged(field, control, this.fields);
      } else if (!control.value) {
        this.clearFieldDesc(field);
        this.clearFieldValueAndDescOfDependents(field);
        this.clearFieldValueAndDescOfChildren(field);
      }
    }
    return true;
  }

  public buttonClicked(field: FormField): void {
    if (this.controller && this.controller.buttonClicked) {
      const fieldName: string = field.name;
      let control: AbstractControl = this.getControl(fieldName);
      if (!control) {
        Util.FieldMappings.forEachTemplateField(this.fields, true, (curField: any): boolean => {
          if (curField.name === fieldName) {
            if (!!curField.extensionParent) {
              control = this.getControl(curField.extensionParent);
              return false;
            }
          }
          return true;
        });
      }
      this.controller.buttonClicked(field, control, this.fields);
    }
  }

  public getList(kind: string): any {
    return this.data ? this.data[kind] : null;
  }

  public setSecurityList(data: ListItem[]): void {
    this.securityList = data;
  }

  public getSecurityList(): ListItem[] {
    const securityControl: AbstractControl = this.form && this.form.controls ? this.form.controls['SECURITY'] : null;
    if (securityControl && securityControl.dirty) {
      if (securityControl.value === '1') {
        const authorControl: AbstractControl = this.form.controls['AUTHOR_ID'];
        const author = !!authorControl && !!authorControl.value ? authorControl.value : null;
        let bAddedAuthor = false;
        if (this.createType) {
          if (!this.securityList) {
            if (authorControl && authorControl.value) {
              this.securityList = this.listService.addAuthorTypistAsTrustee(authorControl.value, Util.RestAPI.getUserID());
              bAddedAuthor = true;
            }
          }
        }
        if (!this.securityList) { // User selected from public to restricted
          this.securityList = this.listService.addAuthorTypistAsTrustee(this.desc['AUTHOR_ID'], Util.RestAPI.getUserID());
          bAddedAuthor = true;
        }
        if (!bAddedAuthor && !!author && author.toUpperCase() !== Util.RestAPI.getUserID().toLocaleUpperCase()) {
          this.securityList.push({ flag: 2, USER_ID: author, rights: AccessLevel.ACCESS_LEVEL_FULL_RM }as any);
        }
        return this.securityList;
      } else {
        return null;
      }
    } else if (this.data['SECURITY']>='1') {
      return this.securityList;
    }
    return null;
  }

  private formatDocNumsInFormValue(formValue: any): any {
    if (this.formKind.startsWith('profile_query')) {
      const docuNum: string = formValue['DOCNUM'];
      if (docuNum && docuNum.toUpperCase().indexOf(' TO ') === -1) {
        formValue['DOCNUM'] = docuNum.replace(/[\ \t+]/g,';');
      }
    }
    return formValue;
  }

  public getValue(): any {
    return this.formatDocNumsInFormValue(this.form ? this.form.value : {});
  }

  public getAllData(): any {
    const allData: any = this.getValue();
    const originalKeys: string[] = this.data ? Object.keys(this.data) : [];
    const allDataKeys: string[] = Object.keys(allData);
    const allControlKeys: string[] = Object.keys(this.form.controls);
    let key;
    for (key of originalKeys) {
      if (allDataKeys.indexOf(key)===-1 && !!this.data[key]) {
        allData[key] = this.data[key];
      }
    }
    for (key of allControlKeys) {
      if (this.form.controls[key].dirty && allDataKeys.indexOf(key)===-1) {
        allData[key] = this.form.controls[key].value;
      }
    }
    return allData;
  }

  private finishGetDirty(formValue: any): any {
    const securityControl: AbstractControl = this.form.controls['SECURITY'];
    if (securityControl && securityControl.dirty) {
      formValue['SECURITY'] = securityControl.value;
    }
    if (!!this.data && !!this.data['FORM'] && this.isProfileForm() && !this.formKind.startsWith('profile_query')) {
      formValue['FORM'] = this.data['FORM'];
    }
    return this.formatDocNumsInFormValue(formValue);
  }

  public getDirtyValue(): any {
    let formValue: any = {};
    const isFilterForm: boolean = this.desc && this.desc.type==='searches';
    const isSearchForm: boolean = this.formKind.startsWith('profile_query');
    const isCopyForm: boolean = this.formKind.startsWith('profile_copy');
    const theForm: FormGroup = this.form;
    const addEachDirtyOrNonEmptyField = (theFields: any , allowEmpty: boolean) => {
      for (const curField of theFields) {
        if (curField.fields) {
          addEachDirtyOrNonEmptyField(curField.fields, allowEmpty);
        }
        if (curField.isVisible || !isSearchForm) {
          const control: AbstractControl = theForm.controls[curField.name];
          if (control && (isFilterForm || isCopyForm || control.dirty || !!this.createType) && (control.value || (allowEmpty && !curField.isRequired))) {
            if (isCopyForm && curField.controltype === 'editdate') {
              formValue[curField.name] =  Util.Transforms.formatDateForDM(control.value);
            } else {
              formValue[curField.name] = curField.controltype.indexOf('number')!==-1 ? control.value.toString() : control.value;
            }
          }
        }
      }
    };
    formValue = {};
    addEachDirtyOrNonEmptyField(this.fields, !this.createType && !isSearchForm);
    return this.finishGetDirty(formValue);
  }

  public getDirtyAndRequiredValue(): any {
    const formValue: any = {};
    const theForm: FormGroup = this.form;
    const isSearchForm: boolean = this.formKind.startsWith('profile_query');
    const isCopyForm: boolean = this.formKind.startsWith('profile_copy');
    const addEachDirtyAndRequiredField = (theFields: any , allowEmpty: boolean) => {
      for (const curField of theFields) {
        if (curField.fields) {
          addEachDirtyAndRequiredField(curField.fields,allowEmpty);
        }
        const control: AbstractControl = theForm.controls[curField.name];
        if (control && (((control.dirty || isCopyForm) && (control.value || (allowEmpty && !curField.isRequired)) && !curField.isExtension) || curField.isRequired)) {
          let value = control.value;
          if (!value) {
            value = 'DEFAULT';
          }
          if (isCopyForm && curField.controltype === 'editdate') {
            formValue[curField.name] =  Util.Transforms.formatDateForDM(control.value);
          } else {
            formValue[curField.name] = curField.controltype.indexOf('number')!==-1 ? control.value.toString() : control.value;
          }
        }
      }
    };
    addEachDirtyAndRequiredField(this.fields, !this.createType && !isSearchForm);
    return this.finishGetDirty(formValue);
  }

  public getEditableValues(includeReadOnlyFields: boolean = false): any {
    const editableData: any = {};
    const allData: any = this.form.value;
    const allDataKeys: string[] = Object.keys(allData);
    for (const key of allDataKeys) {
      const value = !!this.form.controls[key] ? this.form.controls[key].value : undefined;
      if (value !== undefined && value !== null) {
        if (key==='SECURITY') {
          editableData[key] = value;
        } else if (key!=='DOCNAME') {
          const field: any = this.getField(key);
          if (!!field && (field.fldtype==='edit' || field.fldtype==='checkbox') && field.isEnabled && (!field.isReadonly || includeReadOnlyFields)) {
            editableData[key] = value;
          }
        }
      }
    }
    return editableData;
  }

  public getControl(name: string): AbstractControl {
    return this.form.controls[name];
  }

  public getControlValue(name: string): any {
    return !!this.form.controls[name] ? this.form.controls[name].value : null;
  }

  public getField(name: string): FormField {
    let field: FormField = null;
    const findFieldWithName = theFields => {
      if (theFields) {
        for (const curField of theFields) {
          if (curField.name===name) {
            field = curField;
            return true;
          }
          if (curField.fields) {
            if (findFieldWithName(curField.fields)) {
              return true;
            }
          }
        }
      }
      return false;
    };
    if (name) {
      findFieldWithName(this.fields);
    }
    return field;
  }

  public markForCheck(): void {
    this.cdr.markForCheck();
  }

  public setLoading(loading: boolean): void {
    this.loadingCount += loading ? 1 : -1;
    if (this.loadingCount<0) {
      this.loadingCount = 0;
    }
    this.cdr.markForCheck();
  }

  public isLoading(): boolean {
    return this.loadingCount > 0;
  }

  public userChangingProfileForm(libChanged: boolean, formChanged: boolean): void {
    if (libChanged) {
      if (Util.Device.bIsOfficeAddinOutlook) {
        this.savedFormData = {};
        const dirtyVal = this.getDirtyValue();
        const dirtyValKeys = Object.keys(dirtyVal);
        for (const key of dirtyValKeys) {
          if (Util.isEmailField(key)) {
            this.savedFormData[key] = dirtyVal[key];
          }
        }
      }
    } else {
      this.savedFormData = this.getDirtyValue();
      if (!formChanged && Util.Device.bIsOfficeAddinOutlook) {
        const allData = this.getAllData();
        const allKeys = Object.keys(allData);
        for (const key of allKeys) {
          if (Util.isEmailField(key)) {
            this.savedFormData[key] = allData[key];
          }
        }
      }
    }
  }

  public setFieldVisibility(name: string, visible: boolean): void {
    const field: FormField = this.getField(name);
    if (field) {
      if (visible !== field.isVisible) {
        field.isVisible = visible;
        this.cdr.markForCheck();
      }
    }
  }

  public setFieldLabel(name: string, label: string, translate?: boolean): void {
    const field: FormField = this.getField(name);
    if (field) {
      if (label !== field.label) {
        if (translate) {
          label = this.localizer.getTranslation(label);
        }
        field.label = label;
        this.cdr.markForCheck();
      }
    }
  }

  private getKey(field: FormField): string {
    // such as: AUTHOR.USER_ID;DOCSADM.PEOPLE.SYSTEM_ID"
    const sqlinfo: string = field.mvinfo || field.sqlinfo;
    if (sqlinfo && sqlinfo.length) {
      const arg: string = sqlinfo.split(';')[0];
      const parts: string[] = arg.split('.');
      if (parts.length>1) {
        let key: string = parts[parts.length-1];
        if (field.name === 'APP_ID' && key === 'APPLICATION') {
          key = 'APP_ID';
        }
        // Looks like all sqlinfo key components have to be valid fields also.
        // Otherwise we have to use the field name as the key. If this is true
        // in all cases above code for APP_ID may nor be needed. (Velimir)
        if (!this.verifyKey(key)) {
          key = field.name;
        }
        return key;
      }
    } else if (field.name === '%KEYWORD') {
      return 'KEYWORD_ID';
    }
    return null;
  }

  private verifyKey(key: string): boolean {
    const field = this.getField(key);
    return !!field;
  }

  public setFieldValueAndDescOfParents(field: FormField, data: any): void {
    // Ignore unused and invalid fields returned by DM server. Note that CLIENT_ID
    // does NOT contain the actual client value but it is in CLIENT_ID01 !!??
    const ignoreFields = ['%PRIMARY_KEY','lib',field.name];
    if (this.formKind.startsWith('profile_query') && field.name === 'TYPE_ID') {
      ignoreFields.push('DOCTYPE_STORAGE');
    }
    if (data) {
      const keys: string[] = Object.keys(data);
      for (const key of keys) {
        if (ignoreFields.indexOf(key)<0) {
          // If alias, get true field name (i.e. CLIENT_ID01 maps into CLIENT_ID)
          const fieldName: string = Util.FieldMappings.aliasToField(key);
          const currField: FormField = this.getField(fieldName);
          if (currField) {
            const value: string = data[key];
            currField.lastLookupValue = value;
            currField.autoValidated = true;
            this.updateControlValue(fieldName, value, true, false, null, currField.autoValidated);
            // Check if there is a description field and set it
            const nameKey: string = Util.FieldMappings.descriptionForField(this.formTemplate, currField);
            if (nameKey) {
              const desc: string =  Util.FieldMappings.descriptionValueForField(data, currField);
              this.setFieldDesc(fieldName, desc);
            }
          }
        }
      }
    }
  }

  public clearFieldValueAndDescOfChildren(fieldIn: FormField): void {
    while (fieldIn && fieldIn.childField) {
      fieldIn = this.getField(fieldIn.childField);
      if (fieldIn) {
        if (fieldIn.retainDefaultValue === false) {
          this.clearFieldValueAndDesc(fieldIn.name);
        } else {
          fieldIn.retainDefaultValue = false;
        }
        // Clear any dependents of this child
        this.clearFieldValueAndDescOfDependents(fieldIn);
      }
    }
  }

  public clearFieldValueAndDescOfDependents(fieldIn: FormField): void {
    if (fieldIn && fieldIn.auxiliary) {
      for (const fieldName of fieldIn.auxiliary) {
        this.clearFieldValueAndDesc(fieldName);
      }
    }
    if (fieldIn && fieldIn.lookups) {
      for (const fieldName of fieldIn.lookups) {
        this.clearFieldValueAndDesc(fieldName);
        const field: FormField = this.getField(fieldName);
        this.clearFieldValueAndDescOfDependents(field);
      }
    }
  }

  public clearFieldValueAndDesc(fieldName: string): void {
    const map = {};
    const field: FormField = this.getField(fieldName);
    if (field) {
      map[field.name] = '';
      if (!!this.data) {
        this.data[field.name] = '';
      }
      field.autoValidated = false;
      this.clearFieldDesc(field, map);
      const control: FormControl = this.form.controls[fieldName] as FormControl;
      if (control) {
        control.markAsDirty();
      }
    }
  }

  public clearFieldDesc(field: FormField, mapFields: any={}): void {
    if (field) {
      let nameKey = '';
      if (!!field.descriptionField) {
        nameKey = field.descriptionField;
      } else {
        nameKey = Util.FieldMappings.descriptionForField(this.formTemplate, field);
      }
      mapFields[nameKey] = '';
      if (!!this.data) {
        this.data[nameKey] = '';
      }
      this.updateFormData(mapFields, true);
      if (!!field.auxiliary) {
        // If a lookup field has dependent fields then force field validation for every field value
        field.lastLookupValue = '*';
      }
    }
  }

  public setFieldDesc(fieldName: string, fieldDesc: string, mapFields: any={}): void {
    const field: FormField = this.getField(fieldName);
    if (field) {
      let nameKey = '';
      if (!!field.descriptionField && (field.descriptionField !== undefined || field.descriptionField !== '')) {
        nameKey = field.descriptionField;
      } else {
        nameKey = Util.FieldMappings.descriptionForField(this.formTemplate, field);
      }
      mapFields[nameKey] = fieldDesc;
      this.updateFormData(mapFields, true);
    }
  }


  // **** PopupCallback implementation

  popupCancel(): void {
    if (this.lookupShown) {
      this.lookupShown = false;
      --this.blockValidation;
    }
    this.lookupField = null;
    if (this.securityShown) {
      this.securityShown = false;
    }
    if (this.fileplansShown) {
      this.fileplansShown = false;
    }
    this.standInBodyHeight = 0;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.cdr.markForCheck();

      if (Util.Device.isPhoneLook() && !!this.formScrollTop) {
        const formBody: HTMLElement = document.getElementById('edx_form_body');
        if (!!formBody) {
          formBody.scrollTop = this.formScrollTop;
        }
      }
    }, 1);
  }

  popupOK(): void {
    if (this.security) {
      let securityType: number;
      this.securityList = this.security.getList();
      setTimeout(() => {
        if (this.securityList && this.securityList.length>0) {
          this.desc['edx_selected_security_choice'] = '1';
          this.updateControlValue('SECURITY','1', true);
          securityType = 1;
        } else {
          this.updateControlValue('SECURITY','0', true);
          securityType = 0;
        }
        if (this.controller.getHeaderForm && this.controller.getHeaderForm()) {
          this.controller.getHeaderForm().updateControlValue('SECURITY', securityType);
        }
        if (this.controller.securityDirty) {
          this.controller.securityDirty(true);
        }
      }, 1);
    } else if (this.fileplans) {
      const filePartList: ListItem[] = this.fileplans.getSelections();
      const description: string = Util.FieldMappings.descriptionValueForField(filePartList[0], this.lookupField);
      this.lookupField.lookupData = [];
      const filepartNo: string = filePartList[0]['PD_FILEPT_NO'] || filePartList[0].DOCNAME;
      const lookupItem = new CacheItem(filepartNo, description);
      this.lookupField.lookupData.push(lookupItem);
    } else if (this.lookupTable && this.lookupField) {
      const fieldSJON: any = {};
      let fieldSecondaryKey: string;
      let secondaryValue: string;
      const sqlORmvinfo: string = this.lookupField.mvinfo ||  this.lookupField.sqlinfo;
      const items: any[] = this.lookupTable.getSelections();
      const isMultiSelectField: boolean = this.lookupTable.isMultiselectItem();
      const listPrimaryKey: string = this.lookupTable.getPrimaryColumn();
      const fieldPrimaryKey: string = this.lookupField.name === 'APP_ID' ? 'APP_ID' : this.getKey(this.lookupField);
      const isSearchForm: boolean = this.formKind.startsWith('profile_query');
      let primaryValue: string = (isSearchForm || isMultiSelectField) ? (this.lookupTable.getSelectedLookupValues() || '').replace(/[\|]/g, ';') : '';

      let semicolon: string = !!primaryValue ? ';' : '';
      for (const item of items) {
        const lookupValueUC = (item[fieldPrimaryKey] || '').toUpperCase();
        if (lookupValueUC && primaryValue.indexOf(lookupValueUC) === -1) {
          primaryValue += semicolon + lookupValueUC;
        }
        semicolon = ';';
        this.scanFieldsForRevalidatonOrEvaluation(item, this.lookupField.name);
      }
      if (this.lookupField.fields && this.lookupField.fields.length) {
        fieldSecondaryKey = this.getKey(this.lookupField.fields[0]);
        secondaryValue = items[0][fieldSecondaryKey];
      }
      if (fieldSecondaryKey) {
        //fieldSJON = '{"'+this.lookupField.name+'":"'+primaryValue+'","'+this.lookupField.fields[0].name+'":"'+secondaryValue+'"}';
        fieldSJON[this.lookupField.name] = primaryValue;
        fieldSJON[this.lookupField.fields[0].name] = secondaryValue;
      } else {
        //fieldSJON = '{"'+this.lookupField.name+'":"'+primaryValue+'"}';
        fieldSJON[this.lookupField.name] = primaryValue;
      }
      if (secondaryValue === undefined) {
        const nameKey: string = Util.FieldMappings.secondaryKeyForField(items[0], this.lookupField);
        secondaryValue = items[0][nameKey];
      }
      if (items.length === 1) {
        this.lookupField.lookupData = [];
        const lookupItem = new CacheItem(primaryValue, secondaryValue, items[0]);
        this.lookupField.lookupData.push(lookupItem);
      }
      const control = this.form.controls[this.lookupField.name] as FormControl;
      this.updateFormData(fieldSJON, true);
      if (!!control) {
        control.markAsDirty();
      }
      if (this.controller.enableOK) {
        setTimeout(() => {
          this.controller.enableOK(this.form.valid, this.form.dirty);
          // TODO: Ron fix or remove in 16.6 this.focusOnFirstEmptyEditable();
        }, 1);
      }
    }
    this.popupCancel();
  }

  private hasLookup(field: FormField): boolean {
    return !!field.lookup && !field.isReadonly && (field.lookup.startsWith('$edx_') || field.lookup.indexOf('@RM.DLL') > -1 || (this.hasLookupTable(field.lookup)));
  }

  private revalidate(field: FormField): boolean {
    if (this.hasLookup(field)) {
      return !field.scheduledForRevalidation && !field.lookupData && !field.autoValidated && field.isRequired;
    }
   return false;
  }

  public doReValidate(field: FormField,value: any): void {
    const control: FormControl = this.form.controls[field.name] as FormControl;
    if (value && ((field.lastLookupValue !== null && field.lastLookupValue !== value) || (field.defaultValue !== null && field.defaultValue !== value))) {
      this.updateControlValue(field.name,value,true);
      if (this.revalidate(field)) {
        field.scheduledForRevalidation = true;
        let parent = this.getField(field.parentField);
        if (parent && !parent.isRequired) {
          parent = null;
        }
        // Do NOT clear children form default values!!!
        const child = this.getField(field.childField);
        if (child) {
          child.retainDefaultValue = true;
        }
        const waitForParent = () => {
          if (parent && parent.scheduledForRevalidation) {
            setTimeout(waitForParent,10);
          } else {
            this.validateLookup(control, field, value);
          }
        };
        setTimeout(waitForParent,10);
      }
    }
  }

  public scanFieldsForRevalidatonOrEvaluation(item: any, selfName?: string): void {
    const enumerableKeys: any = item ? Object.keys(item) : null;
    const rootKeyName: string = selfName ? selfName : '';
    if (enumerableKeys && !!this.createType) {
      for (const key of enumerableKeys) {
        const field = this.getField(key);
        if (field && field.name !== rootKeyName) {
          this.doReValidate(field,item[key]);
        }
      }
    }
  }

  public handleListItemDblClick(table: ListTableComponent, item: ListItem, event: Event, property: string): boolean {
    const handled: boolean = this.canSelectListItem(table, item);
    if (handled) {
      this.popupOK();
    }
    return handled;
  }

  // export interface ListTableParent
  public selectionsUpdated(table: ListTableComponent): void {
    const items: any[] = this.lookupTable ? this.lookupTable.getSelections() : null;
    this.lookupOKDisabled = !(items && items.length);
  }

  public canSelectListItem(table: ListTableComponent, item: ListItem): boolean {
    if (this.lookupTable && !this.formKind.startsWith('profile_query')) {
      if (item['DISABLED'] === '1' || item['DISABLED'] === 'Y') {
        return false;
      }
    }
    return true;
  }

  public isDirty(): boolean {
    return this.form ? this.form.dirty : false;
  }

  public validationBlocking(blockValidation: boolean): void {
    if (blockValidation) {
      ++this.blockValidation;
    } else {
      --this.blockValidation;
    }
  }

  public getCacheKey(fieldName: string): string {
    let key = '';
    if (this.desc) {
      fieldName = Util.FieldMappings.aliasToField(fieldName);
      const field = this.getField(fieldName);
      if (field && field.parentField) {
        const parent_values = this.getParentValues(field);
        key = this.desc.lib + '.' + fieldName + parent_values;
      } else {
        key = this.desc.lib + '.' + fieldName;
      }
    }
    return key;
  }

  private getParentValues(field: FormField): string {
    let keyValues = '';
    field = this.getField(field.parentField);
    while (field) {
      keyValues += '.' + this.getFieldValue(field.name);
      field = this.getField(field.parentField);
    }
    return keyValues;
  }
}
