<ng-template [ngIf]="ui<2 || forceMenuButton">
  <div *ngIf="isMenuShown" [ngClass]="{menubutton:buttonIconSize>=0, menuonly:buttonIconSize<0, open:isOpen, crumby:buttonIconSize==3, mobile:ui>=2}" (click)="toggleMenuOpen($event)">
    <img *ngIf="!!buttonIcon" src="{{isOpen ? buttonIconOpen : buttonIcon}}" [ngClass]="{mini:buttonIconSize==1||buttonIconSize==-1, maxi:buttonIconSize==2||buttonIconSize==-2, crumby:buttonIconSize==3}" alt="{{toolTip}}" title="{{toolTip}}">
    <div *ngIf="!buttonIcon" class="noimg"></div>
    <div *ngIf="isOpen" [ngClass]="{menublocker: true, blocktop:drawUp, blockright: drawToRight}"></div>
  </div>
  <div *ngIf="isOpen" class="menu-overlay" (click)="overlayClick($event)"></div>
  <ul #ul *ngIf="isOpen" [ngClass]="{opening:isOpening, closing:isClosing, drawright:drawToRight, allowchecks:allowChecks, drawup:drawUp, mini:buttonIconMini, wide:wide, noselect:noSelect}" [style.maxHeight]="ulMaxHeight" (animationend)="transitionComplete()">
    <li *ngFor="let item of items" [ngClass]="{separator:item.separator, edx_hidden:!item.enabled, button:item.button, heading:item.heading}">
      <div class="item-container">
        <div *ngIf="item.checkable" class="checkmark" [ngClass]="{checked:item.checked, safari:safari||officeAddinDesktopMac}" (click)="toggleCheck(items.indexOf(item), $event)"></div>
        <div *ngIf="!item.button && !item.heading" class="menu-text" (click)="doCommand(items.indexOf(item), $event)" id="{{getMenuId(item)}}" title="{{item.name}}">{{item.name}}</div>
        <button *ngIf="item.button" class="secondary" (click)="doCommand(items.indexOf(item), $event)" id="{{getMenuId(item)}}" title="{{item.name}}">{{item.name}}</button>
        <div *ngIf="item.heading" class="headingtext" title="{{item.name}}">{{item.name}}</div>
      </div>
    </li>
  </ul>
</ng-template>
<ng-template [ngIf]="ui>=2 && !forceMenuButton">
  <div *ngIf="menuID==3" class="menubutton" [ngClass]="{mobile:ui>=2}" (click)="toggleMenuOpen($event)">
   <img *ngIf="buttonIcon" src="{{isOpen ? buttonIconOpen : buttonIcon}}" title = "{{toolTip}}" alt="{{toolTip}}">
  </div>
  <div *ngIf="isOpen" class="menu-overlay mobile" [ngClass]="{opening:isOpening, closing:isClosing, topright:drawTopRight}" (click)="overlayClick($event)"></div>
  <ul #ul *ngIf="isOpen" class="mobile" [ngClass]="{opening:isOpening, closing:isClosing, topright:drawTopRight, cordova:ui>=4, ios:iOS, doaimac:officeAddinDesktopMac}" (animationend)="transitionComplete()">
    <li class="heading">
      <div class="item-container">
        <img src="{{menuIcon}}" [ngClass]="{edx_transparent:!menuIcon}">
        <div class="headingtext">{{menuTitle}}</div>
      </div>
    </li>
    <li *ngFor="let item of items" [ngClass]="{edx_hidden:!item.enabled, button:item.button, separator:item.separator}">
      <div *ngIf="!item.separator" class="item-container">
        <div *ngIf="item.checkable" class="checkmark" [ngClass]="{checked:item.checked, safari:safari}" (click)="toggleCheck(items.indexOf(item), $event)"></div>
        <img *ngIf="!item.button && !item.heading && !item.checkable" src="{{getItemIcon(item)}}" alt="item.name" [ngClass]="{edx_transparent:!item.icon}">
        <div *ngIf="!item.button && !item.heading" class="menu-text" (click)="doCommand(items.indexOf(item), $event)" id="{{getMenuId(item)}}" title="{{item.name}}">{{item.name}}</div>
        <button *ngIf="item.button" class="secondary mobile" [ngClass]="{oai:officeAddin}" (click)="doCommand(items.indexOf(item), $event)" id="{{getMenuId(item)}}" title="{{item.name}}">{{item.name}}</button>
        <div *ngIf="item.heading" class="headingtext" title="{{item.name}}">{{item.name}}</div>
      </div>
    </li>
  </ul>
</ng-template>