<div #lb class="list-base" [ngClass]="{single:(!showExtras && fieldDataList && fieldDataList.length==1), percenticonwidth:usePercentIconWidth, ie:isIE, edge:isEdge}">
  <div *ngIf="infoMessage" class="celltext info-message">{{infoMessage}}</div>
  <div #tc class="table-container" [ngClass]="{dragover:dragover, draggingcolumn:draggingColumn!=null, hasfootprint:hasFootprint, web:ui==0, readonly:!!schema && schema.readOnly, displayMessage:infoMessage, fullwidth:isIE, slidright: hitPreviewShown, slidingleft: hitPreviewHiding, slidingright: hitPreviewShowing}">
    <edx-spinner *ngIf="reloading"></edx-spinner>
    <table *ngIf="schema && schema.columns && schema.columns.length">
      <thead *ngIf="!fieldDataList || showExtras || fieldDataList.length>1" [ngClass]="{tophead:(schema.columnHeadings && schema.columnHeadings.length)}">
        <tr *ngIf="schema.columnHeadings && schema.columnHeadings.length">
          <ng-template ngFor let-columnHeading [ngForOf]="schema.columnHeadings" let-k="index">
            <th [colSpan]="columnHeading.colspan" class="topheading">
              <div *ngIf="columnHeading.bottomcolor" class="topheadinglabel" [style.borderBottom]="'solid 3px '+columnHeading.bottomcolor">{{this.localizer.getTranslation(columnHeading.label)}}</div>
            </th>
          </ng-template>
        </tr>
        <tr #headerrow>
          <ng-template ngFor let-column [ngForOf]="schema.columns" let-k="index">
            <th *ngIf="k < columnsShown || (column.format === 10 && columnsHidden > 0)" width="{{getColWidth(column)}}" [ngClass]="{numeric:column.format===1, iconic: columIsIconic(column), sortcolumn:getSortCol()==column.property,topheading:column.nonsortable}" (click)="column.nonsortable ? null : headerColumnClick($event, column.property)" [title]="column.label">
              <div *ngIf="column.property === 'select'" (click)="headerSelectClick()" [ngClass]="{headerselect:!!list.length, partialselect:!noneSelected, allselect:allSelected}" id="edx_list_table_selectall" [title]="column.label"></div>
              <div *ngIf="column.property === 'expand' && columnsHidden > 0" [ngClass]="{headerexpand:true, allexpand: allExpanded}" title="{{this.localizer.getTranslation('TOOLTIP.SEE_ALL')}}"></div>
              <div *ngIf="column.property === 'APP_ID'" class="headerobjtype rowicon" [title]="column.label">
                <div *ngIf="getSortCol()==column.property" class="sortarrow" [ngClass]="{sortascending:ascending}" [title]="(ascending ? this.localizer.getTranslation('TOOLTIP.SORT_ARROW_DESCENDING',[column.label]) : this.localizer.getTranslation('TOOLTIP.SORT_ARROW_ASCENDING',[column.label]))"></div>
              </div>
              <div *ngIf="column.property !== 'select' && column.property !== 'expand' && column.property !== 'APP_ID' && k < columnsShown" class="headercolumn" [edx-draggable]="this">
                <span *ngIf="!isImageHeader(column.img)" [ngClass]="{sortcolumn:getSortCol()==column.property}">{{column.label}}</span>
                <span *ngIf="isImageHeader(column.img)" [ngClass]="{sortcolumn:getSortCol()==column.property}">
                  <img class="rightsicon" [src]="column.img" [title]="column.label" alt="column.label">
                </span>
                <div *ngIf="getSortCol()==column.property" class="sortarrow" [ngClass]="{sortascending:ascending}" [title]="(ascending ? this.localizer.getTranslation('TOOLTIP.SORT_ARROW_DESCENDING',[column.label]) : this.localizer.getTranslation('TOOLTIP.SORT_ARROW_ASCENDING',[column.label]))"></div>
              </div>
              <div *ngIf="column.property !== 'select' && column.property !== 'expand' && column.property !== 'APP_ID'" class="colresizer"  [edx-draggable]="this"><div class="colresizerline"></div></div>
            </th>
          </ng-template>
        </tr>
      </thead>
      <tbody #tb class="table-scroller" [edx-drag-target]="this">
        <tr *ngIf="!reloading && (!list || !list.length)"><td [colSpan]="schema.columns.length" class="center"><div class="celltext">{{noHits}}</div></td></tr>
        <ng-template ngFor let-listitem [ngForOf]="list" let-i="index">
          <tr #tr [ngClass]="{selected:selections.indexOf(listitem)>=0 && isSelectable(listitem), open:listitem==openedItem, dropfolder:dropFolder(listitem), expanded: (expandedItems.indexOf(listitem)>=0 && columnsHidden > 0)}" (mouseover)="enableHoverMenu(i)" (mouseleave)="disableHoverMenu(i)" (click)="listItemRowClick(listitem, $event)" (dblclick)="listItemDblClick(listitem, $event)" [edx-draggable-file]="this" [draggable]="isDraggableFile(listitem)" ng-readonly="true">
            <ng-template ngFor let-col [ngForOf]="schema.columns" let-j="index">
              <td *ngIf="j < columnsShown || (col.format===10 && columnsHidden > 0)" [ngSwitch]="col.format" width="{{getColWidth(col)}}" [ngClass]="{numeric:col.format===1, iconic: columIsIconic(col)}" [title]="listItemTooltip(listitem, col.property)">
                <div *ngSwitchCase="0" class="selector rowselector" [ngClass]="{disabled:!canSelectItem(listitem)}" (click)="selectorClick(listitem, $event)"></div>
                <div *ngSwitchCase="1" class="cellnumeric">{{formatNumber(listitem, col.property, j)}}</div>
                <div *ngSwitchCase="2" (click)="listItemClick(listitem, $event, col.property)" [ngClass]="{celltext: true, clickable: isClickable(listitem,col.property)}" title="{{formatString(listitem,col.property)}}">
                  <span>{{schemaId==='VERSIONS'?formatString(listitem,col.property).toUpperCase():formatString(listitem,col.property)}}</span>
                  <edx-inline-action-bar *ngIf="col.property=='DOCNAME' && inlineActionMenuId!=-1" [resid]="inlineActionMenuId" [shown]="listitem==hoverListItem" [target]="inlineActionTarget"></edx-inline-action-bar>
                </div>
                <div *ngSwitchCase="3" class="celltext">{{formatDate(listitem, col.property)}}</div>
                <div *ngSwitchCase="4">
                  <edx-spinner *ngIf="listitem.id=='' && listitem.lib=='' " [mini]="true"></edx-spinner>
                  <img *ngIf="listitem.id!='' || listitem.lib!='' " [ngClass]="{rowicon: true, clickable:  isClickable(listitem,col.property)}" src="{{formatTypeIcon(listitem)}}" alt="{{formatTypeText(listitem)}}" (click)="listItemClick(listitem, $event, col.property)">
                  <img *ngIf="displayMiniStatus(listitem, col.property)" class="overlaystatusicon clickable" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}" (click)="listItemClick(listitem, $event, 'APP_ID')">
                </div>
                <div *ngSwitchCase="5" class="status">
                  <img *ngIf="displayStatusIcon(listitem)" class="statusicon" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}">
                  <div class="celltext" [ngClass]="{italic:statusTextItalic(listitem), red:statusTextRed(listitem), blue:statusTextBlue(listitem)}">{{formatStatusText(listitem)}}</div>
                </div>
                <div *ngSwitchCase="6" class="status">
                  <img *ngIf="getTrusteeAccess(i,j-1)=='2'" class="rightsicon" src="assets/images/col_hdr_deny24.svg" alt="{{this.localizer.getTranslation('METADATA.FOOTER.SECURITY.DENY')}}" (click)="setTrusteeAccess(i,j-1,2)" >
                  <img *ngIf="getTrusteeAccess(i,j-1)=='1'" class="rightsicon" src="assets/images/checkmark_green24.svg" alt="{{this.localizer.getTranslation('METADATA.FOOTER.SECURITY.ALLOW')}}" (click)="setTrusteeAccess(i,j-1,1)" >
                  <img *ngIf="getTrusteeAccess(i,j-1)=='0'" class="rightsicon" src="assets/images/col_hdr_inherit24.svg" alt="{{this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.INHERIT')}}" (click)="setTrusteeAccess(i,j-1,0)" >
                </div>
                <div *ngSwitchCase="7"  class="celltext">{{formatPath(listitem[col.property])}}</div>
                <edx-select *ngSwitchCase="8" [id]="i" [items]="rightsValues" [value]="setDisplayAccessLevel(listitem[col.property])" (change)="setTemplatedRights($event, i)" [disabled]="isReadonly(i,col.property)" [inAccessRights]="true"></edx-select>
                <div *ngSwitchCase="9"  class="celltext" (click)="listItemClick(listitem, $event, col.property)" [ngClass]="{clickable: isClickable(listitem,col.property)}">{{formatAction(listitem)}}</div>
                <div *ngSwitchCase="10" class="expander" (click)="expanderClick(listitem, $event)" title="{{this.localizer.getTranslation('TOOLTIP.SEE_MORE')}}"></div>
                <edx-progress *ngSwitchCase="11" max="1.0" [value]="listitem[col.property] || 0"></edx-progress>
                <div *ngSwitchCase="12" class="celltext" title="{{formatEnum(listitem,col.property,j)}}">
                  <span>{{formatEnum(listitem,col.property,j)}}</span>
                </div>
                <img *ngSwitchCase="13" src="listitem[col.property]" alt="{{listitem[col.property]}}">
                <div *ngSwitchCase="14" class="selector schemacb" [ngClass]="{on:!!listitem[col.property], readonly:col.readonly||isReadonly(i,col.property)}" (click)="checkboxClick(listitem,col.property,$event)"></div>
                <div *ngIf="!showExtrasRow(listitem) && !((isIE || isEdge) && hasFootprint)" class="cellborder"></div>
              </td>
            </ng-template>
          </tr>
          <tr *ngIf="expandedItems.indexOf(listitem)>=0 && columnsHidden > 0" class="expansion">
            <td [colSpan]="columnsShown">
              <ng-template ngFor let-extracol [ngForOf]="schema.columns" let-n="index">
                <div *ngIf="n >= columnsShown && extracol.format!==10" [ngSwitch]="extracol.format" class="expandedrow">
                  <div class="expandedlabel">{{extracol.label}}</div>
                  <div *ngSwitchCase="1" class="expandedvalue">{{formatNumber(listitem, extracol.property, j)}}</div>
                  <div *ngSwitchCase="2" class="expandedvalue">
                    <span>{{formatString(listitem,extracol.property)}}</span>
                  </div>
                  <div *ngSwitchCase="3" class="expandedvalue">{{formatDate(listitem, extracol.property)}}</div>
                  <div *ngSwitchCase="5" class="expandedvalue">
                    <img *ngIf="displayStatusIcon(listitem)" class="expandstaticon" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}">
                    <div class="expandstatvalue" [ngClass]="{italic:statusTextItalic(listitem), red:statusTextRed(listitem), blue:statusTextBlue(listitem)}">{{formatStatusText(listitem)}}</div>
                  </div>
                  <div *ngSwitchCase="7"  class="expandedvalue">{{formatPath(listitem[extracol.property])}}</div>
                  <edx-select *ngSwitchCase="8"  [items]="rightsValues" [value]="setDisplayAccessLevel(listitem[extracol.property])"></edx-select>
                  <div *ngSwitchCase="9" class="expandedvalue">{{formatAction(listitem[extracol.property])}}</div>
                  <progress *ngSwitchCase="11" max="1.0" [value]="listitem[extracol.property] || 0"></progress>
                  <div *ngSwitchCase="12" class="expandedvalue">
                    <span>{{formatEnum(listitem,col.property,j)}}</span>
                  </div>
                  <img *ngSwitchCase="13" class="image" src="listitem[col.property]" alt="{{listitem[col.property]}}">
                  <div *ngSwitchCase="14" class="selector schemacb" [ngClass]="{on:!!listitem[col.property], readonly:col.readonly||isReadonly(i,col.property)}" (click)="checkboxClick(listitem,col.property,$event)"></div>
                </div>
              </ng-template>
              <div *ngIf="!showExtrasRow(listitem)" class="cellborder"></div>
            </td>
          </tr>
          <tr *ngIf="showExtrasRow(listitem)" class="expansion previewrow">
            <td *ngIf="isHistoryExtrasRow(listitem)" width="{{getColWidth(schema.columns[0])}}"><div class="cellborder"></div></td>
            <td [colSpan]="isHistoryExtrasRow(listitem)?(columnsShown-1):columnsShown">
              <div class="comments" [ngClass]="{historyextrasrow:isHistoryExtrasRow(listitem)}">
                <span class="label">{{labelExtrasRow(listitem)}}</span>
                <span>{{formatExtrasRow(listitem)}}</span>
              </div>
              <div class="cellborder"></div>
            </td>
          </tr>  
        </ng-template>
        <tr *ngIf="(pageSizeIncremental) > 0 && !allItemsIn && (list.length > 0)" class="showmore">
          <td [attr.colspan]="schema.columns.length">
            <button [ngClass]="{mobile:ui>=2, oai:officeAddin}" (click)="fetchMoreItems()" class="secondary">{{loadMore}}</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div #rdbt class="edx_hidden row-dragover-border top"></div>
    <div #rdbb class="edx_hidden row-dragover-border bottom"></div>
    <div #rdbl class="edx_hidden row-dragover-border left"></div>
    <div #rdbr class="edx_hidden row-dragover-border right"></div>
  </div>
  <div *ngIf="hitPreviewShown" class="hitpreviewpopup" [ngClass]="{shown: hitPreviewShown, hiding: hitPreviewHiding, showing: hitPreviewShowing}" (mouseleave)="hitPreviewLeave($event)" (animationend)="hitPreviewAnimationComplete()">
    <div class="hitpreviewheader">
      <div class="hitpreviewtitle">{{hitPreviewData?hitPreviewData.DOCNAME:""}}</div>
      <div class="hitpreviewpager">
        <span>{{hitPreviewData?this.localizer.getTranslation('PAGINATOR.OF_PAGES',[hitPreviewData.page,hitPreviewData.totalpages]):""}}</span>
        <div class="hitpreviewbuttons">
          <div class="rowicon prev" (click)="showHitPreview(null, -1)"></div>
          <div class="rowicon next" (click)="showHitPreview(null, 1)"></div>
        </div>
      </div>
    </div>
    <div class="hitpreviewcontainer">
      <div class="hitpreviewbody">
        <edx-spinner *ngIf="hitPreviewLoading"></edx-spinner>
       <edx-list-item-preview *ngIf="hitPreviewData" [data]="hitPreviewData.runs"></edx-list-item-preview>
      </div>
    </div>
  </div>
</div>
