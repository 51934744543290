import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, NgZone } from '@angular/core';

import { SelectItem } from '../models/form-field';
import { DataFile } from '../models/file-form-info';
import { ListData } from '../models/base';
import { ListItem } from '../models/list-item';
import { FileFormInfo } from '../models/file-form-info';
import { LocalizeService } from '../services/localize.service';
import { Util } from '../utils/utils.module';
import { SegmentComponent } from './segment.component';
import { AppComponent } from '../app.component';

declare let Office: any;
declare let Word: any;
declare let Excel: any;
const searchForTestDoc = false;

@Component({
  selector: 'edx-search-office',
  styleUrls: ['search-mobile.component.scss', 'search-office.component.scss'],
  template: `
    <div class="contentpanel oai" [edx-file-drop-target]="this">
      <div *ngIf="!isOfficeAddinOutlookMessageEditable" class="officerow" id="edx_search_outlook">
        <div class="officebar">
          <img class="headericon" src="{{officeItemIcon()}}">
          <div class="text wrap" title="{{officeItemTitle}}">{{officeItemName() || this.localizer.getTranslation('DOC_STATUS.NOTAVILABLE')}}</div>
          <button *ngIf="showSaveButton()" class="addbtn" [ngClass]="{readonly:!addEnabled && !officeItem}" (click)="saveButtonClicked($event)">{{saveButtonTitle()}}</button>
        </div>
        <div class="finding" [ngClass]="{edx_hidden:headerCollapsed}">
          <ng-template [ngIf]="!findingOfficeItem && ((!!officeItemOptionItems || !isOfficeAddinOutlook) && !officeItem)">
            <div class="text">{{this.localizer.getTranslation((!!officeItemAttachments || isOfficeAddinOutlook) ? 'FORMS.LOCAL.OAI_ADD.ADD_MSG' : 'FORMS.LOCAL.OAI_ADD.ADD_DOC_AS')}}</div>
            <edx-select *ngIf="(!!officeItemOptionItems || !isOfficeAddinOutlook)" id="edx_message_options" [items]="officeItemOptionItems" [value]="officeItemOption" [inDialog]="true" (change)="officeItemOptionChanged($event)"></edx-select>
          </ng-template>
          <div *ngIf="findingOfficeItem" class="spincontainer">
            <edx-spinner [mini]="true"></edx-spinner>
          </div>
        </div>
        <ng-template [ngIf]="!findingOfficeItem && !!officeItem">
          <div class="officerow list" (click)="profileClick('profile')">
            <div class="icon profile"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.PROFILE')}}</div>
          </div>
          <div class="officerow list" (click)="profileClick('preview')">
            <div class="icon preview"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.PREVIEW')}}</div>
          </div>
          <div *ngIf="bravaEnabled" class="officerow list" (click)="profileClick('viewer')">
            <div class="icon preview"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.VIEWER')}}</div>
          </div>
          <div class="officerow list" (click)="profileClick('versions')">
           <div class="icon versions"></div>
           <div class="text">{{this.localizer.getTranslation('METADATA.TABS.VERSIONS')}}</div>
          </div>
          <div class="officerow list" (click)="profileClick('history')">
            <div class="icon history"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.HISTORY')}}</div>
          </div>
          <div class="officerow list" (click)="profileClick('whereused')">
            <div class="icon whereused"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.WHERE_USED')}}</div>
          </div>
          <div class="officerow list" (click)="profileClick('related')">
            <div class="icon whereused"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.RELATED')}}</div>
          </div>
          <div class="officerow list" (click)="profileClick('attachments')">
            <div class="icon whereused"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.ATTACHMENTS')}}</div>
          </div>
          <div class="officerow list" (click)="profileClick('security')">
            <div class="icon security"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.SECURITY')}}</div>
          </div>
        </ng-template>
      </div>
    </div>
  `
})
export class SearchOfficeComponent implements OnInit, OnDestroy {
  public isOfficeAddinOutlookMessageEditable: boolean;
  public dataFiles: DataFile[] = null;
  public desc: any = null;
  public formData: any = null;
  public trustees: any[] = null;
  private app: AppComponent = Util.RestAPI.getAppComponent();
  private bravaEnabled: boolean;
  private isOfficeAddinOutlook: boolean;
  private findingOfficeItem = false;
  private officeItem: ListItem = null;
  private officeItemFileName: string;
  private officeItemFilePath: string;
  private officeItemTitle: string;
  private officeItemVersionLabel: string;
  private officeItemExtension: string;
  private officeItemAttachments: any[] = null;
  private officeItemOption: string = null;
  private officeItemOptionItems: SelectItem[] = null;
  private officeItemChangedEventID = 0;
  private officePropertiesError: any = null;
  @Output() addToDMClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() gotOfficeDataFiles: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() descDataFilesChanging: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() addEnabled?: boolean = false;
  @Input() headerCollapsed?: boolean = false;
  @Input('docName') set setter(value: string) {
    if (!!value) {
      this.officeItemTitle = value;
      if (!!this.dataFiles && (Util.Device.bIsOfficeAddinExcel || Util.Device.bIsOfficeAddinPowerPoint)) {
        const fileNameParts = this.dataFiles[0].name.split('.');
        fileNameParts[0] = fileNameParts[0] || value;
        this.dataFiles[0].name = fileNameParts.join('.');
      }
    }
  }

  constructor(public localizer: LocalizeService, protected zone: NgZone) {
  }

  ngOnInit(): void {
    this.bravaEnabled = Util.RestAPI.isBravaEnabled();
    this.isOfficeAddinOutlook = Util.Device.bIsOfficeAddin && !!Office && !!Office.context && !!Office.context.mailbox;
    this.isOfficeAddinOutlookMessageEditable = Util.Device.bIsOfficeAddinOutlookMessageEditable;
    this.officeItemChangedEventID = Util.RestAPI.registerOfficeItemChangedEvent(this.startFindOfficeItem.bind(this));
    this.officeItemExtension = this.isOfficeAddinOutlook ? 'msg' : Util.Device.bIsOfficeAddinWord ? 'docx' : Util.Device.bIsOfficeAddinExcel ? 'xlsx' : Util.Device.bIsOfficeAddinPowerPoint ? 'pptx' : '';
    setTimeout(() => {
      this.startFindOfficeItem();
    }, 500);
  }

  ngOnDestroy(): void {
    this.gotOfficeDataFiles.emit(false);
    Util.RestAPI.deRegisterOfficeItemChangedEvent(this.officeItemChangedEventID);
  }

  private showSaveButton(): boolean {
    if (!this.findingOfficeItem && (!this.officeItem && this.addEnabled) || (!!this.officeItem/* && !Util.Device.bIsOfficeAddinOutlook*/)) {
      return true;
    }
    return false;
  }

  private saveButtonTitle(): string {
    return this.localizer.getTranslation('FORMS.BUTTONS.' + (!!this.officeItem ? 'SAVE_AS' : 'SAVE'));
  }

  private extractTrusteesFromProfileData(profileData: any): any[] {
    let trustees: any[] = null;
    if (!!profileData['edx_trustees']) {
      trustees = profileData['edx_trustees'];
      delete profileData['edx_trustees'];
    } else {
      this.trustees = null;
    }
    return trustees;
  }

  private startFindOutlookItem(): void {
    const outlookMailbox = Util.Device.bIsOfficeAddin && Office && Office.context ? Office.context.mailbox : null;
    const outlookItem = outlookMailbox ? outlookMailbox.item : null;
    const notFound = () => {
      Util.RestAPI.getOutlookItemProfileData(outlookMailbox, null, true).then(profileData => {
        const nAttach: number = outlookItem.attachments ? outlookItem.attachments.length : 0;
        const fileName: string = this.officeItemTitle+'.msg';
        this.officeItemAttachments = outlookItem.attachments && outlookItem.attachments.length > 0 ? outlookItem.attachments: null;
        this.dataFiles = [{name:fileName, url:'promise_msg', attachNum:-1}];
        this.desc = {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:fileName};
        this.trustees = this.extractTrusteesFromProfileData(profileData);
        this.formData = profileData;
        if (this.officeItemAttachments)  {
          this.officeItemOptionItems = [];
          this.officeItemOptionItems.push({value: 'M', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.MSG_ATTACHMENTS')});
          if (!!Office.context.mailbox.restUrl) {
            this.officeItemOptionItems.push({value: 'E', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.EXCLUDE_ATTACHMENTS')});
          }
          this.officeItemOptionItems.push({value: 'ALL', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.ALL')});
          this.officeItemOption = 'M';
          for (let i=0; i < nAttach; i++) {
            const att = this.officeItemAttachments[i];
            this.officeItemOptionItems.push({value: i.toString(), display: att.name || att.Name});
          }
        } else if (!!Office.context.mailbox.restUrl) {
          this.officeItemOptionItems = [{ value: 'E', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.EXCLUDE_ATTACHMENTS') }];
          this.officeItemOption = 'E';
        }
        this.gotOfficeDataFiles.emit(true);
        this.findingOfficeItem = false;
      });
    };
    const findNewItem = () => {
      this.formData = null;
      this.dataFiles = null;
      this.officeItemOption = null;
      this.gotOfficeDataFiles.emit(false);
      if (outlookItem && outlookItem.internetMessageId) {
        this.findingOfficeItem = true;
        this.officeItem = null;
        Util.Device.officeDMItem = null;
        this.officeItemTitle = outlookItem.subject;
        Util.RestAPI.getCurrentOutlookExistingListItem(outlookItem).then(listItem => {
          this.officeItem = listItem;
          Util.Device.officeDMItem = this.officeItem;
          this.findingOfficeItem = false;
        }, notFound);
      } else {
        notFound();
      }
    };
    if (!!outlookMailbox) {
      this.zone.run(findNewItem);
    }
  }

  private startFindOfficeItem(): void {
    let forcedSave = false;
    const notFound = () => {
      this.officeItemTitle = this.officeItemTitle || '';
      let nameParts: string[] = this.officeItemTitle.split('.');
      if (nameParts.length > 1) {
        nameParts.splice(nameParts.length-1);
      }
      this.officeItemTitle = nameParts.join('.');
      if (this.officeItemTitle.startsWith('http')) {
        nameParts = this.officeItemTitle.split('/');
        this.officeItemTitle = nameParts[nameParts.length-1];
      }
      this.findingOfficeItem = false;
      this.officeItemExtension = Util.Device.bIsOfficeAddinWord ? 'docx' : Util.Device.bIsOfficeAddinExcel ? 'xlsx' : Util.Device.bIsOfficeAddinPowerPoint ? 'pptx' : '';
      if (!!Office.context.document) {
        this.dataFiles = [{name:this.officeItemTitle + '.' + this.officeItemExtension, officeDoc:Office.context.document, url:this.officeItemFilePath}];
      } else {
        this.dataFiles = [{name:this.officeItemTitle + '.docx', b64Data:btoa('A fake document')}];
      }
      this.desc = {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:this.dataFiles[0].name};
      this.officeItemOptionItems = [{ value: 'D', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.DEFAULT') }];
      if (!this.officePropertiesError && (Util.Device.bIsOfficeAddinDesktop || Util.Device.bIsOfficeAddinWord)) {
        this.officeItemOptionItems.push({ value: 'P', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.PDF') });
      }
      if (Util.Device.bIsOfficeAddinWord) {
        const appID: string = Util.RestAPI.getAppIDForFile('tmp.txt', null);
        if (!!appID) {
          this.officeItemOptionItems.push({ value: 'T', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.TEXT') });
        }
      }
      this.officeItemOption = 'D';
      Util.RestAPI.getFormInfoForFile(this.dataFiles[0].name, Util.RestAPI.getPrimaryLibrary()).then((ffi: FileFormInfo) => {
        const formName = !!ffi.defForm ? ffi.defForm : Util.RestAPI.getDefaultProfileForm().id;
        const formIndex: number = ffi.forms.indexOf(formName);
        if (formIndex >= 0) {
          this.formData = Util.deepCopy(ffi.profile_defaults[formIndex]);
          this.formData['FORMNAME'] = formName;
          this.trustees = ffi.trustees[formIndex];
        }
        this.gotOfficeDataFiles.emit(true);
      });
      if (!!this.officeItem && forcedSave) {
        Util.RestAPI.monitorFile(this.dataFiles[0].name, this.dataFiles[0].url);
      }
    };
    const searchForDoc = (docNum: string, lib: string): void => {
      const searchCriteria: any = {};
      const criteria: any = {};
      const searchForms: any[] = Util.RestAPI.getSearchForms();
      if (searchForms && searchForms.length) {
        searchCriteria['FORM_NAME'] = searchForms[0]['%FORM_NAME'];
      }
      searchCriteria['DESCRIPTION'] = docNum;
      criteria['DOCNUM'] = docNum;
      searchCriteria['criteria'] = criteria;
      this.officeItem = null;
      Util.Device.officeDMItem = null;
      this.findingOfficeItem = true;
      Util.RestAPI.post({id:'evaluation',lib,type:'searches'},searchCriteria,'','libs='+lib+'&start=0&max=1&ascending=SYSTEM_ID').subscribe((listData: ListData) => {
        if (!!listData && !!listData.list && listData.list.length) {
          this.officeItem = listData.list[0];
          Util.Device.officeDMItem = this.officeItem;
          this.findingOfficeItem = false;
          this.desc = null;
        } else {
          notFound();
        }
      });
    };
    const getFileNameDocProperty = async (done) => {
      try {
        await Office.context.document.getFilePropertiesAsync(async asyncResult => {
          let officeFilename: string;
          if (!!asyncResult && !!asyncResult.value && !!asyncResult.value.url) {
            const sep: string = (Util.Device.bIsWindows && !asyncResult.value.url.startsWith('http')) ? '\\' : '/';
            const components: string[] = asyncResult.value.url.split(sep);
            officeFilename = components[components.length-1];
            this.officeItemFilePath = asyncResult.value.url;
          } else if (!!asyncResult) {
            this.officePropertiesError = asyncResult.error;
          }
          let edxFileName: string = null;
          if (!!Office.context.document.settings) {
            edxFileName = Office.context.document.settings.get('edx_filename');
          }
          done(officeFilename, edxFileName);
        });
      } catch (e) {
        done();
      }
    };
    const afterSave = async () => {
      await getFileNameDocProperty(async (officeFilename: string, edxFileName: string) => {
        const fileName = edxFileName ? edxFileName : officeFilename;
        const isManagedFile: boolean = !!fileName && fileName.indexOf('-#')!==-1 && fileName.indexOf('-v')!==-1;
        this.zone.run(() => {
          this.officeItemTitle = this.officeItemFileName = fileName;
          this.officeItemVersionLabel = null;
          if (isManagedFile) {
            const docObj: any = Util.decodeFileName(fileName);
            if (!!docObj) {
              this.officeItemTitle = docObj.name;
              this.officeItemVersionLabel = docObj.vers;
              searchForDoc(docObj.docNum, docObj.lib);
            } else {
              notFound();
            }
          } else {
            notFound();
          }
        });
      });
    };
    const startLooking = async () => {
      if (!!Office.context.mailbox) {
        this.startFindOutlookItem();
      } else if (!!Office.context.document) {
        try {
          if (Util.Device.bIsOfficeAddinWord) {
            await Word.run(async (context) => {
              const builtInProperties = context.document.properties;
              builtInProperties.load('security');
              await context.sync();
              // security 0 = File on disk is read/write or undefined is not set so save it
              if (builtInProperties.security === 0 || !builtInProperties.security) {
                context.document.save();
                forcedSave = true;
                await context.sync();
              }
              afterSave();
            });
          } if (Office.context.requirements.isSetSupported('ExcelApi', '1.11')) {
            await Excel.run(async (context) => {
              const builtInProperties = context.workbook.properties;
              builtInProperties.load('readOnly');
              await context.sync();
              // security 0 = File on disk is read/write or undefined is not set so save it
              if (!builtInProperties.readOnly) {
                context.workbook.save(Excel.SaveBehavior.save);
                forcedSave = true;
                await context.sync();
              }
              afterSave();
            });
          } else {
            afterSave();
          }
        } catch (e) {
          notFound();
        }
      } else {
        // test code
        if (searchForTestDoc) {
          searchForDoc('7650', 'RHQA167-Legal');
        } else {
          this.officeItemTitle = 'Fake';
          this.officeItemFileName = 'fake.docx';
          notFound();
        }
      }
    };
    const waitForCache = () => {
      if (Util.RestAPI.cacheIsLoaded() && !!Util.RestAPI.getTiles() && Util.RestAPI.getTiles().length > 0) {
        startLooking();
      } else {
        setTimeout(() => {
          waitForCache();
        }, 500);
      }
    };
    if (Util.Device.bIsOfficeAddin && !!Office && !!Office.context) {
      this.officeItemTitle = null;
      this.officeItemFileName = null;
      this.officeItemAttachments = null;
      this.dataFiles = null;
      this.findingOfficeItem = true;
      this.officePropertiesError = null;
      this.gotOfficeDataFiles.emit(false);
      waitForCache();
    }
  }

  private officeItemName(): string {
    return this.findingOfficeItem ? '...' : this.officeItem ? this.officeItem['DOCNAME'] : this.officeItemTitle;
  }

  private officeItemIcon(): string {
    const iconPath = 'assets/images/';
    let icon: string;
    if (window.hasOwnProperty('Word')) {
      icon = 'mime_word.svg';
    } else if (window.hasOwnProperty('Excel')) {
      icon = 'mime_excel.svg';
    } else if (window.hasOwnProperty('PowerPoint')) {
      icon = 'mime_powerpoint.svg';
    } else {
      icon = 'mime_outlook.svg';
    }
    return !this.findingOfficeItem && this.officeItem ? Util.Transforms.iconUrlFromDesc(this.officeItem) : (iconPath + icon);
  }

  private officeItemIconAdd(): boolean {
    return !this.findingOfficeItem && !this.officeItem && !!this.officeItemTitle;
  }

  private officeItemIconProfile(): boolean {
    return !this.findingOfficeItem && !!this.officeItem;
  }

  private profileClick(tab: string): boolean {
    if (!this.findingOfficeItem) {
      if (this.officeItem) {
        this.app.doCommand('profile_outlook_item', {list:this.officeItem,tab});
      } else if (!!Office &&!!Office.context) {
        if (!!Office.context.mailbox) {
          this.app.doCommand('uploadofficeitem');
        } else if (Office.context.document) {
          Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 }, result => {
            if (result.status === 'succeeded') {
              this.zone.run(() => {
                const file = result.value;
                const fileSize: number = file.size * 2; // this is only for progress and we are base64 encoding so double the size
                file.closeAsync();
                Util.RestAPI.uploadFilesWithUI(null, null, [{name: this.officeItemTitle, size: fileSize, officeDoc: Office.context.document}]);
              });
            }
          });
        }
      }
    }
    return true;
  }

  private officeItemOptionChanged(seg: SegmentComponent): void {
    const updateDataFiles = (newDataFiles: DataFile[], newDesc: any, newFormData: any) => {
      this.descDataFilesChanging.emit(true);
      setTimeout(() => {
        this.dataFiles = newDataFiles;
        this.desc = newDesc;
        if (newFormData) {
          this.trustees = this.extractTrusteesFromProfileData(newFormData);
          this.formData = newFormData;
          this.gotOfficeDataFiles.emit(false);
          setTimeout(() => {
            // destroy the profile form then reload it with the new data
            this.gotOfficeDataFiles.emit(true);
          }, 300);
        } else {
          this.gotOfficeDataFiles.emit(true);
        }
      }, 1);
    };
    if (this.isOfficeAddinOutlook) {
      Util.RestAPI.getOutlookItemProfileData(Office.context.mailbox, null, true).then(profileData => {
        this.officeItemOption = seg.value;
        let attIndex: number = null;
        let descName: string = this.officeItemTitle;
        switch (this.officeItemOption) {
        case 'M':
        case 'E':
          this.dataFiles = [{name:this.officeItemTitle+'.msg', url:'promise_msg', attachNum:-1, excludeAttachments:this.officeItemOption==='E'}];
          this.desc = {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:descName};
          break;
        case 'ALL':
          attIndex = -1;
          break;
        default:
          attIndex = parseInt(this.officeItemOption);
          break;
        }
        if (attIndex !== null && this.officeItemAttachments) {
          let attData: any;
          const dataFiles: DataFile[] = [];
          const nAttachments: number = this.officeItemAttachments.length;
          if (attIndex>=0) {
            attData = this.officeItemAttachments[attIndex];
            descName = attData.Name || attData.name;
            profileData['DOCNAME'] = descName;
            dataFiles.push({name:descName, url:'promise_'+attIndex, attachNum:attIndex});
          } else {
            for (let i=0; i<nAttachments; i++) {
              attData = this.officeItemAttachments[i];
              dataFiles.push({name:attData.Name||attData.name, url:'promise_'+i, attachNum:i});
            }
          }
          updateDataFiles(dataFiles, {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:descName}, profileData);
        }
      });
    } else {
      this.officeItemOption = seg.value;
      switch (this.officeItemOption) {
      case 'D':
        this.officeItemExtension = Util.Device.bIsOfficeAddinWord ? 'docx' : Util.Device.bIsOfficeAddinExcel ? 'xlsx' : Util.Device.bIsOfficeAddinPowerPoint ? 'pptx' : '';
        break;
      case 'T':
        this.officeItemExtension = Util.Device.bIsOfficeAddinWord ? 'txt' : '';
        break;
      case 'P':
        this.officeItemExtension = 'pdf';
        break;
      }
      updateDataFiles([{name:this.officeItemTitle+'.' + this.officeItemExtension, officeDoc: Office.context.document}], {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:this.officeItemExtension}, null);
    }
  }

  private saveButtonClicked(event: Event): void {
    const fileName: string = this.officeItemTitle + (this.officeItemTitle.endsWith(this.officeItemExtension) ? '' : ('.' + this.officeItemExtension));
    if (!this.officeItem) {
      this.addToDMClicked.emit(true);
    } else {
      const title = this.localizer.getTranslation('FORMS.BUTTONS.SAVE_AS');
      Util.Notify.confirm(title,'__local_save_as',null,{list:[this.officeItem]},true,true,true).then(confirmed => {
        if (confirmed && confirmed.confirm) {
          const radioVal: string = confirmed.data['$edx_save_as_radio'];
          const comment: string = confirmed.data['COMMENT'];
          const dataFile: DataFile = this.isOfficeAddinOutlook ? {name:fileName, url:'promise_msg'} : {name:(this.officeItemFileName||fileName), officeDoc:Office.context.document, url:this.officeItemFilePath};
          const vers: string = this.officeItemVersionLabel || 'C';
          const formData: any = !!comment ? {COMMENT:comment} : {};

          const doUploadNewVers = (aVers: string): void => {
            formData['_restapi'] = {src:{ver_label:vers,id:this.officeItem.id,type:this.officeItem.type,lib:this.officeItem.lib}};
            Util.RestAPI.uploadDataFiles(this.officeItem, formData, [dataFile], false, aVers, null);
          };
          const cancelPFTAThenUpload = (aVers: string): void => {
            if (Util.Device.bIsOfficeAddinWord || Util.Device.bIsOfficeAddinExcel || Util.Device.bIsOfficeAddinPowerPoint) {
              Util.RestAPI.cancelMonitorFile(this.officeItemFileName, this.officeItemFilePath).then((res) => {
                if (!!aVers) {
                  this.officeItemVersionLabel = aVers;
                }
                doUploadNewVers(aVers);
              }, () => {
                doUploadNewVers(aVers);
              });
            } else {
              doUploadNewVers(aVers);
            }
          };
          switch (radioVal) {
          case 'RV':
            Util.RestAPI.uploadDataFiles(this.officeItem, formData, [dataFile], true, vers);
            break;
          case 'NV':
            cancelPFTAThenUpload(null);
            break;
          case 'NSV':
            cancelPFTAThenUpload(vers);
            break;
          case 'ND':
            const docObj: any = Util.decodeFileName(dataFile.name);
            if (!!docObj) {
              dataFile.name = docObj.name + (!!docObj.ext ? '.' + docObj.ext : '');
            }
            if (this.isOfficeAddinOutlook) {
              Util.RestAPI.getOutlookItemProfileData(Office.context.mailbox, null, true).then((data: any) => {
                this.extractTrusteesFromProfileData(data);
                Util.RestAPI.uploadFilesWithUI(null, null, [dataFile], null, data);
              }, e => {
                Util.RestAPI.uploadFilesWithUI(null, null, [dataFile]);
              });
            } else {
              dataFile.fromSaveAsDesc = Util.deepCopy(this.officeItem);
              const addNewDoc = () => {
                dataFile.fromSaveAsDesc.STATUS = '0';
                dataFile.fromSaveAsDesc.vers = docObj.vers;
                Util.RestAPI.get(this.officeItem, 'profile').subscribe((data: any) => {
                  Util.RestAPI.uploadFilesWithUI(null, null, [dataFile], null, data);
                }, e => {
                  Util.RestAPI.uploadFilesWithUI(null, null, [dataFile]);
                });
              };
              const unlockOldDoc = () => {
                Util.RestAPI.put(this.officeItem, {'%STATUS':'%UNLOCK'}, 'profile', null, {observe:'response'}).subscribe(addNewDoc, addNewDoc);
              };
              Util.RestAPI.cancelMonitorFile(this.officeItemFileName, this.officeItemFilePath).then(unlockOldDoc, unlockOldDoc);
            }
            break;
          }
        }
      });
    }
    if (!!event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
