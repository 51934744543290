import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy, OnChanges, SimpleChange, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { WindowModalHeaderComponent } from './window-modal-header.component';
import { WindowModalMetadataComponent } from './window-modal-metadata.component';
import { SchemaDef, SchemaService } from '../services/schema.service';
import { ListTableComponent, ListTableParent } from '../lists/list-table.component';
import { FilterTarget, FilterSidebarComponent } from '../forms/filter-sidebar.component';
import { ActionBarComponent } from '../widgets/action-bar.component';
import { MenuComponent } from '../widgets/menu.component';
import { PaginatorComponent } from '../widgets/paginator.component';
import { ListItem } from '../models/list-item';
import { Tile } from '../models/tile';
import { BaseDesc, ListData } from '../models/base';
import { MenuItem } from '../models/menu-item';
import { ColumnDesc, ColFormat } from '../models/column';
import { CommandHandler, MenuItemSetter } from '../models/command-handler';
import { LocalizeService } from '../services/localize.service';
import { TileService } from '../services/tile.service';
import { Util, UserInterface } from '../utils/utils.module';
import { AppComponent } from '../app.component';
import { SecurityControl, AccessLevel, AccessRights, AccessSearch, DynamicViewRights } from '../models/security-control';
import { DataService } from '../services/data.service';
import { MenuService, MenuDef, MenuId } from '../services/menu.service';

declare let Office;

@Component({
  selector: 'edx-window-view-folders',
  styleUrls: ['window-modal.component.scss', 'window-modal-folders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <edx-window-modal-header *ngIf="ui<2" [parent]="this" [title]="name" [desc]="desc" [menuID]="menuID" [filtered]="filtered" [rightBtn]="showSaveSearch?saveSearchStr:null" [rerender]="rerender" (rightHdrBtnClick)="rightHdrBtnClick()" id="edx_list_header"></edx-window-modal-header>
    <div class="main" [ngClass]="{web: ui===0, desktop: ui===1, mobile: ui!==0 && ui!==1, tablet: ui===3||ui===5}">
      <div *ngIf="ui<2" class="sidebar" [ngClass]="{open:isFilterOpen(), opening:isFilterOpening, closing: isFilterClosing}" (animationend)="filterAnimationComplete()">
        <edx-filter-sidebar *ngIf="showFilter" [target]="this" id="edx_filter_side_panel"></edx-filter-sidebar>
      </div>
      <ng-template [ngIf]="ui<2">
        <div class="body">
          <edx-action-bar [menuID]="actionMenuId" [target]="this" id="edx_list_action_menu" class="actionbar"></edx-action-bar>
          <div class="listcontainer" id="edx_list_container">
            <edx-list-table *ngIf="viewMode==='grid'" #folderList [desc]="desc" [inlineActionMenuId]="6" [inlineActionTarget]="this" [parent]="this" [paginator]="paginator"></edx-list-table>
            <edx-list-summary *ngIf="viewMode==='summary'" #folderList [desc]="desc" [inlineActionMenuId]="6" [inlineActionTarget]="this" [parent]="this" [paginator]="paginator"></edx-list-summary>
          </div>
          <edx-paginator *ngIf="!!paginatorMenuId" [menuID]="paginatorMenuId" [target]="folderList" id="edx_footer_pagination" class="paginator"></edx-paginator>
        </div>
      </ng-template>
      <ng-template [ngIf]="ui>=2">
        <edx-list-mobile #folderList [desc]="desc" [viewKind]="!!docView?1:0" [filters]="filterKeys" [hasHeader]="true" [hasFooter]="!docView && !this.noneSelected" [splitView]="!!docView" [inlineActionMenuId]="6" [inlineActionTarget]="this" [parent]="this" (actionHandler)="actionHandler($event)"></edx-list-mobile>
        <edx-action-bar *ngIf="!docView && !this.noneSelected" [menuID]="actionMenuId" [target]="this" [menuTitle]="actionMenuTitle" [menuIcon]="actionMenuIcon" id="edx_list_action_menu"></edx-action-bar>
        <edx-filter-sidebar *ngIf="showFilter" [target]="this"></edx-filter-sidebar>
      </ng-template>
    </div>
  `
})
export class WindowViewFoldersComponent implements OnDestroy, OnChanges, FilterTarget, CommandHandler, ListTableParent {
  @Input() desc: BaseDesc;
  @Input() name: string;
  @Input() docView?: WindowModalMetadataComponent;
  @ViewChild(FilterSidebarComponent) private filters: FilterSidebarComponent;
  @ViewChild(ActionBarComponent) private actionBar: ActionBarComponent;
  @ViewChild('folderList') private folderList: ListTableComponent;
  @ViewChild(PaginatorComponent) private paginator: PaginatorComponent;
  @ViewChild(WindowModalHeaderComponent) private modalHeader: WindowModalHeaderComponent;
  public ui: UserInterface;
  public rerender = 0;
  private appComponent: AppComponent = null;
  private actionMenuId: MenuId = MenuId.MENU_LIST_MAIN;
  private paginatorMenuId: MenuId;
  private selections: ListItem[] = null;
  private selectionRights: SecurityControl = new SecurityControl();
  private noneSelected = true;
  private menuID = 0;
  private defaultCols: ColumnDesc[] = null;
  private schemaChanged = false;
  private configRedChanged = false;
  private filtered = false;
  private showFilter = false;
  private isFilterOpening = false;
  private isFilterClosing = false;
  private filterKeys: string;
  private actionMenuTitle: string;
  private actionMenuIcon: string;
  private mobileActionMenuID: number = Util.Device.bIsOfficeAddin ? MenuId.MENU_OAI_ACTION_LIST : MenuId.MENU_MOBILE_ACTION_LIST;
  private viewMode = 'grid';
  private showSaveSearch = false;
  private changedViewMode = false;
  private lastCmd = null;
  private saveSearchStr: string;

  constructor(private cdr: ChangeDetectorRef, private localizer: LocalizeService, private tileService: TileService, private schemaService: SchemaService, private dataService: DataService, private menuService: MenuService) {
    this.ui = Util.Device.ui;
    this.appComponent = Util.RestAPI.getAppComponent();
    this.saveSearchStr = this.localizer.getTranslation('FORMS.LOCAL.SAVE_SEARCH.SAVE_SEARCH');
  }

  ngOnDestroy() {
    this.appComponent.setActionMenu(null, -1, null, null);
    if (!!this.folderList) {
      this.dataService.setListState(this.desc, this.folderList.getListState());
      if (this.folderList.parent === this) {
        this.folderList.parent = null;
      }
    }
    if (this.filters) {
      const filterWasOpen: boolean = this.filters.isFilterOpen();
      const autoOpenFilterStr: string = Util.RestAPI.getPreference('$edx_auto_filter');
      const autoOpenFilter = !!autoOpenFilterStr ? JSON.parse(autoOpenFilterStr) : {};
      let changed = false;
      if (!!autoOpenFilter[this.desc.type] && !filterWasOpen) {
        delete autoOpenFilter[this.desc.type];
        changed = true;
      } else if (!autoOpenFilter[this.desc.type] && filterWasOpen) {
        autoOpenFilter[this.desc.type] = true;
        changed = true;
      }
      if (changed) {
        Util.RestAPI.setPreference('$edx_auto_filter', JSON.stringify(autoOpenFilter));
      }
    }
    if (this.changedViewMode) {
      Util.RestAPI.setPreference('$edx_view_mode', this.viewMode);
    }
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    const chng: SimpleChange = changes['desc'];
    const loginReply: any = Util.RestAPI.getLoginReply();
    if (!!chng && !!chng.previousValue) {
      if (!!this.folderList) {
        this.dataService.setListState(chng.previousValue, this.folderList.getListState());
      }
      if (this.changedViewMode) {
        Util.RestAPI.setPreference('$edx_view_mode', this.viewMode);
        this.changedViewMode = false;
      }
    }
    const autoOpenFilterStr = Util.RestAPI.getPreference('$edx_auto_filter');
    const autoOpenFilter = !!autoOpenFilterStr ? JSON.parse(autoOpenFilterStr) : {};
    if (!!autoOpenFilter[this.desc.type]) {
      setTimeout(() => {
        const showFiltersEnabled = this.commandEnabled('showfilter');
        if (showFiltersEnabled && !this.isFilterOpen()) {
          setTimeout(() => {
            this.doCommand('showfilter');
          }, 1);
        }
      }, 1);
    }
    if ((this.desc.id && this.desc.id !== 'templates' && this.desc.id !== 'all' && this.desc.id !== 'deleted' && this.desc.id !== 'recentedits' && this.desc.id !== 'checkedout' && this.desc.id !== 'public' && !this.desc.id.startsWith('evaluation')) || (this.desc.id === '' && this.desc.type === 'fileplans')) {
      if (!this.docView) {
        // if we are in a split view do not wack the docView item with our list desc
        const curItem: ListItem = new ListItem(this.desc);
        if (!curItem.DOCNAME) {
          curItem.DOCNAME = this.name;
        }
        Util.RestAPI.setCurItem(curItem);
      }
      this.updateMobileMenu();
      this.menuID = MenuId.MENU_LIST_WINDOW;
      this.actionMenuTitle = this.name;
      this.actionMenuIcon = Util.Transforms.iconUrlFromDesc(this.desc);
      this.appComponent.setActionMenu(this, this.mobileActionMenuID, this.actionMenuTitle, this.actionMenuIcon);
    } else {
      this.updateMobileMenu();
      this.menuID = 0;
    }
    if (loginReply.SEARCH_CAPABILITIES && loginReply.SEARCH_CAPABILITIES.SUMMARY === 'Y') {
      const viewModeStr = Util.RestAPI.getPreference('$edx_view_mode');
      const changeMode = (newMode: string): void => {
        this.viewMode = newMode;
        setTimeout(() => {
          if (this.actionBar) {
            this.actionBar.setCmdValue('view_mode_', this.viewMode);
          }
        }, 1);
      };
      if (viewModeStr !== this.viewMode) {
        if (!viewModeStr && this.desc.type === 'searches' && !!this.desc.id && this.viewMode !== 'summary') {
          changeMode('summary');
        } else if (!!viewModeStr) {
          if ((viewModeStr === 'summary' && Util.canHaveSummary(this.desc)) || viewModeStr !== 'summary') {
            changeMode(viewModeStr);
          }
        }
      }
    }
    if (!this.desc['DOCNAME'] && !!this.name && this.desc.type !== 'activities') {
      // so lists will have it and then the copy dialog will have a name to copy to. Whatever... shoot me.
      // I got shot. Came back to bite may ass. My own shortcut.
      // OK so DO NOT do this if we are an activity list.
      this.desc['DOCNAME'] = this.name;
    }
    if (this.ui >= 2) {
      this.actionMenuId = -1;
      const waitForReload = () => {
        setTimeout(() => {
          if (this.folderList && !this.folderList.isReloading()) {
            this.showFilter = this.canHaveFilters();
            this.cdr.markForCheck();
          } else {
            waitForReload();
          }
        }, 100);
      };
      waitForReload();
    }
    this.selectionRights = new SecurityControl(); // reset
    let hidePaginator = false;
    if (this.desc.type === 'activities') {
      const parts = this.desc.id.split('-');
      if (isNaN(parseInt(parts[parts.length-1]))) {
        hidePaginator = true;
      }
    }
    if (Util.isExternalLib(this.desc.lib) || hidePaginator) {
      this.paginatorMenuId = undefined;
    } else {
      this.paginatorMenuId = MenuId.MENU_PAGINATOR;
    }
    this.cdr.markForCheck();
  }

  public refresh(cmd?: string, value?: any): void {
    if (this.folderList) {
      this.folderList.reloadList();
    }
    switch (this.lastCmd) {
      case 'doc_removereadonly':
        this.desc['READONLY'] = 'N';
        this.desc['STATUS'] = '0';
        break;
      case 'doc_readonly':
        this.desc['READONLY'] = 'Y';
        this.desc['STATUS'] = '19';
        break;
    }
  }

  // **** ListTableParent implementation
  public listUpdated(table: ListTableComponent): void {
    if (this.folderList && this.folderList === table) {
      const list: ListItem[] = this.folderList.getList();
      const set: any = this.folderList.getSet();
      this.folderList.clearSelection();// Clear the selection, so that the acive menus can be updated.
      this.selections = [];
      this.noneSelected = true;
      this.calculateSelectionRights();
      if (this.actionBar) {
        this.actionBar.updateActions();
      }
      if (this.desc['READONLY'] === undefined || this.desc['STATUS'] === undefined) {
        if (!!set.readonly) {
          this.desc['READONLY'] = set.readonly;
        }
        if (!!set.status) {
          this.desc['STATUS'] = set.status;
        }
      }
      ++this.rerender;
      setTimeout(() => {
        if (this.filters && this.showFilter) {
          if (this.filters.getAndSetFormData() && !this.filtered) {
            this.filtered = true;
            this.filterKeys = this.filters.getFilterList();
            this.cdr.markForCheck();
          }
        } else if (this.filtered) {
          this.filtered = false;
          this.filterKeys = null;
          this.cdr.markForCheck();
        }
        ++this.rerender;
      }, 300);
      if (this.desc.id.startsWith('evaluation') && list && list.length > 0) {
        const searchData: any = this.dataService.getSearchData(this.desc);
        this.showSaveSearch = false;
        if (searchData && !!searchData.criteria && (!!searchData.DESCRIPTION || this.filtered)) {
          if (Object.keys(searchData.criteria).length === 0) {
            searchData.criteria['FULLTEXT_CONTENT_PROFILE'] = '';
          }
          Util.RestAPI.conformFulltextCriteria(searchData.criteria);
          this.showSaveSearch = this.commandEnabled('savesearch');
          if ((Util.RestAPI.restAPIVersion() >= 0x00160700) && searchData.criteria && !searchData['UNSAVED'] && !searchData.criteria['%FOLDER_ID'] && set.search.type !== 'activity') {
            searchData['UNSAVED'] = 'Y';
            Util.RestAPI.post('searches', searchData).subscribe((data: any) => {
              const oldDesc = { lib: this.desc.lib, id: this.desc.id, type: this.desc.type };
              this.desc.id = String(data.list.id);
              this.desc['UNSAVED'] = 'Y';
              if (!!data.list.name && !data.list.name.startsWith('FULLTEXT_CONTENT_PROFILE')) {
                this.name = this.desc['DOCNAME'] = data.list.name;
              }
              searchData['type'] = 'unsaved';
              set['search'] = searchData;
              this.dataService.descChanged(oldDesc, this.desc);
              let curUrl: string = location.hash;
              if (!!curUrl && curUrl.startsWith('#')) {
                curUrl = curUrl.substr(1).replace(oldDesc.id, this.desc.id);
                Util.RestAPI.getAppComponent().curUrlChanged(curUrl);
              }
            }, error => {
              console.log('save search history failed: ' + Util.Transforms.formatError(error));
            });
          }
        } else {
          // When a blank search after a successful search performed, update the header from previous value
          this.name = this.localizer.getTranslation('HEADER.SEARCH_RESULTS');
        }
      } else if (set.search && set.search.type === 'unsaved' && !!list.length) {
        this.desc['UNSAVED'] = 'Y';
        this.showSaveSearch = this.commandEnabled('savesearch');
        Util.RestAPI.conformFulltextCriteria(set.search.criteria);
      } else if (set.search && set.search.type === 'saved' && this.filtered && !!list.length) {
        this.showSaveSearch = this.commandEnabled('savesearch');
      } else {
        this.showSaveSearch = false;
        // When a new search after a successful search does not return anything, update the header from previous value
        if (this.desc.id.startsWith('evaluation') && (!list || list.length <= 0)) {
          this.name = this.localizer.getTranslation('HEADER.SEARCH_RESULTS');
        }
      }
    }
  }

  public hoverItemUpdated(table: ListTableComponent): void {
    if (this.folderList && this.folderList === table) {
      if (this.noneSelected) {
        const listItem: ListItem = this.folderList.getHoverItem();
        if (listItem) {
          this.selections = [listItem];
        } else {
          this.selections = null;
        }
      }
      this.calculateSelectionRights();
    }
  }

  public selectionsUpdated(table: ListTableComponent): void {
    if (this.folderList && this.folderList === table) {
      this.selections = this.folderList.getSelections();
      this.noneSelected = (this.selections.length === 0);
      this.calculateSelectionRights();
      if (this.actionBar) {
        this.actionBar.updateActions();
      }
      if (!this.docView) {
        this.actionMenuTitle = this.noneSelected ? this.name : this.localizer.getTranslation('HEADER.NUM_SELECTED', [this.selections.length.toString()]);
        this.actionMenuIcon = this.noneSelected ? Util.Transforms.iconUrlFromDesc(this.desc) : null;
        this.updateMobileMenu();
        this.appComponent.setActionMenu(this, this.mobileActionMenuID, this.name, Util.Transforms.iconUrlFromDesc(this.desc));
      }
      if (this.ui >= 2) {
        const oldActionMenuId: number = this.actionMenuId;
        if (this.noneSelected) {
          this.actionMenuId = -1;
        } else {
          this.actionMenuId = MenuId.MENU_MOBILE_LIST_MAIN;
        }
        if (this.actionMenuId !== oldActionMenuId) {
          this.cdr.markForCheck();
        }
      }
    }
  }

  public initialList(table: ListTableComponent): ListItem[] {
    if (this.docView) {
      return Util.RestAPI.getCurList();
    }
    return null;
  }

  public initialSet(table: ListTableComponent): any {
    if (this.docView) {
      return Util.RestAPI.getCurSet();
    }
    return null;
  }

  public getName(table: ListTableComponent): string {
    return this.name;
  }

  public gotFocus(): void {
    // cancel select mode incase we were in it.
    this.folderList.doCommand('list_selectmode_off');
  }

  private isFilterOpen(): boolean {
    return this.filters && this.filters.isFilterOpen();
  }

  private updateMobileMenu(): void {
    if (this.commandEnabled('newmenu') || this.commandEnabled('savesearch')) {
      if (Util.Device.bIsOfficeAddin) {
        this.mobileActionMenuID = MenuId.MENU_OAI_ACTION_LIST;
      } else {
        this.mobileActionMenuID = MenuId.MENU_MOBILE_ACTION_LIST;
      }
    } else {
      this.mobileActionMenuID = -1;
    }
  }

  // **** CommandHandler implementation

  private rightHdrBtnClick(): void {
    this.doCommand('savesearch');
  }

  private actionHandler(cmd: string): void {
    if (this.commandEnabled(cmd)) {
      this.doCommand(cmd);
    }
  }

  public doCommand(cmd: string): boolean {
    const loginReply: any = Util.RestAPI.getLoginReply();
    let extAppInfo: any = null;
    setTimeout(() => {
      if (!!this.folderList) {
        this.folderList.closePopuMenus();
      }
    }, 1000);
    this.lastCmd = cmd;
    if (cmd.startsWith('list_')) {
      if (!!this.folderList) {
        this.folderList.doCommand(cmd);
      }
      return true;
    }
    if (cmd && cmd.startsWith('_custom_')) {
      const doFunc = Util.RestAPI.getCustomCommandFunction(cmd, 'do');
      if (!!doFunc) {
        doFunc(this.folderList ? this.folderList.desc : null, this.selections);
      }
      return true;
    }
    switch (cmd) {
      case 'showfilter':
        if (this.filters) {
          if (this.isFilterOpen()) {
            this.isFilterClosing = true;
          } else {
            this.isFilterOpening = true;
            this.filters.open();
          }
          if (this.actionBar) {
            setTimeout(() => {
             this.actionBar.updateActions();
            }, 300);
          }
        }
        break;
      case 'clearfilter':
        this.clearFilters();
        if (this.actionBar) {
          setTimeout(() => {
            this.actionBar.updateActions();
          }, 300);
        }
        break;
      case 'profile':
      case 'profile_mini':
      case 'share':
        if (this.selections.length) {
          if (cmd === 'share') {
            this.folderList.openMetadata(this.name, 'security', '&share=true');
          } else {
            this.folderList.openMetadata(this.name);
          }
        }
        break;
      case 'inlinepreview_mini':
        if (this.selections.length) {
          this.folderList.showHitPreview(this.selections[0]);
        }
        break;
      case 'doc_profile':
        if (!(this.desc as any).DOCNAME) {
          (this.desc as any).DOCNAME = this.name;
        }
        this.folderList.openContainerMetadata(this.desc as any);
        break;
      case 'create_tile':
        const desc = Util.Device.isMobile() ? this.selections[0] : this.desc;
        const isFilePlan: boolean = desc.type === 'fileplans';
        const docNum: string = isFilePlan ? desc['DOCNUM'] : desc['docNumber'];
        let tileSecurity: string = desc['SECURITY'] ? desc['SECURITY'] : '0';
        let name = Util.Device.isMobile() ? this.selections[0]['DOCNAME'] : this.name;
        if (desc.type === 'workspaces') {
          tileSecurity = '1';
        }
        if (desc.type === 'activities' && !isNaN(parseInt(desc['ACTIVITY_TYPE']))) {
          name = this.localizer.getTranslation('HISTORY_ACTIONS.' + parseInt(desc['ACTIVITY_TYPE']));
        } else {
          name = !!desc['DOCNAME'] ? desc['DOCNAME'] : name;
        }
        const tile: Tile = new Tile({
          type: desc.type,
          id: isFilePlan ? decodeURIComponent(desc.id) : desc.id,
          lib: desc.lib,
          index: 0,
          size: 1,
          name,
          imgPath: desc['imgPath'],
          tooltip: desc['tooltip'] || name,
          docNumber: docNum,
          IS_SHARED: desc['IS_SHARED'] || '',
          SECURITY: tileSecurity
        });
        const tiles: Tile[] = this.tileService.appendTile(tile);
        if (tiles.indexOf(tile) === -1) {
          Util.Notify.success(this.localizer.getTranslation('TILE_MENU.TILE_NOT_ADDED', [name, '']));
        } else {
          Util.Notify.success(this.localizer.getTranslation('TILE_MENU.TILE_ADDED', [name]));
        }
        break;
      case 'editcolumn_setdefault':
        this.folderList.revertSchema();
        this.schemaChanged = false;
        break;
      case 'openonline':
        extAppInfo = Util.RestAPI.findExternalApp(this.selections[0].lib);
        if (extAppInfo && !!this.selections[0]['webUrl']) {
          const newTab = window.open(this.selections[0]['webUrl']);
          /* Velimir Code TODO for 21.2
          if (newTab) {
            const waitForUnload = () => {
              if (newTab.closed) {
                // Velimir: TODO Add code to check-in the file after editing
                // if using macro-command "edit-online-edocs-document"
                console.log('Tab closed!');
              } else {
                setTimeout(waitForUnload,500);
              }
            };
            waitForUnload();
          }
          */
        }
        break;
      case 'open':
        if (this.selections[0]['STATUS'] === 3) {
          const checkOutDate: string = this.selections[0]['checkout'] ? this.selections[0]['checkout']['CHECKOUT_DATE'] : '';
          const checkOutName: string = this.selections[0]['checkout'] ? this.selections[0]['checkout']['TYPIST_ID'] : '';
          const isCheckedOutByMe: boolean = checkOutName.toUpperCase() === Util.RestAPI.getUserID();
          if (!isCheckedOutByMe) {
            // checked out by someone else and we must check it out, so error
            const notifyBody: string = this.localizer.getTranslation('FORMS.LOCAL.OPEN.ERROR_OPEN', [this.selections[0].DOCNUM, this.selections[0].DOCNAME, checkOutName, Util.Transforms.formatDate(checkOutDate)]);
            Util.Notify.warning(this.localizer.getTranslation('FOLDER_ACTIONS.OPEN'), notifyBody);
            return false;
          }
        }
        extAppInfo = Util.RestAPI.findExternalApp(this.selections[0].lib);
        if (extAppInfo && !!this.selections[0]['openInAppUrl']) {
          window.open(this.selections[0]['openInAppUrl']);
          break;
        } else {
          if (Util.Device.bIsOfficeAddin && (this.selections[0]['MAIL_ID'] && this.selections[0]['APP_ID'] === 'MS OUTLOOK') || Util.Device.canDownloadOfficeDoc(this.selections[0])) {
            return this.appComponent.doCommand(cmd);
          }
        }
      // fall thru
      case 'view':
        for (const item of this.selections) {
          this.folderList.listItemClicked(item, null, cmd === 'view', true);
        }
        break;
      case 'savesearch':
        this.openSaveSearchModal();
        break;
      case 'view_mode_grid':
      case 'view_mode_summary':
        if (loginReply && loginReply.SEARCH_CAPABILITIES && loginReply.SEARCH_CAPABILITIES.SUMMARY === 'Y') {
          this.viewMode = cmd.substr(10);
          this.changedViewMode = true;
        }
        break;
      case 'editsecurity':
        if (!this.noneSelected && this.allSelectedItemsHaveProperty('type', 'searches') && this.allSelectedItemsHaveAccessRight(AccessRights.ACCESS_EDIT_PROFILE)) {
          this.folderList.openMetadata(this.name, 'security');
          break;
        }
      // fall thru intentional
      default:
        return this.appComponent.doCommand(cmd);
    }
    return true;
  }

  public commandEnabled(cmd: string): boolean {
    let enabled = false;
    const isOffline: boolean = Util.RestAPI.offline();
    const isDownloads: boolean = Util.isSharedDownloads(this.desc);
    const isImports: boolean = Util.isSharedImports(this.desc);
    const isExternalContainer: boolean = this.folderList && this.folderList.desc && Util.isExternalLib(this.folderList.desc.lib) ? true : false;
    const loginReply: any = Util.RestAPI.getLoginReply();
    const effectiveRights: any = Util.RestAPI.getLibraryEffectiveRights(this.desc.lib) || {};
    const rights: SecurityControl = this.selectionRights;
    let folderListSet: any = this.folderList && this.folderList.getSet() ? this.folderList.getSet() : {};
    const containerSecurity: number = this.desc && this.desc['%SECURITY'] ? this.desc['%SECURITY'] : folderListSet.rights ? folderListSet.rights : 0;
    const containerRights: SecurityControl = new SecurityControl(containerSecurity); //get rights of the this folder.
    const extAppInfo: any = Util.RestAPI.findExternalApp(this.desc.lib);
    const isNoActionContainer = (this.desc.id === 'deleted' || this.desc.id === 'templates' || this.desc.id === 'all');
    if (cmd && cmd.startsWith('_custom_')) {
      const enableFunc = Util.RestAPI.getCustomCommandFunction(cmd, 'enable');
      if (!!enableFunc) {
        enabled = enableFunc(this.folderList ? this.folderList.desc : null, this.selections, rights);
      }
    } else if (cmd && (cmd.startsWith('editcolumn_') || cmd.startsWith('configred_'))) {
      enabled = true;
    } else if (cmd && cmd.startsWith('view_mode_')) {
      enabled = loginReply && loginReply.SEARCH_CAPABILITIES && loginReply.SEARCH_CAPABILITIES.SUMMARY === 'Y' && Util.canHaveSummary(this.desc);
    } else if (cmd && cmd.startsWith('list_')) {
      enabled = !!this.folderList && this.folderList.commandEnabled(cmd);
    } else {
      if (isOffline) {
        // precheck for only items that work offline
        switch (cmd) {
          case 'print':
          case 'view':
          case 'open':
          case 'export':
          case 'removefromfolder':
            if (isDownloads || isImports) {
              break;
            }
          // fall thru intentional
          default:
            return false;
        }
      }
      switch (cmd) {
        // commands not yet enabled
        case 'print':
        case 'security':
        case 'attache':
          enabled = false; break;
        case 'declarerecord':
          if (Util.RestAPI.restAPIVersion() < 0x00160500) {
            break;
          }
          enabled = effectiveRights.DECLARE_RECORD === 'Y' && !this.noneSelected && this.allSelectedItemsHaveProperty('STATUS', '0') && this.allSelectedItemsHaveProperty('RECORD', 'N') && this.allSelectedItemsHaveProperty('type', 'documents') && !this.anySelectedItemHasProperty('READONLY', 'Y');
          enabled = enabled && ((this.existsInRMsetting(loginReply, 'RequireRecordInFile', 'Y') && this.isIn_RM()) || (this.existsInRMsetting(loginReply, 'RequireRecordInFile', 'N')));
          break;
        case 'undeclarerecord':
          if (Util.RestAPI.restAPIVersion() < 0x00160500) {
            break;
          }
          enabled = effectiveRights.UNDECLARE_RECORD === 'Y' && !this.noneSelected && this.allSelectedItemsHaveProperty('RECORD', 'Y') && this.allSelectedItemsHaveProperty('type', 'documents'); break;
        case 'rm_request':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['REQUEST'] === 'Y' && this.allSelectedItemsHaveProperty('ITEM_TYPE', 'M') && !this.anySelectedItemHasProperty('STORAGE', 'K') && !this.isRMItem(); break;
        case 'rm_changevitalstatus':
          enabled = loginReply['RM_ENABLED'] && loginReply.RM_SECURITY['CHANGE_VITAL_STATUS'] === 'Y' && !this.noneSelected && this.allSelectedItemsHaveProperty('type', 'documents') && !this.anySelectedItemHasProperty('STATUS', '18'); break;
        case 'rm_vitalreviewdate':
          enabled = loginReply['RM_ENABLED'] && loginReply.RM_SECURITY['CHANGE_VITAL_REVIEW_DATE'] === 'Y' && !this.noneSelected && (!!this.selections && this.selections.length === 1) && this.allSelectedItemsHaveProperty('type', 'documents') && this.allSelectedItemsHaveProperty('PD_VITAL', 'Y') && !this.anySelectedItemHasProperty('STATUS', '18'); break;
        case 'rm_printlabel':
          enabled = false; break; //loginReply['RM_ENABLED'] && !this.noneSelected && this.selections.length===1 && this.allSelectedItemsHaveProperty('type', 'documents'); break;
        case 'rm_suspend':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['SUSPEND'] === 'Y' && !this.noneSelected && (this.allSelectedItemsHaveProperty('type', 'documents')) && (this.allSelectedItemsHaveProperty('PD_SUSPEND', 'N')) && !this.anySelectedItemHasProperty('STATUS', '18'); break;
        case 'rm_release':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['RELEASE'] === 'Y' && !this.noneSelected && (this.allSelectedItemsHaveProperty('type', 'documents')) && this.allSelectedItemsHaveProperty('PD_SUSPEND', 'Y'); break;
        case 'rm_supersede':
          enabled = loginReply['RM_ENABLED'] && (!this.existsInRMsetting(loginReply, 'RequireRecordInFile', 'Y') || (this.existsInRMsetting(loginReply, 'RequireRecordInFile', 'Y') && this.isIn_RM())) && loginReply.RM_SECURITY['SUPERSEDE'] === 'Y' && !this.noneSelected && this.allSelectedItemsHaveProperty('RECORD', 'Y') && !this.anySelectedItemHasProperty('PD_SUPSEDED', 'Y'); break;
        case 'rm_disposalinstructions':
          enabled = loginReply['RM_ENABLED'] && !this.noneSelected && false; break; // Disable until doCommand code is done
        case 'rm_changefilepart':
          enabled = loginReply['RM_ENABLED'] && (!this.allSelectedItemsHavePropertyValueFromArray('PD_FILE_PART', ['', '0'])) && (this.isRMItem() || this.allSelectedItemsHavePropertyValueFromArray('STORAGE', ['P', 'K'])) && !(this.allSelectedItemsHaveProperty('PD_SUSPEND', 'Y')) && loginReply.RM_SECURITY['CHANGE_FILEPART'] === 'Y' && !this.noneSelected && this.allSelectedItemsHaveProperty('type', 'documents'); break;
        case 'rm_assignfilepart':
          enabled = loginReply['RM_ENABLED'] && (this.allSelectedItemsHavePropertyValueFromArray('PD_FILE_PART', ['', '0'])) && (this.isRMItem() || this.allSelectedItemsHavePropertyValueFromArray('STORAGE', ['P', 'K'])) && !(this.allSelectedItemsHaveProperty('PD_SUSPEND', 'Y')) && loginReply.RM_SECURITY['CHANGE_FILEPART'] === 'Y' && !this.noneSelected && this.allSelectedItemsHaveProperty('type', 'documents'); break;
        case 'records':
          enabled = loginReply['RM_ENABLED'] && !isNoActionContainer && !this.noneSelected && this.allSelectedItemsHaveProperty('type', 'documents') && !this.anySelectedItemHasProperty('STATUS', '18'); break;
        case 'rm_cancelrequest':
          enabled = loginReply['RM_ENABLED'] && !this.noneSelected && this.allSelectedItemsHaveProperty('type', 'requests') && loginReply.RM_SECURITY['CANCEL_REQUEST'] === 'Y'; break;
        case 'rm_returnrequest':
          enabled = loginReply['RM_ENABLED'] && loginReply.RM_SECURITY['RETURN'] === 'Y' && !this.noneSelected && this.allSelectedItemsHaveProperty('type', 'documents') && this.allSelectedItemsHavePropertyValueFromArray('ITEM_TYPE', ['D', 'M']) && this.allSelectedItemsHaveProperty('PD_STATUSES', '1'); break;
        case 'rm_loanrequest':
          enabled = loginReply['RM_ENABLED'] && loginReply.RM_SECURITY['BORROW'] === 'Y' && !this.noneSelected && this.isIn_RM() && this.allSelectedItemsHaveProperty('type', 'documents')
            && !(this.anySelectedItemHasProperty('PD_STATUSES', '1') && this.existsInRMsetting(loginReply, 'ReturnOnBorrow', 'Y'))
            && ((this.existsInRMsetting(loginReply, 'CanBorrowElectronicRecords', 'Y') && this.anySelectedItemHasProperty('ITEM_TYPE', 'D')) || (this.anySelectedItemHasProperty('ITEM_TYPE', 'M'))); break;
        case 'rm_showfiles':
          enabled = loginReply['RM_ENABLED'] && loginReply.RM_SETTINGS['ShowClosedFiles'] === 'N' && (this.desc && this.desc.id === '' && this.desc.type === 'fileplans'); break;
        case 'rm_hidefiles':
          enabled = loginReply['RM_ENABLED'] && loginReply.RM_SETTINGS['ShowClosedFiles'] === 'Y' && (this.desc && this.desc.id === '' && this.desc.type === 'fileplans'); break;
        case 'fp_box_rm_loanrequest':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['BORROW'] === 'Y' && !((this.anySelectedItemHasProperty('PD_STATUSES', '1') || this.anySelectedItemHasProperty('PD_PART_STATUS', 'BORROWED') || this.anySelectedItemHasProperty('STATUS', '18')) && this.existsInRMsetting(loginReply, 'ReturnOnBorrow', 'N')) && !this.noneSelected && this.isRMItem(); break;
        case 'fp_box_returnrequest':
          enabled = loginReply['RM_ENABLED'] && loginReply.RM_SECURITY['RETURN'] === 'Y' && !this.anySelectedItemHasProperty('STATUS', '18') && !this.noneSelected && (this.allSelectedItemsHaveProperty('type', 'fileplans') || this.allSelectedItemsHaveProperty('type', 'boxes')) && (this.allSelectedItemsHaveProperty('PD_STATUSES', '1') || this.allSelectedItemsHaveProperty('PD_PART_STATUS', 'BORROWED')); break;
        // The following are for file-part menu in list mode
        case 'fp_box_rm_request':
          enabled = loginReply['RM_ENABLED'] && this.isRMItem() && this.desc && !this.anySelectedItemHasProperty('STATUS', '18') && (this.allSelectedItemsHaveProperty('type', 'fileplans') || this.allSelectedItemsHaveProperty('type', 'boxes')) && loginReply.RM_SECURITY['REQUEST'] === 'Y' && !this.anySelectedItemHasProperty('STORAGE', 'K'); break;
        case 'fp_rm_suspend':
          enabled = loginReply['RM_ENABLED'] && this.desc && this.allSelectedItemsHaveProperty('type', 'fileplans') && this.allSelectedItemsHaveProperty('PD_SUSPEND', 'N') && !this.anySelectedItemHasProperty('STATUS', '18'); break;
        case 'fp_rm_release':
          enabled = loginReply['RM_ENABLED'] && this.desc && this.allSelectedItemsHaveProperty('type', 'fileplans') && this.allSelectedItemsHaveProperty('PD_SUSPEND', 'Y') && !this.anySelectedItemHasProperty('STATUS', '18'); break;
        case 'settings':
          enabled = !!this.desc && this.desc.id !== 'checkedout' && ((this.desc.type === 'searches' && !!this.desc.id) || this.desc.type === 'folders' || this.desc.type === 'workspaces' || Util.isRMFilePart(this.desc)); break;
        case 'configure_red':
          enabled = !!this.desc && this.desc.id === 'recentedits' && this.desc.type === 'folders'; break;
        case 'showfilter':
          this.showFilter = enabled = this.canHaveFilters(); break;
        case 'clearfilter':
          enabled = !!this.filters; break;
        case 'separator':
          enabled = this.ui < 2 ? !this.noneSelected : true; break;
        case 'newmenu':
          enabled = (rights.canEditContent && ((this.desc.type === 'workspaces') || ((this.desc.type === 'flexfolders') && ((this.desc.id && this.desc.id.indexOf('SUBSCRIPTIONS_NODE') > -1) || (this.desc.id && this.desc.id.indexOf('LEVEL_NODE') > -1) || (this.desc.id && this.desc.id.indexOf('ENUMERATION_NODE') > -1))) || (this.desc.type === 'folders' && this.desc.id !== 'checkedout' && this.desc.id !== 'downloads' && this.desc.id !== 'imports' && this.desc.id !== ''))) || (this.desc.type === 'fileplans' && this.desc.id.startsWith('FP-FilePart-') && rights.canAssignToFilePart);
          if (enabled) { // Do not use this settings for adhoc folder
            if (((effectiveRights.ROOT_FOLDER === 'N' && this.desc.id === 'public') || (effectiveRights.ALLOW_DOC_CREATE === 'N' && this.desc.id === 'recentedits'))) {
              // If user cannot create folder or (user cannot create public folder , but trying to create one)
              enabled = false; // IF cannot create a public folder we will remove the + button as there are no more buttons under that
            } else if (this.desc.type === 'folders' && (this.desc['STATUS'] === '3' || !!this.desc['CHECKOUT_DATE'] || folderListSet.status === '3')) {
              // Checked out folders cannot add new folders and/or new documents
              enabled = false;
            } else {
              enabled = false;
              // Get the list of commands in the submenu
              const submenu: MenuDef = this.menuService.getMenu(MenuId.MENU_LIST_NEWITEMS);
              // Check if at last one list menu command is enabled, else remove the + button
              for (const item of submenu.items) {
                // Avoid recursive death spiral !!!
                if (item.cmd !== 'newmenu') {
                  enabled = this.commandEnabled(item.cmd);
                }
                if (enabled) {
                  break;
                }
              }
            }
          }
          break;
        case 'removefromfolder':
          enabled = !this.noneSelected && !this.anySelectedItemHasProperty('type', 'urls') && ((this.desc.id !== 'recentedits') && (this.desc.id !== '') && ((containerRights && containerRights.canViewDocument) || this.desc.id === 'downloads' || this.desc.id === 'imports') && (!isNaN(Number(this.desc.id)) || this.desc.id === 'downloads' || this.desc.id === 'imports') && ((this.desc.type === 'folders') || (this.desc.type === 'workspaces'))); break;
        case 'newworkspace':
          enabled = rights.canEditContent && this.desc && (this.desc.type === 'workspaces') && (this.desc.id === ''); break;// Show only for workspaces node
        case 'newpaperdocument':
          if (Util.RestAPI.restAPIVersion() < 0x00160500) {
            break;
          }
        // fall thru intentional
        case 'newdocument':
        case 'import':
        case 'uploadofficeitem':
          enabled = !isNoActionContainer && !isExternalContainer && this.desc && effectiveRights.ALLOW_DOC_CREATE !== 'N' && ((rights.canEditContent && (!Util.Device.isMobile() || effectiveRights.ALLOW_MOBILE_UPLOAD === 'Y') && ((this.desc.id !== 'public') && (this.desc.id !== 'checkedout') && (this.desc.type !== 'searches') && !((this.desc.type === 'workspaces') && (this.desc.id === ''))) || (this.desc.type === 'fileplans' && this.desc.id.startsWith('FP-FilePart-') && rights.canAssignToFilePart)));
          if (enabled) {
            if (cmd === 'uploadofficeitem') {
              enabled = !Util.Device.officeDMItem;
            } else if (cmd === 'import') {
              enabled = this.allSelectedItemsHaveProperty('id', '0');
            } else {
              enabled = !isDownloads && !isImports;
            }
            if (enabled && !!folderListSet) {
              const levelsInfo: any[] = folderListSet.flexinfo ? folderListSet.flexinfo['levels'] : [];
              const currentLevel: any = levelsInfo && levelsInfo.length > 0 ? levelsInfo[levelsInfo.length - 1] : {};
              if (this.desc.type === 'flexfolders' && currentLevel['HIDE_NEW_DOCUMENT'] === 'Y') {
                enabled = false;
              }
            }
          }
          break;
        case 'newversion':
          enabled = rights.canEditContent && this.desc && Util.Device.isMobile() && effectiveRights.ALLOW_MOBILE_UPLOAD === 'Y' && Util.isSharedDownloads(this.desc) && !!this.selections && this.selections.length === 1 && this.allSelectedItemsAreManagedFiles();
          if (enabled) {
            enabled = Util.RestAPI.canAddNewVersion(this.folderList.getList());
          }
          break;
        case 'adddocuments':
          enabled = rights.canEditContent && !isNoActionContainer && !isDownloads && this.desc && this.desc.type !== 'searches' && this.desc.type !== 'fileplans' && this.desc.id !== 'checkedout' && this.desc.id !== 'recentedits' && this.desc.id !== 'public' && this.desc.type !== 'flexfolders' && !(this.desc.type === 'workspaces' && this.desc.id === ''); break;
        case 'newfolder':
          enabled = rights.canEditContent && this.desc && !isDownloads && !isNoActionContainer && !isExternalContainer && this.desc.type !== 'searches' && this.desc.type !== 'fileplans' && this.desc.id !== 'checkedout' && (!((this.desc.type === 'workspaces') && (this.desc.id === '')));
          if (enabled) {  // Do not use this settings for adhoc folder
            if (((effectiveRights.CREATE_FOLDER === 'N') || ((effectiveRights.ROOT_FOLDER === 'N') && (this.desc.id === 'public')))) { // If user cannot create folder or (user cannot create public folder , but trying to create one)
              enabled = false;
            } else if (this.desc.type === 'flexfolders' && this.folderList) {
              folderListSet = this.folderList.getSet() || {};
              const levelsInfo: any = folderListSet.flexinfo ? folderListSet.flexinfo['levels'] : [];
              const currentLevel: any = levelsInfo && levelsInfo.length > 0 ? levelsInfo[levelsInfo.length - 1] : {};
              if (currentLevel['ADHOC_FOLDERING'] === 'N') {
                enabled = false;
              } else if (loginReply.FLEX_RIGHTS) {
                const rootNodeId: string = this.getFlexfolderRootNodeId(this.desc.id);
                const rootNodeRights: number = loginReply.FLEX_RIGHTS[rootNodeId];
                if ((rootNodeRights & DynamicViewRights.DVRIGHTS_EDIT_FOLDERS) !== DynamicViewRights.DVRIGHTS_EDIT_FOLDERS) {
                  enabled = false;
                }
              }
            }
          }
          break;
        case 'createfolder':
          enabled = isExternalContainer; break;
        case 'uploadfile':
          enabled = isExternalContainer; break;
        case 'addfilepart':
        case 'newurl':
        case 'addquicksearch':
          enabled = rights.canEditContent && this.desc && this.desc.type === 'workspaces' && this.desc.id.length > 0; break;
        case 'addflexfolder':
          enabled = this.desc.id && this.desc.id.indexOf('SUBSCRIPTIONS_NODE') !== -1; break;
        case 'publish':
          enabled = rights.canEditProfile && !this.noneSelected && this.anySelectedItemHasProperty('type', 'documents') && !this.anySelectedItemHasPropertyValueFromArray('type', ['workspaces', 'urls']) && this.allSelectedItemsHaveProperty('STATUS', '0');
          if (enabled) {
            if (effectiveRights.PUBLISH_VERSION === 'N') {
              enabled = false;
            }
          }
          break;
        //case 'unpublish': // WE do not know from document, if it is published as this shows in version table status field and not in profiel table
        // enabled = !this.noneSelected && this.anySelectedItemHasProperty('STATUS','20'); break;
        case 'removereadonly':
          enabled = rights.canEditProfile && !this.noneSelected && effectiveRights.REMOVE_READ_ONLY === 'Y' && !this.anySelectedItemHasProperty('RECORD', 'Y') && this.desc && ((this.desc.type === 'searches' && this.allSelectedItemsHaveProperty('STATUS', '19')) || (this.desc.type !== 'searches' && this.allSelectedItemsHaveProperty('READONLY', 'Y'))); break;
        case 'readonly':
          enabled = rights.canEditProfile && !this.noneSelected && (!this.allSelectedItemsContainProperty('RECORD') || this.allSelectedItemsHaveProperty('RECORD', 'N')) && effectiveRights.MAKE_READ_ONLY === 'Y' && this.allSelectedItemsHavePropertyValueFromArray('type', ['documents', 'folders']) && !this.allSelectedItemsHavePropertyValueFromArray('type', ['urls', 'workspaces']) && this.allSelectedItemsHaveProperty('STATUS', '0') && !this.anySelectedItemHasProperty('READONLY', 'Y') && !(this.anySelectedItemHasProperty('ITEM_TYPE', 'M'));
          if (enabled && this.allSelectedItemsHaveProperty('STORAGE', 'T') && effectiveRights.TEMPLATE_MANAGER === 'N') {
            enabled = false;
          }
          break;
        case 'unlock':
          enabled = rights.canEditContent && !this.noneSelected && (this.allSelectedItemsHaveProperty('type', 'documents') || this.allSelectedItemsHaveProperty('type', 'folders')) && (this.allSelectedItemsHaveProperty('STATUS', '3') || this.desc.id === 'checkedout');
          if (enabled && effectiveRights.OTHER_CHECKIN !== 'Y') {
            enabled = this.allSelectedItemsCheckedOutByMe();
          }
          break;
        case 'checkin':
          enabled = rights.canEditContent && !this.noneSelected && (this.allSelectedItemsHaveProperty('type', 'documents') || this.allSelectedItemsHaveProperty('type', 'folders')) && (this.allSelectedItemsHaveProperty('STATUS', '3') || this.desc.id === 'checkedout') && this.allSelectedItemsHaveVersionLabel();
          if (enabled) {
            enabled = (Util.Device.bIsElectron || Util.Device.bIsCordova || Util.RestAPI.hasPFTA() || !extAppInfo || (extAppInfo && this.anySelectedItemsAreExternalyCheckedOut())) && this.allSelectedItemsCheckedOutByMe();
          }
          break;
        case 'checkout':
          enabled = rights.canEditContent && effectiveRights.CHECKOUT === 'Y' && !this.noneSelected && !isExternalContainer && (!Util.Device.bIsOfficeAddinDesktop || Util.RestAPI.hasPFTA()) && (this.allSelectedItemsHaveProperty('type', 'documents') || this.allSelectedItemsHaveProperty('type', 'folders')) && !this.anySelectedItemHasProperty('type', 'urls') && this.allSelectedItemsHaveProperty('STATUS', '0') && !this.anySelectedItemHasProperty('READONLY', 'Y') && !(this.anySelectedItemHasProperty('ITEM_TYPE', 'M'));
          if (enabled && this.allSelectedItemsHaveProperty('STORAGE', 'T') && effectiveRights.TEMPLATE_MANAGER === 'N') {
            enabled = false;
          }
          break;
        case 'editprofile':
          enabled = rights.canEditContent && !this.noneSelected && this.allSelectedItemsHavePropertyValueFromArray('type', ['documents', 'folders', 'workspaces']) && this.allSelectedItemsAreManagedFiles() && this.allSelectedItemsHavePropertyValueFromArray('lib', [this.selections[0].lib]) && this.allSelectedItemsHavePropertyValueFromArray('type', [this.selections[0].type]) && !this.anySelectedItemHasProperty('STATUS', '18') && !this.anySelectedItemHasProperty('STATUS', '19') && !this.anySelectedItemHasProperty('READONLY', 'Y');
          if (enabled && this.selections.length > 1 && this.anySelectedItemHasProperty('type', 'workspaces')) {
            enabled = false;
          }
          break;
        case 'profile':
          enabled = rights.canViewProfile && !this.docView && !this.noneSelected && !(Util.isRMTerm(this.selections[0]) || Util.isRMFile(this.selections[0])) && (!!this.selections && this.selections.length === 1 || !Util.Device.isPhoneLook()) && ((this.anySelectedItemHasProperty('type', 'documents') && this.allSelectedItemsAreManagedFiles()) || this.anySelectedItemHasProperty('type', 'folders') || this.anySelectedItemHasProperty('type', 'workspaces') || this.allSelectedItemsHaveProperty('type', 'fileplans') || this.allSelectedItemsHaveProperty('type', 'boxes')) && !this.anySelectedItemHasPropertyValueFromArray('type', ['urls', 'flexfolders', 'searches']); break;
        case 'doc_profile':
          enabled = (rights.canViewProfile && this.desc && (!(this.desc.type === 'flexfolders' || this.desc.type === 'searches' || this.desc.type === 'activities') && this.desc.type !== 'fileplans' || this.desc.id.indexOf('FilePart') !== -1)) || (extAppInfo && extAppInfo['apptype'] === 'onedrive'); break;
        case 'doc_removereadonly':
          enabled = rights.canEditProfile && this.noneSelected && effectiveRights.REMOVE_READ_ONLY === 'Y' && !!this.desc && (!this.desc['RECORD'] || this.desc['RECORD'] === 'N') && (this.desc.type === 'documents' || this.desc.type === 'folders') && (this.desc['STATUS'] === '19' || this.desc['READONLY'] === 'Y'); break;
        case 'doc_readonly':
          enabled = rights.canEditProfile && this.noneSelected && effectiveRights.MAKE_READ_ONLY === 'Y' && !!this.desc && (!this.desc['RECORD'] || this.desc['RECORD'] === 'N') && (this.desc.type === 'documents' || this.desc.type === 'folders') && this.desc['STATUS'] === '0' && this.desc['READONLY'] !== 'Y' && this.desc['ITEM_TYPE'] !== 'M' && !(this.desc['STORAGE'] === 'T' && effectiveRights.TEMPLATE_MANAGER === 'N'); break;
        case 'savetoedocs':
          enabled = rights.canCopy && !this.noneSelected && isExternalContainer && !this.anySelectedItemsContainProperty('checkout') && this.allSelectedItemsHaveProperty('type', 'documents'); break;
        case 'addto':
          enabled = rights.canCopy && !this.noneSelected && !isExternalContainer && !(this.isRMItem() && this.anySelectedItemHasProperty('STATUS', '18')) &&
            ((((this.anySelectedItemHasProperty('type', 'fileplans') && this.isRMItem()) || this.anySelectedItemHasProperty('type', 'documents')) && this.allSelectedItemsAreManagedFiles()) ||
              (this.anySelectedItemHasProperty('type', 'folders') && !this.anySelectedItemHasProperty('id', ''))) &&
            !this.anySelectedItemHasPropertyValueFromArray('type', ['searches', 'flexfolders', 'workspaces']); break;
        case 'rename':
        case 'editsearch':
          enabled = rights.canCopy && !this.noneSelected && this.allSelectedItemsHaveProperty('type', 'searches') && !this.anySelectedItemHasProperty('UNSAVED', 'Y');
          if (enabled) {
            const searches: any[] = this.selections.filter(d => d.type === 'searches');
            if ((searches.length > 0 && (searches.some(s => isNaN(s.id)) || searches.some(s => !!s['ACCESSRIGHTS'] && (parseInt(s['ACCESSRIGHTS']) & AccessSearch.EDIT) !== AccessSearch.EDIT)))) {
              enabled = false;
            }
          }
          break;
        case 'doc_rename':
          enabled = this.desc.type === 'searches' && this.desc['UNSAVED'] !== 'Y' && !isNaN(parseInt(this.desc.id)); break;
        case 'doc_editsearch':
          enabled = this.desc.type === 'searches' && this.desc['UNSAVED'] !== 'Y' && !isNaN(parseInt(this.desc.id));
          if (enabled) {
            if (!!this.desc['ACCESSRIGHTS'] && (parseInt(this.desc['ACCESSRIGHTS']) & AccessSearch.EDIT) !== AccessSearch.EDIT) {
              enabled = false;
            }
          }
          break;
        case 'addtoworkspace':
          enabled = rights.canCopy && (this.desc.type !== 'flexfolders') && !this.noneSelected && (this.allSelectedItemsHaveProperty('type', 'searches') && !this.anySelectedItemHasProperty('UNSAVED', 'Y')); break;
        case 'doc_addtoworkspace':
          enabled = rights.canCopy && this.desc.type === 'searches' && this.desc['UNSAVED'] === 'N'; break;
        case 'copy':
          enabled = effectiveRights.ALLOW_DOC_CREATE === 'Y' && rights.canCopy && (!!this.selections && this.selections.length === 1) && !this.noneSelected && !this.anySelectedItemHasProperty('STATUS', '18') && ((this.anySelectedItemHasProperty('type', 'documents') && this.allSelectedItemsAreManagedFiles()) || this.anySelectedItemHasProperty('type', 'folders') || this.anySelectedItemHasProperty('type', 'workspaces')) && !this.anySelectedItemHasPropertyValueFromArray('type', ['urls', 'workspaces', 'searches']) && (!this.anySelectedItemHasProperty('ITEM_TYPE', 'M')) && ((this.anySelectedItemHasProperty('STATUS', '3') ? effectiveRights['COPY_IN_USE'] === 'Y' : true)); break;
        case 'openonline':
          if (!extAppInfo) {
            break;
          }
        case 'open':
          enabled = rights.canViewDocument && !this.noneSelected && (!!this.selections && this.selections.length === 1) && !this.anySelectedItemHasProperty('STATUS', '18') && ((Util.Device.bIsOfficeAddin && Util.Device.canDownloadOfficeDoc(this.selections[0])) || ((Util.Device.bIsCordova && (isOffline || effectiveRights.ALLOW_MOBILE_DOWNLOAD === 'Y') || (!Util.Device.bIsCordova && !Util.Device.bIsElectron && Util.RestAPI.hasPFTA())) && this.anySelectedItemHasProperty('type', 'documents') && !this.anySelectedItemHasProperty('type', 'urls') && !(this.anySelectedItemHasProperty('ITEM_TYPE', 'M'))));
          if (!enabled && extAppInfo) {
            enabled = rights.canViewDocument && !this.noneSelected && (!!this.selections && this.selections.length === 1) && !this.anySelectedItemHasProperty('STATUS', '18');
          }
          break;
        case 'shareonedrive':
          enabled = extAppInfo && !this.noneSelected && this.selections.length === 1 && !Util.Device.bIsElectron && !Util.Device.bIsCordova && this.allSelectedItemsHavePropertyValueFromArray('STATUS', ['0', '3', '19']) && this.allSelectedItemsHavePropertyValueFromArray('type', ['documents', 'folders']); break;
        case 'stopshareonedrive':
          enabled = extAppInfo && !this.noneSelected && this.selections.length === 1 && !this.selections[0]['inheritedFrom'] && this.allSelectedItemsHaveProperty('shared', 'Yes') && !Util.Device.bIsElectron && !Util.Device.bIsCordova && this.allSelectedItemsHavePropertyValueFromArray('STATUS', ['0', '3', '19']) && this.allSelectedItemsHavePropertyValueFromArray('type', ['documents', 'folders']); break;
        case 'view':
          enabled = !Util.Device.bIsAndroid && Util.Device.bIsCordova && rights.canViewDocument && !this.noneSelected && (!!this.selections && this.selections.length === 1) && this.anySelectedItemHasProperty('type', 'documents') && this.allSelectedItemsAreManagedFiles() && !this.anySelectedItemHasProperty('type', 'urls') && !(this.anySelectedItemHasProperty('ITEM_TYPE', 'M')); break;
        case 'export':
          enabled = rights.canCopy && !this.noneSelected && (!(Util.Device.isMobile() && !Util.Device.bIsOfficeAddin) || effectiveRights.ALLOW_MOBILE_DOWNLOAD === 'Y') && (!Util.Device.bIsOfficeAddinDesktop || Util.RestAPI.hasPFTA()) && (this.allSelectedItemsHaveProperty('type', 'documents') && this.allSelectedItemsAreManagedFiles()) && this.allSelectedItemsHavePropertyValueFromArray('STATUS', ['0', '3', '19', '20']) && (!this.anySelectedItemHasProperty('ITEM_TYPE', 'M')); break;
        case 'link':
          if (!this.selections || this.selections.length !== 1 || Util.Device.bIsElectron || Util.Device.bIsCordova) {
            break;
          }
        // fall thru
        case 'email':
          enabled = rights.canCopy && !this.noneSelected && !this.anySelectedItemHasProperty('ITEM_TYPE', 'M') && this.allSelectedItemsHavePropertyValueFromArray('STATUS', ['0', '3', '19']) && this.allSelectedItemsHavePropertyValueFromArray('type', ['documents', 'folders', 'workspaces']) && this.allSelectedItemsAreManagedFiles(); break;
        case 'insertintodocument':
        case 'insertlinkintodocument':
          enabled = rights.canCopy && (!!this.selections && this.selections.length === 1) && Util.Device.bIsOfficeAddin && Office && Office.context && Office.context.requirements && (Office.context.requirements.isSetSupported('WordApi', '1.2') || Office.context.requirements.isSetSupported('ExcelApi', '1.2') || (cmd === 'insertintodocument' && Office.context.requirements.isSetSupported('PowerPointApi', '1.1'))) && ((this.allSelectedItemsHaveProperty('type', 'documents') && this.allSelectedItemsAreManagedFiles()) || (cmd === 'insertlinkintodocument' && this.allSelectedItemsHaveProperty('type', 'folders'))) && this.allSelectedItemsHavePropertyValueFromArray('STATUS', ['0', '3', '19']) && (!this.anySelectedItemHasProperty('ITEM_TYPE', 'M'));
          if (enabled && cmd === 'insertintodocument') {
            enabled = (Util.Device.bIsOfficeAddinWord && this.allSelectedItemsHaveProperty('APP_ID', 'MS WORD')) || this.allSelectedItemsHaveProperty('APP_ID', 'DOCSIMAGE') || this.allSelectedItemsHaveProperty('APP_ID', 'MSPHOTOED');
          }
          break;
        case 'subscribe':
          enabled = !!loginReply && !!loginReply.FLEX_RIGHTS && Object.keys(loginReply.FLEX_RIGHTS).length > 0  && rights.canViewDocument && !this.noneSelected && !isExternalContainer &&
            (((this.anySelectedItemHasProperty('type', 'documents') && this.allSelectedItemsAreManagedFiles()) || this.anySelectedItemHasProperty('type', 'folders') && !this.anySelectedItemHasProperty('type', 'flexfolders')) ||
              (this.desc.type === 'flexfolders' && this.allSelectedItemsHaveProperty('subscribe', 'S'))) &&
            this.allFilteredItemsHaveProperty(['type', 'flexfolders'], ['subscribe', 'S']) &&
            !this.anySelectedItemHasPropertyValueFromArray('type', ['searches', 'urls', 'workspaces', 'fileplans']) &&
            !this.anySelectedItemHasProperty('STATUS', '18');
          break;
        case 'unsubscribe':
          enabled = !this.noneSelected && this.desc.type === 'flexfolders' && (this.allSelectedItemsHaveProperty('subscribe', 'U') || this.allSelectedItemsHaveProperty('SUBSCRIBED_INDICATOR', 'S')); break;
        case 'delete':
          enabled = rights.canDelete && !this.noneSelected && this.allSelectedItemsAreManagedFiles() && !this.anySelectedItemHasPropertyValueFromArray('type', ['fileplans', 'boxes']);
          if (enabled) {
            const searches: any[] = this.selections.filter(d => d.type === 'searches');
            const nonSearches: any[] = this.selections.filter(d => d.type !== 'searches');
            // delete should not be enabled for Level Searches in Flex Folders or for saved searches that do not have the delete bit 0x4
            if ((searches.length > 0 && (searches.some(s => isNaN(s.id)) || searches.some(s => !!s['ACCESSRIGHTS'] && (parseInt(s['ACCESSRIGHTS']) & AccessSearch.DELETE) !== AccessSearch.DELETE))) || (nonSearches.length > 0 && !this.itemsHavePropertyValueFromArray(nonSearches, 'STATUS', ['0', '18'])) || !this.allSelectedItemsAreManagedFiles()) {
              enabled = false;
            }
          }
          if (enabled && !Util.RestAPI.isAdmin() && (effectiveRights.ALLOW_CONTENT_DEL === 'N' || !this.selections.every(item => item.type === 'documents' && item['STATUS'] !== '18' && item.APP_ID !== '' && item['STORAGE'] !== 'P' && !item['VERSION_ID'])) && effectiveRights.ALLOW_DOC_DELETE === 'N') {
            if (effectiveRights.ALLOW_QUEUE_DEL === 'N' || this.anySelectedItemHasProperty('STORAGE', 'D')) {
              enabled = false;
            }
          }
          if (enabled && this.allSelectedItemsHaveProperty('STORAGE', 'T') && effectiveRights.TEMPLATE_MANAGER === 'N') {
            enabled = false;
          }
          break;
        case 'doc_delete':
          enabled = rights.canDelete && this.desc && !(this.desc.type === 'flexfolders' || this.desc.type === 'fileplans' || this.desc.type === 'activities' || this.desc.type === 'boxes' || (this.desc.type === 'searches' && isNaN(parseInt(this.desc.id))) || isNoActionContainer); break;
        case 'profile_mini':
          enabled = rights.canViewProfile && !this.docView && this.anySelectedItemHasPropertyValueFromArray('type', ['documents', 'folders', 'workspaces']) && this.allSelectedItemsAreManagedFiles() && !this.anySelectedItemHasProperty('type', 'urls'); break;
        case 'email_mini':
          enabled = rights.canCopy && this.noneSelected && !this.anySelectedItemHasProperty('ITEM_TYPE', 'M') && this.allSelectedItemsHavePropertyValueFromArray('type', ['documents', 'folders', 'workspaces']) && this.allSelectedItemsAreManagedFiles() && this.allSelectedItemsHavePropertyValueFromArray('STATUS', ['0', '3', '19']); break;
        case 'share_mini':
          enabled = extAppInfo && rights.canCopy && this.noneSelected && this.allSelectedItemsHavePropertyValueFromArray('type', ['documents', 'folders']) && this.allSelectedItemsHavePropertyValueFromArray('STATUS', ['0', '3', '19']); break;
        case 'edit_mini':
          enabled = rights.canEditContent && this.anySelectedItemHasPropertyValueFromArray('type', ['documents', 'folders', 'workspaces']) && this.allSelectedItemsAreManagedFiles() && !this.anySelectedItemHasProperty('type', 'urls') && !(this.selections[0]['STATUS'] === '19' || this.selections[0]['STATUS'] === '18' || this.selections[0]['READONLY'] === 'Y' ); break;
        case 'inlinepreview_mini':
          enabled = rights.canViewProfile && this.noneSelected && this.allSelectedItemsHavePropertyKey('preview'); break;
        case 'create_tile':
          if (Util.Device.isMobile()) {
            if (!this.noneSelected && this.selections.length === 1 && !this.tileService.getTile(this.selections[0])) {
              enabled = (this.selections[0]['type'] === 'searches' && (this.selections[0]['UNSAVED'] === 'N' || this.desc.type === 'workspaces')) || (this.selections[0]['type'] !== 'searches' && Util.RestAPI.isContainer(this.selections[0]['type']));
            }
          } else {
            enabled = !this.tileService.getTile(this.desc);
            if (enabled && this.desc.type === 'searches' && this.desc['UNSAVED'] === 'Y') {
              enabled = false;
            }
          }
          break;
        case 'rm_putinbox':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['PUT_IN_BOX'] === 'Y' && this.allSelectedItemsHaveProperty('PD_OBJ_TYPE', '4') && this.allSelectedItemsHavePropertyValueFromArray('PD_STATUSES', ['', '0', '1']); break;
        case 'rm_removefrombox':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['REMOVE_FROM_BOX'] === 'Y' && this.allSelectedItemsHaveProperty('PD_OBJ_TYPE', '4') && this.allSelectedItemsHavePropertyValueFromArray('PD_STATUSES', ['2', '3']); break;
        case 'savesearch':
          enabled = (!Util.isFolderWithId(this.desc) || this.desc['UNSAVED'] === 'Y' || this.filtered) && (!folderListSet.search || (!folderListSet.search.criteria['%FOLDER_ID'] && folderListSet.search.type !== 'activity' && (folderListSet.search.criteria['FULLTEXT_CONTENT_PROFILE'] === undefined || !!folderListSet.search.criteria['FULLTEXT_CONTENT_PROFILE'] || this.filtered))) && (this.filtered || (this.desc.type === 'searches' && (this.desc.id.startsWith('evaluation') || this.desc['UNSAVED'] === 'Y' || (folderListSet.search && folderListSet.search.type === 'unsaved')))) && (!Util.RestAPI.isGuestUser() || (loginReply['SYSTEM_DEFAULTS'] && loginReply['SYSTEM_DEFAULTS']['ALLOW_SAVING_OF_SEARCHES'] === 'Y')); break;
        case 'rm_close':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['CLOSE_FILEPART'] === 'Y' && this.allSelectedItemsHaveProperty('PD_OBJ_TYPE', '4') && !this.anySelectedItemHasProperty('PD_ACTIVE_STATUS', 'C'); break;
        case 'rm_reopen':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['REOPEN_FILEPART'] === 'Y' && this.allSelectedItemsHaveProperty('PD_OBJ_TYPE', '4') && this.allSelectedItemsHaveProperty('PD_ACTIVE_STATUS', 'C') && !this.anySelectedItemHasProperty('STATUS', '18'); break;
        case 'doc_editsecurity':
          enabled = this.desc.type === 'flexfolders' && (!!this.desc['CAN_CHANGE_SECURITY'] && this.desc['CAN_CHANGE_SECURITY'] !== '0') && (!!this.desc['SEC_REASON_LINK'] && this.desc['SEC_REASON_LINK'] !== '0'); break;
        case 'doc_setsecurity':
          enabled = this.desc.type === 'flexfolders' && (!!this.desc['CAN_CHANGE_SECURITY'] && this.desc['CAN_CHANGE_SECURITY'] !== '0') && (!!this.desc['SEC_REASON_LINK'] && this.desc['SEC_REASON_LINK'] === '0'); break;
        case 'editsecurity':
          enabled = !this.noneSelected && ((this.selections.length === 1 && this.allSelectedItemsHaveProperty('type', 'flexfolders') && (this.allSelectedItemsHavePropertyKey('CAN_CHANGE_SECURITY') && !this.anySelectedItemHasProperty('CAN_CHANGE_SECURITY', '0')) && (this.allSelectedItemsHavePropertyKey('SEC_REASON_LINK') && !this.anySelectedItemHasProperty('SEC_REASON_LINK', '0')))); break;
        case 'setsecurity':
          enabled = !this.noneSelected && this.selections.length === 1 && this.allSelectedItemsHaveProperty('type', 'flexfolders') && (this.allSelectedItemsHavePropertyKey('CAN_CHANGE_SECURITY') && !this.anySelectedItemHasProperty('CAN_CHANGE_SECURITY', '0')) && this.allSelectedItemsHaveProperty('SEC_REASON_LINK', '0'); break;
        case 'share':
          enabled = !this.noneSelected && this.selections.length === 1 && ((this.allSelectedItemsHaveProperty('type', 'searches') && !this.anySelectedItemHasProperty('UNSAVED', 'Y') && this.allSelectedItemsHaveAccessRight(AccessRights.ACCESS_EDIT_PROFILE)) || (this.allSelectedItemsHaveProperty('type', 'workspaces') && this.allSelectedItemsHaveAccessRight(AccessLevel.ACCESS_LEVEL_MANAGE))); break;
        case 'doc_share':
          enabled = this.desc.type === 'searches' && this.desc['UNSAVED'] === 'N' && ((this.desc['ACCESSRIGHTS'] & AccessRights.ACCESS_EDIT_PROFILE) === AccessRights.ACCESS_EDIT_PROFILE); break;
      }
    }
    if (enabled && extAppInfo) {
      enabled = this.extAppCommandEnabled(cmd, extAppInfo);
    }
    return enabled;
  }

  private extAppCommandEnabled(cmd: string, extAppInfo: any): boolean {
    let enabled = false;
    if (extAppInfo['access'].indexOf(cmd) !== -1 || extAppInfo['access'].indexOf(cmd + '-from') !== -1 || extAppInfo['access'].indexOf(cmd + '-to') !== -1 || cmd.startsWith('list_choosecol_')) {
      enabled = true;
    }
    // Now test for exceptions and if selections are local
    switch (cmd) {
      case 'checkin':
        enabled = this.allSelectedItemsHavePropertyKey('checkout');
        break;
      case 'create_tile':
      case 'profile':
      case 'doc_profile':
        enabled = true;
        break;
      case 'view_mode_':
        const searchCapabilities: any = Util.RestAPI.getLoginReplySetting('SEARCH_CAPABILITIES');
        enabled = extAppInfo['apptype'] === 'onedrive' && !!searchCapabilities && searchCapabilities.SUMMARY === 'Y' && Util.canHaveSummary(this.desc);
        break;
      case 'open':
        enabled = this.selections.length === 1 && !!this.selections[0]['openInAppUrl'];
      case 'newmenu':
        if (!!this.desc['READONLY'] && this.desc['READONLY'] === 'Y' || (!!this.folderList['set'] && !!this.folderList['set']['readonly']) && this.folderList['set']['readonly'] === 'Y') {
          enabled = false;
        }
        break;
    }
    return enabled;
  }

  private isIn_RM(): boolean {
    return (this.allSelectedItemsContainProperty('PD_FILE_PART') && !(this.anySelectedItemHasProperty('PD_FILE_PART', '') || this.anySelectedItemHasProperty('PD_FILE_PART', '0'))) || (this.desc.type === 'fileplans');
  }

  // Box or filepart
  private isRMItem(): boolean {
    return this.allSelectedItemsHaveProperty('PD_OBJ_TYPE', '4') || this.allSelectedItemsHaveProperty('PD_OBJ_TYPE', '5');
  }

  private existsInRMsetting(loginReply: any, setting: string, value: string): boolean {
    if (!!loginReply.RM_SETTINGS) {
      if (loginReply.RM_SETTINGS[setting] === value) {
        return true;
      }
    }
    return false;
  }

  private getFlexfolderRootNodeId(id: string): string {
    let rootNodeId = '';
    try {
      rootNodeId = id.split('-')[2].slice(1);
    } catch (e) {
      rootNodeId = '';
    }
    return rootNodeId;
  }

  public commandChecked(cmd: string): boolean {
    if (cmd.startsWith('editcolumn_')) {
      return true;
    } else if (cmd.startsWith('list_choosecol_')) {
      return this.folderList.getSortCol() === cmd.substr(15);
    } else if (cmd.startsWith('configred_')) {
      const property = cmd.substring(10);
      const configRedStr: string = Util.RestAPI.getPreference('$edx_configred');
      if (!!configRedStr) {
        const configRed = JSON.parse(configRedStr);
        return !!configRed[property];
      } else {
        return true;
      }
    }
    return false;
  }

  public checkCommand(cmd: string, setChecked: boolean): boolean {
    let property: string;
    if (cmd.startsWith('editcolumn_')) {
      property = cmd.substring(11);
      const schema: SchemaDef = this.folderList.getSchema();
      const schemaCols: ColumnDesc[] = schema.columns;
      const searchSchemaForm: string = this.folderList.getSearchSchemaForm();
      if (!!searchSchemaForm && Util.FieldMappings.isSearchFormTypistColumn(property)) {
        const index = this.getSchemaIndexForProperty(this.defaultCols, property);
        if (index === -1) {
          property = Util.FieldMappings.getSearchFormTypistColumn(searchSchemaForm);
        }
      }
      if (setChecked) {
        const index = this.getSchemaIndexForProperty(this.defaultCols, property);
        if (index !== -1) {
          schemaCols.splice(-1, 0, this.defaultCols[index]);
          this.schemaChanged = true;
        }
      } else {
        const index = this.getSchemaIndexForProperty(schemaCols, property);
        if (index !== -1) {
          schemaCols.splice(index, 1);
          this.schemaChanged = true;
        }
      }
      if (this.schemaChanged) {
        this.folderList.changedSchema();
      }
    } else if (cmd.startsWith('configred_')) {
      this.configRedChanged = true;
    }
    return setChecked;
  }

  public menuOpening(menuItemSetter: MenuItemSetter, id: number): void {
    if (id === MenuId.MENU_COLUMN_LIST) {
      const schema: SchemaDef = this.folderList.getSchema();
      const set: any = this.folderList.getSet();
      this.schemaChanged = false;
      if (schema && set) {
        const items: MenuItem[] = [];
        const listSchemaCols: ColumnDesc[] = schema.columns;
        let item: MenuItem;
        item = new MenuItem({
          heading: true,
          name: this.localizer.getTranslation('CUSTOM_COLUMNS.SELECT_COLUMNS'),
          enabled: true,
          cmd: 'menu_close'
        });
        items.push(item);
        item = new MenuItem({
          button: true,
          name: this.localizer.getTranslation('CUSTOM_COLUMNS.SET_TO_DEFAULT'),
          enabled: true,
          cmd: 'editcolumn_setdefault'
        });
        items.push(item);
        menuItemSetter.setMenuItems(items);
        let formName = '';
        if (this.desc.id.indexOf('FilePart') > -1 || this.desc.type === 'boxes') {
          formName = 'PD_SEARCH';
        } else {
          formName = set['FORM_NAME'] ? set['FORM_NAME'] : (set.search && set.search['FORM_NAME'] ? set.search['FORM_NAME'] : Util.RestAPI.getDefaultProfileForm().id);
        }

        this.schemaService.getAllColumns(formName, this.desc.lib).then(allCols => {
          if (allCols && allCols.length) {
            this.defaultCols = Util.deepCopy(allCols);
            for (const col of this.defaultCols) {
              if (col.property !== 'select' && col.property !== 'expand' && col.property !== 'APP_ID') {
                item = new MenuItem({
                  name: col.label,
                  checked: this.getSchemaIndexForProperty(listSchemaCols, col.property) !== -1,
                  enabled: true,
                  cmd: 'editcolumn_' + col.property
                });
                items.push(item);
              }
            }
            menuItemSetter.setMenuItems(items);
          }
        });
      }
    } else if (id === MenuId.MENU_RECORDS_MANAGEMENT) {
      const items: MenuItem[] = [];
      const item = new MenuItem({
        heading: true,
        name: this.localizer.getTranslation('FOLDER_ACTIONS.RECORDS'),
        enabled: true,
        cmd: 'menu_close'
      });
      items.push(item);
      menuItemSetter.prependMenuItems(items);
    } else if (id === MenuId.MENU_MOBILE_CHOOSE_COLUMN) {
      const schema: SchemaDef = this.folderList.getSchema();
      if (schema) {
        const menuItems: MenuItem[] = [];
        for (const col of schema.columns) {
          if ((col.format !== ColFormat.SELECTOR && col.format !== ColFormat.OBJTYPE) && !!col.label) {
            menuItems.push(new MenuItem({ name: col.label, cmd: 'list_choosecol_' + col.property, checked: true }));
          }
        }
        menuItemSetter.setMenuItems(menuItems);
      }
    } else if (id === MenuId.MENU_CONFIGURE_RED) {
      let configRed = {documents:true, folders:true, emails:true, paper:true, fileplans:true};
      const configRedStr: string = Util.RestAPI.getPreference('$edx_configred');
      if (!!configRedStr) {
        configRed = JSON.parse(configRedStr);
      }
      menuItemSetter.setMenuItems([
        new MenuItem({name:this.localizer.getTranslation('CONFIGURE_RED.DOCUMENTS'), cmd:'configred_documents', checked:configRed['documents'], enabled:true}),
        new MenuItem({name:this.localizer.getTranslation('CONFIGURE_RED.FOLDERS'), cmd:'configred_folders', checked:configRed['folders'], enabled:true}),
        new MenuItem({name:this.localizer.getTranslation('CONFIGURE_RED.EMAILS'), cmd:'configred_emails', checked:configRed['emails'], enabled:true}),
        new MenuItem({name:this.localizer.getTranslation('CONFIGURE_RED.PAPER'), cmd:'configred_paper', checked:configRed['paper'], enabled:true}),
        new MenuItem({name:this.localizer.getTranslation('CONFIGURE_RED.FILEPLANS'), cmd:'configred_fileplans', checked:configRed['fileplans'], enabled:true})
      ]);
    }
  }

  public menuClosing(menuComp: MenuComponent, id: number): void {
    if (id === MenuId.MENU_COLUMN_LIST) {
      if (this.schemaChanged) {
        this.folderList.saveSchema();
      }
    } else if (id === MenuId.MENU_CONFIGURE_RED) {
      if (this.configRedChanged) {
        let nChecked = 0;
        const configRed = {};
        const items = menuComp.getMenuItems();
        for (const item of items) {
          const checked = !!item.checked;
          configRed[item.cmd.substring(10)] = checked;
          if (checked) {
            ++nChecked;
          }
        }
        Util.RestAPI.setPreference('$edx_configred', nChecked === items.length ? undefined : JSON.stringify(configRed));
        Util.RestAPI.refreshRecentEdits();
        this.folderList.reloadList();
      }
    }
  }

  // calculate effective rights from container masked by rights of selected items
  public calculateSelectionRights(): void {
    const selectionRights: SecurityControl = new SecurityControl(AccessLevel.ACCESS_LEVEL_FULL_RM);
    const selections: ListItem[] = this.folderList.getSelections();
    if (selections.length > 0) {
      for (const item of selections) {
        if (item && item['%SECURITY']) {
          const itemAccess = +item['%SECURITY'];
          if (itemAccess > 0) {
            selectionRights.access &= itemAccess;
          }
        }
      }
    } else { // when the selections array is empty, check for security in the desc.
      const item = this.desc;
      if (item && item['%SECURITY']) {
        const itemAccess = +item['%SECURITY'];
        if (itemAccess > 0) {
          selectionRights.access &= itemAccess;
        }
      }
    }
    this.selectionRights = selectionRights;
  }

  private getSchemaIndexForProperty(cols: ColumnDesc[], property: string): number {
    let i = 0;
    for (const col of cols) {
      if (col.property === property) {
        return i;
      }
      ++i;
    }
    return -1;
  }

  // selection enabling logic methods
  private allSelectedItemsContainProperty(key: string): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        if (listItem[key] === undefined) {
          return false;
        }
      }
      return this.selections.length !== 0;
    }
    return false;
  }
  private anySelectedItemsContainProperty(key: string): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        if (!!listItem[key]) {
          return true;
        }
      }
    }
    return false;
  }
  private anySelectedItemsAreExternalyCheckedOut(): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        if (!!listItem['checkout'] && listItem['checkout']['LOCATION'].toUpperCase().startsWith('HTTP')) {
          return true;
        }
      }
    }
    return false;
  }
  private anySelectedItemHasProperty(key: string, value: any): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        if (listItem[key] === value) {
          return true;
        }
      }
    }
    return false;
  }
  private anySelectedItemHasPropertyValueFromArray(key: string, values: any[]): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        if (values.indexOf(listItem[key]) >= 0) {
          return true;
        }
      }
    }
    return false;
  }
  private allSelectedItemsCheckedOutByMe(): boolean {
    if (this.selections) {
      if (this.desc.id === 'checkedout') {
        return true;
      }
      for (const listItem of this.selections) {
        if (!listItem['checkout'] || listItem['checkout']['TYPIST_ID'].toUpperCase() !== Util.RestAPI.getUserID()) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  private allSelectedItemsHavePropertyKey(key: string): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        if (!listItem[key]) {
          return false;
        }
      }
      return this.selections.length !== 0;
    }
    return false;
  }
  private allSelectedItemsHaveProperty(key: string, value: any): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        if (listItem[key] !== value) {
          return false;
        }
      }
      return this.selections.length !== 0;
    }
    return false;
  }
  private allSelectedItemsHavePropertyValueFromArray(key: string, values: any[]): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        if (values.indexOf(listItem[key]) < 0) {
          return false;
        }
      }
      return this.selections.length !== 0;
    }
    return false;
  }
  private itemsHavePropertyValueFromArray(selections: any[], key: string, values: any[]): boolean {
    if (selections) {
      for (const listItem of selections) {
        if (values.indexOf(listItem[key]) < 0) {
          return false;
        }
      }
      return selections.length !== 0;
    }
    return false;
  }
  private allSelectedItemsAreManagedFiles(): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        if (Util.isUnmangedFile(listItem)) {
          return false;
        }
      }
      return this.selections.length !== 0;
    }
    return false;
  }
  private allFilteredItemsHaveProperty(filter: any[], prop: any[]): boolean {
    const filterKey: string = filter[0];
    const filterValue: string = filter[1];
    const propKey: string = prop[0];
    const propValue: string = prop[1];
    if (this.selections) {
      for (const listItem of this.selections) {
        if (listItem[filterKey] === filterValue) {
          if (listItem[propKey] !== propValue) {
            return false;
          }
        }
      }
      return this.selections.length !== 0;
    }
    return false;
  }
  private allSelectedItemsHaveVersionLabel(): boolean { // if the selected item is locked(%LOCK), then donot show option "check-in"
    if (this.selections) {
      for (const listItem of this.selections) {
        const hasVersionLabel: boolean = this.desc.id === 'checkedout' ? listItem['VERSION_LABEL'] : (!!listItem['checkout'] && !!listItem['checkout']['VERSION_LABEL']);
        // if there is no version, then document is only locked(%LOCK).
        if (!hasVersionLabel) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  private allSelectedItemsHaveAccessRight(right: number): boolean {
    if (this.selections) {
      for (const listItem of this.selections) {
        const itemRights: number = listItem['ACCESSRIGHTS'] || listItem['%EFFECTIVE_RIGHTS'];
        if ((itemRights & right) !== right) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  // selection enabling logic methods end

  private canHaveFilters(): boolean {
    return !this.docView && !!this.folderList && this.folderList.canHaveFilters();
  }

  private filterAnimationComplete(): void {
    if (this.isFilterClosing) {
      this.filters.close();
    }
    this.isFilterOpening = false;
    this.isFilterClosing = false;
  }

  //FilterTarget
  public getDesc(): BaseDesc {
    return this.desc;
  }
  public getListData(): ListData {
    return { set: this.folderList.getSet(), list: this.folderList.getList() };
  }
  public setListData(data: ListData, isFiltered: boolean): void {
    this.filtered = isFiltered;
    this.folderList.loadListContent(data);
    this.filterKeys = this.filtered && this.filters ? this.filters.getFilterList() : null;
    this.toggleSortColumnsForFilters(isFiltered, 'FOLDERS', ['VS.VERSION_LABEL']);
  }
  public applyFilters(filters: any): void {
    this.filtered = (filters && filters.length);
    if (this.filtered) {
      this.folderList.applyFilters(filters);
    } else {
      this.folderList.reloadList();
    }
    this.filterKeys = this.filtered && this.filters ? this.filters.getFilterList() : null;
  }
  public filterShown(shown: boolean): void {
    this.folderList.changedSchema();
  }
  public startSearching(): void {
    if (!!this.paginator) {
      this.paginator.targetUpdated(0);
    }
    this.folderList.startSearching();
  }
  public getSortKey(): string {
    return this.folderList.getSortKey();
  }
  public resetSortParams(): void {
    this.folderList.resetSortParams();
  }
  public toggleSortColumnsForFilters(disableSort: boolean, schemaId: string, columns: string[]): void {
    if (this.folderList.schemaId === schemaId && !!this.folderList.schema) {
      for (const column of columns) {
        const schemaColumn = this.folderList.schema.columns.find(col => col.property === column);
        if (!!schemaColumn) {
          if (disableSort) {
            schemaColumn.nonsortable = disableSort;
          } else {
            delete schemaColumn.nonsortable;
          }
        }
      }
    }
  }
  public clearFilters(): void {
    if (this.filters) {
      this.filters.reset();
      this.filters.close();
      this.filtered = false;
    }
    this.filterKeys = null;
  }
  public getFilterFormTemplate(): Promise<any> {
    return this.folderList.getFilterFormTemplate();
  }
  public getFacets(queryArgs: string): Observable<any> {
    return this.folderList.getFacets(queryArgs);
  }
  public getFacetTitles(): Promise<string[]> {
    return this.folderList.getFacetTitles();
  }
  public hasFacets(): Promise<boolean> {
    return this.folderList.hasFacets();
  }

  private openSaveSearchModal(): void {
    if (this.commandEnabled('savesearch')) {
      //display save dialog
      const title: string = this.localizer.getTranslation('FORMS.LOCAL.SAVE_SEARCH.SAVE_SEARCH');
      const okTitle: string = this.localizer.getTranslation('FORMS.LOCAL.SAVE_SEARCH.SAVE');
      const lastSearchData: any = this.folderList.getSet().search;
      const isSavedSearch: boolean = this.desc && this.desc.type === 'searches' && this.desc.id && !this.desc.id.startsWith('evaluation') && (!isNaN(+this.desc.id) || (this.desc.id && this.desc.id.startsWith('DV-')));
      const searchData: any = (isSavedSearch && lastSearchData) ? lastSearchData : this.dataService.getSearchData(this.desc);
      const isUnsavedSearch: boolean = !!searchData && (searchData['UNSAVED'] === 'Y' || searchData['type'] === 'unsaved');
      const filters: any = (isSavedSearch && !isUnsavedSearch && lastSearchData && !this.filtered) ? {} : this.dataService.getFilters(this.desc);
      const filterKeys: string[] = !!filters ? Object.keys(filters) : [];
      let name: string;
      let nErrors = 0;
      let errMsg = '';
      let savedSearchDesc: any;
      const saveDone = (isNewSearch?: boolean) => {
        if (nErrors === 0) {
          if (savedSearchDesc) {
            const queries: string = 'name=' + Util.RestAPI.encodeChildRouteName(savedSearchDesc.name) + '&max=' + Util.RestAPI.getDefualtMaxItems();
            Util.RestAPI.setCurDesc(savedSearchDesc);
            const url: string = Util.RestAPI.makeChildRouteURL('home', 'tcc_outlet', 'searches', savedSearchDesc, null, queries);
            Util.RestAPI.navToURL(url);
          }
          const successResource: string = isNewSearch ? 'FORMS.LOCAL.SAVE_SEARCH.SUCCESS' : 'FORMS.LOCAL.SAVE_SEARCH.UPDATE_SUCCESS';
          Util.Notify.success(title, this.localizer.getTranslation(successResource, [name]));
          const curApp: AppComponent = Util.RestAPI.getAppComponent();
          if (curApp) {
            curApp.refreshSearchesOnSearchMenu();
          }
          setTimeout(() => {
            Util.RestAPI.refreshSavedSearches();
          }, 1000);
        } else {
          const failureResource: string = isNewSearch ? 'FORMS.LOCAL.SAVE_SEARCH.FAILURE' : 'FORMS.LOCAL.SAVE_SEARCH.UPDATE_FAILURE';
          Util.Notify.info(title, this.localizer.getTranslation(failureResource, [name, errMsg]));
        }
      };
      if (!isSavedSearch && searchData && searchData.criteria) {
        Util.RestAPI.kFullTextTypes.forEach((ft) => {
          if (searchData.criteria[ft] === '') {
            delete searchData.criteria[ft];
          }
        });
      }
      // add in filters
      if (filterKeys.length) {
        searchData.criteria = Object.assign(searchData.criteria, filters);
        filterKeys.forEach(key => {
          if (!!searchData['DESCRIPTION']) {
            if (searchData['DESCRIPTION'].split(',').indexOf(filters[key]) === -1) {
              searchData['DESCRIPTION'] += ',' + filters[key];
            }
          } else {
            searchData['DESCRIPTION'] = filters[key];
          }
        });
      }
      const formData: any = {
        name: searchData['DESCRIPTION'] || searchData.criteria['FULLTEXT_CONTENT_PROFILE'] || searchData.criteria['FULLTEXT_EDIT'],
        savedSearchName: isSavedSearch && !isUnsavedSearch ? this.name : null,
        isSavedSearch: isSavedSearch && !isUnsavedSearch
      };
      formData.name = Util.Transforms.restoreQueryValue(formData.name);
      Util.Notify.confirm(title, '__local_savesearch', okTitle, formData, true, true, true).then(confirmed => {
        if (confirmed && confirmed.confirm) {
          if (!!confirmed.data.name) {
            this.name = confirmed.data.name;
          }
          name = searchData['DESCRIPTION'] = this.name;
          if (!isSavedSearch || (isSavedSearch && confirmed.data.$edx_savesearch_combo === '1')) {
            //For global search, modify the criteria while saving a search
            if (searchData && searchData.criteria) {
              if (Object.keys(searchData.criteria).length === 0) {
                searchData.criteria['FULLTEXT_CONTENT_PROFILE'] = '';
              }
              Util.RestAPI.conformFulltextCriteria(searchData.criteria);
            }
            Util.RestAPI.post('searches', searchData).subscribe((data: any) => {
              if (!!data && data.list) {
                savedSearchDesc = { lib: '', type: 'searches', id: String(data.list.id), name: data.list.name };
              }
              saveDone(true);
            }, error => {
              ++nErrors;
              errMsg = Util.Transforms.formatError(error);
              saveDone(true);
            });
          } else {
            if (Util.RestAPI.restAPIVersion() < 0x00160700) {
              delete searchData['FORM_NAME'];
            }
            delete searchData['type'];
            searchData['UNSAVED'] = 'N';
            this.desc['UNSAVED'] = 'N';
            Util.RestAPI.conformFulltextCriteria(searchData.criteria);
            Util.RestAPI.put(this.desc, searchData, 'profile').subscribe((response) => {
              this.desc['DOCNAME'] = this.name;
              savedSearchDesc = this.desc;
              savedSearchDesc.name = this.name;
              saveDone(isUnsavedSearch);
            }, error => {
              ++nErrors;
              errMsg = Util.Transforms.formatError(error);
              saveDone();
            });
          }
        }
      });
    }
  }
}
