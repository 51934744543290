 /**
  * Created by kevin on 2016-10-07.
  *
  * Implements a filter sidebar component as an accessory to a file/search list
  *
  */

import { Component, Input, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { LocalizeService } from '../services/localize.service';
import { BaseDesc, ListData } from '../models/base';
import { DynamicFormComponent, FormController } from './dynamic-form.component';
import { FormField } from '../models/form-field';
import { Util, UserInterface } from '../utils/utils.module';
import { FormService } from '../services/form.service';
import { DataService } from '../services/data.service';
import { PopupCallback } from '../widgets/popup.component';

const _facetKey = (item: any): string => {
  const keys: string[] = Object.keys(item);
  for (const key of keys) {
    if (key!=='checked' && key!=='readOnly') {
      return key;
    }
  }
  return '';
};

const kLessLen = 5;

class FilterSection {
  lessLen: number = kLessLen;
  localizer: LocalizeService;
  items: any[];
  id: string;
  title: string;
  field: any;
  isOpen = false;
  isOpening = false;
  isClosing = false;
  isExpanding = false;
  isContracting = false;
  isSearchOpen = false;
  showClearIcon = false;
  loading = false;
  moreShown = false;
  moreLess: string = null;

  constructor(localizer: LocalizeService, id: string, title: string, field: any) {
    this.localizer = localizer;
    this.items = [];
    this.id = id;
    this.title = title;
    this.field = field;
  }

  public empty(): void {
    this.items = [];
  }

  public reset(): void {
    for (const item of this.items) {
      if (item.checked) {
        item.checked = false;
      }
    }
  }

  public pruneToChecked(): void {
    const newItems: any[] = [];
    for (const item of this.items) {
      if (item.checked) {
        newItems.push(item);
      }
    }
    this.items = newItems;
    this.moreLess = null;
  }

  public add(items: any[], checkedList: string[], readOnlyList: string[]): void {
    let item: any;
    for (item of items) {
      const key: string = _facetKey(item);
      if (!this.itemExists(key)) {
        this.items.push(item);
      }
    }
    if (checkedList && checkedList.length) {
      for (const checkedItem of checkedList) {
        const checkedItemParts: string[] = checkedItem.split(',');
        for (const singleCheckedItem of checkedItemParts) {
          item = this.getItem(singleCheckedItem);
          if (!item) {
            item = {};
            item[singleCheckedItem] = 0;
            this.items.push(item);
          }
          item.checked = true;
        }
      }
    }
    if (readOnlyList && readOnlyList.length) {
      for (const readOnlyItem of readOnlyList) {
        const readOnlyItemParts: string[] = readOnlyItem.split(',');
        for (const singleReadOnlyItem of readOnlyItemParts) {
          item = this.getItem(singleReadOnlyItem);
          if (!item) {
            item = {};
            item[singleReadOnlyItem] = 0;
            this.items.push(item);
          }
          item.readOnly = true;
        }
      }
    }
    if (this.items.length < this.lessLen) {
      this.moreShown = false;
      this.moreLess = null;
    } else {
      this.setMoreLessText();
    }
  }

  public toggleMoreLess(): boolean {
    this.moreShown = !this.moreShown;
    this.setMoreLessText();
    return this.moreShown;
  }

  public hideMore(): void {
    if (this.moreShown) {
      this.moreShown = false;
      this.setMoreLessText();
    }
  }

  private setMoreLessText(): void {
    const chevCodePoint: number = this.moreShown ? 0x2303 : 0x2304;
    const strKey: string = 'FORMS.BUTTONS.' + (this.moreShown ? 'LESS' : 'MORE');
    this.moreLess = this.localizer.getTranslation(strKey) + ' ' + String.fromCodePoint(chevCodePoint);
  }

  private itemExists(key: string): boolean {
    for (const item of this.items) {
      if (key === _facetKey(item)) {
        return true;
      }
    }
    return false;
  }

  private getItem(key: string): boolean {
    for (const item of this.items) {
      if (key === _facetKey(item)) {
        return item;
      }
    }
    return null;
  }
}

@Component({
  selector: 'edx-filter-sidebar',
  styleUrls: ['filter-sidebar.component.scss'],
  templateUrl: 'filter-sidebar.component.html'
})
export class FilterSidebarComponent implements OnInit, FormController, PopupCallback {
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChild('docNameInput') docNameInput: ElementRef;
  @Input() target: FilterTarget;
  public isFiltered = false;
  public ui: UserInterface;
  public isOpen = false;
  private officeAddin: boolean;
  private canHaveFacets: boolean;
  private title: string;
  private applyStr: string;
  private clearStr: string;
  private noFiltersStr: string;
  private cancelStr: string;
  private docNameStr: string;
  private showClearIcon = false;
  private formLayout = 'column';
  private kind = 'profile_query_filter';
  private okDisabled = true;
  private facetsDirty = false;
  private formTemplate: any;
  private facetFormTemplate: any;
  private desc: BaseDesc;
  private parentSearchCriteria: any;
  private formName: string;
  private setData: any;
  private showFilters = false;
  private resetShownOnce = false;
  private filters: any = {};
  private initialCriteria: any = {};
  private searchInValue: any = null;
  private extrasShown = false;
  private extrasButtonStr: string;
  private loading = false;
  private sections: FilterSection[] = null;
  private expandedSection: FilterSection = null;

  constructor(private localizer: LocalizeService, private formService: FormService, private dataService: DataService, private changeDetector: ChangeDetectorRef) {
    this.ui = Util.Device.ui;
    this.officeAddin = Util.Device.bIsOfficeAddin;
    this.resetShownOnce = Util.Device.isPhoneLook() ? false : true;
    this.title = this.localizer.getTranslation('REFINE_SEARCH.REFINE_BY');
    this.applyStr = this.localizer.getTranslation('REFINE_SEARCH.APPLY');
    this.clearStr = this.localizer.getTranslation('REFINE_SEARCH.CLEAR');
    this.noFiltersStr = this.localizer.getTranslation('REFINE_SEARCH.NO_FILTERS');
    this.extrasButtonStr = this.localizer.getTranslation('FORMS.BUTTONS.MORE');
    this.docNameStr = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.NAME');
  }

  ngOnInit(): void {
    this.isFiltered = false;
    this.loading = true;
    this.getAndSetFormData();
  }

  @HostListener('document:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (this.ui<2 && this.isOpen) {
      if (event.which===27 && this.showReset()) {
        this.reset();
      } else if (event.which===13) {
        if (!this.okDisabled) {
          this.search();
        }
      }
    }
  }

  private clearSection(sectionOrNullForAll?: FilterSection): void {
    if (!!sectionOrNullForAll) {
      sectionOrNullForAll.items = [];
      sectionOrNullForAll.hideMore();
    } else if (this.sections) {
      for (const section of this.sections) {
        section.items = [];
        section.hideMore();
      }
    }
  }

  private checkForFacets(): Promise<boolean> {
    this.clearSection();
    if (this.target && this.target.hasFacets) {
      return this.target.hasFacets();
    }
    return Promise.resolve(false);
  }

  private setupFacetFilters(titles: string[]): void {
    const sections: FilterSection[] = [];
    if (!!this.setData.datefacets) {
      sections.push(new FilterSection(this.localizer, 'datefacets', this.localizer.getTranslation('COLUMN_HEADINGS.HISTORY.DATE'), null));
    }
    if (!!titles && titles.length>0) {
      for (const facet of titles) {
        const field = Util.FieldMappings.templateField(this.formTemplate.defs, facet);
        let name: string = facet;
        if (field && !!field.prompt && field.prompt !== ' ') {
          name = field.prompt;
        } else {
          const colNameKey: string = 'IDS_COL_NAME_'+ (facet === 'AUTHOR_FULL_NAME' ? 'AUTHOR_NAME' : facet);
          const colName: string = this.localizer.translationExists(colNameKey) ? this.localizer.getTranslation(colNameKey).split(';')[0] : null;
          if (!!colName) {
            name = colName;
          }
        }
        sections.push(new FilterSection(this.localizer, facet, name, field));
      }
    }
    this.sections = sections;
    this.changeDetector.markForCheck();
    this.parentSearchCriteria = {};
  }

  private getSectionFacets(section: FilterSection, all?: boolean): void {
    if (section.id !== 'datefacets') {
      const filters: string = Util.RestAPI.stringifyFilters(this.dataService.getFilters(this.desc));
      const query: string = '&facet='+section.id + ((filters && filters.length) ? ('&filter='+filters) : '') + (!all ? ('&max='+(kLessLen+1)) : '');
      section.loading = true;
      this.target.getFacets(query).subscribe(facets => {
        const facetKeys = facets ? Object.keys(facets) : [];
        if (facetKeys.length > 0) {
          const selectedFilters: string[] = !!this.filters && !!this.filters[section.id] ? this.filters[section.id].split(',') : [];
          const selectedCriteria: string[] = !!this.setData && !!this.setData.search && !!this.setData.search.criteria && !!this.setData.search.criteria[section.id] ? Util.Transforms.restoreQueryValue(this.setData.search.criteria[section.id]).split('|') : [];
          section.add(facets[section.id], selectedFilters.concat(selectedCriteria), selectedCriteria);
        }
        section.loading = false;
        this.changeDetector.markForCheck();
      }, err => {
        section.loading = false;
        this.changeDetector.markForCheck();
      });
    }
  }

  private placeholderForSection(section: FilterSection): string {
    return this.localizer.getTranslation('PLACEHOLDER.SEARCH_FOR', [section.title]);
  }

  private openSection(index: number) {
    const section = this.sections[index];
    this.getSectionFacets(section);
    section.isOpen = true;
    section.isOpening = true;
    section.isClosing = false;
  }

  private closeSection(index: number) {
    const section = this.sections[index];
    section.isOpening = false;
    section.isClosing = true;
  }

  private animationComplete(section: FilterSection): void {
    if (this.expandedSection) {
      if (this.expandedSection.isContracting) {
        this.expandedSection.isContracting = false;
        this.expandedSection.isExpanding = false;
        this.expandedSection = null;
      } else if (this.expandedSection.isExpanding) {
        this.expandedSection.isExpanding = false;
        this.expandedSection.isContracting = false;
      }
    } else if (section) {
      if (section.isClosing) {
        section.isOpen = false;
      }
      section.isOpening = false;
      section.isClosing = false;
    }
  }

  private toggleExpandedSection(section: FilterSection): void {
    if (this.ui<2) {
      if (!!this.expandedSection) {
        section.isContracting = true;
      } else {
        section.isExpanding = true;
        this.expandedSection = section;
        this.getSectionFacets(section, true);
      }
    } else {
      if (section.toggleMoreLess()) {
        this.getSectionFacets(section, true);
      }
    }
  }

  private toggleExtras(): void {
    this.extrasShown = !this.extrasShown;
    this.extrasButtonStr = this.extrasShown ? this.localizer.getTranslation('FORMS.BUTTONS.LESS') : this.localizer.getTranslation('FORMS.BUTTONS.MORE');
    if (this.dynamicForm) {
      this.dynamicForm.dynamicExtrasToggled(this.extrasShown);
    }
  }

  private clearName(): void {
    if (this.docNameInput && this.docNameInput.nativeElement) {
      (this.docNameInput.nativeElement as HTMLInputElement).value = '';
      this.showClearIcon = false;
    }
  }

  private sectionSearchKeyup(event: KeyboardEvent, section: FilterSection): void {
    event.stopPropagation();
    event.preventDefault();
    if (event.which===13) {
      const txt: string = (event.target as HTMLInputElement).value;
      let queryArgs: string = '&facet='+section.id + ((txt && txt.length) ? '&prefix='+encodeURIComponent(txt) : '');
      const filterObj = this.dataService.getFilters(this.desc);
      if (filterObj && filterObj[section.id]) {
        delete filterObj[section.id];
      }
      const filters: string = Util.RestAPI.stringifyFilters(filterObj);
      if (filters && filters.length) {
        queryArgs += '&filter='+filters;
      }
      section.loading = true;
      section.pruneToChecked();
      this.toggleSectionSearch(section);
      this.target.getFacets(queryArgs).subscribe(facets => {
        const facetKeys = facets ? Object.keys(facets) : [];
        if (facetKeys.length!==0) {
          section.add(facets[section.id], this.filters && !!this.filters[section.id] ? this.filters[section.id].split(',') : null, null);
        }
        section.loading = false;
        this.changeDetector.markForCheck();
      }, err => {
        section.loading = false;
        this.changeDetector.markForCheck();
      });
    } else if (event.which===27) {
      this.toggleSectionSearch(section);
    }
  }

  private showReset(): boolean {
    if (!this.resetShownOnce) {
      if (!this.okDisabled || this.isFiltered) {
        this.resetShownOnce = true;
      }
    }
    return this.resetShownOnce;
  }

  private disableReset(): boolean {
    return this.okDisabled && !this.isFiltered;
  }

  private search(): void {
    if (this.desc && !this.okDisabled) {
      const dirtyValues: any = this.getFilters();
      // When looking for documents include paper type docs also
      if (!!dirtyValues['ITEM_TYPE'] && dirtyValues['ITEM_TYPE'].indexOf('D') !== -1 && dirtyValues['ITEM_TYPE'].indexOf('M') === -1) {
        dirtyValues['ITEM_TYPE'] += ',M';
      }
      let serverData: any = Util.deepCopy(this.initialCriteria);
      this.updateSearchCriteria(serverData);
      this.updateSearchCriteria(dirtyValues);
      if (this.desc.type !== 'searches') { // for container search.
        serverData['%FOLDER_ID'] = this.desc.lib + ',' + this.desc.id;
        if (this.isFiltered && !!this.filters) {
          const filterKeys = Object.keys(this.filters);
          for (const key of filterKeys) {
            if (!dirtyValues[key] && !!this.filters[key]) {
              dirtyValues[key] = this.filters[key];
            }
          }
        }
      }
      // Assign dirty values to criteria
      serverData = Object.assign(serverData, dirtyValues);

      const searchCriteria: any = {
        FORM_NAME: this.formName,
        DESCRIPTION: this.dynamicForm ? this.formService.getSearchDescription(serverData, this.dynamicForm) : '',
        criteria: serverData
      };

      this.isFiltered = true;
      this.postFilterSearch(searchCriteria, dirtyValues);
    }
  }

  private anyItemChecked(items: any[]): boolean {
    for (const item of items) {
      if (item.checked) {
        return true;
      }
    }
    return false;
  }

  private getFilters(): any {
    let dirtyValues: any = {};
    const dirtyValuesForSection = (section: FilterSection): void => {
      const items = section.items;
      if (this.anyItemChecked(items)) {
        let sep = '';
        const key: string = section.id;
        dirtyValues[key] = '';
        for (const item of items) {
          if (item.checked) {
            dirtyValues[key] += sep + this.facetKey(item);
            sep = key==='ITEM_TYPE' ? ',' : ';';
          }
        }
      }
    };
    if (this.expandedSection) {
      dirtyValuesForSection(this.expandedSection);
    }
    if (this.sections) {
      for (const section of this.sections) {
        if (section !== this.expandedSection) {
          dirtyValuesForSection(section);
        }
      }
      if (this.docNameInput && this.docNameInput.nativeElement) {
        const value = (this.docNameInput.nativeElement as HTMLInputElement).value;
        if (!!value) {
          dirtyValues['DOCNAME'] = value;
        }
      }
    }
    if (this.dynamicForm) {
      dirtyValues = Object.assign(dirtyValues, this.canHaveFacets && !this.sections ? this.filters : {}, this.dynamicForm.getDirtyValue());
      // remove any initial criteria fields from the filter data
      for (const key in this.initialCriteria) {
        if ((key in dirtyValues)) {
          delete dirtyValues[key];
        }
      }
    }
    return dirtyValues;
  }

  private updateSearchCriteria(criteria: any): void {
    if ('SEARCH_IN' in criteria) {
      if (!criteria['FULLTEXT_EDIT'] || criteria['FULLTEXT_EDIT'] === '') {
        delete criteria['SEARCH_IN'];
      }
    } else if (criteria['FULLTEXT_EDIT'] && criteria['FULLTEXT_EDIT'] !== '') {
      criteria['SEARCH_IN'] = 2;  //If the criteria has 'FULLTEXT_EDIT' without 'SEARCH_IN' value, making the 'SEARCH_IN' value as 2. Bydefault 'SEARCH_IN' value will not be dirty until unless its been changed by user.
    }
    this.formService.updateSearchCriteria(criteria, this.formTemplate);
  }

  private postFilterSearch(searchCriteria: any, dirtyValues?: any): void {
    let sortKey: string;
    if (this.target.startSearching) {
      this.target.startSearching();
    }
    if (this.target.getSortKey) {
      sortKey = this.target.getSortKey();
      if (this.isFiltered && sortKey.endsWith('=VS.VERSION_LABEL')) {
        sortKey = null;
        if (this.target.resetSortParams) {
          this.target.resetSortParams();
        }
      }
    }
    Util.RestAPI.doFilterSearch(this.desc, searchCriteria, dirtyValues, sortKey).subscribe(data => {
      this.filters = data.set.filter;
      if (this.target.setListData) {
        this.target.setListData(data, this.isFiltered);
      }
    }, err => {
      if (this.target.setListData) {
        this.target.setListData({list:[], set: {total:0}}, this.isFiltered);
      }
    });
  }

  private delayedPrefillInitialValuesForForm(): void {
    let field: FormField;

    Util.RestAPI.conformFulltextCriteria(this.parentSearchCriteria);
    Util.RestAPI.conformFulltextCriteria(this.initialCriteria);
    Util.RestAPI.conformFulltextCriteria(this.filters);

    if (this.dynamicForm) {
      if (!this.searchInValue) {
        // default value selection in search - in dropdown. else use what the user last set
        if (this.initialCriteria['SEARCH_IN']) {
          this.searchInValue = this.initialCriteria['SEARCH_IN'];
        } else {
          this.searchInValue = '2';
        }
      }
      this.dynamicForm.updateControlValue('SEARCH_IN', this.searchInValue, false);
      if (Object.keys(this.parentSearchCriteria).length > 0) {
        // Only saved searches/ profile search will have search criteria & filters and others like folders will have only filters.
        let forceRender = false;
        for (const key in this.parentSearchCriteria) {
          field = this.dynamicForm.getField(key);
          if (field) {
            this.dynamicForm.updateControlValue(key, this.parentSearchCriteria[key], false);
            if (key in this.initialCriteria) {
              if (field.isCheckboxGroup && field.buttonMap) {
                if (Util.isContainerWithId(this.desc)) {
                  // If it is a container search and having intial criteria of checkboxgroup, shouldn't allow to change them
                  field.disabledCheckbox = field.buttonMap;
                } else {
                  const IC: any = this.initialCriteria[key].split(',').map(ic => ic && ic.trim());
                  // If it is a profile search, should able to choose remaining checkbox items other than items which were in intial criteria
                  field.disabledCheckbox = field.buttonMap.filter(button => IC && IC.indexOf(button.value) !== -1);
                }
              } else {
                field.isReadonly = true;
                forceRender = true;
              }
            }
          }
        }
        if (forceRender) {
          this.dynamicForm.dynamicExtrasToggled(!this.extrasShown);
          setTimeout(() => {
            this.dynamicForm.dynamicExtrasToggled(this.extrasShown);
          }, 1);
        }
      }
      if (Object.keys(this.filters).length > 0) {
        // If it is a container search other than saved searches/ profile search, it will have only filters
        for (const key in this.filters) {
          let value = this.filters[key];
          if (key === 'ITEM_TYPE' && value==='D,M') {
            value = 'D';
          }
          this.dynamicForm.updateControlValue(key, value, false);
        }
      }
    }
  }

  private refreshForm(): void {
    const waitDFT = () => {
      if (!this.dynamicForm) {
        setTimeout(() => {
         waitDFT();
        }, 100);
      } else {
        this.dynamicForm.setLoading(true);
        setTimeout(() => {
          this.dynamicForm.setLoading(false);
        }, 300);
      }
    };
    if (!this.officeAddin) {
      waitDFT();
    }
  }

  private facetValue(item: any): string {
    return item[this.facetKey(item)];
  }

  private facetKey(item: any): string {
    return _facetKey(item);
  }

  private facetKeyName(item: any, section: FilterSection): string {
    let name: string = this.facetKey(item);
    if (section.field && section.field.fldtype==='radiogroup' && section.field.buttons) {
      const buttons = section.field.buttons.split('|');
      for (const button of buttons) {
        const parts: string[] = button.split(';');
        if (parts[0] === name) {
          name = parts[1];
          break;
        }
      }
    }
    return name;
  }

  private facetClicked(event: Event, item: any): boolean {
    if (item.readOnly) {
      return false;
    }
    item.checked = !item.checked;
    this.facetsDirty = true;
    this.okDisabled = false;
    return true;
  }

  private loadFacetsIfNeeded(): void {
    if (this.canHaveFacets) {
      if (!this.sections) {
        if (this.target && this.target.getFacetTitles) {
          this.target.getFacetTitles().then((titles: string[]) => {
            this.setupFacetFilters(titles);
            if (this.expandedSection) {
              this.expandedSection.reset();
              this.getSectionFacets(this.expandedSection, true);
            }
          });
        }
      } else {
        for (const section of this.sections) {
          if (section === this.expandedSection) {
            this.expandedSection.reset();
            this.getSectionFacets(this.expandedSection, true);
          } else if (section.isOpen) {
            this.getSectionFacets(section);
          }
        }
      }
    }
  }

  // ---------------- Public Methods --------------
  public toggleSection(index: number) {
    if (index >= 0 && index < this.sections.length) {
      if (this.sections[index].isOpen) {
        this.closeSection(index);
      } else {
        this.openSection(index);
      }
    }
  }

  public toggleSectionSearch(section: FilterSection) {
    section.isSearchOpen = !section.isSearchOpen;
    if (section.isSearchOpen) {
      setTimeout(() => {
        const input: HTMLInputElement = document.getElementById('input_'+section.id) as HTMLInputElement;
        if (!!input) {
          input.focus();
        }
      }, 1);
    }
  }

  public getFilterList(): string {
    let list = '';
    if (this.filters && this.formTemplate && this.formTemplate.defs) {
      Object.keys(this.filters).forEach((key, index) => {
        const field: any = Util.FieldMappings.templateField(this.formTemplate.defs, key);
        if (field && field.prompt) {
          if (list.length) {
            list += ', ' + this.localizer.getTranslation(field.prompt);
          } else {
            list = this.localizer.getTranslation(field.prompt);
          }
        }
      });
    }
    return this.localizer.getTranslation('REFINE_SEARCH.FILTERS_LIST',[list]);
  }

  public enableOK(enable: boolean, dirty: boolean): void {
    if (this.isOpen && this.dynamicForm) {
      this.okDisabled = this.facetsDirty ? false : (!this.dynamicForm.isDirty() || !enable);
      this.dynamicForm.markForCheck();
    }
  }

  public reset(): void {
    // Clear the changes done by the user.
    if (this.expandedSection && !this.isFiltered) {
      this.expandedSection.reset();
    } else {
      if (this.expandedSection) {
        this.expandedSection.empty();
      }
      if (!this.okDisabled || this.isFiltered) {
        if (this.isFiltered) {
          this.clearVariables(false);
          this.setInitialCriteria();
          if (this.initialCriteria) {
            this.formService.updateSearchCriteria(this.initialCriteria, this.formTemplate);
            const searchCriteria: any = {
              FORM_NAME: this.formName,
              DESCRIPTION: this.dynamicForm ? this.formService.getSearchDescription(this.initialCriteria, this.dynamicForm) : '',
              criteria: this.initialCriteria
            };
            this.postFilterSearch(searchCriteria, {});
          }
        } else {
          this.revertDirtyFields();
        }
      }
      this.facetsDirty = false;
      this.okDisabled = true;
    }
  }

  public setInitialCriteria(): void {
    if (Object.keys(this.parentSearchCriteria).length > 0 && Object.keys(this.filters).length > 0) {
      for (const key in this.parentSearchCriteria) {
        if (!(key in this.filters)) {
          this.initialCriteria[key] = this.parentSearchCriteria[key];
        }
      }
    }
  }

  public revertDirtyFields(): void {
    if (this.dynamicForm) {
      const dirtyFields = this.dynamicForm.getDirtyValue();
      for (const key in dirtyFields) {
        const field = this.dynamicForm.getField(key);
        const control = this.dynamicForm.getControl(key);
        if (control) {
          if (field && field.isCheckboxGroup && field.buttonMap && this.initialCriteria[key]) {
            control.reset(this.initialCriteria[key]);
          } else {
            if (field && field.fldtype==='radiogroup') {
             control.setValue(field.defaultValue);
             this.dynamicForm.useForceRender(field);
            }
            control.reset();
          }
        }
      }
      this.dynamicForm.markForCheck();
    }
    if (this.canHaveFacets && !!this.sections && this.facetsDirty) {
      this.clearSection();
      this.loadFacetsIfNeeded();
      this.facetsDirty = false;
    }
  }

  public clearVariables(listIsChanging: boolean=true): void {
    this.isFiltered = false;
    this.filters = {};
    this.okDisabled = true;
    this.sections = null;
    if (listIsChanging) {
      this.canHaveFacets = false;
    }
  }

  public isFilterOpen(): boolean {
    return this.isOpen;
  }

  public open(): void {
    if (!this.isOpen) {
      this.isOpen = true;
      this.clearSection();
      this.loadFacetsIfNeeded();
      this.refreshForm();
    }
  }

  public close(): void {
    if (this.isOpen) {
      this.isOpen = false;
      if (this.ui>=2) {
        this.resetShownOnce = false;
        this.changeDetector.markForCheck();
      }
    }
  }

  public getFormName(): string {
    return this.formName;
  }

  public prefillInitialValuesForForm(): void {
    setTimeout(() => {
      this.delayedPrefillInitialValuesForForm();
    }, 1);
  }

  public getAndSetFormData(): boolean {
    const listData: any = this.target.getListData();
    const wasOpen: boolean = this.isOpen;
    this.filters = {};
    this.showFilters = false;
    const okToShowFilters: boolean = this.isFiltered || (listData && listData.list && (listData.list.length > 0)) ? true : false;
    if (okToShowFilters) {
      this.parentSearchCriteria = {};
      this.desc = this.target.getDesc();
      this.setData = listData ? listData.set : null;
      if (this.setData) {
        if (this.setData.search && this.setData.search.criteria) {
          const fields = this.setData.search.criteria;
          for (const key in fields) {
            // Get value and restore any | multivalue separator with ; used in the UI
            const value = Util.Transforms.restoreMultiValueSearchText(fields[key]);
            this.parentSearchCriteria[key] = value && Array.isArray(value) ? value.join(',') : value;
          }
          this.formName = this.setData.search.FORM_NAME;
        }
        if (this.setData.filter) {
          for (const key in this.setData.filter) {
            // Get value and restore any | multivalue separator with , used in the UI
            const value = Util.Transforms.restoreMultiValueSearchText(this.setData.filter[key]);
            this.filters[key] = value && Array.isArray(value) ? value.join(';') : value;
          }
          if (!this.isFiltered && Object.keys(this.filters).length>0) {
            this.isFiltered = true;
          }
        }
      }
      if (!this.isFiltered) {
        if (Object.keys(this.parentSearchCriteria).length > 0) {
          this.initialCriteria = this.parentSearchCriteria;
        }
      }
      const gotTemplate = (formTemplate, listHasFacets) => {
        formTemplate['extrasshown'] = true;
        this.extrasShown = true;
        this.loading = false;
        this.formTemplate = formTemplate;
        this.canHaveFacets = listHasFacets;
        this.showFilters = true;
        if (wasOpen) {
          this.changeDetector.markForCheck();
          this.loadFacetsIfNeeded();
          this.refreshForm();
        }
        if (!!this.setData && !!this.setData.datefacets) {
          const fieldNames: string[] = this.setData.datefacets;
          this.facetFormTemplate = { fldtype: 'form', name: 'datefacets', prompt: '', defs: [] };
          for (const dateField of fieldNames) {
            const field = Util.FieldMappings.templateField(formTemplate.defs, dateField);
            this.facetFormTemplate.defs.push( {
              fldtype: 'edit',
              datatype: '8',
              name: dateField,
              prompt: !!field && !!field.prompt ? field.prompt : dateField,
              sqlinfo: dateField,
              lookup: '$edx_date_range',
              flags: 0x00000006
            });
          }
        }
      };
      if (!this.formName) {
        const form: any = Util.RestAPI.getDefaultSearchForm();
        if (!!form && !!form['%FORM_NAME']) {
          this.formName = form['%FORM_NAME'];
        } else {
          this.formName = '';
        }
      }
      this.loading = true;
      this.checkForFacets().then(listHasFacets => {
        const formName: string = this.ui<2 || this.officeAddin || listHasFacets ? this.formName : '__local_filters_mobile';
        if (this.target && this.target.getFilterFormTemplate) {
          this.target.getFilterFormTemplate().then(formTemplate => {
            if (formTemplate) {
              gotTemplate(formTemplate, listHasFacets);
            } else {
              this.formService.getFormTemplate(formName, this.desc.lib, true).then(defFormTemplate => {
                gotTemplate(defFormTemplate, listHasFacets);
              });
            }
          });
        } else {
          this.formService.getFormTemplate(formName, this.desc.lib, true).then(formTemplate => {
            gotTemplate(formTemplate, listHasFacets);
          });
        }
      });
    } else {
      this.loading = false;
    }
    return this.isFiltered;
  }

  // **** FormController implementation
  public fieldChanged(field: FormField, control: AbstractControl, formFields: FormField[]): boolean {
    this.enableOK(true, true);
    if (field.name==='SEARCH_IN') {
      this.searchInValue = control.value;
    }
    return true;
  }

  // **** PopupCallback implementation
  public popupOK(): void {
    this.search();
    this.close();
  }

  public popupCancel(): void {
    this.close();
  }
}

export interface FilterTarget {
  applyFilters?(filters: any): void;
  clearFilters?(): void;
  getDesc?(): BaseDesc;
  getListData?(): ListData;
  setListData?(data: ListData, isFiltered: boolean): void;
  startSearching?(): void;
  getSortKey?(): string;
  resetSortParams?(): void;
  getFilterFormTemplate?(): Promise<any>;
  getFacets?(queryArgs: string): Observable<any>;
  getFacetTitles?(): Promise<string[]>;
  hasFacets?(): Promise<boolean>;
}
