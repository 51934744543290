import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Util } from '../utils/utils.module';
import { FormField } from '../models/form-field';
import { ListItem } from '../models/list-item';
import { DynamicFormComponent } from './dynamic-form.component';
import { LocalizeService } from '../services/localize.service';
import { ListTableComponent, ListTableParent } from '../lists/list-table.component';

@Component({
  selector: 'edx-form-field-list',
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
    <ng-template [ngIf]="fieldDataList">
      <div *ngIf="showExtras" class="form-field-list" [ngClass]="{indialog:inDialog}">
        <edx-list-table #table [schemaId]="field.schemaID" [fieldDataList]="fieldDataList" [hasFootprint]="true"></edx-list-table>
      </div>
      <span *ngIf="!showExtras" [ngClass]="{indialog:inDialog,columnview:columnView}">
        <label attr.for="{{field.name}}">{{field.label.replace(':', '')}}</label>
        <edx-list-table *ngIf="showLessList()" #table [schemaId]="field.schemaID" [fieldDataList]="fieldDataList" [hasFootprint]="true" [showExtras]="showExtras"></edx-list-table>
        <input *ngIf="!showLessList()" type="text" [readonly]="true" [value]="getFileMessage()">
      </span>
    </ng-template>
  `
})

export class FormFieldListComponent implements OnChanges {
  @Input() parent: DynamicFormComponent;
  @Input() field: FormField;
  @Input() showExtras: boolean;
  @Input() inDialog: boolean;
  @Input() columnView: boolean;
  @Input() rerender = 0;
  @ViewChild('table') private table: ListTableComponent;
  public fieldDataList: ListItem[] = [];

  constructor(private localizer: LocalizeService) {
  }

  ngOnChanges() {
    this.fieldDataList = this.parent.getList(this.field.listKind);
    setTimeout(() => {
      if (!!this.table) {
        this.table.setOpenedItem(null);
      }
    }, 1);
  }

  private getFileMessage(): string {
    const nItems = this.fieldDataList ? this.fieldDataList.length : 0;
    if (nItems===1 && this.fieldDataList[0].DOCNAME) {
      return this.fieldDataList[0].DOCNAME;
    }
    return this.localizer.getTranslation('PAGINATOR.COUNT',[''+nItems]);
  }

  private showLessList(): boolean {
    if (Util.Device.ui<2) {
      return this.fieldDataList.length===1;
    }
    return false;
  }
}
