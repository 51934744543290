import { Component, Input, Output, OnInit, OnChanges, ViewChild, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

import { Util, UserInterface } from '../utils/utils.module';
import { BaseDesc } from '../models/base';
import { ListItem } from '../models/list-item';
import { SecurityControl, AccessRights, AccessLevel, AccessSearch } from '../models/security-control';
import { SelectItem } from '../models/form-field';
import { LocalizeService } from '../services/localize.service';
import { ListService } from '../services/list.service';
import { MenuId } from '../services/menu.service';
import { FooterDisplayItem } from '../widgets/list-info-footer.component';
import { ActionBarComponent } from '../widgets/action-bar.component';
import { ListTableComponent, ListTableParent } from './list-table.component';

@Component ({
  selector: 'edx-list-security',
  styleUrls: [ 'list-security.component.scss' ],
  providers: [ListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-template [ngIf]="ui<2">
      <edx-action-bar [menuID]="actionMenuId" [target]="this" #actionbar></edx-action-bar>
      <div *ngIf="massProfile" class="selwrapper">
        <edx-select id="edx_security_option" [items]="trusteesDirectives" [value]="trusteesDirective" (change)="setRrusteesDirective($event)"></edx-select>
      </div>
      <div class="list" [ngClass]="{footer:showFooter, disablelist:isReadOnly, massprofile:massProfile}">
        <edx-list-table #table *ngIf="detailView" (detailviewnotify)='onGranularRightChange($event)'  [schemaId]="'ACCESS_CONTROL'" [desc]="desc" [params]="'security'" [inlineActionMenuId]="6" [inlineActionTarget]="this" [parent]="this"></edx-list-table>
        <edx-list-table #table *ngIf="!detailView" (summaryviewnotify)='onTemplateRightChange($event)' [schemaId]="'SECURITY'" [desc]="desc" [params]="'security'" [inlineActionMenuId]="6" [inlineActionTarget]="this" [parent]="this"></edx-list-table>
      </div>
      <edx-list-info-footer *ngIf="showFooter" [displayItems]="footerItems" [heading]="footerHeading" ></edx-list-info-footer>
    </ng-template>
    <ng-template [ngIf]="ui>=2">
      <edx-action-bar *ngIf="tablet" [menuID]="actionMenuId" [target]="this" [forceDekstopLook]="true" #actionbar></edx-action-bar>
      <div class="header">{{permissions}}</div>
      <div *ngIf="massProfile" class="selwrapper">
        <edx-select id="edx_security_option" [items]="trusteesDirectives" [value]="trusteesDirective" (change)="setRrusteesDirective($event)"></edx-select>
      </div>
      <div class="list mobile" [ngClass]="{tablet:tablet, ios:iOS, massprofile:massProfile}">
        <edx-list-mobile #table (summaryviewnotify)='onTemplateRightChange($event)' [schemaId]="'SECURITY'" [desc]="desc" [params]="'security'" [inlineActionMenuId]="6" [inlineActionTarget]="this" [parent]="this" [splitView]="splitView" [hasFootprint]="inDialog" [hasHeader]="true"></edx-list-mobile>
      </div>
      <edx-action-bar *ngIf="!tablet" [menuID]="actionMenuId" [target]="this" #actionbar></edx-action-bar>
    </ng-template>
  `
})
export class ListSecurityComponent implements OnInit, OnChanges, ListTableParent {
  public ui: UserInterface;
  private actionMenuId: MenuId;
  private rightsLabels: any[];
  private allowedList: string = null;
  private selectedListItems: ListItem[];
  private footerItems: FooterDisplayItem[] = [];
  private footerHeading: string = null;
  private deniedList: string = null;
  private isDirty = false;
  private detailView = false;
  private permissions: string;
  private tablet: boolean = Util.Device.isTabletLook();
  private iOS: boolean = Util.Device.bIsIOSDevice;
  private isWorkspaceOnMobile = false;
  private disableInherited = false;
  private trusteesDirectives: SelectItem[];
  @Input() kind: string;
  @Input() desc?: BaseDesc;
  @Input() showFooter?: boolean;
  @Input() isReadOnly: boolean;
  @Input() securityList: ListItem[];
  @Input() splitView?: boolean;
  @Input() inDialog?: boolean;
  @Input() autoAdd?: boolean;
  @Input() massProfile?: boolean;
  @Input() trusteesDirective?: string;
  @Output() notifySecurityDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notifySecurityReadOnly: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() trusteesDirectiveChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('actionbar') actionBar: ActionBarComponent;
  @ViewChild('table') private table: ListTableComponent;

  constructor(protected localizer: LocalizeService, protected listService: ListService) {
    this.ui = Util.Device.ui;
    this.actionMenuId = this.ui<2 ? MenuId.MENU_SECURITY_ACTIONS : MenuId.MENU_MOBILE_SECURITY_ACTIONS;
    this.permissions = this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.PERMISSIONS');
    this.trusteesDirectives = [
      {display: this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.REPLACE_TRUSTEES'), value: 'replace'},
      {display: this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.ADD_TRUSTEES'), value: 'add'},
      {display: this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.REMOVE_TRUSTEES'), value: 'remove'}
    ];
  }

  ngOnInit(): void {
    if (this.showFooter) {
      this.setFooterLabels();
    }
    if (Util.RestAPI.restAPIVersion() <= 0x00160301) {
      this.disableInherited = true;
    }
    this.isWorkspaceOnMobile = (!!this.desc && this.desc.type === 'workspaces') && Util.Device.isPhoneLook();
  }

  ngOnChanges(): void {
    this.updateActionBar();
  }

  private setFooterLabels(): void {
    this.rightsLabels = [];
    this.rightsLabels.push({ value: AccessRights.ACCESS_VIEW_PROFILE, label: this.localizer.getTranslation('SECURITY.RIGHTS.VIEW_PROFILE')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_EDIT_PROFILE, label: this.localizer.getTranslation('SECURITY.RIGHTS.EDIT_PROFILE')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_VIEW_DOCUMENT, label: this.localizer.getTranslation('SECURITY.RIGHTS.VIEW_DOCUMENT')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_RETRIEVE_DOCUMENT, label: this.localizer.getTranslation('SECURITY.RIGHTS.RETRIEVE_DOCUMENT')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_EDIT_CONTENT, label: this.localizer.getTranslation('SECURITY.RIGHTS.EDIT_CONTENT')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_COPY, label: this.localizer.getTranslation('SECURITY.RIGHTS.COPY')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_DELETE, label: this.localizer.getTranslation('SECURITY.RIGHTS.DELETE')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_CONTROL_ACCESS, label: this.localizer.getTranslation('SECURITY.RIGHTS.CONTROL_ACCESS')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED, label: this.localizer.getTranslation('SECURITY.RIGHTS.ALLOW_VIEW_PUBLISHED')});
  }

  private setRrusteesDirective(sel: SelectItem): void {
    this.trusteesDirective = sel.value;
    this.trusteesDirectiveChange.emit(this.trusteesDirective);
  }

  private onTemplateRightChange(right: number): void {
    if (right === -1) {
      this.detailView = !this.detailView;
      this.securityList  = this.table.getList();
      setTimeout(() => {
        this.table.setTrusteeList(this.securityList);
      }, 300);
    }
    this.isDirty = true;
    this.notifySecurityDirty.emit(this.isDirty);
    this.actionBar.updateActions();
  }

  private onGranularRightChange(right: boolean): void {
    this.isDirty = true;
    this.notifySecurityDirty.emit(this.isDirty);
    this.actionBar.updateActions();
  }

  private updateListFooter(): void {
    this.footerItems = [];
    this.footerHeading = null;
    if (this.selectedListItems && this.selectedListItems.length === 1) {
      const listItem: ListItem = this.selectedListItems[0];
      this.enumerateRights(listItem['rights']);
      this.footerHeading = this.localizer.getTranslation('METADATA.FOOTER.SECURITY.HEADER', [ listItem['USER_ID'] ]);
      if (this.desc.type === 'workspaces') {
        let accessRighsInfo = 'METADATA.FOOTER.SECURITY.ALLOW_WORKSPACE_VIEW';
        if ((listItem['rights'] & AccessLevel.ACCESS_LEVEL_MANAGE) === AccessLevel.ACCESS_LEVEL_MANAGE) {
          accessRighsInfo = 'METADATA.FOOTER.SECURITY.ALLOW_WORKSPACE_MANAGE';
        } else if ((listItem['rights'] & AccessLevel.ACCESS_LEVEL_COLLABORATE) === AccessLevel.ACCESS_LEVEL_COLLABORATE) {
          accessRighsInfo = 'METADATA.FOOTER.SECURITY.ALLOW_WORKSPACE_COLLABORATE';
        }
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.ALLOW'), value: this.localizer.getTranslation(accessRighsInfo) });
      } else {
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.ALLOW'), value: this.allowedList });
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.DENY'), value: this.deniedList });
      }
    }
  }

  private enumerateRights(rights: number) {
    const access: SecurityControl = new SecurityControl(rights);
    this.allowedList = this.deniedList = '';
    for (const right of this.rightsLabels) {
      if (rights & right.value) {
        if (this.allowedList.length) {
          this.allowedList += ', ' + right.label;
        } else {
          this.allowedList += right.label;
        }
      } else {
        if (this.deniedList.length) {
          this.deniedList += ', ' + right.label;
        } else {
          this.deniedList += right.label;
        }
      }
    }
  }

  public getList(): ListItem[] {
    return this.securityList = this.table.getList();
  }

  public setDirty(dirty: boolean): void {
    this.isDirty = dirty;
  }

  private checkUserRights(): void {
    const trustees: ListItem[] = this.getList();
    const thisUser: string = Util.RestAPI.getUserID();
    for (const trustee  of trustees) {
      if (trustee['USER_ID'].toUpperCase() === thisUser.toUpperCase()) {
        const rights = trustee['rights'];
        const canEdit = (rights === AccessLevel.ACCESS_LEVEL_FULL || rights === AccessLevel.ACCESS_LEVEL_FULL_RM || rights === AccessLevel.ACCESS_LEVEL_CREATOR) || (this.desc && this.desc.type === 'searches' && ((rights & AccessSearch.VIEW_EDIT) === AccessSearch.VIEW_EDIT));
        if ((!canEdit && !this.isReadOnly) || (canEdit && this.isReadOnly)) {
          this.isReadOnly = !canEdit;
          this.notifySecurityReadOnly.emit(this.isReadOnly);
        }
        break;
      }
    }
    if (this.desc['STORAGE'] === 'T') {
      const effectiveRights: any = Util.RestAPI.getLoginReply() && Util.RestAPI.getLoginReply().EFFECTIVE_RIGHTS ? Util.RestAPI.getLoginReply().EFFECTIVE_RIGHTS : {};
      if (effectiveRights.TEMPLATE_MANAGER==='N') {
        this.isReadOnly = true;
        this.notifySecurityReadOnly.emit(this.isReadOnly);
      }
    }
    if (this.desc && (['3', '18', '19'].indexOf(this.desc['STATUS']) > -1 || this.desc['READONLY'] === 'Y')) {
      if (this.desc['SECURITY'] !== '1') {
        this.isReadOnly = true;
      }
    }
  }

  // **** ListTableParent implementation
  public listUpdated(table: ListTableComponent): void {
    this.selectedListItems = this.table.getSelections();
    this.checkUserRights();
    if (this.actionBar) {
      this.actionBar.updateActions();
    }
    if (this.showFooter) {
      this.updateListFooter();
    }
    if (this.autoAdd) {
      this.doCommand('security_add');
      this.autoAdd = false;
    }
  }

  public selectionsUpdated(table: ListTableComponent): void {
    this.listUpdated(table);
  }

  public canSelectListItem(table: ListTableComponent, item: ListItem): boolean {
    if (!!item && (item['USER_ID']!==this.desc['AUTHOR_ID']) && (Util.RestAPI.getUserID()!==item['USER_ID'])) {
      return true;
    }
    return false;
  }

  public updateActionBar(): void {
    if (this.actionBar) {
      this.actionBar.updateActions();
    }
  }

  public initialList(table: ListTableComponent): ListItem[] {
    return this.securityList;
  }

  public reloadList(): void {
    this.table.clearList();
    this.desc['edx_selected_security_choice']='';
    setTimeout(() => {
      this.table.loadList(true);
     }, 10);
     this.securityList = this.table.getList();
  }

  // **** CommandHandler implementation
  public commandEnabled(cmd: string): boolean {
    let enabled = false;
    if (!!this.desc) {
      switch (cmd) {
      case 'security_add':
        if (!this.isReadOnly) {
          enabled = true;
        }
        break;
      case 'security_save':
        enabled = this.isDirty;
        break;
      case 'security_summary':
        enabled = this.detailView;
        break;
      case 'security_details':
        enabled = this.desc.type !== 'searches' && this.desc.type !== 'workspaces' && this.desc['isWorkspace']!== '1' && !this.detailView;
        break;
      case 'security_remove':
        enabled = false;
        if (this.selectedListItems && (this.selectedListItems.length > 0)) {
          const myAuthorID = !!this.desc['AUTHOR_ID'] ? this.desc['AUTHOR_ID'].toUpperCase() : '';
          const myUserID = Util.RestAPI.getUserID();
          enabled = !this.selectedListItems.some(item => {
            const userID = item['USER_ID'].toUpperCase();
            if (userID === myUserID || userID === myAuthorID) {
              return true;
            }
            return false;
          });
        }
        break;
      }
    }
    return enabled;
  }

  public doCommand(cmd: string): boolean {
    let handled = true;
    let data = null;
    const newcmd: string = cmd;

    switch (cmd) {
    case 'security_save':
      handled = false;
      this.isDirty = false;
      if (this.isReadOnly) {
        handled = true;
        this.table.clearList();
        setTimeout(() => {
          this.table.loadList();
        }, 300);
        this.isDirty = true;
      } else if (this.desc['SECURITY'] !== '1' || this.desc['SEC_REASON_LINK'] !== '1') {
        this.desc['SECURITY'] = '1';
        this.desc['SEC_REASON_LINK'] = '1';
      }
      this.table.spinnerOn();
      this.securityList = this.table.getList();
      data = this.securityList;
      Util.RestAPI.setCurList([new ListItem(this.desc)], null);
      Util.RestAPI.setCurListComponent(this.table);
      setTimeout(() => {
        this.notifySecurityDirty.emit(this.isDirty);
      }, 300);
      break;
    case 'security_summary':
    case 'security_details':
      this.detailView = !this.detailView;
      this.securityList = this.table.getList();
      setTimeout(() => {
        this.table.setTrusteeList(this.securityList);
      }, 300);
    break;
    case 'security_add':
      Util.RestAPI.pickGroupsUsers((pickedList: any[], success: boolean) => {
        if (success && pickedList && pickedList.length) {
          const newTrusteeOrGroupAdded: boolean = this.table.addTrusteeToList(pickedList);
          this.securityList = this.table.getList();
          if (newTrusteeOrGroupAdded) {
            this.isDirty = true;
            this.notifySecurityDirty.emit(this.isDirty);
            this.actionBar.updateActions();
          }
        }
      }, this.table.getList());
      break;
    case 'security_remove':
      const selections: ListItem[] = this.table.getSelections();
      const list: ListItem[] = this.table.getList();
      if (list && list.length && selections && selections.length) {
        const indexesToDelete: number[] = [];
        for (const listItem of selections) {
          indexesToDelete.push(list.indexOf(listItem));
        }
        // we want big index to little index for delete so list does not reindex when deleting
        indexesToDelete.sort((a,b) => b-a);
        for (const index of indexesToDelete) {
          list.splice(index, 1);
        }
        this.securityList = list;
        this.isDirty = true;
        this.notifySecurityDirty.emit(this.isDirty);
        this.table.clearSelection();
      }
      break;
    }
    this.selectedListItems = [];
    if (this.actionBar) {
      this.actionBar.updateActions();
    }
    if (!handled) {
      handled = Util.RestAPI.getAppComponent().doCommand(newcmd, data);
    }
    return handled;
  }
}
