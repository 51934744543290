/**
 * Created by kevin on 2016-12-16.
 *
 *   Descriptor class for security control on an eDOCS object.  A securityControl object has a 'local' access level (its
 *   own selected rights) and a 'contextual' access level, which is configured by passing in its 'parent' SecurityControl
 *   object.  This class has an interface that differentiates in that way.
 *
 */
export enum AccessRights {
  ACCESS_VIEW_PROFILE           = 1,
  ACCESS_EDIT_PROFILE           = 2,
  ACCESS_VIEW_DOCUMENT          = 4,
  ACCESS_RETRIEVE_DOCUMENT      = 8,
  ACCESS_EDIT_CONTENT           = 16,
  ACCESS_COPY                   = 32,
  ACCESS_DELETE                 = 64,
  ACCESS_CONTROL_ACCESS         = 128,
  ACCESS_CAN_ASSIGN_TO_FILEPART = 256,
  ACCESS_ALLOW_VIEW_PUBLISHED   = 512,
}

export enum AccessLevel {
  ACCESS_LEVEL_CUSTOM           = -1,
  ACCESS_LEVEL_VIEW_PROFILE     = 1,
  ACCESS_LEVEL_READ_ONLY        = 45,
  ACCESS_LEVEL_NORMAL           = 63,
  ACCESS_LEVEL_FULL             = 255,
  ACCESS_LEVEL_FULL_RM          = 511,
  ACCESS_LEVEL_VIEW             = 1,
  ACCESS_LEVEL_COLLABORATE      = 63,
  ACCESS_LEVEL_MANAGE           = 255,
  ACCESS_LEVEL_COLLABORATE_CWT  = 127, // SECURITY VALUES FOR WORKSPACES CREATED OR UPDATED FROM CWT.
  ACCESS_LEVEL_VIEW_CWT         = 45,
  ACCESS_LEVEL_CREATOR          = 32255
}

export enum AccessSearch {
  VIEW                  = 1,
  EDIT                  = 2,
  VIEW_EDIT             = 3,
  DELETE                = 4,
  VIEW_EDIT_DELETE      = 7,
  CREATOR               = 255
}

export enum AccessType {
  AT_NONE   = 0,
  AT_ALLOW  = 1,
  AT_DENY   = 2
}

export class SecurityControl {
  private _access: number;

  constructor(access?: number) {
    this._access = (typeof access !== undefined) ? access : 0;
  }

  get access(): number {
    return this._access;
  }

  set access(access: number) {
    this._access = access;
  }

  get accessLevel(): number {
    let level: number;

    switch (this._access) {
      case AccessLevel.ACCESS_LEVEL_VIEW_PROFILE:
      case AccessLevel.ACCESS_LEVEL_READ_ONLY:
      case AccessLevel.ACCESS_LEVEL_NORMAL:
      case AccessLevel.ACCESS_LEVEL_FULL:
        level = this._access; break;
      default:
        level = AccessLevel.ACCESS_LEVEL_CUSTOM;
    }
    return level;
  }

  // **** access flag accessors

  get canViewProfile(): boolean {
    return ((this._access & AccessRights.ACCESS_VIEW_PROFILE)=== AccessRights.ACCESS_VIEW_PROFILE);
  }

  get canEditProfile(): boolean {
    return ((this._access & AccessRights.ACCESS_EDIT_PROFILE) === AccessRights.ACCESS_EDIT_PROFILE);
  }

  get canViewDocument(): boolean {
    return ((this._access & AccessRights.ACCESS_VIEW_DOCUMENT) === AccessRights.ACCESS_VIEW_DOCUMENT);
  }

  get canRetrieveDocument(): boolean {
    return ((this._access & AccessRights.ACCESS_RETRIEVE_DOCUMENT) === AccessRights.ACCESS_RETRIEVE_DOCUMENT);
  }

  get canEditContent(): boolean {
    return ((this._access & AccessRights.ACCESS_EDIT_CONTENT) === AccessRights.ACCESS_EDIT_CONTENT);
  }

  get canCopy(): boolean {
    return ((this._access & AccessRights.ACCESS_COPY) ===  AccessRights.ACCESS_COPY);
  }

  get canDelete(): boolean {
    return ((this._access & AccessRights.ACCESS_DELETE) === AccessRights.ACCESS_DELETE);
  }

  get canControlAccess(): boolean {
    return ((this._access & AccessRights.ACCESS_CONTROL_ACCESS) === AccessRights.ACCESS_CONTROL_ACCESS);
  }

  get canAssignToFilePart(): boolean {
    return ((this._access & AccessRights.ACCESS_CAN_ASSIGN_TO_FILEPART) === AccessRights.ACCESS_CAN_ASSIGN_TO_FILEPART);
  }

  get canAllowViewPublished(): boolean {
    return ((this._access & AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED) === AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED);
  }

  get denyRightsToSetOnDeny(): number {
    let level: number;
    switch (this._access) {
      case AccessRights.ACCESS_VIEW_PROFILE:
        level = AccessRights.ACCESS_VIEW_PROFILE | AccessRights.ACCESS_EDIT_PROFILE| AccessRights.ACCESS_VIEW_DOCUMENT |
        AccessRights.ACCESS_EDIT_CONTENT| AccessRights.ACCESS_RETRIEVE_DOCUMENT| AccessRights.ACCESS_COPY| AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS | AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED;
        break;
      case AccessRights.ACCESS_EDIT_PROFILE:
        level = AccessRights.ACCESS_EDIT_PROFILE | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_VIEW_DOCUMENT:
        level = AccessRights.ACCESS_VIEW_DOCUMENT | AccessRights.ACCESS_RETRIEVE_DOCUMENT | AccessRights.ACCESS_EDIT_CONTENT | AccessRights.ACCESS_COPY | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_RETRIEVE_DOCUMENT:
        level = AccessRights.ACCESS_RETRIEVE_DOCUMENT | AccessRights.ACCESS_EDIT_CONTENT | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_EDIT_CONTENT:
        level = AccessRights.ACCESS_EDIT_CONTENT |  AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_COPY:
        level =  AccessRights.ACCESS_COPY |  AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_DELETE:
        level = AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_CONTROL_ACCESS:
        level =  AccessRights.ACCESS_CONTROL_ACCESS;
        break;
    }
    return level;
  }

  get rightsToSet(): number {
    let level: number;
    switch (this._access) {
      case AccessRights.ACCESS_VIEW_PROFILE:
        level = AccessRights.ACCESS_VIEW_PROFILE;
        break;
      case AccessRights.ACCESS_EDIT_PROFILE:
        level = AccessRights.ACCESS_VIEW_PROFILE | AccessRights.ACCESS_EDIT_PROFILE;
        break;
      case AccessRights.ACCESS_VIEW_DOCUMENT:
        level = AccessRights.ACCESS_VIEW_PROFILE | AccessRights.ACCESS_VIEW_DOCUMENT;
        break;
      case AccessRights.ACCESS_RETRIEVE_DOCUMENT:
        level = AccessRights.ACCESS_VIEW_PROFILE | AccessRights.ACCESS_VIEW_DOCUMENT | AccessRights.ACCESS_RETRIEVE_DOCUMENT;
        break;
      case AccessRights.ACCESS_EDIT_CONTENT:
        level = AccessRights.ACCESS_VIEW_PROFILE | AccessRights.ACCESS_VIEW_DOCUMENT | AccessRights.ACCESS_RETRIEVE_DOCUMENT | AccessRights.ACCESS_RETRIEVE_DOCUMENT | AccessRights.ACCESS_EDIT_CONTENT;
        break;
      case AccessRights.ACCESS_COPY:
        level = AccessRights.ACCESS_VIEW_PROFILE | AccessRights.ACCESS_VIEW_DOCUMENT | AccessRights.ACCESS_COPY;
        break;
      case AccessRights.ACCESS_DELETE:
        level = AccessRights.ACCESS_VIEW_PROFILE | AccessRights.ACCESS_EDIT_PROFILE | AccessRights.ACCESS_VIEW_DOCUMENT | AccessRights.ACCESS_EDIT_CONTENT | AccessRights.ACCESS_RETRIEVE_DOCUMENT | AccessRights.ACCESS_COPY| AccessRights.ACCESS_DELETE;
        break;
      case AccessRights.ACCESS_CONTROL_ACCESS:
        level = AccessRights.ACCESS_VIEW_PROFILE | AccessRights.ACCESS_EDIT_PROFILE | AccessRights.ACCESS_VIEW_DOCUMENT | AccessRights.ACCESS_EDIT_CONTENT | AccessRights.ACCESS_RETRIEVE_DOCUMENT | AccessRights.ACCESS_COPY | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED:
        level = AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED;
        break;
    }
    return level;
  }

  get rightsToUnSet(): number {
    let level: number;
    switch (this._access) {
      case AccessRights.ACCESS_VIEW_PROFILE:
        level = AccessRights.ACCESS_VIEW_PROFILE | AccessRights.ACCESS_EDIT_PROFILE | AccessRights.ACCESS_VIEW_DOCUMENT | AccessRights.ACCESS_RETRIEVE_DOCUMENT | AccessRights.ACCESS_EDIT_CONTENT | AccessRights.ACCESS_COPY | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_EDIT_PROFILE:
        level =  AccessRights.ACCESS_EDIT_PROFILE | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_VIEW_DOCUMENT:
        level = AccessRights.ACCESS_VIEW_DOCUMENT | AccessRights.ACCESS_RETRIEVE_DOCUMENT | AccessRights.ACCESS_EDIT_CONTENT | AccessRights.ACCESS_COPY | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_RETRIEVE_DOCUMENT:
        level = AccessRights.ACCESS_RETRIEVE_DOCUMENT | AccessRights.ACCESS_EDIT_CONTENT | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_EDIT_CONTENT :
        level = AccessRights.ACCESS_EDIT_CONTENT | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_COPY:
        level = AccessRights.ACCESS_COPY | AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_DELETE:
        level = AccessRights.ACCESS_DELETE | AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_CONTROL_ACCESS:
        level = AccessRights.ACCESS_CONTROL_ACCESS;
        break;
      case AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED:
        level = AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED;
        break;
    }
    return level;
  }
}

export enum DynamicViewRights {
  DVRIGHTS_READ_DV		          = 0x01,	// Bit 0 = Can see the Dynamic View
 Â DVRIGHTS_EDIT_DV		          = 0x02,	// Bit 1 = Can edit this Dynamic View
 Â DVRIGHTS_READ_SUBSCR	        = 0x04,	// Bit 2 = Can see the Subscribed View Node
 Â DVRIGHTS_READ_RECENT	        = 0x08,	// Bit 3 = Can see the Recent View Node
 Â DVRIGHTS_READ_ALL             = 0x10,	// Bit 4 = Can see the âAllâ node for the view
 Â DVRIGHTS_EDIT_SUBSCR          = 0x20,	// Bit 5 = Can subscribe to the view (My Subscriptions Node for the view).They would need âCan see subscribed viewâ bit enabled to be able to set this extra security permission
 Â DVRIGHTS_EDIT_SECURITY        = 0x40,	// Bit 6 â Can set security against a level node in this dynamic view, for inheritance purposes when creating items under the dynamic view
 Â DVRIGHTS_EDIT_FOLDERS         = 0x80,	// Bit 7 â Can add/create folders at levels where defined as supporting adhoc foldering
}
