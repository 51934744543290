import { Component, Input, Output, OnChanges, EventEmitter, SimpleChange, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

import { SelectItem } from '../models/form-field';
import { Util } from '../utils/utils.module';

export class DropDown {
  public _items: SelectItem[] = [];
  public ulWidth = '';
  public ulLeft: string = null;
  public ulRight = false;
  public ulTop: string = null;
  public currentItem: SelectItem = null;
  public isOpen = false;
  public isPhone: boolean = Util.Device.isPhoneLook();
  public iOS: boolean = Util.Device.bIsIOSDevice;
  public inNofityDialog = false;
  private disabledScrollableItems: HTMLElement[] = [];

  // overrides
  constructor(protected cdr: ChangeDetectorRef) { }
  protected getNativeUL(): HTMLUListElement {
    return null;
  }
  protected itemSelected(item: SelectItem, event: Event): boolean {
    return false;
  }

  protected toggleMenuOpen(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  protected checkLayout(selNativeEl: HTMLElement, ulNativeEl: HTMLUListElement): void {
    if (!!selNativeEl) {
      let topParentWidth: number = window.innerWidth;
      let topParentHeight: number = window.innerHeight;
      const pageOffset = (element) => {
        let top = 0; let left = 0;
        do {
          top -= element.scrollTop || 0;
          if (element.tagName !== 'TBODY') {
            top += element.offsetTop  || 0;
          }
          left += element.offsetLeft || 0;
          if (element.tagName === 'TD') {
            element = element.parentElement.parentElement;  // go up past the tr to the tbody
          } else {
            if (!!element.parentElement && element.parentElement !== element.offsetParent && element.parentElement.scrollTop) {
              top -= element.parentElement.scrollTop;
            }
            element = element.offsetParent;
          }
          if (!!element) {
            if (element.classList.contains('popup')) {
              if (Util.Device.bIsIE) {
                const rect = element.getBoundingClientRect();
                top += rect.top;
                left += rect.left;
              } else {
                topParentHeight = element.clientHeight;
                topParentWidth = element.clientWidth;
              }
              element = null;
            } else {
              this.disabledScrollableItems.push(element);
              element.classList.add('edx_noscroll');
            }
          }
        } while (element);
        return {
          top,
          left
        };
      };
      const offset = pageOffset(selNativeEl);
      this.ulLeft = offset.left.toString() + 'px';
      this.ulRight = false;
      this.ulTop = (this.inNofityDialog ? '64' : offset.top.toString()) + 'px';
      setTimeout(() => {
        const ulHeight: number = !!ulNativeEl ? (ulNativeEl.clientHeight + 8) : 0;
        const ulWidth: number = !!ulNativeEl ? ulNativeEl.clientWidth : 0;
        if ((offset.top + ulHeight) > topParentHeight) {
          offset.top = topParentHeight - ulHeight;
          this.ulTop = (this.inNofityDialog ? '64' : offset.top.toString()) + 'px';
          this.cdr.markForCheck();
        }
        if ((offset.left + ulWidth) > topParentWidth) {
          this.ulLeft = null;
          this.ulRight = true;
          this.cdr.markForCheck();
        }
      }, 1);
    }
  }

  protected menuClosing(): void {
    this.disabledScrollableItems.forEach(element => {
      element.classList.remove('edx_noscroll');
    });
    this.disabledScrollableItems = [];
  }

  private scrollIntoView(index: number): void {
    const ulNativeEl: HTMLUListElement = this.getNativeUL();
    if (!!ulNativeEl) {
      const currentScrollTop: number = ulNativeEl.scrollTop;
      const ulHeight: number = ulNativeEl.offsetHeight;
      const curLI: HTMLLIElement = Array.from(ulNativeEl.children)[index] as HTMLLIElement;
      const liTop = curLI.offsetTop;
      if ((liTop < currentScrollTop) || (liTop > (currentScrollTop+ulHeight))) {
        ulNativeEl.scrollTop = liTop;
      }
    }
  }

  private nav(inc: number): void {
    let index: number = this._items.indexOf(this.currentItem) + inc;
    if (index<0) {
      index = 0;
    } else if (index>=this._items.length) {
      index = this._items.length-1;
    }
    this.currentItem = this._items[index];
    this.scrollIntoView(index);
  }

  protected onKeyup(event: KeyboardEvent): boolean {
    let rc = true;
    if (this.isOpen) {
      switch (event.which) {
        case 13: this.itemSelected(null, event); rc = false; break;
        case 27: this.toggleMenuOpen(event); rc = false; break;
        case 38: this.nav(-1); rc = false; break;
        case 40: this.nav(1); rc = false; break;
      }
    } else {
      switch (event.which) {
       case 32: this.toggleMenuOpen(event); rc = false; break;
      }
    }
    if (!rc) {
      event.stopPropagation();
      event.preventDefault();
    }
    return rc;
  }
}

@Component({
  selector: 'edx-select',
  styleUrls: ['select.component.scss'],
  template: `
    <ng-template [ngIf]="useNativeSelect && !disabled">
      <div #nativeDiv *ngIf="!justButton" class="edx_select" [ngClass]="{disabled:disabled, savesearchselect:id==='$edx_savesearch_combo', justbutton:justButton, header:inHeader, formselect:id==='formselect', indialog:inDialog, phone:isPhone, accessrights: inAccessRights}">
        <div class="text">{{initialItem ? initialItem.display : value}}</div>
      </div>
      <select #nativeSel [id]="id" (change)="selChange($event)" [required]="required || null" [ngClass]="{justbutton:justButton,formselect:id==='formselect',phone:isPhone,ios:iOS,indialog:inDialog}" tabindex="0">
        <option *ngIf="initialPlaceHolder" selected hidden value=" ">{{initialPlaceHolder}}</option>
        <option *ngFor="let item of _items" value="{{item.value}}" [disabled]="item.disabled" [selected]="isItemSelected(item) || null">{{item.display}}</option>
      </select>
      <div *ngIf="justButton" class="edx_select" [ngClass]="{disabled:disabled, open:isOpen, savesearchselect:id==='$edx_savesearch_combo', justbutton:justButton, accessrights: inAccessRights}" tabindex="0"></div>
    </ng-template>
    <ng-template [ngIf]="!useNativeSelect || disabled">
      <div #sel class="edx_select" [ngClass]="{singleitem:_items?.length===0, disabled:disabled, open:isOpen, savesearchselect:id==='$edx_savesearch_combo', justbutton:justButton, header:inHeader, formselect:id==='formselect', indialog:inDialog, phone:isPhone, accessrights: inAccessRights}" (click)="toggleMenuOpen($event)" tabindex="0">
        <div *ngIf="!justButton" class="text">{{initialItem ? initialItem.display : value}}</div>
      </div>
      <div *ngIf="isOpen" class="overlay" (click)="toggleMenuOpen($event)"></div>
      <ul *ngIf="_items?.length!==0" #ul [ngClass]="{edx_hidden:!isOpen, right:ulRight, innotify:inNotify}" [style.top]="ulTop" [style.left]="ulLeft" [style.width]="ulWidth">
        <li *ngIf="initialPlaceHolder" selected hidden value=" ">
          <div class="item-container">
            <div class="text">{{initialPlaceHolder}}</div>
          </div>
        </li>
        <li *ngFor="let item of _items" [ngClass]="{selected:isItemSelected(item), disabled:item.disabled, heading:item.heading}" (click)="itemSelected(item, $event)">
          <div class="text">{{item.display}}</div>
        </li>
      </ul>
    </ng-template>
  `
})
export class SelectComponent extends DropDown implements OnChanges {
  @Input('items') set setter(items: SelectItem[]) {
    this._items = items;
  }
  @Input() value?: any = null;
  @Input() disabled?: boolean = false;
  @Input() required?: boolean = false;
  @Input() justButton?: boolean = false;
  @Input() id?: string;
  @Input() initialPlaceHolder?: string;
  @Input() inDialog?: boolean = false;
  @Input() inNotify?: boolean = false;
  @Input() inHeader?: boolean = false;
  @Input() inAccessRights?: boolean = false;
  @Output() change: EventEmitter<SelectComponent> = new EventEmitter<SelectComponent>();
  @ViewChild('ul') private ul: ElementRef;
  @ViewChild('sel') private sel: ElementRef;
  @ViewChild('nativeSel') private nativeSel: ElementRef;
  @ViewChild('nativeDiv') private nativeDiv: ElementRef;
  public useNativeSelect = false;
  private initialItem: SelectItem = null;

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    const valueChng: any = changes['value'];
    const itemsChng: any = changes['items'];
    this.useNativeSelect = !this.inAccessRights && !Util.Device.bIsOfficeAddin && Util.Device.bIsTouchDevice;
    if (valueChng || itemsChng) {
      if (this.value !== null && this._items && this._items.length > 0) {
        this.initialItem = this.currentItem = this._items.find(i => {
          const selVal: any = i.value;
          const selType: string = typeof selVal;
          let dataVal: any = this.value;
          const dataType: string = typeof dataVal;
          if (dataType !== selType) {
            switch (selType ) {
              case 'string':
                dataVal = dataVal.toString();
                break;
              case 'number':
                if (dataType === 'boolean' && dataVal === false) {
                  dataVal = 0;
                } else if (dataType === 'boolean' && dataVal === true) {
                  dataVal = 1;
                } else if (dataType === 'string') {
                  dataVal = parseInt(dataVal);
                }
                break;
              case 'booelan':
                if (dataType === 'number' && dataVal===0) {
                  dataVal = false;
                } else if (dataType === 'number' && dataVal!==0) {
                  dataVal = true;
                }
                break;
            }
          }
          return selVal === dataVal;
        });
      }
    }
    this.inNofityDialog = this.inNotify;
    setTimeout(() => {
      this.onResize();
    }, 1);
  }

  public isItemSelected(item: SelectItem): boolean {
    return !!item && !!this.currentItem && item.value === this.currentItem.value;
  }

  public getItems(): SelectItem[] {
    return this._items;
  }

  protected getNativeUL(): HTMLUListElement {
    return !!this.ul ? this.ul.nativeElement : null;
  }

  protected toggleMenuOpen(event: Event): void {
    if (!this.disabled) {
      const select: HTMLSelectElement = this.nativeSel ? (this.nativeSel.nativeElement as HTMLSelectElement) : null;
      if (!!select) {
        select.click();
      } else {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
          this.checkLayout(!!this.sel ? this.sel.nativeElement : null , !!this.ul ? this.ul.nativeElement : null);
        } else {
          this.menuClosing();
        }
      }
    }
    super.toggleMenuOpen(event);
  }

  protected itemSelected(item: SelectItem, event: Event): boolean {
    if (this.isOpen) {
      this.initialItem = this.currentItem = (item || this.currentItem);
      this.value = this.currentItem.value;
      this.change.emit(this);
      this.toggleMenuOpen(event);
    } else if (this.useNativeSelect) {
      this.change.emit(this);
    }
    return false;
  }

  @HostListener('keyup', ['$event'])
  protected onKeyup(event: KeyboardEvent): boolean {
    return super.onKeyup(event);
  }

  @HostListener('window:resize')
  private onResize(): void {
    if (this.nativeDiv && this.nativeSel) {
      this.nativeSel.nativeElement.style.top = this.nativeDiv.nativeElement.offsetTop + 'px';
      this.nativeSel.nativeElement.style.left = this.nativeDiv.nativeElement.offsetLeft + 'px';
    }
  }

  private selChange(event: Event): void {
    this.value = (event.currentTarget as HTMLInputElement).value;
    this.currentItem = this._items.find(i => i.value===this.value);
    this.itemSelected(this.currentItem, event);
    event.stopPropagation();
    event.preventDefault();
  }
}
