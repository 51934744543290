import { Component, OnInit, Input, HostListener, ChangeDetectorRef, ViewChild, AfterViewChecked, ElementRef, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LocalizeService } from './services/localize.service';
import { DataService } from './services/data.service';
import { FormService } from './services/form.service';
import { SchemaService } from './services/schema.service';
import { ProfileService } from './services/profile.service';
import { oAuth2Service } from './services/oauth2.service';
import { OOxmlService } from './services/ooxml.service';
import { Util, UserInterface } from './utils/utils.module';
import { AppComponent } from './app.component';
import { CommandHandler } from './models/command-handler';
import { MenuComponent } from './widgets/menu.component';
import { PopupComponent } from './widgets/popup.component';
import { MenuItem } from './models/menu-item';

const kOverflowMenuWidth = 70;

class _BCState {
  private _crumb: string;
  private _url: string;
  private _originalUrl: string;
  constructor(crumb: string, url: string) {
    this._crumb = crumb;
    this._url = url;
    this._originalUrl = url;
  }
  public changeCrumb(crumb: string): void {
    this._crumb = crumb;
  }
  public changeUrl(url: string): void {
    this._url = url;
  }
  public get crumb(): string {
    return this._crumb;
  }
  public get url(): string {
    return this._url;
  }
  public get originalUrl(): string {
    return this._originalUrl;
  }
}

@Component({
  selector: 'edx-app-breadcrumbs',
  styleUrls: [ 'app-breadcrumbs.component.scss' ],
  template: `
    <div #container *ngIf="displayCrumbs.length > 1 || ui===0" class="headercrumbcontainer" [ngClass]="{shown:isShown, showing:isShowing, hiding:isHiding}" (animationend)="breadcrumbsAnimationComplete()">
      <ng-template ngFor let-crumb [ngForOf]="displayCrumbs" let-isLast="last" let-i="index">
        <div class="crumb" title="{{crumb}}" [ngClass]="{last:isLast}">
          <div class="name" (click)="crumbClicked(i)">{{crumb}}</div>
          <div class="separator" [ngClass]="{last:isLast}"></div>
        </div>
        <ng-template [ngIf]="i === 0">
          <span class="overflow" [ngClass]="{edx_hidden: !hasOverflow}">
            <edx-menu #overflowMenu [menuID]="overflowMentID" [callback]="this"></edx-menu>
            <div class="separator"></div>
          </span>
        </ng-template>
      </ng-template>
    </div>
  `
})
export class AppBreadcrumbsComponent implements OnInit, AfterViewChecked, CommandHandler {
  @ViewChild('container') container: ElementRef;
  @ViewChild('overflowMenu') overflowMenu: MenuComponent = null;
  @Input() app: AppComponent;
  public ui: UserInterface;
  public isShown = false;
  public displayCrumbs: string[] = []; // when all the crumbs will not fit this list is smaller than the full list
  private isEnabled = true;
  private isShowing = false;
  private isHiding = false;
  private isMeasuringCrumbs = false;
  private layoutComplete = false;
  private hasOverflow = false;
  private overflowEndIdx = -1;
  private overflowMentID = -1;
  private states: _BCState[] = [];
  private navLoc = '';

  constructor(private router: Router, private http: HttpClient, private cdr: ChangeDetectorRef, private zone: NgZone, private localizer: LocalizeService, private dataService: DataService, private formService: FormService, private schemaService: SchemaService, private profileService: ProfileService, private oauth2Service: oAuth2Service, private ooxmlService: OOxmlService) {
    Util.Device.init();
    this.ui = Util.Device.ui;
  }

  @HostListener('window:resize')
  public updateCrumbs(): void {
    this.layoutComplete = false;
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    Util.Transforms.setLocalizer(this.localizer);
    Util.Help.setLocalizer(this.localizer);
    Util.RestAPI.init(this.router, this.http, this.zone, this.app, this.localizer, this.dataService, this.formService, this.schemaService, this.profileService, this.oauth2Service, this.ooxmlService);
    this.navLoc = Util.RestAPI.getLoginURL();
    this.isEnabled = true;
    this.overflowMentID = this.ui===UserInterface.desktop ? 17 : this.ui===UserInterface.web ? 18 : 0;
    this.states.push(new _BCState(this.localizer.getTranslation('HEADER.HOME'),Util.RestAPI.getHomeURL()));
    this.updateCrumbs();
    if (this.app) {
      this.app.registerBreadcrumbs(this);
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const decodedPrevUrl: string = Util.RestAPI.decodeChildRouteURL(this.navLoc);
        const descPrev: any = Util.RestAPI.getDescFromURL(decodedPrevUrl);
        const hashPrev: string = this.navLoc.lastIndexOf('#')!==-1? this.navLoc.substr(this.navLoc.lastIndexOf('#')) : null;

        this.navLoc = this.router.url;
        const hash: string = this.navLoc.lastIndexOf('#')!==-1? this.navLoc.substr(this.navLoc.lastIndexOf('#')) : null;
        if (this.navLoc===Util.RestAPI.getLoginURL()) {
          this.states = [];
          if (hashPrev) {
            this.app.popCommandHandler(!hash);
          }
        } else {
          const decodedUrl: string = Util.RestAPI.decodeChildRouteURL(this.navLoc);
          const name: string = this.navLoc===Util.RestAPI.getHomeURL() ? this.localizer.getTranslation('HEADER.HOME') : Util.RestAPI.getNameFromURL(decodedUrl);
          const index: number = this.stateIndex(this.navLoc);
          const desc: any = Util.RestAPI.getDescFromURL(decodedUrl);
          const isSearchPage: boolean = desc && desc.id.startsWith('evaluation');
          const isFolderPage: boolean = desc && desc.id.startsWith('evaluation') && descPrev && (Util.isFolderWithId(descPrev) || Util.isFlexfolderLevelNode(descPrev));
          const isReSearchedFolderPage: boolean = decodedPrevUrl.indexOf(Util.kSearchFolderQuery)>0;
          const isSearchSaved: boolean = desc && desc.type === 'searches' && !isNaN(parseInt(desc.id)) && descPrev && descPrev.type === 'searches' && (descPrev.id.startsWith('evaluation') || !isNaN(parseInt(descPrev.id)));

          if (index >= 0) {
            this.states.splice(index + 1).forEach((deadState: _BCState) => {
              const deadUrl: string = deadState.url;
              const deadHash: string = deadUrl.lastIndexOf('#')!==-1 ? deadUrl.substr(deadUrl.lastIndexOf('#')) : null;
              if (!deadHash) {
                const deadDecodedUrl = Util.RestAPI.decodeChildRouteURL(deadUrl);
                const deadDesc = Util.RestAPI.getDescFromURL(deadDecodedUrl);
                this.dataService.clear(deadDesc);
              }
            });
            if (hashPrev) {
              this.app.popCommandHandler(!hash);
            }
          } else {
            if ((((name && name.length) || (hash && hash.length)) && this.navLoc && this.navLoc.length && desc && !desc.id.startsWith('evaluation')) || isSearchPage) {
              if (isSearchPage && (!isFolderPage && !isReSearchedFolderPage)) {
                this.states.splice(1);
              } else if (isSearchSaved || (isSearchPage && isReSearchedFolderPage && (!hash || !hash.length))) {
                this.states.splice(this.states.length - 1);
              }
              this.states.push(new _BCState(hash ? hash : name, this.navLoc));
            }
          }
        }
        this.setVisibility();
        this.updateCrumbs();
        this.app.routeChanged();
      }
    });
  }

  ngAfterViewChecked(): void {
    this.doLayout();
  }

  private stateIndex(navLoc: string): number {
    let state: _BCState = this.states.find(s => s.url === navLoc);
    if (!state) {
      state = this.states.find(s => s.originalUrl === navLoc);
    }
    if (!!state) {
      return this.states.indexOf(state);
    }
    return -1;
  }

  private fillOverflowMenu(): void {
    if (!this.isMeasuringCrumbs && !this.layoutComplete) {
      if (this.overflowEndIdx > 0) {
        const menuItems: MenuItem[] = [];
        this.displayCrumbs = [];
        for (let i=0; i<this.states.length; i++) {
          if (i>=1 && i<this.overflowEndIdx) {
            menuItems.push(new MenuItem({name: this.states[i].crumb, cmd: i.toString()}));
          } else {
            this.displayCrumbs.push(this.states[i].crumb);
          }
        }
        this.overflowMenu.replaceMenuItems(menuItems);
        this.hasOverflow = true;
      }
      this.layoutComplete = true;
      this.cdr.markForCheck();
    }
  }

  private measureCrumbs(): void {
    this.isMeasuringCrumbs = false;
    this.overflowEndIdx = -1;
    if (this.container) {
      let i: number;
      let margin = 0;
      let availableWidth: number = this.container.nativeElement.offsetWidth;
      const crumbEls: any[] = this.container.nativeElement.children as any[];
      let crumbEl;
      const crumbWidths: number[] = [];
      i = 0;
      for (crumbEl of crumbEls) {
        if (i===0) {
          margin = crumbEl.offsetLeft;
        }
        if (i>=1) {
          crumbWidths.push((crumbEl.offsetWidth+margin));
        } else {
          availableWidth -= crumbEl.offsetWidth + margin;
        }
        ++i;
      }
      for (i=crumbWidths.length-1; i>=0; i--) {
        availableWidth -= crumbWidths[i];
        if (availableWidth < kOverflowMenuWidth) {
          this.overflowEndIdx = i + 1;
          break;
        }
      }
    }
    // fill up overflow menu and truncate the crumbs that are shown
    if (this.overflowEndIdx===-1) {
      this.displayCrumbs = [];
      for (const state of this.states) {
        this.displayCrumbs.push(state.crumb);
      }
      this.hasOverflow = false;
    }
    this.fillOverflowMenu();
  }

  private doLayout(): void {
    if (!this.layoutComplete) {
      if (!this.isMeasuringCrumbs) {
        this.displayCrumbs = [];
        for (const state of this.states) {
          this.displayCrumbs.push(state.crumb);
        }
        this.hasOverflow = false;
        this.overflowEndIdx = -1;
        if (this.container) {
          this.isMeasuringCrumbs = true;
          setTimeout(() => {
            this.measureCrumbs();
          }, 1);
        }
      }
    }
  }

  public navBack(): void {
    if (this.states.length) {
      const lastCrumb: number = this.states.length-1;
      const crumb: string = this.states[lastCrumb].crumb;
      if (crumb.startsWith('#')) {
        history.go(-1);
      } else {
        this.navToUrlIndex(lastCrumb - 1);
      }
    }
  }

  public contains(url: string): boolean {
    let found = false;
    for (const state of this.states) {
      const crumbUrl = state.url;
      const crumbChild = Util.RestAPI.decodeChildRouteURL(crumbUrl);
      if (url === crumbUrl || url === crumbChild) {
        found = true;
        break;
      }
    }
    return found;
  }

  public acceptFileDrag(): boolean {
    const hash: string = this.navLoc.lastIndexOf('#')!==-1? this.navLoc.substr(this.navLoc.lastIndexOf('#')) : null;
    if (!!hash) {
    return false;
    }
    return MenuComponent.nMenusShown===0 && PopupComponent.nPopupsShown===0;
  }

  public canBeShown(): boolean {
    const decodedUrl: string = Util.RestAPI.decodeChildRouteURL(this.navLoc);
    return this.ui<2 && this.navLoc !== '/' && this.navLoc !== Util.RestAPI.getHomeURL() && this.navLoc !== Util.RestAPI.getLoginURL() && decodedUrl.indexOf('/form') !== 0;
  }

  private setVisibility(): void {
    if (this.isEnabled && this.canBeShown()) {
      this.show();
    } else {
       this.hide();
    }
  }

  private crumbClicked(index: number): void {
    if (this.isShown) {
        if (index >  0 && this.overflowEndIdx > 0) {
        index = index + this.overflowEndIdx - 1;
      }
      this.navToUrlIndex(index);
    }
  }

  private navToUrlIndex(index: number): void {
    if (index>=0 && index < this.states.length-1) {
      Util.RestAPI.navToURL(this.states[index].url);
    }
  }

  public setEnabled(enabled: boolean): void {
    this.isEnabled = enabled;
    this.setVisibility();
  }

  public show(): void {
    this.isHiding = false;
    if (!this.isShown) {
      this.isShowing = true;
    }
  }

  public hide(): void {
    this.isShowing = false;
    if (this.isShown) {
      this.isHiding = true;
    }
  }

  public breadcrumbsAnimationComplete(): void {
    if (this.isHiding) {
      this.isShown = false;
    } else if (this.isShowing) {
      this.isShown = true;
    }
    this.isShowing = false;
    this.isHiding = false;
  }

  public curWindowChangedTitle(title: string, oldTitle: string): void {
    const nStates: number = this.states.length;
    for (let i=nStates-1; i>=0; i--) {
      if (this.states[i].crumb === oldTitle) {
        this.states[i].changeCrumb(title);
        this.states[i].changeUrl(this.states[i].url.replace('%26name%3D'+oldTitle, '%26name%3D'+title));
        this.updateCrumbs();
        break;
      }
    }
  }

  public curUrlChanged(url: string): void {
    if (this.states.length>0 && this.states[this.states.length-1].url !== url) {
      this.states[this.states.length-1].changeUrl(url);
    }
  }

  // *** Implement commandHandler for overflow
  public commandEnabled(cmd: string): boolean {
    return true;
  }

  public doCommand(cmd: string): boolean {
    this.navToUrlIndex(parseInt(cmd));
    return true;
  }
}
