import { Component, Input, ElementRef, ViewChild, ViewChildren, QueryList, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { InlineActionBarComponent } from '../widgets/inline-action-bar.component';
import { ListItem } from '../models/list-item';
import { ListTileComponent } from './list-tile.component';
import { CommandHandler } from '../models/command-handler';
import { SecurityControl, AccessLevel } from '../models/security-control';
import { LocalizeService } from '../services/localize.service';
import { Util } from '../utils/utils.module';

const kSmallTileCols = ['DOCNAME', 'DOCNUM', 'ACTIVITY_TYPE'];

@Component ({
  selector: 'edx-list-item-tile',
  styleUrls: [ 'list-item-tile.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div [ngClass]="{largetile:tilesize===2,smalltile:tilesize===1,clickable:canClick(),dragover:dragover}" class="tile-list-item" [edx-draggable]="this" [edx-draggable-file]="this" [draggable]="isDraggableFile()" [edx-drag-target]="this" (mouseover)="!isMobile||isOfficeAddin?enableHoverMenu():undefined" (mouseleave)="!isMobile||isOfficeAddin?disableHoverMenu():undefined" (click)="onClick($event)">
      <img [ngClass]="{tile_list_item_draggable:canDrag(0,0,null)}" class="tile-list-icon" src="{{icon}}" alt="{{altText}}">
      <img *ngIf="displayMiniStatus(this.desc)" class="overlaystatusicon" src="{{formatStatusIcon(this.desc)}}" alt="{{formatStatusText(this.desc)}}">
      <div *ngIf="canShowColumn('DOCNAME')" class="tile-list-name" title="{{getDocName()}}">
        <span>{{getDocName()}}</span>
      </div>
      <div *ngIf="canShowColumn('AUTHOR_ID')" class="tile-list-second">{{desc.AUTHOR_ID}}</div>
      <div *ngIf="canShowColumn('LAST_EDIT_DATE')" class="tile-list-third">{{formatDate(desc, 'LAST_EDIT_DATE')}}</div>
      <div *ngIf="canShowColumn('DOCNUM')" class="tile-list-docnum">{{getDocNum()}}</div>
      <div *ngIf="canShowColumn('ACTIVITY_TYPE')" class="tile-list-activity">
        <span>{{getActivity()}}</span>
      </div>
      <edx-inline-action-bar #inlineMenu [resid]="34" [onWhite]="true" [shown]="inlineActionMenuShown" [target]="this"></edx-inline-action-bar>
    </div>
  `
})
export class ListItemTileComponent implements OnInit, CommandHandler {
  @ViewChildren(InlineActionBarComponent) inlineMenus: QueryList<InlineActionBarComponent>;
  @ViewChild('inlineMenu') inlineMenu: InlineActionBarComponent;
  public isMobile: boolean = Util.Device.isMobile();
  public isOfficeAddin: boolean = Util.Device.bIsOfficeAddin;
  public dragover = false;
  public icon: string;
  public altText: string;
  public inlineActionMenuShown = false;
  public didADrag = false;
  private hoverOpenTimer: number;
  private rights: SecurityControl = new SecurityControl();
  @Input() tilesize: number;
  @Input() desc: ListItem;
  @Input() parent: ListTileComponent;
  @Input('showHover')
  set setter(value: boolean) {
    if (value) {
      this.enableHoverMenu();
    } else {
      this.disableHoverMenu();
    }
  }

  constructor(private cdr: ChangeDetectorRef, private localizer: LocalizeService) {}

  ngOnInit(): void {
    this.icon = Util.Transforms.iconUrlFromDesc(this.desc);
    this.altText = Util.Transforms.iconAltTextFromDesc(this.desc);
    this.calculateRights();
    setTimeout(() => {
      if (!!this.inlineMenu) {
        this.inlineMenu.updateActionItems();
      }
    }, 1);
  }

  public onClick(event: Event): void {
    if (!this.didADrag) {
      if (this.parent) {
        this.parent.listItemClicked(this.desc, event);
      }
    }
    this.didADrag = false;
  }

  private calculateRights(): void {
    const containerRights: SecurityControl = this.parent.containerRights();
    let access: number = AccessLevel.ACCESS_LEVEL_FULL;
    if (containerRights) {
      access = containerRights.access;
    }
    const rights: SecurityControl = new SecurityControl(access);
    const item = this.desc;
    if (item && item['%SECURITY']) {
      const itemAccess = +item['%SECURITY'];
      if (itemAccess > 0) {
        rights.access &= itemAccess;
      }
    }
    this.rights = rights;
  }

  public canClick(): boolean {
    return this.parent ? this.parent.canOpenListItem(this.desc) : false;
  }

  public getActivity(): string {
    return this.parent.formatAction(parseInt(this.desc['ACTIVITY_TYPE']));
  }

  public getDocNum(): string {
    if (!!this.parent && !!this.parent.desc && (this.parent.desc.type === 'flexfolders' || this.parent.desc.type === 'activities')) {
      return null;
    }
    return this.desc.DOCNUM;
  }

  public getDocName(): string {
    let docName = this.desc['DOCNAME'];
    if (!docName && !!this.desc['lang_key_DOCNAME'] && this.localizer.translationExists(this.desc['lang_key_DOCNAME'])) {
      docName = this.desc['DOCNAME'] = this.localizer.getTranslation(this.desc['lang_key_DOCNAME']);
    }
    return docName;
  }

  protected formatDate(item: any, property: string): string {
    let dateStr: string = item[property+'__cached'];
    if (!dateStr) {
      dateStr = Util.Transforms.formatDate(item[property]);
      // cache it as date transforms are VERY expensive and will slow down rendering
      item[property+'__cached'] = dateStr;
    }
    return dateStr;
  }

  // drag directives
  public canDrag(x: number, y: number, element: ElementRef): boolean {
    return !Util.Device.bIsTouchDevice && Util.isContainer(this.desc.type);
  }

  public isDraggableFile(): boolean {
    // Temporarily disable all drgagging of external services items with !Util.isExternalLib(this.desc.lib)
    // allowing it for onedrive
    const extAppInfo: any = Util.RestAPI.findExternalApp(this.desc.lib);
    return !Util.Device.bIsCordova && !Util.isContainer(this.desc.type) && (!extAppInfo || (extAppInfo['apptype'] === 'onedrive' || extAppInfo['apptype'] === 'core'));
  }

  getDragURL(element: ElementRef): string {
    let url: string = null;
    if (this.isDraggableFile()) {
      url = Util.RestAPI.makeDragURL(this.desc);
    }
    return url;
  }
  getDragName(): string {
    return Util.RestAPI.makeDragName(this.desc);
  }
  public getDragJson(element: ElementRef): string {
    let json: string = null;
    if (this.isDraggableFile()) {
      json = JSON.stringify(this.desc);
    }
    return json;
  }
  public getDragAppID(element: ElementRef): string {
    return !!this.desc ? this.desc.APP_ID : null;
  }
  public getDragDesc(element: ElementRef): any {
    return this.desc;
  }
  public getDragPromisedFile(element: ElementRef): string {
    if (Util.RestAPI.findExternalApp(this.desc.lib)) {
      return this.getDragJson(element);
    }
    return null;
  }
  public getEffectAllowed(): string {
    return 'copy';
  }
  preDragStart(element: ElementRef) {
    if (this.parent) {
      this.parent.listItemPreDragStart(this.desc, element);
    }
  }
  dragMoved(x: number, y: number, element: ElementRef) {
    if (this.parent) {
      this.parent.listItemDragMoved(this.desc, x, y, element);
    }
  }
  dragEnded(event: any, element: ElementRef) {
    if (this.parent) {
      this.parent.listItemDragEnded(this.desc,event,element);
    }
    this.didADrag = true;
    setTimeout(() => {
      this.didADrag = false;
    },250);
  }
  acceptDrag(event: any, element: ElementRef): boolean {
    if (this.parent && Util.isUploadTarget(this.desc) && this.parent.getSet().readonly!=='Y' && this.rights.canEditContent) {
      const items = event.dataTransfer && event.dataTransfer.items ? event.dataTransfer && event.dataTransfer.items : null;
      if (Util.dragHasFiles(event.dataTransfer) || Util.dragHasJSON(event.dataTransfer)) {
        this.dragover = true;
        this.parent.listItemDragOver(this.desc,event);
        return true;
      }
    }
    return false;
  }
  dragLeave(event: any, element: ElementRef): void {
    this.dragover = false;
    if (this.parent) {
      this.parent.listItemDragLeave(this.desc);
    }
  }
  drop(event: any, element: ElementRef): void {
    if (this.parent && Util.isUploadTarget(this.desc) && this.parent.getSet().readonly !== 'Y' && this.rights.canEditContent) {
      Util.getDropFiles(event.dataTransfer).then((items: any[]) => {
        const count = items ? items.length : 0;
        if (count || Util.Device.bIsOfficeAddin) {
          this.parent.uploadFiles(items, this.desc);
        }
        if (this.parent) {
          this.parent.listItemDrop(this.desc);
        }
      });
    } else {
      if (this.parent) {
        this.parent.listItemDragLeave(this.desc);
      }
    }
    this.dragover = false;
  }

  displayMiniStatus(item: ListItem): boolean {
    return Util.RestAPI.displayMiniStatus(item, this.parent ? this.parent.desc : null);
  }

  formatStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatStatusIcon(item, !!this.parent ? this.parent.desc : null);
  }

  formatStatusText(item: ListItem): string {
    return Util.RestAPI.formatStatusText(item);
  }

  protected doesFieldsExist(columnName: string): boolean {
    let exists = this.desc[columnName] ? true : false;
    if (!exists && !!this.desc['lang_key_'+columnName]) {
      exists = this.localizer.translationExists(this.desc['lang_key_'+columnName]);
    }
    return exists;
  }

  public canShowColumn(columnName: string): boolean {
    return this.doesFieldsExist(columnName) && (this.tilesize === 2 || kSmallTileCols.indexOf(columnName) !== -1);
  }

  // hover menu
  public enableHoverMenu(): void {
    if (!this.dragover) {
      if (!this.inlineActionMenuShown) {
        if (this.hoverOpenTimer) {
          window.clearTimeout(this.hoverOpenTimer);
        }
        this.hoverOpenTimer = window.setTimeout(() => {
          if (this.parent && !this.parent.isHidden()) {
            this.inlineActionMenuShown = true;
            this.cdr.markForCheck();
          } else {
            if (this.hoverOpenTimer) {
              window.clearTimeout(this.hoverOpenTimer);
            }
           }
        }, 400);
      }
    }
  }

  public disableHoverMenu(): void {
    if (this.hoverOpenTimer) {
      window.clearTimeout(this.hoverOpenTimer);
    }
    setTimeout(() => {
      if (this.inlineActionMenuShown) {
        this.inlineActionMenuShown = false;
        this.cdr.markForCheck();
      }
    }, 200);
  }

  public doCommand(cmd: string): boolean {
    switch (cmd) {
    case 'create_tile':
      this.parent.createTile(this.desc, -1);
      break;
    case 'profile_mini':
      this.parent.profileItem(this.desc, this.rights);
      break;
    case 'email_mini':
      this.parent.emailItem(this.desc);
      break;
    case 'share_mini':
      this.parent.shareItem(this.desc);
      break;
    default:
      return false;
    }
    this.inlineActionMenuShown = false;
    this.cdr.markForCheck();
    return true;
}

  public commandEnabled(cmd: string): boolean {
    switch (cmd) {
    case 'create_tile':
      if (Util.isContainer(this.desc.type)) {
        return true;
      }
      break;
    case 'profile_mini':
      if (this.rights.canViewProfile && (this.desc.type==='documents'||this.desc.type==='folders'||this.desc.type==='workspaces')) {
        return true;
      }
      break;
    case 'email_mini':
      if (this.rights.canCopy && this.desc['STATUS']!=='18' && this.desc['STORAGE']!=='P' && (this.desc.type==='documents'||this.desc.type==='folders'||this.desc.type==='workspaces')) {
        if (Util.isExternalLib(this.desc.lib)) {
          return false;
        } else {
          return true;
        }
      }
      break;
    case 'share_mini':
      if (this.rights.canCopy && this.desc['STATUS']!=='18' && (this.desc.type==='documents' || this.desc.type==='folders')) {
        const extAppInfo: any = Util.RestAPI.findExternalApp(this.desc.lib);
        if (!!extAppInfo && extAppInfo['apptype'] === 'onedrive') {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }
}
