import { mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { Directive, OnInit, EventEmitter, HostListener, ElementRef, Input, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';

import { Util } from '../utils/utils.module';

@Directive({
  selector: '[edx-draggable]'
})
export class DraggableDirective implements OnInit {
  private _elemBounds: ClientRect;
  private _mouseup: EventEmitter<any> = new EventEmitter();
  private _mousedown: EventEmitter<any> = new EventEmitter();
  private _mousemove: EventEmitter<any> = new EventEmitter();
  private _mouseout: EventEmitter<any> = new EventEmitter();
  private _keydown: EventEmitter<any> = new EventEmitter();
  private _margin: number[] = new Array<number>();
  private _startX: number;
  private _startY: number;
  private _minDragMove: number = Util.Device.bIsTouchDevice ? 6 : 3;
  private _allowVertical = true;
  private _dragInProgress = false;
  private _leftMouseDown = false;
  private _touchStartRC = false;
  private _originalTouchTarget: any = null;
  private boundOnMouseMove;
  private boundOnTouchMove;
  private boundOnMouseUp;
  @Input('edx-draggable') listener: any;

  constructor(private _elem: ElementRef, private _renderer: Renderer2) { }

  @HostListener('mousedown', ['$event'])
  onMousedown(event) {
    this._allowVertical = true;
    if (event.target && event.target.tagName==='INPUT') {
      // do not block inputs from getting clicks
      return true;
    }
    if (this.listener && this.listener.canDrag) {
      const bounds = this._elem.nativeElement.getBoundingClientRect();
      if (!this.listener.canDrag(event.clientX-bounds.left, event.clientY-bounds.top, this._elem)) {
        return true;
      }
    }
    if (this.listener && this.listener.allowVertical) {
      this._allowVertical = this.listener.allowVertical(this._elem);
    }
    if (Util.Device.bIsTouchDevice) {
      this.boundOnTouchMove = this.onTouchmove.bind(this);
      addEventListener('touchmove', this.boundOnTouchMove);
    } else {
      this.boundOnMouseMove = this.onMousemove.bind(this);
      addEventListener('mousemove', this.boundOnMouseMove);
      this.boundOnMouseUp = this.onMouseup.bind(this);
      addEventListener('mouseup', this.boundOnMouseUp);
    }
    this._mousedown.emit(event);
    return false;
  }
  @HostListener('touchstart', ['$event'])
  onTouchStart(event) {
    event = event.touches && event.touches.length ? event.touches[0] : event;
    event.which = 1;
    this._touchStartRC = this.onMousedown(event);
    if (!this._touchStartRC) {
      this._originalTouchTarget = event.target;
    }
    return this._touchStartRC;
  }

  onMousemove(event) {
    if (!!this._leftMouseDown) {
      this._mousemove.emit(event);
    }
  }
  onTouchmove(event) {
    event = event.touches && event.touches.length ? event.touches[0] : event;
    return this.onMousemove(event);
  }

  onMouseup(event) {
    let rc: boolean;
    if (!!this._dragInProgress) {
      this.endDrag(event);
      this._mouseup.emit(event);
      rc = true;
    } else if (!this.boundOnMouseMove && !this.boundOnTouchMove) {
      this._mouseup.emit(event);
      rc = false;
    } else {
      rc = true;
    }
    this._leftMouseDown = false;
    if (Util.Device.bIsTouchDevice) {
      if (!!this.boundOnTouchMove) {
        removeEventListener('touchmove', this.boundOnTouchMove);
        this.boundOnTouchMove = null;
      }
    } else {
      if (!!this.boundOnMouseMove) {
        removeEventListener('mousemove', this.boundOnMouseMove);
        this.boundOnMouseMove = null;
      }
      if (!!this.boundOnMouseUp) {
        removeEventListener('mouseup', this.boundOnMouseUp);
        this.boundOnMouseUp = null;
      }
    }
    return rc;
  }
  @HostListener('touchend', ['$event'])
  onTouchend(event) {
    const rc = this._dragInProgress && this._leftMouseDown ? false : true;
    event = event.touches && event.touches.length ? event.touches[0] : event;
    this.onMouseup(event);
    if (rc && !this._touchStartRC && this._originalTouchTarget) {
      event = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
      this._originalTouchTarget.dispatchEvent(event);
      this._originalTouchTarget = null;
    }
    return rc;
  }
  @HostListener('document:touchcancel', ['$event'])
  onTouchcancel(event) {
    event = event.touches && event.touches.length ? event.touches[0] : event;
    this.onMouseup(event);
    return false;
  }
  @HostListener('document:keydown', ['$event'])
  onKeyup(event) {
    if (event.which===27) {
      this.endDrag(event);
    }
    this._keydown.emit(event);
  }

  private startDrag(): void {
    if (!this._dragInProgress) {
      this._dragInProgress = true;
      if (this.listener && this.listener.preDragStart) {
        this.listener.preDragStart(this._elem);
      }
      this._renderer.setStyle(this._elem.nativeElement, 'z-index', '100000');
      this._renderer.setStyle(this._elem.nativeElement, 'position', 'fixed');
      if (this.listener && this.listener.dragStarted) {
        this.listener.dragStarted(this._elem);
      }
    }
  }
  private setPosition(pos): void {
    this._renderer.setStyle(this._elem.nativeElement, 'left', (pos['left'] - this._margin[1] - document.body.scrollLeft).toString() + 'px');
    if (this._allowVertical) {
      this._renderer.setStyle(this._elem.nativeElement, 'top', (pos['top'] - this._margin[0] - document.body.scrollTop).toString() + 'px');
    }
    if (this.listener && this.listener.dragMoved) {
      if (this._allowVertical) {
        this.listener.dragMoved(pos['x'],pos['y'],this._elem);
      } else {
        this.listener.dragMoved(pos['x'],this._startY,this._elem);
      }
    }
  }
  private endDrag(event): void {
    if (!!this._dragInProgress) {
      if (this.listener && this.listener.dragEnded) {
        this.listener.dragEnded(event,this._elem);
      }
      this._renderer.setStyle(this._elem.nativeElement, 'z-index', '');
      this._renderer.setStyle(this._elem.nativeElement, 'left', '');
      this._renderer.setStyle(this._elem.nativeElement, 'top', '');
      this._renderer.setStyle(this._elem.nativeElement, 'position', '');
      this._dragInProgress = false;
    }
    this._leftMouseDown = false;
  }

  ngOnInit() {
    const margin = getComputedStyle(this._elem.nativeElement).getPropertyValue('margin').split(' ');
    if (margin.length === 1) {
      this._margin.push(+margin[0].slice(0, margin[0].indexOf('px')));
      this._margin.push(+margin[0].slice(0, margin[0].indexOf('px')));
    } else {
      this._margin.push(+margin[0].slice(0, margin[0].indexOf('px')));
      this._margin.push(+margin[1].slice(0, margin[1].indexOf('px')));
    }
    this._mousedown.pipe(switchMap((mdwnEvn, i) => {
      this._leftMouseDown = mdwnEvn.which === 1;
      this._startX = mdwnEvn.clientX;
      this._startY = mdwnEvn.clientY;
      this._elemBounds = this._elem.nativeElement.getBoundingClientRect();
      return Observable.create((observer) => {
        observer._next({
          prevx: mdwnEvn.clientX - this._elemBounds.left,
          prevy: mdwnEvn.clientY - this._elemBounds.top
        });
      });
    }),mergeMap((offSet, i) => this._mousemove.pipe(mergeMap((mmoveEvn, iMove) => {
      if (!this._dragInProgress && this._leftMouseDown && (Math.abs(this._startX-mmoveEvn.clientX)>this._minDragMove || (this._allowVertical && Math.abs(this._startY-mmoveEvn.clientY)>this._minDragMove))) {
        this.startDrag();
      }
      if (!!this._dragInProgress && mmoveEvn.preventDefault) {
        mmoveEvn.preventDefault();
      }
      return Observable.create(observer => {
        observer._next({
          left: mmoveEvn.clientX - offSet['prevx'],
          top: mmoveEvn.clientY - offSet['prevy'],
          x: mmoveEvn.clientX,
          y: mmoveEvn.clientY
        });
      });
    }),takeUntil(this._mouseout),takeUntil(this._mouseup),)),).subscribe({
      next: pos => {
        if (!!this._dragInProgress) {
          this.setPosition(pos);
        }
      }
    });
  }
}
