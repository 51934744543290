import { Component, Input, OnChanges, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, SimpleChange, Output, EventEmitter, NgZone } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { DynamicFormComponent, FormController } from './dynamic-form.component';
import { FormService } from '../services/form.service';
import { ProfileService } from '../services/profile.service';
import { FormField, Visibility, FieldScript, SelectItem } from '../models/form-field';
import { Util, UserInterface } from '../utils/utils.module';
import {  BaseDesc } from '../models/base';
import { FileFormInfo, AppIDInfo, DataFile } from '../models/file-form-info';
import { ListItem } from '../models/list-item';
import { AccessLevel } from '../models/security-control';
import { LocalizeService } from '../services/localize.service';
import { PopupCallback } from '../widgets/popup.component';
import { SelectComponent } from '../widgets/select.component';
import { CommandHandler } from '../models/command-handler';
import { FormScripts } from './form-scripts';
import { WindowModalComponent } from '../windows/window-modal.component';
import { ListService } from '../services/list.service';
import { FileDropTargetDirective } from '../directives/drag-target.directive';

class CustomForm {
  constructor(private _fw: FormWrapperComponent) {}
  public setFieldValue(field: string, value: any, makeDirty: boolean): void {
    this._fw.updateControlValue(field, value, makeDirty);
  }
  public getFieldValue(field: string): any {
    return this._fw.dynamicForm.getControlValue(field);
  }
  public setHidden(field: string, hidden: boolean): void {
    this._fw.dynamicForm.setFieldVisibility(field, !hidden);
  }
  public setReadOnly(field: string, readOnly: boolean): void {
    this._fw.dynamicForm.setFieldEditable(field, !readOnly);
  }
}

declare const Office;
declare const Word;
declare const ICC;
const kExtrasShownKey = 'edx_extras_shown';
const noAddFolderIDs: string[] = ['recentedits', 'checkedout', 'downloads', 'imports', 'all', 'deleted', 'templates', ''];

@Component({
  selector: 'edx-form-wrapper',
  styleUrls: ['form-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-template [ngIf]="!hideChoosers">
      <div *ngIf="showLocationChooser()" class="chooseform" [ngClass]="{mobile:ui>=2,phone:ui===2||ui===4, columnview:pickersAreColumnview(),nobottom:showProfilePicker()}">
        <label for="locationchooser">{{saveToStr}}</label>
        <edx-location-chooser (change)="locationChanged($event)" [id]="'locationchooser'" [desc]="desc"></edx-location-chooser>
      </div>
      <div *ngIf="showProfilePicker()" class="chooseform" [ngClass]="{mobile:ui>=2,phone:ui===2||ui===4, columnview:pickersAreColumnview(),notop:showLocationChooser(),copy:kind==='profile_copy'}">
        <label for="formselect">{{profileStr}}</label>
        <edx-select (change)="changeFormTemplate($event)" [id]="'formselect'" [value]="defForm" [items]="formSelectList()" [disabled]="isProfilePickerDisabled()" [inDialog]="inDialog"></edx-select>
      </div>
      <div *ngIf="showSaveAsPicker()" class="chooseform" [ngClass]="{mobile:ui>=2,phone:ui===2||ui===4, columnview:pickersAreColumnview()}">
        <label for="saveasselect">{{this.localizer.getTranslation('FORMS.BUTTONS.SAVE_AS')}}</label>
        <edx-select (change)="changeSaveAs($event)" [id]="'savaselect'" [value]="'D'" [items]="saveAsSelectList()"></edx-select>
      </div>
    </ng-template>
    <edx-dynamic-form [formKind]="kind" [createType]="createType" [layout]="layout" [formTemplate]="formTemplate" [inDialog]="inDialog" [inNotify]="inNotify" [data]="formData" [desc]="desc" [readOnly]="readOnly" [controller]="this" [inlineParent]="inlineParent" [fileDropTarget]="fileDropTarget" [choosersHidden]="hideChoosers"></edx-dynamic-form>
    <div *ngIf="applyAllShown()" class="applyall">
      <input type="checkbox" class="checkbox" [ngClass]="{checked:applyAllChecked}">
      <span (click)="applyAllClicked($event)">{{applyAllStr}}</span>
    </div>
    <div *ngIf="relateToStr&&showRelateTo" class="applyall" [ngClass]="{phone:ui===2||ui===4}">
      <input type="checkbox" class="checkbox" [ngClass]="{checked:relateToChecked}">
      <span (click)="relateToClicked($event)">{{relateToStr}}</span>
    </div>
  `
})
export class FormWrapperComponent implements OnChanges, PopupCallback, FormController {
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @Output() notifyPermissionChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyNameChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() applyAllChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() extrasShownByChild: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() pccExtrasShownByChild: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formDataChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() okEnabled: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifySecurityDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notifyShareChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() kind = '';
  @Input() desc: BaseDesc;
  @Input() selections?: any[] = [];
  @Input() disableFirstFocus?: boolean = true;
  @Input() readOnly?: boolean = false;
  @Input() inDialog?: boolean = false;
  @Input() inNotify?: boolean = false;
  @Input() refresh?: boolean = false;
  @Input() hideChoosers?: boolean = false;
  @Input() createType?: string = null;
  @Input() formData?: any = {};
  @Input() trustees?: any[] = [];
  @Input() fileFormInfo?: FileFormInfo = null;
  @Input() layout?: string = null;
  @Input() rightWrapper?: FormWrapperComponent = null;
  @Input() mainWrapper?: FormWrapperComponent = null;
  @Input() headerWrapper?: FormWrapperComponent = null;
  @Input() fileList?: File[] = null;
  @Input() filePaths: string[] = null; // like fileList but Electron or Cordova paths
  @Input() dataFiles?: DataFile[] = null;
  @Input() changeListener?: FieldChangeListener = null;
  @Input() applyAllChecked?: boolean = true;
  @Input() formDialog?: CommandHandler = null;
  @Input() inlineParent?: DynamicFormComponent = null;
  @Input() fileDropTarget?: FileDropTargetDirective = null;
  @Input('filesChanging') set setter(value: boolean) {
    if (value) {
      this.dynamicForm.userChangingProfileForm(false, false);
      this.formChanged = true;
    }
  }
  public scriptData: any = {};
  public okDisabled = false;
  public copyFileName: string = null;
  public copyAppID: string = null;
  public disableInherited = false;
  public securityIsReadOnly = false;
  public formTemplate: any = null;
  public relateToStr: string;
  public showRelateTo = true;
  public bMassProfileUpdate = false;
  private extrasShown = false;
  private profileFormList: any[] = [];
  private profileStr = '';
  private saveToStr: string = this.localizer.getTranslation('FORMS.BUTTONS.SAVE_TO');
  private defForm: string = null;
  private allowProfFormPicker = true;
  private ui: UserInterface;
  private officeAddin: boolean;
  private accessInfo: ListItem[];
  private formChanged = false;
  private relateToChecked = false;
  private relateToDesc: BaseDesc = null;
  private applyAllStr: string = this.localizer.getTranslation('FORMS.BUTTONS.APPLY_TO_ALL');
  private originalFormData: any = null;
  private formLayout: string;
  private effectiveRights: any;
  private originalDesc: any = null;
  private bSecurityWasEdited = false;
  private bApplyAllOriginalState: boolean;
  private thirdButtonIsAutoProfile: boolean;
  private customForm: CustomForm = null;

  constructor(private cdr: ChangeDetectorRef, private zone: NgZone, private formService: FormService, private localizer: LocalizeService, private profileService: ProfileService, public listService: ListService) {
    this.ui = Util.Device.ui;
    this.thirdButtonIsAutoProfile = (Util.Device.bIsOfficeAddinWord || Util.Device.bIsOfficeAddinOutlook);
    this.officeAddin = Util.Device.bIsOfficeAddin;
    this.formLayout = (this.ui<2 || this.readOnly) ? 'page' : 'column';
    this.showRelateTo = Util.RestAPI.canUserCreateRelations();
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    setTimeout(() => {
      if (changes['desc'] || changes['kind'] || changes['formData'] || changes['refresh']) {
        if (changes['kind'] && changes['kind'].currentValue === 'profile_query_edit' && changes['formData']) {
          Util.RestAPI.conformFulltextCriteria(this.formData);
        }
        if (!this.formData) {
          this.formData = {};
        }
        this.defForm = null;
        if (!this.customForm && this.kind.startsWith(Util.kCustomFormPrefix)) {
          this.customForm = new CustomForm(this);
        }
        if ((this.kind.startsWith(Util.kLocalFormPrefix) || this.kind.startsWith(Util.kCustomFormPrefix)) && !this.createType) {
          let desc: any = null;
          if (this.selections && this.selections.length) {
            this.formData = Object.assign(this.formData, this.formService.makeFormData(this.kind,this.selections));
            desc = this.selections[0];
          } else if (!changes['desc'] && this.formData && Object.keys(this.formData).length && this.desc && this.kind === '__local_permissions_selector') {
            // send profile_defaults as formdata when defaults are set. as long as the desc has not changed.
          } else if (!!this.desc) {
            if (!this.mainWrapper || this.kind !== '__local_permissions_selector') {
              this.formData = Object.assign(this.formData, this.formService.makeFormData(this.kind,[this.desc]));
            }
            desc = this.desc;
          }
          if (!!desc && (!!desc['checkout'] || !!desc['LOCATION'] || Util.Device.bIsCordova) && this.kind==='__local_checkin') {
            this.formData['STATUS'] = desc['checkout'] ? desc['checkout']['STATUS'] : desc['STATUS'];
            this.formData['%CHECKIN_LOCATION'] = desc['checkout'] ? desc['checkout']['LOCATION'] : desc['LOCATION'];
            if (!this.formData['%CHECKIN_LOCATION']) {
              this.formData['%CHECKIN_LOCATION'] = Util.RestAPI.getTempPath();
            }
            if (!!desc['checkout']) {
              const docs: any[] = this.formData['DOCUMENTS'];
              const nDocs: number = docs ? docs.length : 0;
              let nDocsWithVersionLabels = 0;
              const setVersLabel = (doc: any, versLabel: string) => {
                if (doc['VERSION_LABEL'] === undefined) {
                  doc['VERSION_LABEL'] = versLabel;
                }
                if (++nDocsWithVersionLabels === nDocs) {
                  this.loadFormTemplate();
                }
              };
              for (let i=0; i<nDocs; i++) {
                const doc: any = docs[i];
                if (!!doc['checkout']['id']) {
                  doc['id'] = doc['checkout']['id'];
                }
                if (!!doc['checkout']['lib']) {
                  doc['lib'] = doc['checkout']['lib'];
                }
                if (doc['VERSION_LABEL'] === undefined) {
                    setVersLabel(doc, doc['checkout']['VERSION_LABEL']);
                } else {
                  if (++nDocsWithVersionLabels === nDocs) {
                    this.loadFormTemplate();
                  }
                }
              }
            } else {
              this.loadFormTemplate();
            }
            this.okDisabled = true;
          } else if (desc && this.kind==='__local_permissions_selector') {
            if (Util.RestAPI.restAPIVersion() <= 0x00160301) {
                this.disableInherited = true;
            }
            if (!!this.mainWrapper) {
              if (this.mainWrapper.bMassProfileUpdate) {
                this.formData = Util.deepCopy(this.mainWrapper.formData);
                this.bMassProfileUpdate = true;
              } else {
                if (this.mainWrapper.desc.lib !== this.desc.lib) {
                  this.desc = this.mainWrapper.desc;
                  this.formData = {};
                } else {
                  this.formData = Object.assign(this.mainWrapper.formData, this.formService.makeFormData(this.kind,[this.desc]));
                }
                this.bMassProfileUpdate = false;
              }
            }
            this.loadFormTemplate();
          } else {
            this.loadFormTemplate();
          }
        } else {
          const isProfileForm: boolean = this.kind.startsWith('profile');
          const isSearchForm: boolean = this.kind.startsWith('profile_query');
          let copyDesc: any = null;
          if (isProfileForm && !(this.profileFormList && this.profileFormList.length)) {
            this.profileStr = this.localizer.getTranslation('METADATA.TABS.PROFILE');
            if (isSearchForm) {
              this.profileFormList = Util.RestAPI.getSearchForms();
            } else {
              this.profileFormList = [];
            }
          }
          this.okDisabled = isProfileForm && !isSearchForm;
          if (this.kind === 'profile_copy') {
            const primaryLib = Util.RestAPI.getPrimaryLibrary();
            if (!!this.desc && !!this.desc.lib && this.desc.lib.toUpperCase() !== primaryLib.toUpperCase() && this.selections[0].lib !== primaryLib.toUpperCase()) {
              // copying a remote lib doc, we need primary forms, lookups etc. Do not wack the list item so deep copy
              copyDesc = Util.deepCopy(this.desc);
              copyDesc.lib = primaryLib;
              copyDesc.id = '0';
            }
          }
          this.effectiveRights = Util.RestAPI.getLibraryEffectiveRights(!!copyDesc ? copyDesc.lib : !!this.desc ? this.desc.lib : null) || {};
          if (!!copyDesc) {
            this.locationChanged(copyDesc);
          } else if (this.createType && isProfileForm) {
            this.setFormListLoadTemplate();
          } else if (isProfileForm && !!this.desc && (!this.selections || this.selections.length <= 1)) {
            if (this.desc.type==='folders') {
              this.applyAllStr = this.localizer.getTranslation('FORMS.BUTTONS.PUBLIC_FOLDER');
              this.applyAllChecked = false;
            }
            this.getFormDataAndFormTemplate();
          } else {
            this.setFormListLoadTemplate();
          }
        }
      }
    }, 1);
  }

  private setFormListLoadTemplate(defForm?: string): void {
    let appProfileForms: any[] = null;
    let appType: string = null;
    this.setLoading(true);
    const getProfileForms = (anAppType: string): void => {
      let allProfileForms = Util.RestAPI.getProfileForms(this.desc.lib);
      this.profileFormList = [];
      if (!!anAppType) {
        allProfileForms = allProfileForms.sort((f1, f2) => f1['%FORM_APPLICATION'] === anAppType ? -1 : f2['%FORM_APPLICATION'] === anAppType ? 1 : 0);
      }
      allProfileForms.forEach(f1 => {
        if (this.profileFormList.findIndex(f2 => f2.DOCNUM === f1.DOCNUM) === -1) {
          this.profileFormList.push(f1);
        }
      });
    };
    const afterGetDefaults = () => {
      if (appProfileForms && appProfileForms.length) {
        getProfileForms(appType); // prefer an app type so the duplicates remove the other types if the doc number matches
        this.fillProfileFormList(appProfileForms, defForm);
        if (!this.defForm && !!this.fileFormInfo && !!this.fileFormInfo.defForm) {
          this.defForm = this.fileFormInfo.defForm;
        }
      } else if (!this.readOnly && !this.createType && !!this.selections && this.selections.length > 1) {
        getProfileForms(null);  // Mass Profile Update
      }
      this.loadFormTemplate();
      this.setLoading(false);
    };
    if (this.createType==='folders' && !Util.isExternalLib(this.desc.lib)) {
      appType = 'FOLDER';
      this.applyAllStr = this.localizer.getTranslation('FORMS.BUTTONS.PUBLIC_FOLDER');
      this.applyAllChecked = this.desc.id === 'public';
    } else if (this.createType==='documents') {
      if (!!this.formData && this.formData['STORAGE'] !== 'P') {
        appType = this.appIDForUpload();
        appType = appType.split(Util.RestAPI.kMultiFileSeparator)[0];
      }
    } else if (this.kind === 'profile_copy' && !!this.desc['APP_ID']) {
      appType = this.desc['APP_ID'];
    }
    if (!!appType) {
      if (!!this.formData && this.formData['APP_ID']) {
        const descMap: string[] = Util.FieldMappings.descMapForField('APP_ID');
        if (descMap && descMap.length) {
          for (const desc of descMap) {
            delete this.formData[desc];
          }
        }
      }
      this.formData['APP_ID'] = appType;
    }
    if (!appType && !this.fileList && !!this.formData && this.formData['STORAGE']==='P') {
      appType = 'Paper';
      appProfileForms = Util.RestAPI.getProfileFormsForApp(appType, this.desc.lib);
      if (appProfileForms && appProfileForms.length) {
        this.formData['FORM'] = appProfileForms[0]['%FORM_NAME'];
      }
      afterGetDefaults();
    } else {
      if (!appType && !!this.formData && !!this.formData['APP_ID']) {
        appType = this.formData['APP_ID'];
      }
      if (!!appType && appType.indexOf(Util.RestAPI.kMultiAppIdSeparator) === -1) {
        appProfileForms = Util.RestAPI.getProfileFormsForApp(appType, !!this.desc ? this.desc.lib : Util.RestAPI.getPrimaryLibrary());
      }
      afterGetDefaults();
    }
  }

  private pickersAreColumnview(): boolean {
    if (!!this.kind && this.kind === 'profile_office_home') {
      return true;
    }
    return this.formLayout==='column';
  }

  private checkFileNameExistsInDM(): void {
    const fromSaveAsDesc = !!this.dataFiles && this.dataFiles.length ? this.dataFiles[0].fromSaveAsDesc : null;
    const docObj: any = this.applyAllChecked ? null : Util.decodeFileName(this.fileNameForUpload(false));
    if (!!fromSaveAsDesc) {
      this.relateToStr = this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.RELATE_TO', ['#'+fromSaveAsDesc.id]);
    } else if (!!docObj && docObj.libExists) {
      this.relateToStr = this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.RELATE_TO', ['#'+docObj.docNum]);
    } else {
      this.relateToStr = null;
    }
  }

  public setRelateToDesc(relateToDesc: BaseDesc): void {
    this.relateToDesc = relateToDesc;
    this.relateToStr = this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.RELATE_TO', ['#'+this.relateToDesc.id]);
  }

  private relateToClicked(event: Event): void {
    this.relateToChecked = !this.relateToChecked;
  }

  public applyAllShown(): boolean {
    if (!this.mainWrapper && !this.readOnly && !this.bMassProfileUpdate) {
      if (this.createType === 'documents') {
        const nFiles: number = this.fileList ? this.fileList.length : this.filePaths ? this.filePaths.length : this.dataFiles ? this.dataFiles.length : 0;
        return nFiles > 1;
      } else if (this.createType === 'folders' || (!!this.desc && !!this.desc.id && this.desc.type==='folders' && !!this.formData && !!this.formData['public_system_id'])) {
        return !!this.effectiveRights && this.effectiveRights.ROOT_FOLDER === 'Y';
      }
    }
    return false;
  }

  private applyAllClicked(event: Event): void {
    if (this.bApplyAllOriginalState === undefined) {
      this.bApplyAllOriginalState = this.applyAllChecked;
    }
    this.applyAllChecked = !this.applyAllChecked;
    if (this.createType === 'documents') {
      this.checkFileNameExistsInDM();
      this.dynamicForm.fillNameAndAppID();
      this.applyAllChanged.emit(this.applyAllChecked);
    }
  }

  private getTextForLogging(text: string): string {
    const trimSize = 500;
    if (!!text && text.length > trimSize) {
      return text.substr(0, trimSize) + '....[' + (text.length - trimSize) + ' more characters truncated]';
    }
    return text;
  }

  private getTextLength(text: string): number {
    return !!text ? text.length: 0;
  }

  private autoProfileClicked(): void {
    Util.Notify.info(this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.HEADING'), this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.STARTED'));
    this.setLoading(true);

    const errHandler = (err: any): void => {
      console.log(err);
      Util.Notify.error('Auto profile', err);
      this.setLoading(false);
    };

    const doneHandler = (data: any): void => {
      Util.Notify.success(this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.HEADING'), this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.COMPLETED'));
      const result: any = data;
      const keys: string[] = Object.keys(result);
      this.dynamicForm.validationBlocking(true);
      const formValues: any = this.dynamicForm.getValue();

      const outputMessages = [];

      for (const key of keys) {
        const field: FormField = this.dynamicForm.getField(key);

        if (!!field) {
          console.log('autoProfileClicked(): Processing field with key: ' + key + '\t\tField details: ' + JSON.stringify(field, null, '\t'));

          const hits: any[] = result[key];
          const firstHit: any = hits[0];
          const firstValue: any = firstHit.value;

          if (!field.parentField) {
            this.dynamicForm.updateControlValue(key, firstValue, true);
            this.dynamicForm.setFieldDesc(key, firstHit.desc);
            outputMessages.push({field: key, oldValue: formValues[key], newValue: firstValue});
            const uniqueHits = [];
            for (const hit of hits) {
              if (!uniqueHits.some(uniqueHit => uniqueHit.value === hit.value && uniqueHit.DESCRIPTION === hit.DESCRIPTION)) {
                uniqueHits.push(hit);
              }
            }

            field.hits = uniqueHits;
            field.autoValidated = true;
            field.lastLookupValue = firstValue;

            const childHits: any[] = result[field.childField];

            if (field.childField) {
              this.dynamicForm.updateControlValue(field.childField, '', true);
              this.dynamicForm.setFieldDesc(field.childField, '');
              const childField: FormField = this.dynamicForm.getField(field.childField);

              childField.hits = [];
              childField.autoValidated = true;
              childField.lastLookupValue = '';

              if (childHits) {
                for (const childHit of childHits) {
                  if (childHit.parent === firstValue) {
                    const firstChildHit: any = childHit.value[0];
                    this.dynamicForm.updateControlValue(field.childField, firstChildHit.value, true);
                    this.dynamicForm.setFieldDesc(field.childField, firstChildHit.desc);

                    outputMessages.push({field: field.childField, oldValue: formValues[field.childField], newValue: firstChildHit.value});
                    childField.hits = childHits;
                    childField.autoValidated = true;
                    childField.lastLookupValue = firstChildHit.value;
                    break;
                  }
                }
              }
            }
          }
        } else {
          console.log('autoProfileClicked(): Could not find field with key: ' + key);
        }
      }

      ['CLIENT_ID', 'MATTER_ID', 'TYPE_ID'].forEach(key => {
        const formField = this.dynamicForm.getField(key);
        const message = outputMessages.filter(outputMessage => outputMessage.field === key);

        if (message.length > 0) {
          if (message[0].oldValue && message[0].oldValue !== message[0].newValue) {
            formField.statusMessage = this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.FIELD.CHANGE', [message[0].oldValue]);
            return;
          }
          formField.statusMessage = this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.FIELD.MATCH', [message[0].newValue]);
          return;
        }

        // If the results returned all the fields it was checking on or this case was not required, we wouldn't need the hardcoded array.
        formField.statusMessage = this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.FIELD.NO_MATCH', [formField.label]);
      });

      setTimeout(() => {
        this.dynamicForm.validationBlocking(false);
        this.setLoading(false);
      }, 300);
    };
    const doAutoProfile = (input: any): void => {
      this.profileService.getSuggestions(input).then(data => {
        doneHandler(data);
      }, err => {
        errHandler(err);
      });
    };
    const sendToAutoProfile = (addinType: string, lib: string, formName: string, textStr: string): void => {
      Util.RestAPI.getSmartProfilingData(lib, formName).toPromise().then((smartProfilingData: any) => {
        console.log('autoProfileClicked(): [' + addinType + '] Sending ' + this.getTextLength(textStr) + ' characters of text to smart profiling: "' + this.getTextForLogging(textStr) + '"');
        const profilingObj = new Object({text: textStr, smartProfilingData});
        doAutoProfile(profilingObj);
      }, error => {
        errHandler(error);
      });
    };
    if (Util.Device.bIsOfficeAddinWord) {
      const addinType = 'MS WORD';
      console.log('autoProfileClicked(): This is a [' + addinType + '] add-in.');
      Word.run(context => {
        const body = context.document.body;
        context.load(body, 'text');
        return context.sync().then(() => {
          sendToAutoProfile(addinType, this.desc.lib, this.getFormName(), body.text);
        });
      }).catch(error => {
        errHandler(error);
      });
    } else if (Util.Device.bIsOfficeAddinOutlook) {
        const addinType = 'OUTLOOK';
        const NEWLINE = '\r\n';
        console.log('autoProfileClicked(): This is an [' + addinType + '] add-in.');
        const addMailAddress = (label: string, addrs: any[]): string  => {
          let addrsStr = '';
          if (addrs && addrs.length) {
            for (const addr of addrs) {
              addrsStr += label + ': ' + addr.displayName + ' <' + addr.emailAddress + '>' + NEWLINE;
            }
          }
        return addrsStr;
      };
      const addAttachments = (attachments: any[]): string  => {
          let attachmentsStr = '';
          if (attachments && attachments.length) {
            attachmentsStr += 'ATTACHMENT_COUNT: ' + attachments.length + NEWLINE;
            for (const attachment of attachments) {
              //TODO for attachment.id / attachment.contentType / attachment.size / attachment.attachmentType / attachment.isInline
              attachmentsStr += 'ATTACHMENT_NAME: ' + attachment.name + NEWLINE;
            }
          }
        return attachmentsStr;
      };
      const outlookItem = Office.context.mailbox.item;
      outlookItem.body.getAsync('text', asyncResult => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          //TODO for emails APP_ID / PARENTMAIL_ID / EMAIL_RECEIVED ? / MSG_ITEM: '1' / ATTACH_NUM
          const internetMessageId: string = outlookItem.internetMessageId.startsWith('<') ? outlookItem.internetMessageId : ('<' + outlookItem.internetMessageId + '>');
          sendToAutoProfile(addinType, this.desc.lib, this.getFormName(), 'OUTLOOK_ITEM_CLASS: ' + outlookItem.itemClass + NEWLINE + 'EMAIL_ID: ' + internetMessageId + NEWLINE + addMailAddress('EMAIL_FROM', outlookItem.from) + 'EMAIL_DATE_TIME_CREATED: ' + outlookItem.dateTimeCreated + NEWLINE + 'EMAIL_DATE_TIME_MODIFIED: ' + outlookItem.dateTimeModified + NEWLINE + addMailAddress('EMAIL_TO', outlookItem.to) + addMailAddress('EMAIL_CC', outlookItem.cc) + addMailAddress('EMAIL_BCC', outlookItem.bcc) + 'EMAIL_NORMALIZED_SUBJECT: ' + outlookItem.normalizedSubject + NEWLINE + addAttachments(outlookItem.attachments) + asyncResult.value);
        } else {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.log('autoProfileClicked(): [' + addinType + '] Failed to retrieve email body text content. ERROR: ' + asyncResult.error.message);
          }
        }
      });
    } else {
      //Log this in case we need to know why the smart profiling is not getting the actual text we expect
      console.log('autoProfileClicked(): Error: Could not retrieve document text for smart profiling. This is not a MS WORD or OUTLOOK Office add-in.');
      doAutoProfile('');
    }
  }

  private toggleExtras(): void {
    this.extrasToggled(!this.extrasShown);
  }

  private formSelectList(): SelectItem[] {
    const selItems: SelectItem[] = [];
    for (const profForm of this.profileFormList) {
      selItems.push({ value: profForm.id, display: profForm.id + ' | ' + profForm.FORM_TITLE });
    }
    return selItems;
  }

  private saveAsSelectList(): SelectItem[] {
    const items: SelectItem[] = [
      { value: 'D', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.DEFAULT') },
      { value: 'P', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.PDF') }
    ];
    if (Util.Device.bIsOfficeAddinWord) {
      items.push({ value: 'T', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.TEXT') });
    }
    return items;
  }

  private isProfilePickerDisabled(): boolean {
    return this.readOnly || this.kind.startsWith('profile_editdefs_') || ((this.createType || this.kind === 'profile_query') && !Util.RestAPI.canUserChangeProfileFormOnSave()) || ((!this.createType && this.kind !== 'profile_query') && !Util.RestAPI.canUserChangeProfileFormOnEdit());
  }

  private fillProfileFormList(forms: any[], defForm?: string): void {
    const newList: any[] = [];
    if (!!forms && !!this.profileFormList) {
      for (const form of forms) {
        for (const aForm of this.profileFormList) {
          //Look for %FORM_APPLICATION and compare it, if it is available
          const formApp: any = form['%FORM_APPLICATION'];
          if (newList.findIndex(nForm => nForm.id === aForm.id) === -1 && (!formApp || (formApp && (formApp === aForm['%FORM_APPLICATION']))) && (aForm.id === form.FORM || aForm.id === form.id)) {
            newList.push(aForm);
            break;
          }
        }
      }
    }
    if (newList.length) {
      let formName: string = null;
      if (!defForm) {
        if (this.formData && this.formData['FORM'] && !isNaN(parseInt(this.formData['FORM']))) {
          const form: any = newList.find(f => f['DOCNUM'] === this.formData['FORM']);
          formName = form ? form.id : Util.RestAPI.getProfileFormName(this.formData['FORM'], this.desc.lib);
        } else if (this.formData && this.formData['FORMNAME']) {
          if (this.kind === 'profile_savetoedocs') {
            const appType: string = this.formData['APP_ID'] ? this.formData['APP_ID'] : 'DEFAULT';
            const appProfileForms: any[] = Util.RestAPI.getProfileFormsForApp(appType);
            if (appProfileForms.length > 0) {
              formName = appProfileForms[0]['%FORM_NAME'];
              this.defForm = formName;
            }
          } else {
            formName = this.formData['FORMNAME'];
          }
       }
      }
      this.defForm = defForm || formName || newList[0].id;
      this.profileFormList = newList;
    }
  }

  private getFormDataAndFormTemplate(defForm?: string): void {
    const isProfileForm: boolean = this.kind.startsWith('profile');
    const isSearchForm: boolean = this.kind.startsWith('profile_query');
    const kind: string = isProfileForm ? 'profile' : this.kind;
    if (!!defForm) {
      this.defForm = defForm;
    } else if ((isProfileForm || isSearchForm) && !this.defForm && this.desc && this.desc['FORM_NAME']) {
      this.defForm = this.desc['FORM_NAME'];
    }
    this.setLoading(true);
    if (this.kind.startsWith('profile_editdefs_')) {
      let parts = this.kind.split('profile_editdefs_');
      parts = parts[1].split('___');
      const appIDInfo: AppIDInfo = Util.RestAPI.getDefsForAppID(parts[0], parts[1], parts[2]);
      this.formData = appIDInfo.profile_defaults;
      this.trustees = appIDInfo.trustees;
      this.formData['FORMNAME'] = parts[0];
      this.formData['APP_ID'] = parts[1];
      this.setLoading(false);
      this.setFormListLoadTemplate();
    } else {
      this.formService.getFormData(this.desc, kind, this.defForm).then((formData: any): void => {
        if (this.kind !== 'profile_query_edit') {
          this.formData = formData;
        }
        if (!isSearchForm && isProfileForm) {
          if (formData && formData.forms && formData.forms.length) {
            this.fillProfileFormList(formData.forms, this.defForm);
          } else {
            const defProfileForm: any = Util.RestAPI.getDefaultProfileForm(this.desc.lib);
            if (!!defProfileForm) {
              this.fillProfileFormList([defProfileForm]);
            }
          }
          if (this.desc.type==='folders') {
            this.applyAllChecked = !!this.formData['public_system_id'] && this.formData['public_system_id'] !=='0';
          }
        }
        this.setLoading(false);
        if (!this.profileFormList || this.profileFormList.length === 0) {
          this.setFormListLoadTemplate();
        } else {
          this.loadFormTemplate();
        }
        if (!isSearchForm && isProfileForm && formData && formData.DOCNAME) {
          this.notifyNameChanged.emit(formData.DOCNAME);
        }
        if (!isSearchForm && isProfileForm && formData && formData.IS_SHARED) {
          this.notifyShareChanged.emit(formData);
        }
      });
    }
  }

  public showProfilePicker(): boolean {
    return this.kind.startsWith('profile') && (!this.desc || ((this.desc.lib || this.desc.id === 'recentedits') && !Util.isExternalLib(this.desc.lib))) && this.profileFormList && this.profileFormList.length>0 && this.allowProfFormPicker && (!this.readOnly || this.kind === 'profile_readonly');
  }

  public showSaveAsPicker(): boolean {
    return this.kind.startsWith('profile') && !this.readOnly && this.dataFiles && this.dataFiles.length && !!this.dataFiles[0].fromSaveAsDesc;
  }

  public showLocationChooser(): boolean {
    return this.kind.startsWith('profile') && this.kind!=='profile_copy' && !!this.createType && this.createType !== 'workspaces' && !!this.desc && !Util.isExternalLib(this.desc.lib);
  }

  public locationChanged(desc: BaseDesc): void {
    const appID = this.appIDForUpload().split(Util.RestAPI.kMultiFileSeparator)[0];
    this.setLoading(true);
    Util.RestAPI.getFormInfoForAppID(appID, desc).then((ffi: FileFormInfo) => {
      if (ffi.forms.indexOf(this.defForm) === -1) {
        this.defForm = ffi.forms[0];
      }
      this.fileFormInfo = ffi;
      const libChanged = !(this.desc.lib === desc.lib);
      this.desc = desc;
      this.formChanging(libChanged, null);
      this.setLoading(false);
    });
  }

  private patchGuideText(): void {
    const formData: any = this.formData;
    const replaceGuide = (defs: any[]) => {
      for (const obj of defs) {
        if (Array.isArray(obj)) {
          replaceGuide(obj);
        } else if (obj.fldtype==='text' || obj.fldtype==='guidetext') {
          if (!obj.value || !obj.value.length) {
            const value: string = formData[obj.name];
            if (value && value.length) {
              obj.value = value;
            }
          }
        }
      }
    };
    replaceGuide(this.formTemplate.defs);
  }

  private getScriptGroup(name?: string): any {
    let scriptGroup: any;
    if (!!this.formTemplate && !!this.kind) {
      name = name || this.formTemplate.name;
      if (this.kind.startsWith(Util.kCustomFormPrefix)) {
        let formName = Util.removeCustomFormPrefix(this.kind);
        const firstUS: number = formName.indexOf('_');
        const vendor: string = formName.substring(0, firstUS);
        formName = formName.substr(firstUS+1);
        if (!!ICC && !!ICC[vendor] && !!ICC[vendor][formName]) {
          scriptGroup = ICC[vendor][formName];
        }
      } else {
        scriptGroup = FormScripts[name];
      }
    }
    return scriptGroup;
  }

  private formChanging(libChanged: boolean, formChangedName: string): void {
    this.formChanged = true;
    if (this.bMassProfileUpdate || this.desc.id === '0') {
      this.loadFormTemplate();
    } else {
      this.dynamicForm.userChangingProfileForm(libChanged, !!formChangedName);
      if (this.createType || (this.kind==='profile_copy' && libChanged)) {
        this.getNewFormTemplateDefaultsAndTemplate(formChangedName);
      } else {
        this.getFormDataAndFormTemplate(formChangedName);
      }
    }
  }

  private changeFormTemplate(selectComponent: SelectComponent): void {
    const newForm: string = selectComponent.value;
    if (this.defForm !== newForm) {
      this.defForm = newForm;
      this.formChanging(false, newForm);
    }
  }

  private getNewFormTemplateDefaultsAndTemplate(defForm: string): void {
    const loadWithFileFormInfo = (ffi: FileFormInfo) => {
      this.fileFormInfo = ffi;
      const info: FileFormInfo = this.fileFormInfo;
      const defDataKeys = Object.keys(this.formData);
      const emailData = {};
      for (const key of defDataKeys) {
        if (Util.isEmailField(key)) {
          emailData[key] = this.formData[key];
        }
      }
      const emailDataKeys = Object.keys(emailData);
      if (!!info) {
        for (let i=0; i<info.forms.length; i++) {
          if (info.forms[i] === this.defForm) {
            this.formData = Util.deepCopy(info.profile_defaults[i]);
            this.trustees = Util.deepCopy(info.trustees[i]);
            this.originalFormData = null;
            for (const key of emailDataKeys) {
              if (Util.isEmailField(key)) {
                this.formData[key] = emailData[key];
              }
            }
            break;
          }
        }
      }
      this.setFormListLoadTemplate(defForm);
      this.setLoading(false);
    };
    this.setLoading(true);
    if (Util.isFolder(this.desc.type) && noAddFolderIDs.indexOf(this.desc.id)===-1 && this.desc.type!=='fileplans') {
      Util.RestAPI.updateFileFormInfoForContainer(this.fileFormInfo, this.defForm, this.desc).then(loadWithFileFormInfo).catch(() => {
        loadWithFileFormInfo(this.fileFormInfo);
      });
    } else {
      loadWithFileFormInfo(this.fileFormInfo);
    }
  }

  private changeSaveAs(selectComponent: SelectComponent): void {
    let ext: string;
    switch (selectComponent.value) {
    case 'D':
      ext = Util.Device.bIsOfficeAddinWord ? 'docx' : Util.Device.bIsOfficeAddinExcel ? 'xlsx' : Util.Device.bIsOfficeAddinPowerPoint ? 'pptx' : undefined;
      break;
    case 'T':
      ext = Util.Device.bIsOfficeAddinWord ? 'txt' : undefined;
      break;
    case 'P':
      ext = 'pdf';
      break;
    }
    if (!!ext) {
      for (const dataFile of this.dataFiles) {
        const parts: string[] = dataFile.name.split('.');
        if (parts.length > 1) {
          parts.splice(parts.length-1,1);
        }
        parts.push(ext);
        dataFile.name = parts.join('.');
      }
      let appType = this.appIDForUpload();
      if (appType) {
        appType = appType.split(Util.RestAPI.kMultiFileSeparator)[0];
        this.dynamicForm.updateControlValue('APP_ID', appType, true);
      }
    }
  }

  private loadFormTemplate(): void {
    let lib: string = this.desc && this.desc.lib ? this.desc.lib : Util.RestAPI.getPrimaryLibrary();
    let formName: string = this.kind;
    const isProfileForm: boolean = this.kind.startsWith('profile');
    const isSearchForm: boolean = this.kind.startsWith('profile_query');
    if (isProfileForm) {
      if (isSearchForm) {
        if (!this.defForm) {
          const defSearchForm = Util.RestAPI.getDefaultSearchForm() || {};
          this.defForm = defSearchForm['%FORM_NAME'];
        }
        formName = this.defForm;
      } else if (this.desc) {
        if (this.createType === 'workspaces' || (!this.createType && this.desc.type === 'workspaces')) {
          formName = Util.RestAPI.getDefaultWorkspaceForm().id;
          this.defForm = formName;
          this.allowProfFormPicker = false;
        } else if (!this.createType && this.desc.type === 'fileplans') {
          formName = Util.RestAPI.getDefaultFilepartForm().id;
          this.defForm = formName;
          this.allowProfFormPicker = false;
        } else if (!this.createType && this.desc.type === 'boxes') {
          formName = Util.RestAPI.getDefaultBoxForm().id;
          this.defForm = formName;
          this.allowProfFormPicker = false;
        } else if (this.defForm) {
          formName = this.defForm;
        } else if (!!this.formData && !!this.formData['FORMNAME']) {
          formName = this.formData['FORMNAME'];
          this.defForm = formName;
        } else if (this.formData && this.formData['FORM'] && Util.RestAPI.getProfileFormName(this.formData['FORM'], lib)) {
          formName = Util.RestAPI.getProfileFormName(this.formData['FORM'], lib);
          this.defForm = formName;
        } else if (Util.RestAPI.getDefaultProfileForm(lib)) {
          formName = Util.RestAPI.getDefaultProfileForm(lib).id;
          this.defForm = formName;
        }
        if (Util.isExternalLib(this.desc.lib)) {
          if (this.kind === 'profile_savetoedocs') {
            // If we are saving to eDOCS all form and library setting from the item selected
            // in the external app tile must be overriden by DM server default profile form
            // and DM server default library so that the "rightWrapper" will obtain the correct
            // profile form. Hence, work with a copy leaving the original list item intact.
            this.originalDesc = Util.deepCopy(this.desc);
            this.desc =  this.originalDesc;
            const appType: string = this.desc['APP_ID'] ? this.desc['APP_ID'] : 'DEFAULT';
            const appProfileForms: any[] = Util.RestAPI.getProfileFormsForApp(appType);
            if (appProfileForms.length > 0) {
              formName = appProfileForms[0]['%FORM_NAME'];
              this.fillProfileFormList(appProfileForms, formName);
              lib =  appProfileForms[0]['lib'];
              this.defForm = formName;
              this.desc['lib'] = lib;
              this.desc['AUTHOR_ID'] =  Util.RestAPI.getUserID();
              this.formData['AUTHOR_ID'] = Util.RestAPI.getUserID();
              this.createType = 'documents';
              this.dataFiles = [];
              for (let i=0; i<this.selections.length; i++) {
                this.dataFiles[i] = new DataFile();
                this.dataFiles[i].name = this.selections[i]['DOCNAME'];
                this.dataFiles[i].url = this.selections[i]['url'] || this.selections[i]['locationUrl'];
                this.dataFiles[i].externalName = this.selections[i]['DOCNAME'];
                this.dataFiles[i].size = Number(this.selections[i].size);
              }
              Util.RestAPI.uploadFilesWithUI(null, null, this.dataFiles, this.desc);
            }
          } else {
            formName = !!this.formData['FORM'] ? this.formData['FORM'] : this.desc.id;
          }
        }
      }
    }
    this.setLoading(true);
    const postLoad = (formTemplate) => {
      this.formTemplate = formTemplate;
      const extrasShownStr = localStorage.getItem(kExtrasShownKey);
      const extrasShownPref = !!extrasShownStr ? JSON.parse(extrasShownStr) : {};
      if (this.formTemplate && this.formTemplate.defs) {
        if (isProfileForm && !isSearchForm) {
          const storageField: any = Util.FieldMappings.templateField(this.formTemplate.defs, 'STORAGE');
          if (storageField) {
            storageField.vistriggers = [{
              hidden: true,
              fields: ['RETENTION'],
              values: ['K', 'T']
            }];
          }
          if (extrasShownPref['PROFILE'] !== undefined) {
            this.formTemplate['extrasshown'] = extrasShownPref['PROFILE'];
          }
          if (!!this.formData['PD_VITAL'] && this.formData['PD_VITAL'] === 'N' && !!this.formData['PD_VREVIEW_DATE']) {
            delete this.formData['PD_VREVIEW_DATE'];
          }
          if (!this.readOnly && !this.createType && !!this.selections && this.selections.length > 1) {
            const docNameIndex = this.formTemplate.defs.findIndex(f => f.name === 'DOCNAME');
            if (docNameIndex >= 0) {
              this.formTemplate.defs.splice(docNameIndex, 1);
            }
            this.formTemplate.defs.splice(0, 0, {fldtype:'list', name:'$edx_form_list', prompt:this.localizer.getTranslation('FORMS.LOCAL.SHARE.DOCUMENTS'), listKind:'DOCUMENTS', schemaID:'FORM_DOCUMENT_LIST', flags:0x00000040});
            this.formData['DOCUMENTS'] = this.selections;
            this.applyAllChecked = true;
            this.bMassProfileUpdate = true;
          } else if (!!this.dataFiles && this.dataFiles.length && !!this.dataFiles[0].fromSaveAsDesc) {
            this.setRelateToDesc(this.dataFiles[0].fromSaveAsDesc);
          }
        } else if (isSearchForm) {
          if (extrasShownPref['SEARCH_PROFILE'] !== undefined) {
            this.formTemplate['extrasshown'] = extrasShownPref['SEARCH_PROFILE'];
          } else {
            this.formTemplate['extrasshown'] = true;
          }
        }
        if (this.kind.startsWith('profile_editdefs_')) {
          this.formTemplate.extrasshown = true;
          Util.FieldMappings.forEachTemplateField(this.formTemplate.defs, false, (field): boolean => {
            if (field.flags & 0x00000040) {
              field.flags ^= 0x00000040;
            }
            if (field.name==='DOCNAME') {
              field.flags = 0x01000000;
            }
            return true;
          });
        } else if (this.kind === '__local_checkin') {
          if ((!!this.formData['%CHECKIN_LOCATION'] && this.formData['%CHECKIN_LOCATION'].toUpperCase().startsWith('HTTP')) ||
              (!!this.formData['DOCUMENTS'][0]['LOCATION'] && this.formData['DOCUMENTS'][0]['LOCATION'].toUpperCase().startsWith('HTTP'))) {
            const locationField: any = Util.FieldMappings.templateField(this.formTemplate.defs, '%CHECKIN_LOCATION');
            locationField.flags = 0x00080000; // READ-ONLY
            if (!this.formData['%CHECKIN_LOCATION'] && this.formData['DOCUMENTS'][0]['LOCATION']) {
              this.formData['%CHECKIN_LOCATION'] = this.formData['DOCUMENTS'][0]['LOCATION'];
            }
          }
        } else if (this.kind === 'profile_savetoedocs') {
          if (!this.originalDesc) {
            // If there is no originalDesc then we are saving individual files to eDOCS and each file pops-up a new form
            // at which point the originalDesc from previous save was lost. Restore it here to current desc so that we can
            // detect any changes to the save destination as it will change this.desc but not the this.originalDesc
            this.originalDesc = Util.deepCopy(this.desc);
            this.desc =  this.originalDesc;
          }
        }
        if ((this.createType === 'documents' || this.createType === 'folders') && !this.kind.startsWith(Util.kLocalFormPrefix) && !this.kind.startsWith(Util.kCustomFormPrefix)) {
          if (this.createType === 'documents') {
            if (this.formData['STORAGE'] === 'P') {
              const field: any = Util.FieldMappings.templateField(this.formTemplate.defs, 'APP_ID');
              if (field) {
                field.flags = 0x02000000;
              }
            } else {
              if (!this.fileList && !this.filePaths && !this.dataFiles && !this.copyFileName) {
                this.formTemplate.defs.splice(0, 0, {
                  fldtype: 'edit',
                  name: '$edx_file_picker',
                  lookup: '$edx_file_picker',
                  prompt: this.localizer.getTranslation('FORMS.PLACEHOLDERS.FILE'),
                  flags: 0x00000042
                });
              } else {
                this.checkFileNameExistsInDM();
              }
            }
            if (this.desc.type === 'fileplans' && this.desc.id.startsWith('FP-FilePart')) {
              const filePartNo: string = this.desc.id.split('FP-FilePart-')[1];
              this.formData['PD_FILEPT_NO'] = filePartNo;
            }
          }
          if (!this.formData['AUTHOR_ID']) {
            this.formData['AUTHOR_ID'] = Util.RestAPI.getUserID();
            this.formData['AUTHOR_FULL_NAME'] = Util.RestAPI.getUserFullName();
          }
          this.formData['FORMNAME'] = formName;
          if (!this.originalFormData) {
            this.originalFormData = this.formData;
            this.formData = Util.RestAPI.fillInProfile(this.formTemplate, this.formData);
          } else {
            // Add original formdata to formdata.
            for (const prop in this.originalFormData) {
              this.formData[prop] = this.originalFormData[prop];
            }
            this.formData = Util.RestAPI.fillInProfile(this.formTemplate, this.formData);
          }
          this.formData['FORMNAME'] = formName;
        }
        this.patchGuideText();
        if (((isProfileForm && this.readOnly) || this.formTemplate.extrasshown) && !this.extrasShown) {
          this.showExtras(true);
        }
      }
      if (this.formChanged && (this.createType === 'documents' || this.createType === 'folders')) {
        const headerWrapper: FormWrapperComponent = this.headerWrapper;
        if (headerWrapper) {
          const scriptGroupHdr: any = this.getScriptGroup(headerWrapper.formTemplate.name);
          if (scriptGroupHdr && typeof scriptGroupHdr.init === 'function') {
            const headerInitScript = scriptGroupHdr.init.bind(scriptGroupHdr);
            headerInitScript(headerWrapper, headerWrapper.formTemplate, this.formData, headerWrapper.localizer);
          }
        }
      }
      const scriptGroup: any = this.getScriptGroup();
      if (scriptGroup && typeof scriptGroup.init === 'function') {
        const initScript = scriptGroup.init.bind(scriptGroup);
        initScript(this.customForm || this, this.formTemplate, this.formData, this.localizer);
      }
      if (scriptGroup && typeof scriptGroup.getFormData === 'function') {
        const getFormDataScript = scriptGroup.getFormData.bind(scriptGroup);
        this.formData = getFormDataScript(this.customForm || this, this.formTemplate);
      }
      this.cdr.markForCheck();
      const waitForRender = () => {
        if (!!this.dynamicForm && !!this.dynamicForm.form && !!this.dynamicForm.form.controls) {
          const keys: string[] = Object.keys(this.formData);
          for (const key of keys) {
            const field = this.dynamicForm.getField(key);
            const control: AbstractControl = this.dynamicForm.getControl(key);
            if (!!field && field.visibilityTriggers && !!control) {
              this.runVisTriggers(field, control);
            }
          }
          // Code for 16.7.1.1 (Hague) to repopulate profile defaults with additional lookup and auxiliary fields
          // This should be removed once DM server can return profile defaults with all dependent fields
          if (this.dynamicForm.formKind.startsWith('profile') || this.kind === '__local_rm_removefrombox') {
            this.dynamicForm.scanFieldsForRevalidatonOrEvaluation(this.formData);
          }
          this.setLoading(false);
        } else {
          setTimeout(waitForRender, 10);
        }
      };
      waitForRender();
    };
    if (this.formData['formTemplate']) {
      postLoad(this.formData['formTemplate']);
    } else {
      this.formService.getFormTemplate(formName, lib, isSearchForm || this.formData['STORAGE'] === 'P').then(postLoad);
    }
  }

  private destroyScript(confirmed: boolean, allData: any, dirtyData: any): any {
    if (this.formTemplate && this.formTemplate.name) {
      const scriptGroup: any = this.getScriptGroup();
      if (scriptGroup && typeof scriptGroup.destroy === 'function') {
        const destroyScript = scriptGroup.destroy.bind(scriptGroup);
        destroyScript(this.customForm || this, confirmed, allData, dirtyData, this.localizer);
      }
    }
  }

  public onResize(): void {
    setTimeout(() => {
      this.cdr.markForCheck();
      if (this.dynamicForm) {
        this.dynamicForm.onResize();
      }
    }, 1);
  }

  public setLoading(loading: boolean): void {
    this.dynamicForm.setLoading(loading);
    this.cdr.markForCheck();
  }

  public permissionChanged(newVal: string): void {
   this.notifyPermissionChanged.emit(newVal);
  }

  public inheritSecurity(formWrapper: FormWrapperComponent): void {
    this.listService.getListData(formWrapper.desc,'security',true,'LAST_EDIT_DATE',null,0,100).then(data => {
      this.dynamicForm.setSecurityList(data.list);
    });
  }

  public useDefaultTrustees(): void {
    if (this.formData) {
      const defaultTrustees: any[] = this.listService.addAuthorTypistAsTrustee(this.formData['AUTHOR_ID'], Util.RestAPI.getUserID());
      if (!!this.trustees && this.trustees.length) {
        if (Array.isArray(this.trustees[0])) {
          this.trustees = this.trustees[0];
        }
        this.trustees.forEach(t => {
          if (!defaultTrustees.find(d => d.USER_ID === t.USER_ID)) {
            defaultTrustees.push(t);
          }
        });
      }
      this.dynamicForm.setSecurityList(defaultTrustees);
    }
  }

  public editSecurity(): void {
    this.dynamicForm.doEditSecurity();
  }

  public saveSecurity(): void {
    let cmd: string = null;
    const curWindow: WindowModalComponent = Util.RestAPI.getCurWindow();
    const securityControl: AbstractControl = this.dynamicForm.getControl('SECURITY');
    const value: any = securityControl ? securityControl.value : (this.desc.type==='workspaces' || this.desc.type==='searches') ? '1' : '0';
    // Restore selected choice if it was cleared due to an error previous save (it is cleared in the error handler to force trystee list reload)
    this.desc['edx_selected_security_choice'] = value;
    if (curWindow) {
      if (value === '1') {
        cmd = 'security_save';
        curWindow.doCommand(cmd);
        this.dynamicForm.setFieldVisibility('$edx_permissions_edit', false);
      } else if (value === '2') {
        const serverData: any = {'%INHERIT_LOOKUP_SECURITY_FROM':'0'};
        this.formService.putToServer(this.desc, serverData, 'profile', null, false, null, null);
        cmd = 'security_save';
        curWindow.doCommand(cmd);
        this.dynamicForm.setFieldVisibility('$edx_permissions_edit', false);
      } else {
        curWindow.settingUnrestrictedSecurity();
        const notifyTitle: string = this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.SECURITY_REMOVED');
        const notifyErrorTitle: string = this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.SECURITY_NOT_REMOVED');
        const serverData: any = {'%INHERIT_LOOKUP_SECURITY_FROM':'-1',SECURITY:'0' ,SEC_REASON_LINK:'0'};
        this.formService.putToServer(this.desc, serverData, 'profile', null, false, notifyTitle, null, notifyErrorTitle);
        this.dynamicForm.setFieldVisibility('$edx_permissions_edit', false);
        this.securityDirty(false);
      }
      curWindow.refresh(cmd, value);
    }
  }

  public resetSecurity(): void {
    this.dynamicForm.setFieldVisibility('$edx_permissions_edit', false);
    this.dynamicForm.setFieldVisibility('$edx_permissions_cancel', false);
    const curWindow: WindowModalComponent = Util.RestAPI.getCurWindow();
    if (curWindow) {
      curWindow.refresh('security_reset');
    }
  }

  public securityDirty(dirty: boolean): void {
    const securityControl: AbstractControl = this.dynamicForm.getControl('SECURITY');
    const value: any = securityControl ? securityControl.value : (this.desc.type==='workspaces' || this.desc.type==='searches') ? '1' : '0';
    if (this.inDialog) {
      this.dynamicForm.setFieldVisibility('$edx_permissions_edit', value==='1');
    } else {
      if (!Util.Device.isPhoneLook()) {
        this.dynamicForm.setFieldVisibility('$edx_permissions_edit', dirty);
        this.dynamicForm.setFieldLabel('$edx_permissions_edit', 'FORMS.LOCAL.PERMISSIONS_SELECTOR.SAVE', true);
        this.dynamicForm.setFieldVisibility('$edx_permissions_cancel', dirty);
      }
    }
    this.bSecurityWasEdited = dirty;
    if (!!this.mainWrapper) {
      this.mainWrapper.bSecurityWasEdited = dirty;
    }
    setTimeout(() => {
      this.notifySecurityDirty.emit(dirty);
    }, dirty ? 1 : 100);
  }

  public securityReadOnly(readOnly: boolean): void {
    this.securityIsReadOnly = readOnly;
    const securityField: FormField = this.dynamicForm.getField('SECURITY');
    if (!!securityField) {
      let selectionMap: SelectItem[] = securityField.selectionMap;
      if (!!selectionMap) {
       selectionMap = selectionMap.filter(i => i.value==='1');
       securityField.selectionMap = selectionMap;
      }
      securityField.isEnabled = false;
    }
    this.permissionChanged('1');
  }

  public allowFirstFocus(): boolean {
    return !this.disableFirstFocus && !this.readOnly;
  }

  public layoutChanged(layout: string): void {
    this.formLayout = layout;
  }

  private saveProfileDefs(formName: string, appID: string, lib: string, editableData: any, allData: any, masterDynamicForm: DynamicFormComponent): Promise<boolean> {
    return new Promise<boolean>((resovle, reject) => {
      const yesBtn: string = this.localizer.getTranslation('FORMS.BUTTONS.YES');
      const notifyTitle: string = this.localizer.getTranslation('PROFILE_DEFAULTS.DEFAULT_SETTINGS');
      const description: string = Util.RestAPI.getAppIDDescription(appID);
      let notifyBody: string = this.localizer.getTranslation('PROFILE_DEFAULTS.ASK_CHANGE', [description, formName]);
      Util.Notify.confirm(notifyTitle, notifyBody, yesBtn, null, true, true, true).then(confirmed => {
        if (confirmed && confirmed.confirm) {
          if (this.bSecurityWasEdited) {
            if (allData['SECURITY'] === '1') {
              editableData['SEC_REASON_LINK'] = '1';
              editableData['_restapi'] = {security: masterDynamicForm.getSecurityList()};
            } else {
              editableData['_restapi'] = {security: []};
            }
          }
          Util.RestAPI.setProfileDefaults(formName, appID, editableData, lib).then(data => {
            notifyBody = this.localizer.getTranslation('PROFILE_DEFAULTS.DEFAULT_SAVED');
            Util.Notify.success(notifyTitle, notifyBody);
            resovle(true);
          }, error => {
            Util.Notify.warning(notifyTitle, error);
            resovle(error);
          });
        } else {
          resovle(false);
        }
      });
    });
  }

  // **** PopupCallback implementation
  popupThirdBtn(): void {
    if (this.thirdButtonIsAutoProfile) {
      this.autoProfileClicked();
    } else {
      let masterDynamicForm: DynamicFormComponent = this.dynamicForm;
      if (this.rightWrapper && this.rightWrapper.dynamicForm) {
        masterDynamicForm = this.rightWrapper.dynamicForm;
      }
      const editableData: any = masterDynamicForm.getEditableValues();
      const allData: any = masterDynamicForm.getAllData();
      const appID = allData['APP_ID'];
      const formName = this.getFormName();
      this.saveProfileDefs(formName, appID, this.desc.lib, editableData, allData, masterDynamicForm).then(() => {},err => {});
    }
  }

  popupOK(): void {
    const primaryLibUC: string = Util.RestAPI.getPrimaryLibrary().toUpperCase();
    const isPrimaryLib = !!this.desc && (!this.desc.lib || this.desc.lib.toUpperCase() === primaryLibUC);
    const isSearchForm: boolean = this.kind.startsWith('profile_query') || this.kind === (Util.kLocalFormPrefix + 'activitysearch');
    const bRMEnabled: boolean = !!Util.RestAPI.getLoginReply() && !!Util.RestAPI.getLoginReply().RM_ENABLED;
    let notifyTitle: string = null;
    let notifyBody: string = null;
    let notifyError: string = null;
    let masterDynamicForm: DynamicFormComponent = this.dynamicForm;
    if (this.rightWrapper && this.rightWrapper.dynamicForm) {
      masterDynamicForm = this.rightWrapper.dynamicForm;
    }
    const allData: any = masterDynamicForm.getAllData();
    let failedCheckedinCount = 0;
    let serverData: any = masterDynamicForm.getDirtyValue();
    let crossLibRoot: string = null;
    //When looking for documents include paper type docs also
    if (!!serverData['ITEM_TYPE'] && serverData['ITEM_TYPE'].indexOf('D') !== -1) {
      serverData['ITEM_TYPE'] += ',M';
    }
    if (serverData['APP_ID'] === 'MS OUTLOOK') {
      serverData['ITEM_TYPE'] = 'E';
      serverData['MSG_ITEM'] = '1';
    }
    if (!isSearchForm && bRMEnabled) {
      if (!!serverData['PD_VITAL']) {
        if (serverData['PD_VITAL'] === 'N') {
          serverData['%CHANGE_VITAL_STATUS_NEWSTATUS'] = 2;
          if (!!serverData['PD_VREVIEW_DATE']) {
            delete serverData['PD_VREVIEW_DATE'];
          }
        } else {
          serverData['%CHANGE_VITAL_STATUS_NEWSTATUS'] = 1;
        }
      }
      if (!!serverData['PD_VREVIEW_DATE']) {
        serverData['%CHANGE_VITAL_REVIEW_DATE'] = '1';
      }
    }
    if (this.formTemplate.localonly) {
      this.formDataChanged.emit(serverData);
      this.destroyScript(true, allData, serverData);
    } else if (this.kind.startsWith('profile_editdefs_')) {
      const includeDescriptionFields = true;
      const editableData: any = masterDynamicForm.getEditableValues(includeDescriptionFields);
      let parts = this.kind.split('profile_editdefs_');
      parts = parts[1].split('___');
      this.saveProfileDefs(parts[0], parts[1], this.desc.lib, editableData, allData, masterDynamicForm).then(() => {
        this.destroyScript(true, allData, serverData);
      }, err => {
        this.destroyScript(true, allData, serverData);
      });
    } else {
      let localFormName: string = null;
      let params: string = null;
      let version: string;
      let versionIDOverride: string;
      let attachVersion: string;
      let sendRaw = false;
      const downloadList: any[] = [];
      let queryargs: string = null;
      let formName: string;

      if (!serverData._restapi && !isSearchForm) {
        serverData._restapi = {};
      }
      if (this.kind==='profile_savetoedocs') {
        localFormName = 'savetoedocs';
      } else if (this.kind.startsWith(Util.kLocalFormPrefix)) {
        localFormName = Util.removeLocalFormPrefix(this.kind);
      } else if (this.kind.startsWith('profile')) {
        if (!isSearchForm) {
          formName = allData['form_name'] || this.getFormName();
          if (formName) {
            const formID: string = Util.RestAPI.getProfileFormID(formName);
            serverData._restapi['form_name'] = formName;
            if (formID) {
              serverData['FORM'] = formID;
            }
          }
          if (allData['SECURITY'] === '1') {
            serverData['SEC_REASON_LINK'] = '1';
            if (this.desc.type === 'workspaces' && this.desc['NEW_AUTHOR_ID']) {
              serverData._restapi['security'] = [{flag: 2, USER_ID: this.desc['NEW_AUTHOR_ID'], rights: AccessLevel.ACCESS_LEVEL_CREATOR}];
              queryargs = 'add';
            } else {
              if (!!this.createType) {
                const securityControl: AbstractControl = masterDynamicForm.getControl('SECURITY');
                if (securityControl) {
                  securityControl.markAsDirty();
                }
              }
              serverData._restapi['security'] = masterDynamicForm.getSecurityList();
            }
          } else if (allData['SECURITY'] === '2') {
            serverData['SEC_REASON_LINK'] = '2';
            serverData['%INHERIT_LOOKUP_SECURITY_FROM'] = '0';
          }
          if (this.desc['edx_selected_security_choice']) {
            // Delete this to trigger reload of trustee list if re-editing security wihtout leaving the modal view
            delete this.desc['edx_selected_security_choice'];
          }
          if (serverData.DOCNAME || serverData['X1237']) {
            if (Util.RestAPI.getCurWindow()) {
              Util.RestAPI.getCurWindow().changedName(serverData.DOCNAME  || serverData['X1237']);
            }
          }
        }
        if (this.createType) {
          if (this.createType === 'workspaces') {
            // do not send the location to create a workspace as they are no where
            // We need to find all the casees where refid is not valid
            if (!isPrimaryLib) {
              queryargs = '?library=' + this.desc.lib;
            }
            if (!serverData['DOCNAME']) {
              serverData['DOCNAME'] = serverData['X1237'];
            }
            serverData['FILE_EXTENSION'] = 'WORKSPACE';
            serverData['APP_ID'] = 'FOLDER';
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_CREATION',[serverData['DOCNAME']]);
          } else {
            if (Util.isFolder(this.desc.type) && noAddFolderIDs.indexOf(this.desc.id)===-1 && (this.createType!=='documents' || this.desc.type!=='flexfolders') && this.desc.type!=='fileplans') {
              serverData._restapi['ref'] = this.desc;
              if (!isPrimaryLib && this.createType.indexOf('?library=') === -1) {
                this.createType += '?library='+this.desc.lib;
              }
            } else if (!isPrimaryLib) {
              if (this.createType.indexOf('?library=') === -1) {
                this.createType += '?library='+this.desc.lib;
              }
              crossLibRoot = this.desc.lib;
            }
            if (this.createType.startsWith('documents')) {
              const keys: string[] = Object.keys(allData);
              for (const key of keys) {
                if (!!key && Util.isEmailField(key) && !!allData[key] && !serverData[key] && !!Util.FieldMappings.templateField(this.formTemplate.defs, key)) {
                  // add all mail fields filled in by outlook if the fields exist on the profile form
                  serverData[key] = allData[key];
                }
              }
              if (allData['STORAGE'] === 'P' || this.originalFormData['STORAGE'] === 'P') {
                serverData['STORAGE'] = 'P';
                serverData['FILE_EXTENSION'] = '%FORM_PAPER_APPLICATION';
                serverData['APP_ID'] = '';
              } else {
                const nDataFiles = this.dataFiles ? this.dataFiles.length : 0;
                if (this.relateToChecked && !this.applyAllChecked) {
                  const docObj: any = Util.decodeFileName(this.fileNameForUpload(false));
                  if (!!docObj) {
                    serverData._restapi['associate'] = { lib: docObj.lib, id: docObj.docNum };
                  } else if (!!this.relateToDesc) {
                    serverData._restapi['associate'] = { lib: this.relateToDesc.lib, id: this.relateToDesc.id };
                  }
                }
                if (nDataFiles) {
                  serverData['MSG_ITEM'] = '1';
                  const firstAttachNum: number = this.dataFiles[0].attachNum;
                  if (firstAttachNum !== undefined && firstAttachNum !== null) {
                    if (firstAttachNum===-1) {
                      serverData['ATTACH_NUM'] = '-1';
                    } else {
                      serverData['ATTACH_NUM'] = '';
                      for (let i=0; i<nDataFiles; i++) {
                        const dataFile = this.dataFiles[i];
                        const curAttachNum: number = dataFile.attachNum;
                        serverData['ATTACH_NUM'] += curAttachNum.toString();
                        if (i<nDataFiles-1) {
                          serverData['ATTACH_NUM'] += Util.RestAPI.kMultiFileSeparator;
                        }
                      }
                    }
                  }
                  Util.RestAPI.uploadDataFiles(null, serverData, this.dataFiles, false, null, !isPrimaryLib ? ('?library=' + this.desc.lib) : null);
                } else if (this.fileList) {
                  let fileListToUpload: File[];
                  if (this.applyAllChecked===false) {
                    fileListToUpload = [];
                    fileListToUpload.push(this.fileList[0]);
                  } else {
                    fileListToUpload = this.fileList;
                  }
                  const queryArgs = Util.Device.bIsOfficeAddinOutlook && fileListToUpload.length && (fileListToUpload[0].name.toUpperCase().endsWith('.MSG') || fileListToUpload[0].name.toUpperCase().endsWith('.EML')) ? 'outlook' : null;
                  Util.RestAPI.uploadFilesWithBrowser(this.createType, serverData, fileListToUpload, null, queryArgs);
                } else if (this.filePaths && (Util.Device.bIsElectron || Util.Device.bIsCordova || Util.RestAPI.hasPFTA())) {
                  let filePathsTpUpload: string[] = this.filePaths;
                  if (this.applyAllChecked===false) {
                    filePathsTpUpload = [];
                    filePathsTpUpload.push(this.filePaths[0]);
                  } else {
                    filePathsTpUpload = this.filePaths;
                  }
                  if (Util.Device.bIsElectron || Util.Device.bIsCordova) {
                    Util.RestAPI.uploadFilesWithAppWorks(filePathsTpUpload, serverData, false, false, undefined, undefined, undefined, undefined, !isPrimaryLib?this.desc.lib:undefined);
                  } else {
                    Util.RestAPI.uploadFilesWithPFTA(filePathsTpUpload, serverData);
                  }
                }
                // RestAPI takes care of upload and profile data so just get out
                this.destroyScript(true, allData, serverData);
                return;
              }
            } else {
              if (this.createType.startsWith('folders')) {
                notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_CREATION',[serverData['DOCNAME']]);
                if (this.applyAllChecked && this.desc.id !== 'public') {
                  // not in public folders but user wants the folder to be public
                  if (!!serverData._restapi['ref'] && Util.RestAPI.restAPIVersion()>=0x00200400) {
                    const curRef = serverData._restapi['ref'];
                    serverData._restapi['ref'] = [curRef, {type: 'folders', id: 'public', lib: this.desc.lib}];
                  } else {
                    serverData._restapi['ref'] = {type: 'folders', id: 'public', lib: this.desc.lib};
                  }
                } else if (!this.applyAllChecked && this.desc.id === 'public') {
                  // in pubilc folders but user does not want this to be public
                  delete serverData._restapi.ref;
                }
              }
            }
          }
        } else {
          params = 'profile';
          if (this.desc.type==='folders' && this.bApplyAllOriginalState !== undefined && this.bApplyAllOriginalState !== this.applyAllChecked) {
            setTimeout(() => {
              if (this.applyAllChecked) {
                // not in public folders but user wants the folder to be public
                const data: any = [{id:'public', type:'folders', lib:this.desc.lib}];
                Util.RestAPI.post(this.desc, data, 'references').subscribe((response) => {
                }, error => {
                  Util.Notify.error(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), error);
                });
              } else {
                // in pubilc folders but user does not want this to be public
                const query: string = 'reftype=folders&refid=public&reflib=' + this.desc.lib + '&refsysid=' + this.formData['public_system_id'];
                Util.RestAPI.delete(this.desc, 'references', query).subscribe((response) => {
                }, error => {
                  Util.Notify.error(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), error);
                });
              }
            }, 1);
          }
        }
      }
      if (localFormName) {
        let searchCriteria: any = localFormName === 'advancedsearch' ? this.createSearchCriteria(serverData) : null;
        switch (localFormName) {
          case 'url':
            this.selections = []; // ignore selections when creating a new url
            if (this.desc.type !== 'flexfolders' && this.desc.type !== 'fileplans') {
              serverData._restapi['ref'] = this.desc;
            }
            notifyTitle = this.localizer.getTranslation('FOLDER_ACTIONS.URL_CREATED');
            break;
          case 'activitysearch':
            this.formService.updateSearchCriteria(serverData, this.formTemplate);
            searchCriteria = {
              DESCRIPTION: this.formService.getSearchDescription(serverData, masterDynamicForm),
              criteria: serverData
            };
            searchCriteria['$edx_extra_query'] = '&search=activity';
            // fall thru
          case 'advancedsearch':
            setTimeout(() => {
             Util.RestAPI.navToSearchURL('folders', searchCriteria);
            }, Util.kPopupDismissMS);
            // we are navigating to not try and post or put any thing to the server that is contents of this form
            this.destroyScript(true, allData, serverData);
            return;
          case 'editsecurity':
          case 'setsecurity':
            this.selections = []; // ignore selections when setting flexfolder security
            if (this.desc.type === 'flexfolders') {
              serverData._restapi['security'] = {};
              if (allData['SECURITY'] !== '0') {
                serverData['SEC_REASON_LINK'] = '1';
                this.desc['SEC_REASON_LINK'] = '1';
                // Delete this to trigger reload of trustee list if re-editing security wihtout leaving the modal view
                delete this.desc['edx_selected_security_choice'];
                serverData._restapi['security'] = masterDynamicForm.getSecurityList();
              } else if (this.desc['SEC_REASON_LINK'] === '1' && this.desc['HAS_SECURITY'] !== '0') {
                // Has security but the form never loaded the trustee list (hence SECURITY == '0') yet OK was klicked.
                // So, take no action just exit - nothing changed !
                this.destroyScript(true, allData, serverData);
                return;
              } else {
                serverData['SEC_REASON_LINK'] = '0';
                this.desc['SEC_REASON_LINK'] = '0';
              }
              params = 'security';
              notifyTitle = this.localizer.getTranslation('FOLDER_ACTIONS.EDIT_SECURITY');
              notifyBody = this.localizer.getTranslation('FOLDER_ACTIONS.SECURITY_EDITED');
            }
            break;
          case 'createfolder':
            params = 'folders';
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_CREATION',[serverData['DOCNAME']]);
            const notifyErrorTitle: string = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.FAILED_CREATION',[serverData['DOCNAME']]);
            this.formService.postToServer(this.desc, serverData, params, queryargs, sendRaw, notifyTitle, null, notifyErrorTitle);
            this.destroyScript(true, allData, serverData);
            return;
          case 'savetoedocs':
            if (this.dataFiles && this.dataFiles.length >= 1) {
              const securityList: ListItem[] = this.dynamicForm.getSecurityList();
              const descOrCreateType: any = 'documents';
              const isCheckin: any = ((!!this.dataFiles && !!this.dataFiles[0].externalName) && (this.dataFiles[0].name !== this.dataFiles[0].externalName));
              serverData['_restapi'] = {items:[]};
              if (!!this.applyAllChecked) {
                for (let i=0; i<this.dataFiles.length; i++) {
                  serverData['_restapi'].items[i] = {LOCATION: this.dataFiles[i].url, DOCNAME: this.dataFiles[i].name};
                }
              } else {
                serverData['_restapi'].items[0] = {LOCATION: this.dataFiles[0].url, DOCNAME: this.dataFiles[0].name};
              }
              if (Util.RestAPI.getPreference('delete_original') === '1' || isCheckin) {
                serverData['_restapi']['delete_original'] = '1';
              }
              if (!Util.isSameDesc(this.desc, this.originalDesc)) {
                serverData['_restapi']['ref'] = this.desc;
                if (this.desc.lib.toUpperCase() !== primaryLibUC) {
                  queryargs = '?library=' + this.desc.lib;
                }
              }
              if (securityList && securityList.length) {
                serverData['SECURITY'] = '1';
                serverData['_restapi']['security'] = securityList;
              }
              formName = allData['form_name'] || this.getFormName();
              if (formName) {
                const formID: string = Util.RestAPI.getProfileFormID(formName);
                serverData['_restapi']['form_name'] = formName;
                if (formID) {
                  serverData['FORM'] = formID;
                }
              }
              let fileListToUpload: DataFile[] = [];
              if (!!this.applyAllChecked) {
                fileListToUpload = this.dataFiles;
              } else {
                fileListToUpload[0] = this.dataFiles[0];
              }
              Util.RestAPI.uploadFromOneDrive(descOrCreateType,serverData,fileListToUpload,params,queryargs);
              this.destroyScript(true, allData, serverData);
              return;
            }
            break;
        }
      }
      if (localFormName && this.selections && this.selections.length) {
        let sendItem = true;
        let downloadItem = false;
        let downloadQueryargs: string = null;
        let downloadPath: string = null;
        let isExternalCheckout = false;
        let formData: any = {};
        let securityList: ListItem[] = null;
        const firstItem = this.selections[0];
        switch (localFormName) {
          case 'checkout':
            let status = '%LOCK_FOR_CHECKOUT';
            const folderLoc: string = allData['$edx_folder_loc'] ? allData['$edx_folder_loc'] : 'LOCAL';
            if (folderLoc === 'LOCAL' || folderLoc === 'NONE') {
              if (folderLoc === 'LOCAL') {
                downloadPath = allData['%CHECKIN_LOCATION'];
                downloadItem = true;
              } else {
                status = '%LOCK';
              }
            } else {
              isExternalCheckout = true;
              status = '%LOCK_FOR_CHECKOUT';
              if (!serverData._restapi) {
                serverData._restapi = {};
              }
              serverData._restapi['ref'] = this.scriptData['CREATEDESC'];
            }
            serverData['%STATUS'] = status;
            if (!!allData['DOCUMENTS'] && allData['DOCUMENTS'].length && !!allData['DOCUMENTS'][0]['VERSION_ID']) {
              serverData['%VERSION_ID'] = parseInt(allData['DOCUMENTS'][0]['VERSION_ID']);
            }
            params = 'profile';
            notifyTitle = this.localizer.getTranslation('DOC_STATUS.CHECKEDOUT');
            break;
          case 'checkin':
            serverData['%STATUS'] = '%UNLOCK';
            if (allData['%CHECKIN_LOCATION'] && allData['%CHECKIN_LOCATION'].startsWith('file:')) {
              serverData['%CHECKIN_LOCATION'] = '';
            }
            notifyTitle = this.localizer.getTranslation('DOC_STATUS.CHECKEDIN');
            break;
          case 'newversion':
            notifyTitle = this.localizer.getTranslation('METADATA.VERSIONS_ACTIONS_ADD.NEW_VERSION');
            break;
          case 'rm_request':
            if (!!serverData['USER_ID']) {
              serverData['%REQUESTFOR_USERID'] = serverData['USER_ID'];
              delete serverData['USER_ID'];
            }
            break;
          case 'rm_loanrequest':
            if (!!serverData['USER_ID']) {
              serverData[ '%BORROW_USERID'] = serverData['USER_ID'];
              delete serverData['USER_ID'];
            }
            break;
          case 'export':
            sendItem = false;
            if (Util.Device.bIsElectron || Util.RestAPI.hasPFTA()) {
              downloadPath = allData['$edx_download_location'];
            }
            if (allData['$edx_download_type'] !== 'DOWNLOAD') {
              downloadQueryargs = allData['$edx_download_type'];
            }
            if (allData['$edx_version'] !== 'none') {
              version = allData['$edx_version'];
              versionIDOverride = allData['$edx_version_ID'];
              downloadItem = true;
            }
            if (allData['$edx_attachment'] !== 'none') {
              attachVersion = allData['$edx_attachment'];
              downloadItem = true;
            }
            if ((version==='all' && !!this.scriptData['VERSIONS']) || (attachVersion==='all' && !!this.scriptData['ATTACHMENTS'])) {
              const title: string = this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING_ALL');
              let zipQuery: string = (attachVersion==='all' ? (version==='all' ? '&attachments&versions' : '&attachments') : null);
              const allDocs: any[] = [];
              let extraVers: string = null;
              let dlType: string = allData['$edx_download_type'];
              let list: any[];
              if (allData['$edx_download_type'] === 'linkonly') {
                dlType = null;
                if (!!zipQuery) {
                  zipQuery += '&links';
                } else {
                  zipQuery = '&links';
                }
              }
              if (!!version) {
                list = version === 'all' || version === 'all_separate' ? this.scriptData['VERSIONS'] : this.scriptData['VERSIONS'].filter(v => v.VERSION_ID === version);
                allDocs.splice(0,0,...list);
                if (!!attachVersion && (attachVersion !== 'all' && attachVersion !== 'all_separate')) {
                  extraVers = attachVersion;
                }
              }
              if (!!attachVersion) {
                list = attachVersion === 'all' || attachVersion === 'all_separate' ? this.scriptData['ATTACHMENTS'] : this.scriptData['ATTACHMENTS'].filter(v => v.VERSION_ID === attachVersion);
                allDocs.splice(0,0,...list);
                if (!!version && (version !== 'all' && version !== 'all_separate')) {
                  extraVers = version;
                }
              }
              const exportFormData: any = { DOCUMENTS:allDocs, DOCNAME:firstItem.DOCNAME, desc:firstItem, $edx_download_type:dlType, $edx_download_location:allData['$edx_download_location'], $edx_zip_type:zipQuery, $edx_extraVers:extraVers };
              Util.Notify.progress(title,'__local_downloadzip',null,exportFormData,false,false,false).then(confirmed => { });
              // dialog takes care of download when zipping so just get out
              this.destroyScript(true, allData, serverData);
              return;
            } else if (Util.RestAPI.hasPFTA() && (version==='all' && !!this.selections && this.selections.length > 1) || (attachVersion==='all' && !!this.selections && this.selections.length > 1)) {
              const downloadDocs: any[] = [];
              let isAttachments = attachVersion==='all' && version!=='all';
              const nSelections = this.selections.length;
              let iSelection = 0;
              const getVersions = () => {
                const sel = this.selections[iSelection];
                const versDesc = {readDesc: true, desc:{id:sel.id, type:sel.type, lib:sel.lib}};
                FormScripts._shared.get_versions_or_attachments(isAttachments, this, versDesc).then(list => {
                  for (const item of list) {
                    downloadDocs.push({id:versDesc.desc.id, lib:versDesc.desc.lib, type:versDesc.desc.type, ver:item['VERSION_ID']});
                  }
                  ++iSelection;
                  if (iSelection === nSelections) {
                    if (!isAttachments && attachVersion==='all') {
                      isAttachments = true;
                      iSelection = 0;
                      getVersions();
                    } else {
                      const downloadFileName: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.DOCUMENTS')+'.zip';
                      const downloadFileData: any = {filename:downloadFileName, documents:downloadDocs};
                      let downloadQueryArgs = 'documentszip&share';
                      if (version === 'all') {
                        downloadQueryArgs += '&versions';
                      }
                      if (attachVersion === 'all') {
                        downloadQueryArgs += '&attachments';
                      }
                      Util.RestAPI.post('documents/token/zipdownload', downloadFileData, null, downloadQueryArgs).subscribe(tokenData => {
                        if (!!tokenData && tokenData.tokenid) {
                          const downloadFileUrl: string = Util.RestAPI.getServerURLOrigin() + '/documents/' + tokenData.tokenid + '?share';
                          Util.RestAPI.downloadFileUrl(downloadFileUrl, downloadPath, downloadFileName);
                        }
                      });
                    }
                  } else {
                    getVersions();
                  }
                });
              };
              getVersions();
              this.destroyScript(true, allData, serverData);
              return;
            } else {
              let sep = '';
              const addAllSep = (versOrAttachs: any[]): void => {
                // now push the same item to the selections array so the loop to download works with the versions list
                for (const vers of versOrAttachs) {
                  this.selections.push(firstItem);
                  version += sep + vers.VERSION_ID;
                  sep = ';';
                }
              };
              if (!!attachVersion) {
                if (!!version) {
                  if (version === 'all_separate' && attachVersion === 'all_separate') {
                    version = '';
                    addAllSep(this.scriptData['VERSIONS']);
                    addAllSep(this.scriptData['ATTACHMENTS']);
                  } else if (version === 'all_separate') {
                    version = attachVersion;
                    sep = ';';
                    addAllSep(this.scriptData['VERSIONS']);
                  } else if (attachVersion === 'all_separate') {
                    sep = ';';
                    addAllSep(this.scriptData['ATTACHMENTS']);
                  } else {
                    const attachParts: string[] = attachVersion.split(';');
                    const versParts: string[] = version.split(';');
                    versParts.splice(0,0,...attachParts);
                    version = versParts.join(';');
                    // now push the same item to the selections array so the loop to download works with the versions list
                    const selections: any[] = [];
                    selections.splice(0,0,...this.selections);
                    for (const attach of attachParts) {
                      selections.push(firstItem);
                    }
                    this.selections = selections;
                  }
                } else {
                  if (attachVersion === 'all_separate') {
                    version = '';
                    this.selections = [];
                    addAllSep(this.scriptData['ATTACHMENTS']);
                  } else {
                    version = attachVersion;
                  }
                }
              } else if (version === 'all_separate') {
                version = '';
                addAllSep(this.scriptData['VERSIONS']);
              }
            }
            break;
          case 'shareonedrive':
            if (allData['$edx_share_type'] === 'invite') {
              const recipients: string[] = allData['$edx_email_to'].split(',');
              serverData = {};
              serverData['roles'] = [allData['$edx_link_scope'].split('&')[0].split('=')[1]];
              serverData['message'] = allData['$edx_share_message'];
              serverData['requireSignIn'] = true;
              serverData['sendInvitation'] = true;
              serverData['recipients'] = [];
              for (const recipient of recipients) {
                serverData['recipients'].push({email: recipient});
              }
              params = 'invite';
              const item: ListItem = this.selections[0];
              const desc: BaseDesc = {id:item.id,lib:item.lib,type:item.type};
              notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.SHARE.INVITE');
              notifyBody  = this.localizer.getTranslation('FORMS.LOCAL.SHARE.INVITE_SENT');
              notifyError = this.localizer.getTranslation('FORMS.LOCAL.SHARE.INVITE_ERROR');
              this.formService.postToServer(desc, serverData, 'invite', null, false, notifyTitle, notifyBody, notifyError);
              this.destroyScript(true, allData, serverData);
              return;
            }
            // fall thru
          case 'email':
            let nSecuredDocs = 0;
            const attachments: any[] = [];
            const keys: string[] = Object.keys(serverData);
            let emailQueryArgs: string;
            let optionKey = 'DOCUMENTS';
            const bIsOutlookMB: boolean = Util.Device.bIsOfficeAddin && !!Office && !!Office.context && !!Office.context.mailbox;
            if (allData['$edx_version'] !== 'none') {
              version = allData['$edx_version'];
              if (version === 'all') {
                emailQueryArgs = 'versions';
              }
            }
            if (allData['$edx_attachment'] !== 'none') {
              attachVersion = allData['$edx_attachment'];
              if (attachVersion === 'all') {
                emailQueryArgs = !!emailQueryArgs ? (emailQueryArgs + '&attachments') : 'attachments';
              }
            }
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE_SENT');
            notifyError = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE_ERROR');
            const sendOutlookAttach = (outlookAttachments: any[]) => {
              if (Office.context.mailbox.item.addFileAttachmentAsync) {
                for (const outlookAttach of outlookAttachments) {
                  Office.context.mailbox.item.addFileAttachmentAsync(outlookAttach.url, outlookAttach.name, {asyncContext: {app:'edx'}}, result => {
                    if (result.status === Office.AsyncResultStatus.Failed) {
                      this.zone.run(() => {
                        Util.Notify.error(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), result);
                      });
                    }
                  });
                }
              } else {
                Office.context.mailbox.displayNewMessageForm( {
                  subject: this.localizer.getTranslation('FORMS.LOCAL.SHARE.SUBJECT_FORMAT', [this.localizer.getTranslation('FORMS.LOCAL.SHARE.'+optionKey), this.localizer.getTranslation('APP_TITLE')]),
                  htmlBody: '',
                  attachments: outlookAttachments
                });
              }
            };
            const doOutlookAttach = () => {
              let nTokens = 0;
              const outlookAttachments: any[] = [];
              for (const attachItem of attachments) {
                const addLink: boolean = attachItem.link;
                Util.RestAPI.getFileToken(attachItem, attachItem.ver, false, addLink && attachItem.ver === 'all').then(tokenData => {
                  if (tokenData && tokenData.url && tokenData.name) {
                    let outlookFileName: string = tokenData.name;
                    let outlookFileUrl: string = tokenData.url;
                    if (allData['$edx_share_type']==='linkonly' || addLink) {
                      outlookFileName += '.drf';
                      outlookFileUrl += '?linkonly&share';
                      optionKey = allData['$edx_share_type']==='linkonly' ? 'REFERENCES' : 'ALL';
                    } else if (allData['$edx_share_type']==='documentszip') {
                      outlookFileName += '.zip';
                      outlookFileUrl += '?documentszip&share';
                      optionKey = 'DOCUMENTS_ZIP';
                    } else if (allData['$edx_share_type']==='linkonlyzip') {
                      outlookFileName += '.zip';
                      outlookFileUrl += '?linkonlyzip&share';
                      optionKey = 'REFERENCES_ZIP';
                    } else if (allData['$edx_share_type']==='allzip') {
                      outlookFileName += '.zip';
                      outlookFileUrl += '?allzip&share';
                      optionKey = 'ALL_ZIP';
                    } else {
                      outlookFileUrl += '?share';
                    }
                    if (!!emailQueryArgs) {
                      if (emailQueryArgs.startsWith('&')) {
                        outlookFileUrl += emailQueryArgs;
                      } else {
                        outlookFileUrl += '&' + emailQueryArgs;
                      }
                    }
                    outlookAttachments.push({type: 'file', name: outlookFileName, url: outlookFileUrl, isInline: false });
                  }
                  if (++nTokens === attachments.length) {
                    sendOutlookAttach(outlookAttachments);
                  }
                });
              }
            };
            const doOutlookAttachSingleZip = (key: string) => {
              const outlookFileName: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.'+key)+'.zip';
              const fileData: any = {filename:outlookFileName, documents:attachments};
              Util.RestAPI.post('documents/token/zipdownload',fileData, null, emailQueryArgs).subscribe(tokenData => {
                if (!!tokenData && tokenData.tokenid) {
                  const outlookFileUrl: string = Util.RestAPI.getServerURLOrigin() + '/documents/' + tokenData.tokenid + '?share';
                  sendOutlookAttach([{type: 'file', name: outlookFileName, url: outlookFileUrl, isInline: false }]);
                }
              });
            };
            const doShare = () => {
              const postGetHTML = (html: string) => {
                if (!!html) {
                  formData['html'] = html;
                }
                if (localFormName !== 'shareonedrive' && Util.Device.bIsOfficeAddin && Office && Office.context && Office.context.mailbox) {
                  if (Util.RestAPI.restAPIVersion() >= 0x00160702 && (allData['$edx_share_type']==='documentszip' || allData['$edx_share_type']==='linkonlyzip' || allData['$edx_share_type']==='allzip')) {
                    optionKey = allData['$edx_share_type']==='documentszip' ? 'DOCUMENTS_ZIP' : allData['$edx_share_type']==='linkonlyzip' ? 'REFERENCES_ZIP' : 'ALL_ZIP';
                    doOutlookAttachSingleZip(allData['$edx_share_type']==='documentszip' ? 'DOCUMENTS' : allData['$edx_share_type']==='linkonlyzip' ? 'REFERENCES' : 'ALL');
                  } else {
                    doOutlookAttach();
                  }
                } else {
                  this.formService.postToServer('documents/share', formData, null, emailQueryArgs, false, notifyTitle, null, notifyError);
                }
              };
              let subject: string = formData['subject'];
              let message: string;
              if (localFormName === 'shareonedrive') {
                message = `<div>${subject}</div><br><div style="font-weight:700;">${this.localizer.getTranslation('FORMS.LOCAL.SHARE.MESSAGE')}:</div><div>${formData['message']}</div><br>${allData['$edx_share_html_link']}`;
                subject = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE_NOTIFICATION');
                delete formData['$edx_share_html_link'];
              } else {
                message = formData['message'];
              }
              Util.Transforms.formatHTMLEmail(subject, message).then(html => {
                postGetHTML(html);
              }, err => {
                postGetHTML(null);
              });
            };
            const afterAddAttachments = () => {
              formData['_restapi'] = {src: attachments};
              if (nSecuredDocs && Util.RestAPI.getPreference('warn_share_secured')==='1') {
                const title: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SECURITY_WARNING');
                const okBtn: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE');
                const body: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.CHECK_SECURE');
                Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
                  if (confirmed && confirmed.confirm) {
                    doShare();
                  }
                });
              } else {
                doShare();
              }
            };
            const addSelectionsToAttachments = () => {
              const selections = this.selections;
              const nSelections = selections.length;
              let i = 0;
              const addVersions = () => {
                const item = selections[i++];
                const afterAddItem = () => {
                  if (i < nSelections) {
                    addVersions();
                  } else {
                    afterAddAttachments();
                  }
                };
                if (item['SECURITY'] >= '1') {
                  ++nSecuredDocs;
                }
                const addVersion = (versionToAdd: string) => {
                  if (allData['$edx_share_type']!=='linkonly' && (allData['$edx_share_type']!=='linkonlyzip' || !bIsOutlookMB) && (!bIsOutlookMB || ['all', 'allzip'].indexOf(allData['$edx_share_type']) === -1 || versionToAdd !== 'all')) {
                    attachments.push({
                      ver: versionToAdd,
                      id: item.id,
                      type: item.type,
                      lib: item.lib,
                      DOCNAME: item.DOCNAME
                    });
                  }
                  if (allData['$edx_share_type']==='linkonly' || (((['all', 'allzip'].indexOf(allData['$edx_share_type']) !== -1 && (versionToAdd === 'all' || (version !== 'all' && attachVersion !== 'all'))) || allData['$edx_share_type']==='linkonlyzip') && bIsOutlookMB)) {
                    attachments.push({
                      ver: versionToAdd,
                      id: item.id,
                      type: item.type,
                      lib: item.lib,
                      DOCNAME: item.DOCNAME,
                      link: true
                    });
                  }
                };
                if (bIsOutlookMB && ((version==='all' && !!this.scriptData['VERSIONS']) || (attachVersion==='all' && !!this.scriptData['ATTACHMENTS']))) {
                  if (allData['$edx_share_type']==='linkonly' ||allData['$edx_share_type']==='linkonlyzip') {
                    addVersion('all');
                  } else {
                    let list: any[];
                    if (version==='all') {
                      list = this.scriptData['VERSIONS'];
                      if (attachVersion==='all') {
                        list.splice(0,0,...this.scriptData['ATTACHMENTS']);
                      }
                    } else {
                      list = this.scriptData['ATTACHMENTS'];
                    }
                    const nItems = list.length;
                    for (let iItem=nItems-1; iItem>=0; iItem--) {
                      const versItem = list[iItem];
                      const label: string = versItem.VERSION_LABEL.toLowerCase();
                      if (label !== 'pr1' && versItem.DOCNUMBER === item.id) {
                        addVersion(versItem.VERSION_ID);
                      }
                    }
                    if (['all', 'allzip'].indexOf(allData['$edx_share_type']) !== -1) {
                      addVersion('all');
                    }
                  }
                  if (!!version && version!=='all') {
                    addVersion(version);
                  }
                  if (!!attachVersion && attachVersion!=='all') {
                    addVersion(attachVersion);
                  }
                } else if (Util.isContainer(item.type) || version === 'C' || version === 'P') {
                  addVersion(version === 'P' ? 'P' : 'C');
                  if (!!attachVersion) {
                    addVersion(attachVersion);
                  }
                } else {
                  if (version==='all' || attachVersion==='all') {
                    addVersion('all');
                    if (version==='all' && !!attachVersion && attachVersion!=='all') {
                      addVersion(attachVersion);
                    } else if (attachVersion==='all' && !!version && version!=='all') {
                      addVersion(version);
                    }
                  } else {
                    if (!!version) {
                      addVersion(version);
                    }
                    if (!!attachVersion) {
                      addVersion(attachVersion);
                    }
                  }
                }
                afterAddItem();
              };
              addVersions();
            };
            for (let key of keys) {
              if (key === '$edx_version' || key === '$edx_attachment') {
                continue;
              }
              let data: any = serverData[key];
              if (key.startsWith('$edx_')) {
                if (key.startsWith('$edx_email')) {
                  data = data.replace(/\s/g, '');
                }
                const parts: string[] = key.split('_');
                key = parts[parts.length-1];
              }
              formData[key] = data;
            }
            addSelectionsToAttachments();
            // dialog takes care of sharing so just get out
            this.destroyScript(true, allData, serverData);
            return;
          case 'copy':
            const createDesc = this.scriptData['CREATEDESC'];
            securityList = this.rightWrapper.dynamicForm.getSecurityList();
            if (this.rightWrapper && this.rightWrapper.dynamicForm) {
              serverData = this.rightWrapper.dynamicForm.getDirtyAndRequiredValue();
              if (!serverData._restapi) {
                serverData._restapi = {};
              }
            } else {
              serverData = { _restapi: {} };
            }
            if (securityList && securityList.length) {
              serverData['SECURITY'] = '1';
              serverData._restapi['security'] = securityList;
            }
            if (createDesc) {
              if (this.desc.type!=='flexfolders' && this.desc.type!=='fileplans' && createDesc.type!=='fileplans' && noAddFolderIDs.indexOf(createDesc.id)===-1) {
                serverData._restapi['ref'] = createDesc;
                if (!isPrimaryLib) {
                  queryargs = '?library=' + createDesc.lib;
                }
              } else if (createDesc.lib.toUpperCase() !== primaryLibUC) {
                queryargs = '?library=' + createDesc.lib;
                crossLibRoot = createDesc.lib;
              }
            }
            serverData['DOCNAME'] = this.dynamicForm.getFieldValue('DOCNAME');
            if (this.desc['ITEM_TYPE']==='W') {  // workspace
              const wsForm: any = Util.RestAPI.getDefaultWorkspaceForm();
              serverData['ITEM_TYPE'] = 'W';
              serverData['FILE_EXTENSION'] = 'WORKSPACE';
              serverData['APP_ID'] = 'FOLDER';
              serverData._restapi['form_name'] = wsForm.id;
              serverData['FORM'] = wsForm.SYSTEM_ID;
            } else {
              serverData['FULLTEXT'] = 'Y';
              serverData._restapi['form_name'] = this.rightWrapper.defForm;
            }
            if (this.relateToChecked && !!this.relateToDesc) {
              serverData._restapi['associate'] = { lib: this.relateToDesc.lib, id: this.relateToDesc.id };
            }
            if ( this.dynamicForm.getFieldValue('edx_copy_profile') !== 'N' && (!createDesc || createDesc.type!=='fileplans')) {
              // Copy all RM specific fields if present in the original data
              this.copyAll_RM_SpecificFields(allData,serverData);
            } else if (!!createDesc && createDesc.type==='fileplans') {
              // Copy all RM specific fields from the new file-part
              this.copyAll_RM_SpecificFields(createDesc,serverData);
            }
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.COPY.COPY');
            break;
          case 'rm_request':
            if (!serverData['%REQUEST_NOTREQUIREDAFTER_DATE'] || !!serverData['%REQUEST_NOTREQUIREDAFTER_DATE'] && serverData['%REQUEST_NOTREQUIREDAFTER_DATE'] === '') {
              serverData['%REQUEST_NOTREQUIREDAFTER_DATE'] = '1899-12-30'; // This is variant date zero
            }
            break;
          case 'url':
          case 'link':
          case 'fp_box_rm_request':
          case 'rm_vitalreviewdate':
          case 'fp_box_rm_loanrequest':
          case 'rm_loanrequest':
          case 'rm_putinbox':
          case 'rm_removefrombox':
          case 'versions_editprofile':
          case 'shareonedrive':
            break;
          default:
            Util.Notify.error('Client Coding Error','FormWrapperComponent: missing case for local form in popupOK');
            return;
        }
        for (let i=0; i<this.selections.length; i++) {
          const savedServerData: any = serverData;
          const item = this.selections[i];
          let descOrCreateType: any = this.createType;
          let filesMatchDocuments = false;
          notifyBody = item.DOCNAME;
          sendRaw = false;
          if (localFormName==='checkin') {
            let externalLocation: any = null;
            if (!!item.checkout && !!item.checkout['LOCATION'] && item.checkout['LOCATION'].toUpperCase().startsWith('HTTP')) {
              externalLocation = item.checkout['LOCATION'];
            } else if (!!item['LOCATION'] && item['LOCATION'].toUpperCase().startsWith('HTTP')) {
              externalLocation = item['LOCATION'];
            }
            const checkinKind: string = allData['$edx_new_version'];
            if (checkinKind==='NEW_DOCUMENT' && (this.fileList || this.filePaths || externalLocation)) {
              let intoThisFolder: any = Util.RestAPI.getCurListComponent() ? Util.RestAPI.getCurListComponent().desc : null;
              serverData['%STATUS'] = '%UNLOCK';
              params = 'profile';
              descOrCreateType = null;
              intoThisFolder = !!intoThisFolder && Util.isUploadTarget(intoThisFolder) && (!!intoThisFolder.id && (intoThisFolder.type!=='folders' || (intoThisFolder!=='public' && intoThisFolder!=='checkedout'))) ? intoThisFolder : null;
              if (externalLocation) {
                const extension: any = Util.Transforms.getExtensionFromAppID(this.selections[i].APP_ID);
                let docObj: any = Util.decodeFileName(this.selections[i].DOCNAME);
                if (!docObj) {
                  docObj = {name:this.selections[i].DOCNAME,ext:extension};
                }
                if (!this.dataFiles) {
                  this.dataFiles = [];
                }
                this.dataFiles[i]={name:docObj.name + '.' + docObj.ext};
                this.dataFiles[i].url = this.selections[i]['checkout']['LOCATION'];
                this.dataFiles[i].externalName = this.selections[i].DOCNAME;
                this.dataFiles[i].size = Number(this.selections[i].size);
                intoThisFolder = {id:'recentedits',type:'folders',lib:Util.RestAPI.getPrimaryLibrary()};
                this.filePaths = null;
              } else {
                this.dataFiles = null;
              }
              if (i===this.selections.length-1) {
                setTimeout(() => {
                  Util.RestAPI.uploadFilesWithUI(this.fileList, this.filePaths, this.dataFiles, intoThisFolder);
                }, 500);
              }
            } else {
              if (!Util.RestAPI.hasPFTA() && ((this.filePaths && this.filePaths.length !== this.selections.length) || (this.fileList && this.fileList.length !== this.selections.length))) {
                Util.Notify.error(this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.CHECK_IN'),this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.ERROR_FILE_MATCH'));
                this.destroyScript(true, allData, serverData);
                return;
              }
              if (this.fileList) {
                if (!filesMatchDocuments) {
                  [filesMatchDocuments, failedCheckedinCount] = this.selectedFilesMatchSelectedDocuments();
                  if (!filesMatchDocuments) {
                    const notifyBodyNoMatch: string = this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.ERROR_FILE_COUNT',[failedCheckedinCount.toString()]);
                    Util.Notify.error(this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.CHECK_IN'),notifyBodyNoMatch);
                    this.destroyScript(true, allData, serverData);
                    return;
                  }
                }
                // file name is such: DM16L-#33484-v77916-bands.xlsx
                formData = new FormData();
                const file = this.findCheckinDiskFile(item);
                if (!!item.checkout && !!item.checkout['VERSION_LABEL']) {
                  version = item.checkout['VERSION_LABEL'];
                } else if (!!item['VERSION_LABEL']) {
                  version = item['VERSION_LABEL'];
                }
                descOrCreateType = checkinKind==='REPLACE_VERSION' ? null : item;
                formData.append('file', file);
                formData.append('data', JSON.stringify(serverData));
                serverData = formData;
                sendRaw = true;
                if (checkinKind!=='NEW_VERSION' && version && version.length) {
                  params = 'versions/' + version;
                } else {
                  params = null;
                }
              } else if (this.filePaths && (Util.Device.bIsElectron || Util.Device.bIsCordova)) {
                // RestAPI takes care of upload and profile data so just get out
                Util.RestAPI.uploadFilesWithAppWorks(this.filePaths, serverData, checkinKind==='REPLACE_VERSION', checkinKind!=='NEW_VERSION', undefined, this.selections);
                this.destroyScript(true, allData, serverData);
                const curWindow: WindowModalComponent = Util.RestAPI.getCurWindow();
                if (!!curWindow) {
                  curWindow.refresh();
                }
                return;
              } else if (Util.RestAPI.hasPFTA() && !externalLocation) {
                Util.RestAPI.checkinDocsWithPFTA(this.selections, serverData, checkinKind==='REPLACE_VERSION', checkinKind!=='NEW_VERSION');
                this.destroyScript(true, allData, serverData);
                return;
              } else if (externalLocation) {
                formData = new FormData();
                if (checkinKind==='REPLACE_VERSION' || checkinKind==='NEW_SUB_VERSION') {
                  if (checkinKind==='NEW_SUB_VERSION') {
                    descOrCreateType = item;
                  } else {
                    descOrCreateType = null;
                  }
                  if (!!item.checkout && !!item.checkout['VERSION_LABEL']) {
                    version = item.checkout['VERSION_LABEL'];
                  } else if (!!item['VERSION_LABEL']) {
                    version = item['VERSION_LABEL'];
                  }
                } else {
                  descOrCreateType = item;
                }
                serverData['_restapi'] = {LOCATION: externalLocation, DOCNAME: item['DOCNAME']};
                formData.append('data', JSON.stringify(serverData));
                serverData = formData;
                sendRaw = true;
                if (checkinKind!=='NEW_VERSION' && version && version.length) {
                  params = 'versions/' + version;
                } else {
                  params = null;
                }
              }
            }
          } else if ((localFormName==='copy') || (localFormName==='newversion')) {
            const destName: string = serverData._restapi['ref'] ? serverData._restapi['ref'].DOCNAME : !!crossLibRoot ? crossLibRoot : Util.RestAPI.getPrimaryLibrary();
            const newName: string = serverData['DOCNAME'];
            const docNum: string = item['DOCNUMBER'] || item['DOCNUM'];
            const verLbl: string = item['VERSION_LABEL'] || item['ver'];
            notifyBody = this.localizer.getTranslation('FORMS.LOCAL.COPY.DONE',[item.DOCNAME,destName,newName]);
            version = null;
            if (localFormName==='newversion') {
              serverData._restapi = {};
              item['id'] = docNum;
              item['type'] = 'documents';
              version = item['VERSION_ID'];
              if (allData['$edx_new_version']==='NEW_VERSION') {
                params = docNum;
                if (item.lib.toUpperCase() !== primaryLibUC) {
                  queryargs = '?library=' + item.lib;
                }
                notifyBody = this.localizer.getTranslation('FORMS.LOCAL.COPY.VERSION_DONE',[verLbl]);
              } else if (allData['$edx_new_version']==='NEW_SUB_VERSION') {
                params = docNum + '/versions/' + verLbl;
                if (item.lib.toUpperCase !== primaryLibUC) {
                  queryargs = '?library=' + item.lib;
                }
                notifyBody = this.localizer.getTranslation('FORMS.LOCAL.COPY.SUB_VERSION_DONE',[verLbl]);
              }
              if (allData['%CHECKIN_LOCATION']) {
                delete serverData['%CHECKIN_LOCATION'];
                if (this.fileList) {
                  let fileName: string = allData['%CHECKIN_LOCATION'];
                  formData = new FormData();
                  formData.append('file', this.fileList[0]);
                  formData.append('data', JSON.stringify(serverData));
                  serverData = formData;
                  sendRaw = true;
                  notifyTitle = this.localizer.getTranslation('DOC_STATUS.CHECKEDIN');
                  if (fileName.startsWith('file:')) {
                    fileName = fileName.substring(5);
                  }
                  notifyBody = this.localizer.getTranslation(allData['$edx_new_version']==='NEW_VERSION'?'FORMS.LOCAL.COPY.VERSION_DONE_FILE':'FORMS.LOCAL.COPY.SUB_VERSION_DONE_FILE',[fileName]);
                } else if (this.filePaths && (Util.Device.bIsElectron || Util.Device.bIsCordova)) {
                  // RestAPI takes care of upload and profile data so just get out
                  Util.RestAPI.uploadFilesWithAppWorks(this.filePaths, serverData, false, allData['$edx_new_version']==='NEW_SUB_VERSION' && !!verLbl, verLbl, this.selections, notifyTitle);
                  this.destroyScript(true, allData, serverData);
                  return;
                }
              }
            } else {
              version = this.dynamicForm.getFieldValue('$edx_version');
            }
            if (!serverData._restapi) {
              serverData._restapi = {};
            }
            version = version || (!!item['VERSION_ID'] ? item['VERSION_ID']: 'C');
            serverData._restapi['src'] = {
              ver: version,
              id: item.id,
              type: item.type,
              lib: item.lib
            };
            descOrCreateType = item.type;
            if (localFormName==='copy' && serverData._restapi['ref']) {
              if (serverData._restapi['ref'].lib.toUpperCase() !== primaryLibUC) {
                queryargs = '?library=' + serverData._restapi['ref'].lib;
              }
              if (!serverData._restapi['ref'].id || serverData._restapi['ref'].id.substring(0,3)==='DV-') {
                // copy to root
                delete serverData._restapi['ref'];
              }
            }
          } else if (localFormName==='checkout') {
            if (isExternalCheckout) {
              if (serverData._restapi) {
                version = !!item['VERSION_ID'] ? item['VERSION_ID']: 'C';
                serverData._restapi['src'] = {
                  ver: version,
                  id: item.id,
                  type: item.type,
                  lib: item.lib
                };
                const extAppInfo: any = Util.RestAPI.findExternalApp(serverData._restapi['ref']['lib']);
                if (extAppInfo) {
                  serverData['DESCRIPTION'] = this.localizer.getTranslation('HISTORY_ACTIONS.999',[extAppInfo['name']]);
                }
              }
            } else {
              version = downloadItem && item['VERSION_ID'] ? item['VERSION_ID'] : undefined;
            }
          } else if (localFormName === 'rm_request') {
            params = 'requests/';
            descOrCreateType = item;
          } else if (localFormName === 'rm_loanrequest') {
            params = 'requests/';
            serverData['%BORROW_ITEM'] = '1';
          } else if (localFormName === 'rm_vitalreviewdate') {
            params = 'records/';
            serverData['%CHANGE_VITAL_REVIEW_DATE'] = '1';
          } else if (localFormName === 'rm_putinbox') {
            params = 'boxoperations/';
            descOrCreateType = item;
            serverData['%PUT_IN_BOX'] = serverData['PD_BOX'];
            notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.KEPT_IN_BOX');
            notifyBody = this.selections.length === 1 ? this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.PUT_IN_SUCCESS', [item['PD_FILEPT_NO'], serverData['PD_BOX']]): this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.PUT_IN_SUCCESS_MANY', [this.selections.length, serverData['PD_BOX']]);
          } else if (localFormName === 'rm_removefrombox') {
            params = 'boxoperations/';
            descOrCreateType = item;
            serverData['%REMOVE_FROM_BOX'] = serverData['PD_LOCATION_CODE'];
            notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.REMOVED_FROM_BOX');
            notifyBody = this.selections.length === 1 ? this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.REMOVE_FROM_SUCCESS', [item['PD_FILEPT_NO'], serverData['PD_LOCATION_CODE']]) : this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.REMOVE_FROM_SUCCESS_MANY', [this.selections.length, serverData['PD_LOCATION_CODE']]);
          } else if (localFormName === 'versions_editprofile') {
            params = 'versions/' + item['VERSION_ID'] + '/profile';
          }
          if (sendItem) {
            if (descOrCreateType) {
              this.formService.postToServer(descOrCreateType, serverData, params, queryargs, sendRaw, notifyTitle, notifyBody);
            } else {
              this.formService.putToServer(item, serverData, params, queryargs, sendRaw, notifyTitle, notifyBody);
            }
          }
          if (downloadItem) {
            if (Util.Device.bIsElectron || Util.Device.bIsCordova) {
              downloadList.push(item);
            } else {
              const versToUse = versionIDOverride || version;
              let singleVers: string = versToUse;
              if (!!versToUse && versToUse.indexOf(';') >= 0) {
                singleVers = versToUse.split(';')[i];
              }
              if (Util.RestAPI.hasPFTA() && localFormName === 'checkout' && !downloadPath) {
                Util.RestAPI.downloadFile(item, singleVers, downloadQueryargs, 'temp', false, true);
              } else if (Util.RestAPI.hasPFTA() && localFormName === 'export' && !downloadPath) {
                Util.RestAPI.downloadFile(item, singleVers, downloadQueryargs, 'downloads');
              } else {
                Util.RestAPI.downloadFile(item, singleVers, downloadQueryargs, downloadPath, false, localFormName === 'checkout');
              }
            }
          }
          serverData = savedServerData;
        }
        if (downloadList.length) {
          Util.RestAPI.downloadFilesWithAppWorks(downloadList, downloadPath, undefined, versionIDOverride || version, downloadQueryargs, (Util.Device.bIsElectron ? localFormName==='checkout' : false), false);
        }
      } else {
        if (this.createType || isSearchForm) {
          if (isSearchForm) {
            // if we are a search form for execute, massage the save criteria for execute
            if (this.desc.type==='forms') {
              this.formService.updateSearchCriteria(serverData, this.formTemplate);
            }
            const searchCriteria: any = {
              FORM_NAME: allData['form_name'] || this.getFormName(),
              DESCRIPTION: this.formService.getSearchDescription(serverData, masterDynamicForm),
              criteria: serverData
            };
            if (this.kind === 'profile_query_edit') {
              searchCriteria['DESCRIPTION'] = Util.Transforms.restoreQueryValue(this.desc['DESCRIPTION']||this.desc['DOCNAME']);
              notifyTitle = this.localizer.getTranslation('FOLDER_ACTIONS.EDIT_SEARCH');
              notifyBody = this.localizer.getTranslation('FOLDER_ACTIONS.EDIT_CRITERIA_SUCCESS', [searchCriteria['DESCRIPTION']]);
              params = 'profile';
              this.formService.putToServer(this.desc, searchCriteria, params, null, sendRaw, notifyTitle, notifyBody);
            } else {
              if (searchCriteria.criteria.STATUS==='0') {
                searchCriteria.criteria['READONLY'] = 'N';
              }
              setTimeout(() => {
               Util.RestAPI.navToSearchURL('folders', searchCriteria);
              }, Util.kPopupDismissMS);
            }
          } else {
            this.formService.postToServer(this.createType, serverData, params, queryargs, sendRaw, notifyTitle, notifyBody);
          }
        } else {
          if (this.bMassProfileUpdate) {
            serverData._restapi['multipletargets'] = this.selections.map(s => s.id).join(';');
            serverData._restapi['trusteesdirective'] = masterDynamicForm.trusteesDirective;
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.MASS_PROFILE.RESULTS');
            this.formService.putToServer(this.desc.type+'/multiple', serverData, params, '?library='+this.desc.lib, sendRaw, notifyTitle, notifyBody, null, 'massprofile_results');
          } else {
            this.formService.putToServer(this.desc, serverData, params, queryargs, sendRaw, notifyTitle, notifyBody);
          }
        }
      }
      this.destroyScript(true, allData, serverData);
    }
  }

  private copyAll_RM_SpecificFields(sorc: any, dest: any): void {
    // Copy all RM specific fields if present in the source data
    Util.copyFieldIfExists(sorc,dest,'PD_VREVIEW_DATE');
    Util.copyFieldIfExists(sorc,dest,'PD_VITAL');
    Util.copyFieldIfExists(sorc,dest,'PD_FILEPT_NO');
    Util.copyFieldIfExists(sorc,dest,'PD_FILE_PART');
    Util.copyFieldIfExists(sorc,dest,'PD_FILE_NAME');
    Util.copyFieldIfExists(sorc,dest,'PD_SUSPEND');
    Util.copyFieldIfExists(sorc,dest,'PD_LOCATION_CODE');
    Util.copyFieldIfExists(sorc,dest,'PD_ADDRESSEE');
    Util.copyFieldIfExists(sorc,dest,'PD_ORIGINATOR');
    Util.copyFieldIfExists(sorc,dest,'PD_ORGANIZATION');
    Util.copyFieldIfExists(sorc,dest,'X1125'); // BORROWER
    Util.copyFieldIfExists(sorc,dest,'ABSTRACT'); //COMMENT
  }

  // File-picker list of files is in arbitrary order. Find the selected file
  // that matches the dmSelectedFile
  private findCheckinDiskFile(dmFileItem: any): File {
    let i = 0;
    let file: File = null;
    let found = false;
    if (this.fileList.length > 1) {
      for (i; i<this.fileList.length; i++) {
        const fileName: string = this.fileList[i].name;
        const docObj: any = Util.decodeFileName(fileName);
        if (!!docObj) {
          if (dmFileItem.lib === docObj.lib && dmFileItem.id === docObj.docNum && (!dmFileItem['VERSION_LABEL'] || dmFileItem['VERSION_LABEL']===docObj.vers)) {
            found = true;
            break;
          }
        }
      }
    } else if (this.fileList.length === 1) {
      // For single check-in allow an arbitrary disk file name
      found = true;
    }
    if (found) {
      file = this.fileList[i];
    }
    return file;
  }

  private selectedFilesMatchSelectedDocuments(): any {
    let allMatch = true;
    let noMatchCount = 0;
    for (const sel of this.selections) {
      const file = this.findCheckinDiskFile(sel);
      if (file) {
        continue;
      } else {
        allMatch = false;
        noMatchCount = noMatchCount + 1;
      }
    }
    return [allMatch,noMatchCount];
  }

  popupCancel(): void {
    if (this.kind === 'profile' && !!this.dataFiles && this.dataFiles.length && !!this.dataFiles[0].fromSaveAsDesc && !!this.dataFiles[0].name && !!this.dataFiles[0].url) {
      const desc = this.dataFiles[0].fromSaveAsDesc;
      Util.RestAPI.monitorFile(this.dataFiles[0].name, this.dataFiles[0].url, desc.vers, desc);
    }
    this.destroyScript(false, null, null);
    Util.Notify.close();
  }

  public isOutlookMB(): boolean {
    return Util.Device.bIsOfficeAddin && !!Office && !!Office.context && !!Office.context.mailbox;
  }

  public showExtras(show: boolean): void {
    this.extrasToggled(show);
    this.extrasShownByChild.emit(show);
    this.pccExtrasShownByChild.emit(show);
  }

  public extrasToggled(shown: boolean): void {
    this.extrasShown = shown;
    if (this.dynamicForm) {
      this.dynamicForm.dynamicExtrasToggled(shown);
      this.cdr.markForCheck();
    }
    if (this.rightWrapper) {
      this.rightWrapper.extrasToggled(shown);
    }

    const isProfileForm: boolean = this.kind.startsWith('profile');
    const isSearchForm: boolean = this.kind.startsWith('profile_query');
    const extrasShownStr = localStorage.getItem(kExtrasShownKey);
    const extrasShownPref = !!extrasShownStr ? JSON.parse(extrasShownStr) : {};
    let keyForChange: string;
    if (isProfileForm && !isSearchForm) {
      keyForChange = 'PROFILE';
    } else if (isSearchForm) {
      keyForChange = 'SEARCH_PROFILE';
    }
    if (keyForChange) {
      extrasShownPref[keyForChange] = shown;
      localStorage.setItem(kExtrasShownKey, JSON.stringify(extrasShownPref));
    }
  }

  private runScript(field: FormField, control: AbstractControl, formFields: FormField[]): void {
    const trigger: FieldScript = field.scriptTrigger;
    let runIt = true;
    if (trigger.values) {
      const value: string = control.value;
      runIt = (trigger.values.indexOf(value)!==-1);
    }
    const scriptGroup: any = this.getScriptGroup();
    if (runIt && scriptGroup) {
      let script = scriptGroup[trigger.script];
      if (typeof script === 'function') {
        script = script.bind(scriptGroup);
        if (!!this.customForm) {
          script(this.customForm, field.name);
        } else {
          script(this, formFields, field, control, this.localizer);
        }
      }
    }
  }

  private runVisTriggers(field: FormField, control: AbstractControl): void {
    const triggers: Visibility[] = field.visibilityTriggers;
    const value: string = control.value;
    for (const trigger of triggers) {
      let hidden: boolean;
      if (triggers.values) {
        hidden = trigger.hidden === (trigger.values.indexOf(value)!==-1);
      } else if (field.controltype==='checkbox') {
        const on: boolean = control.value===field.checkedValue;
        hidden = trigger.hidden === !on;//element.classList.contains('checked');
      } else {
        hidden = trigger.hidden === !value;
      }
      for (const name of trigger.fields) {
        const triggeredField: FormField = this.dynamicForm.getField(name);
        if (!!triggeredField) {
          triggeredField.isVisible = !hidden;
        }
      }
    }
    this.cdr.markForCheck();
  }

  public fieldChanged(field: FormField, control: AbstractControl, formFields: FormField[]): boolean {
    let rc = true;
    if (field.visibilityTriggers || field.lookup) {
      if (field.visibilityTriggers) {
        this.runVisTriggers(field, control);
      }
      this.cdr.markForCheck();
      rc = false;
    }
    if (field.scriptTrigger && this.formTemplate && this.formTemplate.name) {
      this.runScript(field, control, formFields);
    }
    if (this.changeListener) {
      this.changeListener.fieldChanged(field, control, this);
    }
    return rc;
  }

  public updateControlValue(name: string, value: any, makeDirty?: boolean, translate?: boolean, transArgs?: string[]): void {
    this.dynamicForm.updateControlValue(name, value, makeDirty, translate, transArgs);
  }

  public buttonClicked(field: FormField, control: AbstractControl, formFields: FormField[]): void {
    if (field.scriptTrigger && this.formTemplate && this.formTemplate.name) {
      this.runScript(field, control, formFields);
    }
  }

  public uploadFiles(files: File[], filePaths?: string[]): void {
    this.fileList = files;
    this.filePaths = filePaths;
  }

  public hasUploadFiles(): boolean {
    return ((this.fileList && this.fileList.length>0) || (this.filePaths && this.filePaths.length>0));
  }

  public appIDForUpload(): string {
    let appID: string = this.copyAppID;
    let multiAppFileName: string;
    if (!appID) {
      let nFiles: number = this.fileList ? this.fileList.length : this.filePaths ? this.filePaths.length : this.dataFiles ? this.dataFiles.length : 0;
      if (nFiles) {
        nFiles = this.applyAllChecked ? nFiles : 1;
        appID = '';
        if (Util.RestAPI.restAPIVersion()<0x00160301) {
          nFiles = 1;
        }
        for (let i=0; i<nFiles; i++) {
          let name: string = null;
          if (this.fileList) {
            const file: File = this.fileList[i];
            name = file.name;
          } else if (this.filePaths) {
            name = Util.RestAPI.getFileNameFromPath(this.filePaths[i]);
          } else  if (this.dataFiles) {
            if (!!this.dataFiles[i].templateDesc && !!this.dataFiles[i].templateDesc['APP_ID']) {
              if (appID.length) {
                appID += Util.RestAPI.kMultiFileSeparator;
              }
              appID += this.dataFiles[i].templateDesc['APP_ID'];
            } else {
              name = this.dataFiles[i].name;
            }
          }
          if (!!name) {
            if (appID.length) {
              appID += Util.RestAPI.kMultiFileSeparator;
            }
            appID += Util.RestAPI.getAppIDForFile(name, !!this.desc ? this.desc.lib : Util.RestAPI.getPrimaryLibrary());
            if (nFiles > 1) {
              appID = appID.split(Util.RestAPI.kMultiAppIdSeparator)[0];
            }
            multiAppFileName = name;
          }
        }
      }
      if (!appID || !appID.length) {
        appID = 'DEFAULT';
      }
      if (nFiles === 1 && appID.indexOf(Util.RestAPI.kMultiAppIdSeparator) !== -1 && !!multiAppFileName) {
        this.fillProfileFormList(Util.RestAPI.getProfileFormsForFileExt(multiAppFileName, !!this.desc ? this.desc.lib : Util.RestAPI.getPrimaryLibrary()));
      }
    }
    return appID;
  }

  public fileNameForUpload(decodeFileName: boolean = true): string {
    let names: string = null;
    let nFiles: number = this.fileList ? this.fileList.length : this.filePaths ? this.filePaths.length : this.dataFiles ? this.dataFiles.length : 0;
    if (nFiles) {
      nFiles = this.applyAllChecked ? nFiles : 1;
      for (let i=0; i<nFiles; i++) {
        let name: string;
        if (this.fileList) {
          const file: File = this.fileList[i];
          name = file.name;
        } else if (this.filePaths) {
          name = Util.RestAPI.getFileNameFromPath(this.filePaths[i]);
          if (decodeFileName && this.kind === 'profile_checkin') {
            const docObj = Util.RestAPI.decodeFileName(name);
            if (!!docObj) {
              name = docObj.name + (!!docObj.ext ? '.' + docObj.ext : '');
            }
          }
        } else  if (this.dataFiles) {
          name = this.dataFiles[i].name;
        }
        if (name) {
          const parts: string[] = name.split('.');
          if (parts.length > 1) {
            parts.splice(parts.length-1,1);
            name = parts.join('.');
          }
          name = name.replace(/,; /g,'_');//replace(', ', '__');
          if (names) {
            names += Util.RestAPI.kMultiFileSeparator + name;
          } else {
            names = name;
          }
        }
      }
    } else if (this.copyFileName) {
      names = this.copyFileName;
    }
    return names;
  }

  public enableOK(enable: boolean, dirty: boolean): void {
    this.okDisabled = !enable;
    this.okEnabled.emit({enable, dirty});
  }

  public getFormName(): string {
    return this.defForm;
  }

  public getValue(): any {
    return this.dynamicForm.getValue();
  }

  public getDirtyValue(): any {
    return this.dynamicForm.getDirtyValue();
  }

  public getDirtyAndRequiredValue(): any {
    return this.dynamicForm.getDirtyAndRequiredValue();
  }

  public setEditable(editable: boolean): void {
    if (this.readOnly===editable) {
      this.readOnly = !editable;
      this.dynamicForm.setEditable(editable);
      this.cdr.markForCheck();
    }
  }

  public revert(desc: any): void {
    const kind: string = this.kind.startsWith('profile') ? 'profile' : this.kind;
    this.formService.getFormData(desc, kind).then((formData) => {
      this.formData = formData;
      this.cdr.markForCheck();
    });
  }

  public getRightForm(): DynamicFormComponent {
    if (this.rightWrapper) {
      return this.rightWrapper.dynamicForm;
    }
    return null;
  }

  public getHeaderForm(): DynamicFormComponent {
    if (this.headerWrapper) {
      return this.headerWrapper.dynamicForm;
    }
    return null;
  }

  private validateSearchValue(valueString: string): string {
    // Split semicolon sparated values
    let semicolon = '';
    let validatedValueString = '';
    const values: any = valueString.split(';');
    for (const value of values) {
      validatedValueString += semicolon + Util.Transforms.validateQueryValue(value,'',false);
      semicolon = ';';
    }
    return validatedValueString;
  }

  public createSearchCriteria(dirtyFields): any {
    const criteria: any = {};
    let criteriaValue = '';
    let value: any;
    const AND = '[AND]'; const OR = '[OR]'; const NOT = '[NOT]';
    let validatedFields = '';

    for (const key in dirtyFields) {
      switch (key) {
        case '$edx_advsearch_exact_phrase':
        case '$edx_advsearch_all_these_words':
          validatedFields = this.validateSearchValue(dirtyFields[key]);
          value = validatedFields.replace(/;/g,AND).trim();
          if (value!=='') {
            value = '(' + value + ')';
            criteriaValue += criteriaValue === '' ? value :  AND + value;
          }
          break;
      case '$edx_advsearch_nearby_words':
          const proxValue = dirtyFields['$edx_advsearch_word_prox'] || 5;
          validatedFields = this.validateSearchValue(dirtyFields[key]);
          value = validatedFields.replace(/;/g, '[NEAR/' + proxValue + ']').trim();
        if (value!=='') {
          value = '(' + value + ')';
          criteriaValue += criteriaValue === '' ? value: AND + value;
        }
        break;
      case '$edx_advsearch_any_of_these_words':
        validatedFields = this.validateSearchValue(dirtyFields[key]);
        value = validatedFields.replace(/;/g,OR).trim();
        if (value!=='') {
          value = '(' + value + ')';
          criteriaValue += criteriaValue === '' ? value :OR + value;
        }
        break;
      case '$edx_advsearch_not_these_words':
        validatedFields = this.validateSearchValue(dirtyFields[key]);
        value = validatedFields.replace(/;/g,AND + NOT).trim();
        if (value!=='') {
          value = '(' + NOT + value + ')';
          criteriaValue += criteriaValue === '' ? value : AND + value;
        }
        break;
      }
    }
    // Removed the condition to check if criteria is empty so that search criteria can be maintained in case of empty search as well.
    criteria['FULLTEXT_CONTENT_PROFILE'] = criteriaValue;

    return { DESCRIPTION: criteriaValue, criteria };
  }
}

export interface FieldChangeListener {
  fieldChanged?(field: FormField, control: AbstractControl, formWrapper: FormWrapperComponent): void;
}
