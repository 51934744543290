
import { Component, Input, OnChanges, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef, AfterViewChecked, ChangeDetectorRef, HostListener } from '@angular/core';
import { MenuService, MenuId, MenuDef } from '../services/menu.service';
import { MenuItem } from '../models/menu-item';
import { CommandHandler, MenuItemSetter } from '../models/command-handler';
import { LocalizeService } from '../services/localize.service';
import { MenuComponent } from './menu.component';
import { TabSelectorComponent } from './tab-selector.component';
import { Util, UserInterface, OrientationListener } from '../utils/utils.module';

/**
 * Created by kevin on 2016-10-07.
 *
 * Implements a horizontal action bar with iconic and textual elements that interacts with a list
 *
 */

@Component({
  selector: 'edx-action-bar',
  styleUrls: ['action-bar.component.scss'],
  template: `
    <div class="action-bar-body" [ngClass]="{mobile: isMobileLook(), oai: officeAddin}">
      <div #container class="action-bar" [ngClass]="{mobile: isMobileLook(), oai: officeAddin, newmenu:menuID===20||menuID===27, single:isSingle()}" [style.left]="containerLeft">
        <div *ngFor="let item of items" class="actionitem" [ngClass]="{separator:item.separator && showSeparator, hidden:!item.enabled || (item.cmd === 'separator' && !showSeparator), menu:item.submenu, measuring: !layoutComplete, right:item.right, nopad:!!item.segments}" (click)="itemClick(items.indexOf(item), $event)" id="{{'edx_action_'+item.cmd}}">
          <img *ngIf="item.iconic" src="{{item.icon}}" class="action-icon" title="{{item.name}}" alt="{{item.name}}">
          <div *ngIf="item.separator"></div>
          <edx-menu *ngIf="item.submenu" [menuID]="item.submenu" [callback]="this" class="menu-button" id="edx_action_submenu"></edx-menu>
          <span *ngIf="!item.iconic && !item.submenu && !item.segments">
            {{item.name}}
          </span>
          <edx-tab-selector *ngIf="!!item.segments" [receiver]="this" [tabdefs]="item.segments" [tabID]="item.cmd" [allowOverflow]="false"></edx-tab-selector>
        </div>
        <edx-menu #submenu [menuTitle]="menuTitle" [menuIcon]="menuIcon" [menuID]=3 [callback]="this" [ngClass]="{actionitem: true, menu_button: true, hidden: !hasOverflow}" id="edx_overflow_submenu"></edx-menu>
      </div>
    </div>
  `
})

export class ActionBarComponent implements OnChanges, OnDestroy, AfterViewChecked, CommandHandler, OrientationListener {
  @ViewChild('container') container: ElementRef;
  @ViewChild('submenu') overflowMenu: MenuComponent = null;
  @ViewChildren(TabSelectorComponent) tabs: QueryList<TabSelectorComponent>;
  @Input() menuID: MenuId;
  @Input() target: CommandHandler;
  @Input() menuTitle?: string;
  @Input() menuIcon?: string;
  @Input() forceDekstopLook = false;
  static optionMenuWidth = 50;
  public officeAddin: boolean;
  public items: MenuItem[] = [];
  public hasOverflow = false;
  public containerLeft = '0';
  protected ui: UserInterface;
  private menuDef: MenuDef;
  private layoutComplete = false;
  private overflowStartIdx = -1;
  private allItems: MenuItem[] = [];
  private showSeparator = false;
  private curTab: string = null;

  constructor(private menuService: MenuService, private localizer: LocalizeService, private cdr: ChangeDetectorRef) {
    this.ui = Util.Device.ui;
    this.officeAddin = Util.Device.bIsOfficeAddin;
    ActionBarComponent.optionMenuWidth = this.ui<2 ? 50 : 108;
    Util.Device.registerOrientationListener(this);
  }

  ngOnDestroy() {
    Util.Device.deregisterOrientationListener(this);
  }

  public deviceDidRotate(isPortrait: boolean): void {  // work around iOS/Android cordova crap
    this.updateActions();
  }

  @HostListener('window:resize')
  public updateActions(): void {
    this.copyAllItems();
    this.layoutComplete = false;
    this.hasOverflow = false;
    let isNewMenuEnabled = false;
    let enabledActionsCount = 0;
    for (const itemDef of this.items) {
      if (this.target.commandEnabled && (itemDef.cmd !== 'separator' || !itemDef.enabled)) {
        itemDef.enabled = this.target.commandEnabled(itemDef.cmd);
      }
      // Check the number of actions available[Other than separator],to decide if separator is really needed.
      if (itemDef.enabled === true && itemDef.cmd !== 'separator') {
        enabledActionsCount++;
      }
      // Check if 'newmenu' action is available.
      if (itemDef.enabled === true && itemDef.cmd === 'newmenu' ) {
        isNewMenuEnabled = true;
      }
    }
    // If count of actions is zero or if the only action available is 'newmenu' then don't need separator.
    this.showSeparator = !((enabledActionsCount === 0) || (enabledActionsCount === 1 && isNewMenuEnabled));
    this.cdr.markForCheck();
  }

  ngOnChanges(): void {
    this.menuDef = this.menuID>=0 ? this.menuService.getMenu(this.menuID) : null;
    this.initActionItems();
  }

  ngAfterViewChecked(): void {
    if (!this.layoutComplete) {
      setTimeout(() => {
        this.doLayout();
      }, 1);
    }
  }

  public setCmdValue(cmd: string, value: string): void {
    //'view_mode', 'summary'
    if (this.tabs) {
      const tab: TabSelectorComponent = this.tabs.find((t: TabSelectorComponent, index: number, tabs: TabSelectorComponent[]): boolean => {
        if (t.tabID === cmd) {
          return true;
        }
        return false;
      });
      if (!!tab) {
        tab.selectTabById(value);
      }
    }
  }

  private fillOverflowMenu(): void {
    if (this.overflowStartIdx > 0) {
      this.hasOverflow = true;
      this.overflowMenu.replaceMenuItems(this.items.splice(this.overflowStartIdx));
      this.overflowStartIdx = -1;
    }
    this.layoutComplete = true;
    this.cdr.markForCheck();
  }

  private doLayout(): void {
    if (!this.layoutComplete) {
      this.hasOverflow = false;
      this.overflowStartIdx = -1;
      const tablet: boolean = Util.Device.isTabletLook();
      let availableWidth: number = this.container.nativeElement.offsetWidth;
      const menuEl = this.container.nativeElement.children;
      const widths: number[] = [];
      let lastVisibleIndex = 0;

      this.containerLeft = availableWidth>=592 && tablet && this.isMobileLook() ? 'calc(50% - 18.5rem)' : '0';
      for (const itemEl of menuEl) {
        if (itemEl.classList.contains('measuring') && typeof itemEl.offsetWidth === 'number') {
          widths.push(itemEl.offsetWidth);
          if (!itemEl.classList.contains('hidden')) {
            lastVisibleIndex = widths.length - 1;
          }
        }
      }
      const nItems: number = widths.length;
      let nVisItems = 0;
      for (let i=0; i<nItems; i++) {
        const width: number = widths[i];
        availableWidth -= width;
        if (width) {
          ++nVisItems;
        }
        if ((i < lastVisibleIndex && availableWidth < ActionBarComponent.optionMenuWidth) || availableWidth<0 || (tablet && nVisItems>4)) {
          this.overflowStartIdx = i;
          break;
        }
      }
      // swap overflow items to overflow menu
      setTimeout(() => {
        this.fillOverflowMenu();
      }, 0);
    }
  }

  private initActionItems(): void {
    this.allItems = [];
    if (this.menuDef) {
      for (const itemDef of this.menuDef.items) {
        const item: MenuItem = new MenuItem(itemDef);
        item.name = this.localizer.getTranslation(item.name);
        if (this.target.commandEnabled && (itemDef.cmd !== 'separator' || !itemDef.enabled)) {
          item.enabled = this.target.commandEnabled(item.cmd);
        }
        if (!!item.icon && !item.icon.startsWith('http') && !item.icon.startsWith('assets/images/')) {
          item.icon = 'assets/images/' + item.icon;
        }
        this.allItems.push(item);
      }
      this.updateActions();
    }
  }

  // copy all menu items into current list
  private copyAllItems(): void {
    this.items = [];
    for (const item of this.allItems) {
      this.items.push(new MenuItem(null, item));
    }
  }

  private itemClick(itemIndex: number, event: Event): void {
    if (itemIndex >= 0 && itemIndex < this.items.length) {
      const menuItem: MenuItem = this.items[itemIndex];
      const cmd: string = menuItem.cmd + ((!!menuItem.segments && !!this.curTab) ? this.curTab : '');
      if (this.target) {
        this.target.doCommand(cmd);
      }
    }
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public isSingle(): boolean {
    let nEnabled = 0;
    for (const item of this.items) {
      nEnabled += item.enabled ? 1 : 0;
      if (nEnabled>1) {
        return false;
      }
    }
    return true;
  }

  public isMobileLook(): boolean {
    return !this.forceDekstopLook && this.ui>=2;
  }

  // *** Implement commandHandler for proxying sub-commands

  public doCommand(cmd: string): boolean {
    return this.target.doCommand(cmd);
  }

  public commandEnabled(cmd: string): boolean {
    let enabled = true;
    if (this.target.commandEnabled) {
      enabled = this.target.commandEnabled(cmd);
    }
    return enabled;
  }

  public menuOpening(menuItemSetter: MenuItemSetter, id: number): void {
    if (this.target.menuOpening) {
      this.target.menuOpening(menuItemSetter, id);
    }
  }

  public menuClosing(menuItemSetter: MenuItemSetter, id: number): void {
    if (this.target.menuClosing) {
      this.target.menuClosing(menuItemSetter, id);
    }
  }

  public checkCommand(cmd: string, setChecked: boolean): boolean {
    if (this.target.checkCommand) {
      return this.target.checkCommand(cmd, setChecked);
    }
    return setChecked;
  }

  public getItems(): MenuItem[] {
    return this.items;
  }

  // TablistReceiver interface
  public tabSelected(id: string): void {
    this.curTab = id;
  }

  public tabEnabled(id: string): boolean {
    return true;
  }
}
