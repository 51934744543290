import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { animate, transition, style, state, trigger } from '@angular/animations';
import { LocalizeService } from '../services/localize.service';
import { CommandHandler } from '../models/command-handler';
import { AppComponent } from '../app.component';
import { Util, UserInterface } from '../utils/utils.module';
import { DataFile, FileFormInfo } from '../models/file-form-info';

export interface PopupCallback {
  popupCancel?(): void;
  popupOK?(): void;
  popupThirdBtn?(): void;
  extrasToggled?(shown: boolean): void;
}

@Component({
  selector: 'edx-popup',
  styleUrls: ['popup.component.scss'],
  animations: [
    trigger('showBody', [
      state('true', style({ height: '*', opacity: '1.0' })),
      state('false', style({ height: '0', opacity: '0.0'})),
      transition('false => true', animate('200ms ease-out')),
      transition('true => false', animate('200ms ease-in'))
    ])
  ],
  template: `
  <div *ngIf="!disabled && !isPhoneLook()" class="overlay" [ngClass]="{shown:shown, onpopup:onPopup(), oai:isOfficeAddin, preferences:kind==='__local_preferences', filtersb:kind==='profile_query_filter', hasfooter:hasFooter}"></div>
  <div #popup class="popup" [style.width]="getWidth()" [ngClass]="{shown:shown, readonly:disabled, mobile:ui>=2, phone:isPhoneLook(), cordova:ui>=4, ios:iOS, onpopup:onPopup(), oai:isOfficeAddin, preferences:kind==='__local_preferences', filtersb:kind==='profile_query_filter',hasfooter:hasFooter}">
    <div *ngIf="!isPhoneLook()" class="header" [ngClass]="{underpopup:underPopup()}">
      <div class="title">{{title}}</div>
      <div *ngIf="headerformOnRight" class="header2right">
        <ng-content select="[rightheader]"></ng-content>
      </div>
      <div *ngIf="levelDropdownOnRight" class="dropdown2Right">
        <ng-content select="[levelDropdown]"></ng-content>
      </div>
    </div>
    <div *ngIf="isPhoneLook() && headerformOnRight && !underPopup()" class="header2phone">
      <ng-content select="[phoneheader]"></ng-content>
    </div>
    <div *ngIf="secondtitle" class="header2 text" [ngClass]="{underpopup:underPopup()}">
      <div *ngIf="secondtitle" class="header2text">{{secondtitle}}</div>
    </div>
    <div *ngIf="headerform" class="header2" [ngClass]="{underpopup:underPopup()}">
      <ng-content select="[secondheader]"></ng-content>
    </div>
    <div [@showBody]="showBody" (@showBody.done)="animationDone($event)" class="bodycontainer" [ngClass]="{secondheader:(secondtitle||headerform||(isPhoneLook() && headerformOnRight && !underPopup()))}">
      <div #body class="body" [ngClass]="{secondheader:(secondtitle||headerform),phoneextras:isPhoneLook()&&extras&&!disabled}" id="edx_form_body">
        <ng-content></ng-content>
      </div>
      <div *ngIf="isPhoneLook() && extras && !disabled" class="extras" (click)="toggleExtras()">
        <div class="extras-caret" [ngClass]="{up:extrasShown}"></div>
        <span *ngIf="!extrasShown || !extrasAlt" class="extras-title">{{extras}}</span>
        <span *ngIf="extrasShown && extrasAlt" class="extras-title">{{extrasAlt}}</span>
      </div>
    </div>
    <div *ngIf="!isPhoneLook()" class="footer" [ngClass]="{underpopup:underPopup()}">
      <div *ngIf="extras && !disabled" class="extras" (click)="toggleExtras()">
        <div class="extras-caret" [ngClass]="{up:extrasShown}"></div>
        <span *ngIf="!extrasShown || !extrasAlt" class="extras-title">{{extras}}</span>
        <span *ngIf="extrasShown && extrasAlt" class="extras-title">{{extrasAlt}}</span>
      </div>
      <button *ngIf="!hideCancel && !disabled" class="secondary" [ngClass]="{mobile:ui>=2, oai:isOfficeAddin, edx_hidden:hideCancel}" (click)="doCancel()">{{cancel}}</button>
      <button *ngIf="!hideOK && !disabled" class="primary" [disabled]="okDisabled" [ngClass]="{mobile:ui>=2, oai:isOfficeAddin, edx_hidden:hideOK, readonly:okDisabled}" (click)="doOK()">{{ok}}</button>
      <button *ngIf="thirdtitle && !disabled" class="secondary" [ngClass]="{mobile:ui>=2, oai:isOfficeAddin, edx_hidden:!thirdtitle}" (click)="doThirdBtn()">{{thirdtitle}}</button>
    </div>
  </div>
  `
})
export class PopupComponent implements OnInit, OnDestroy, CommandHandler  {
  @Input() width?: number = 800;
  @Input() disabled?: boolean = false;
  @Input() extrasShown?: boolean = false;
  @Input() ok?: string = '';
  @Input() cancel?: string = '';
  @Input() hideOK?: boolean = false;
  @Input() hideCancel?: boolean = false;
  @Input() okDisabled?: boolean = false;
  @Input() hasFooter?: boolean = false;
  @Input() headerform?: boolean = false;
  @Input() headerformOnRight?: boolean = false;
  @Input() levelDropdownOnRight?: boolean = false;
  @Input() title?: string = '';
  @Input() secondtitle?: string;
  @Input() thirdtitle?: string;
  @Input() extras?: string;
  @Input() extrasAlt?: string;
  @Input() kind?: string = '';
  @Input() desc?: any = {};
  @Input() readOnly?: boolean = false;
  @Input() kind2?: string = null;
  @Input() desc2?: any = null;
  @Input() readOnly2?: boolean = false;
  @Input() createType?: string = null;
  @Input() trustees?: any[] = null;
  @Input() fileFormInfo?: FileFormInfo = null;
  @Input() fileList?: FileList = null;
  @Input() filePaths?: string[] = null;
  @Input() dataFiles?: DataFile[] = null;
  @Input() callback: PopupCallback;
  @Input() selections: any[] = [];
  @ViewChild('popup') popup: ElementRef;
  @ViewChild('body') body: ElementRef;
  static nPopupsShown = 0;
  static PopupStack: PopupComponent[] = [];
  public shown = false;
  public popupIndex: number;
  public showBody = 'true';
  public ui: UserInterface;
  public iOS: boolean;
  public isOfficeAddin: boolean;
  private isClosing: boolean;

  constructor(private localizer: LocalizeService) {
    this.popupIndex = PopupComponent.nPopupsShown++;
    PopupComponent.PopupStack.push(this);
    this.isClosing = false;
    this.ui = Util.Device.ui;
    this.iOS = Util.Device.bIsIOSDevice;
    this.isOfficeAddin = Util.Device.bIsOfficeAddin;
    this.enableInputs(false);
  }

  ngOnInit() {
    if (!this.isPhoneLook() && (this.kind && this.kind.startsWith('list') || this.iOS)) {
      setTimeout(() => {
        const ele = this.popup.nativeElement;
        let parentHeight = 0;
        let parent: any = ele.parentElement;
        while (parent) {
            const classList = parent.classList;
            if (classList && (classList.contains('popup') || classList.contains('window-modal'))) {
              parentHeight = parent.clientHeight;
              if (this.onPopup()) {
                parentHeight -= Util.remsToPx(this.secondtitle||this.headerform ? 7 : 4);
              }
              break;
            }
            parent = parent.offsetParent ? parent.offsetParent : parent.parentElement;
        }
        if (parentHeight === 0) {
          parentHeight = window.innerHeight * 0.80;
        }
        let height: number = parentHeight - (this.secondtitle ? 208 : 160);
        if (!this.onPopup()) {
          height = height < 400 ? 400 : height;
        }
        this.body.nativeElement.style.height = height.toString() + 'px';
      }, 1);
    }
    if (this.ok === '' && this.ok.length===0) {
      // you can pass null and only have cancel
      this.ok = this.localizer.getTranslation('FORMS.BUTTONS.OK');
    }
    if (this.cancel === '' && this.cancel.length===0) {
      // you can pass null and only have ok
      this.cancel = this.localizer.getTranslation('FORMS.BUTTONS.CANCEL');
    }
    this.shown = true;
    if (Util.Device.isMobile()) {
      const app: AppComponent = Util.RestAPI.getAppComponent();
      if (this.isPhoneLook()) {
        app.pushCommandHandler(this, 'popupok', this.hideCancel ? null : this.cancel, this.title, this.ok);
      } else {
        app.pushCommandHandler(this, 'popupok', null, null, null);
        app.maskHeader(true);
      }
      this.shown = true;
    } else {
      setTimeout(() => {
        // delay half a second for auto-close on auto-upload formwrappers
        this.shown = true;
      }, Util.kPopupDismissMS);
    }
  }

  ngOnDestroy() {
    this.isClosing = true;
    this.enableInputs(true);
    PopupComponent.nPopupsShown--;
    PopupComponent.PopupStack.splice(PopupComponent.PopupStack.indexOf(this),1);
    if (Util.Device.isMobile()) {
      const app: AppComponent = Util.RestAPI.getAppComponent();
      if (!this.isPhoneLook()) {
        app.maskHeader(false);
      }
      app.nullTopCommandHandler();
      app.navBack();
    }
  }

  static ElementInTopPopup(element: HTMLElement): boolean {
    const curPopup: PopupComponent = PopupComponent.nPopupsShown>0 ? PopupComponent.PopupStack[PopupComponent.nPopupsShown-1] : null;
    const curPopupBodyEl = curPopup && curPopup.body ? curPopup.body.nativeElement : null;
    if (curPopupBodyEl && element) {
      let curElement = element;
      while (curElement) {
        if (curElement === curPopupBodyEl) {
          return true;
        }
        curElement = curElement.parentElement;
      }
    }
    return false;
  }

  public enableInputs(enable: boolean): void {
    const inputs = document.getElementsByTagName('INPUT');
    const nInputs: number = inputs ? inputs.length : 0;
    for (let i=0; i<nInputs; i++) {
      const input = inputs[i];
      if (enable) {
        input.removeAttribute('disabled');
      } else {
        input.setAttribute('disabled','');
      }
    }
    const selects = document.getElementsByClassName('edx_select');
    const nSelects = selects ? selects.length : 0;
    for (let i=0; i<nSelects; i++) {
      selects[i].setAttribute('tabindex', enable ? '1' : '-1');
    }
  }

  public isPhoneLook(): boolean {
    return Util.Device.isPhoneLook();
  }

  @HostListener('document:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (this.popupIndex === PopupComponent.nPopupsShown-1) {
      // only handle keyup events if we are the top level popup
      if (event.which===27) {
        this.doCancel();
      } else if (event.which===13) {
        const target: any = event.target;
        if (target.type !== 'textarea') {
          if (this.okDisabled) {
            event.preventDefault();
            event.stopPropagation();
          } else {
            if (Util.Device.isMobile() && this.popupIndex===0) {
              Util.RestAPI.getAppComponent().cordovaRight();
            } else {
              this.doOK();
            }
          }
        }
      }
    }
  }

  public getWidth(): string {
    return !this.isPhoneLook() ? (this.width.toString() + 'px') : '100%';
  }

  public onPopup(): boolean {
    return !this.isPhoneLook() && this.iOS && this.popupIndex>0;
  }

  public underPopup(): boolean {
    return this.iOS && this.popupIndex===0 && PopupComponent.nPopupsShown>1;
  }

  public doCancel(): void {
    if (this.callback.popupCancel) {
      this.callback.popupCancel();
    } else if (this.callback.popupOK) {
      this.callback.popupOK();
    }
  }

  public doOK(): void {
    if (this.callback.popupOK) {
      this.callback.popupOK();
    } else if (this.callback.popupCancel) {
      this.callback.popupCancel();
    }
  }

  public doThirdBtn(): void {
    if (this.callback.popupThirdBtn) {
      this.callback.popupThirdBtn();
    }
  }

  public toggleExtras(): void {
    this.extrasShown = !this.extrasShown;
    this.showBody = 'false';
  }

  public animationDone(event: Event): void {
    if (!this.isClosing) {
      setTimeout(() => {
        if (this.callback.extrasToggled) {
          this.callback.extrasToggled(this.extrasShown);
        }
        this.showBody = 'true';
      }, 100);
    }
  }

  // PopupButtonChanger implementation
  public setOKButton(title?: string, hidden?: boolean): void {
    if (title) {
      this.ok = title;
    }
    if (hidden!==undefined) {
      this.hideOK = hidden;
    }
  }

  public setCancelButton(title?: string, hidden?: boolean): void {
    if (title) {
      this.cancel = title;
    }
    if (hidden!==undefined) {
      this.hideCancel = hidden;
    }
  }

  public setThirdButton(title?: string, hidden?: boolean): void {
    if (hidden) {
      this.thirdtitle = null;
    } else if (title) {
      this.thirdtitle = title;
    }
  }

  // CommandHandler implementation
  public doCommand(cmd: string): boolean {
    switch (cmd) {
    case 'popupok':
      if (!this.okDisabled) {
        this.doOK();
        return true;
      }
      break;
    case 'back':
      this.doCancel();
      return true;
    }
    return false;
  }

  public commandEnabled(cmd: string): boolean {
    switch (cmd) {
    case 'popupok':
      if (!this.okDisabled) {
        return true;
      }
      break;
    case 'back':
      return true;
    }
    return false;
  }
}
