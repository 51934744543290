
import { OnChanges, Input, Component, ElementRef } from '@angular/core';
import { FormField, RadioGroupInterface, RadioFieldInterface } from '../models/form-field';
import { FormGroup } from '@angular/forms';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';

@Component({
  selector: 'edx-radio-field',
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
    <div [formGroup]="form" class="controlcontainer" [ngClass]="{columnview: columnView}" [title]="label">
      <div class="checkboxlabel" [ngClass]="{readonly:viewReadonly, indialog:inDialog}">
        <input [formControlName]="field.name" value="{{value}}" type="text" [ngClass]="{checkbox:field.isRGCheckboxes, radio:!field.isRGCheckboxes, checked:isChecked}">
        <span class="checklabel" (click)="rbClicked()">{{label}}</span>
      </div>
    </div>
  `
})
export class RadioFieldComponent implements OnChanges, RadioFieldInterface {
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() value: string;
  @Input() label: string;
  @Input() radioGroup: RadioGroupInterface;
  @Input() parent?: DynamicFormFieldComponent;
  @Input() inDialog?: boolean = false;
  @Input() formReadonly?: boolean = false;
  @Input() columnView?: boolean = false;
  public isChecked = false;
  public viewReadonly = false;

  ngOnChanges() {
    let value: string = this.form.controls[this.field.name].value;
    value = value ? value.toString() : null;
    this.isChecked = (this.value === value) || (this.value === 'null' && value == null);
    this.viewReadonly = this.field.isReadonly || this.formReadonly;
  }

  public rbClicked(): boolean {
    if (this.parent && !this.viewReadonly && !this.isChecked) {
      this.isChecked = true;
      this.radioGroup.radioButtonOn(this, this.value);
    }
    return true;
  }
}
