/**
 * Created by kevin on 2016-12-07.
 *
 *  Numeric form field implementation; includes display formatting from an enumerated list when in read-only mode
 *
 */

import { Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AbstractValueAccessor, MakeProvider } from './abstract-value-accessor';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';
import { FormField } from '../models/form-field';
import { LocalizeService } from '../services/localize.service';

@Component({
  selector: 'edx-numeric-field',
  providers: [MakeProvider(NumericFieldComponent)],
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
  <div [formGroup]="form" class="controlcontainer" [title]="toolTip">
    <input *ngIf="!viewReadonly" placeholder="{{placeholder}}" [id]="field.name"
          type="number" [readonly]="viewReadonly ? true : null"
          [min]="field.minVal >= 0 ? field.minVal : null" [max]="field.maxVal > 0 ? field.maxVal : null"
          [required]="field.isRequired ? true : null" [(value)]="value" [formControlName]="field.name" (blur)="editNumberBlur($event)">
    <div class="errormessage" *ngIf="!viewReadonly">{{field.errormessage}}</div>
    <span *ngIf="viewReadonly" class="formattednumeric" [ngClass]="{columnview:columnView}">{{displayValue}}</span>
  </div>
`
})
export class NumericFieldComponent extends AbstractValueAccessor implements OnChanges {
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() placeholder: string;
  @Input() formReadonly?: boolean = false;
  @Input() columnView?: boolean = false;
  @Input() toolTip?: string = '';
  @Input() rerender = 0;
  @Input() parent: DynamicFormFieldComponent;
  public viewReadonly: boolean;
  private displayValue = '';

  constructor(private localizer: LocalizeService) {
    super();
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    this.viewReadonly = this.field.isReadonly || this.formReadonly;
    this.value = this.form.controls[this.field.name].value;
    if (this.field.lookupData !== null) {
      if (this.field.lookupData.length === 1) {
        // Single hit on lookup
        this.value = this.field.lookupData[0].id;
        this.field.lookupData = null;
      } else {
        this.value = '';
      }
      if (!!this.parent) {
        this.parent.updateControlValue(this.value, true);
      }
    }
    let display = '';
    if (typeof this.value === 'boolean') {
      this.value = this.localizer.getTranslation(this.value ? 'FORMS.BUTTONS.YES' : 'FORMS.BUTTONS.NO');
    }
    if (this.viewReadonly && (this.field.name !== 'DOCNUM' || !isNaN(+this.value))) {
      const selector: string = !!this.value ? this.value.toString() : '';
      display = selector;
      if (this.field.format && this.field.formatMap && this.field.formatMap.length) {
        for (const entry of this.field.formatMap) {
          if (entry.value === selector) {
            display = entry.display;
          }
        }
      }
      this.displayValue = display;
    }
  }

  public editNumberBlur(event: Event): void {
    const blurValue = this.form.controls[this.field.name].value;
    if (!blurValue || (blurValue && blurValue === '')) {
      if (!!this.parent) {
        this.parent.fieldChanged();
      }
    }
  }
}
